import React, { useMemo, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { withRouter } from "react-router-dom";

const PointsDetailModal = ({history, modalOpen, closeModal}) =>{
  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: "613px",
      height:'auto',
      padding:'24px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "24px",
      zIndex: "10000",
    },
  };


  return <Modal
  isOpen={modalOpen}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
> 
<Top>
      <ArrowLeftOutlined style={{cursor:'pointer', marginRight:8}} onClick={closeModal}/><span>Points Information</span> 
    </Top>
    <Title>
    What do points do?
    </Title>
    <Text>
    Points give you exclusive access to big prize pool Quests! When we do $500 or $1,000 or even $10,000 competitions it’ll only be open for players who have been competing on ArcadeQuest and have levelled up.
    </Text>
    <Text>
    This ensures that the players are experienced enough to know how to use ArcadeQuest. We also so exclusive giveaways and prizes for high level players.
    </Text>
    <Title>
    How do I get points
    </Title>
    <PointsContainer>
      <Box>
        <BoxText>
          Enter Quest
        </BoxText>
        <BoxText>
          + 5 Points
        </BoxText>
      </Box>
      <Box>
        <BoxText>
        Subscribe
        </BoxText>
        <BoxText>
        + 50 Points per mo
        </BoxText>
      </Box>
      <Box>
        <BoxText>
        Referral
        </BoxText>
        <BoxText>
        + 10 Points
        </BoxText>
      </Box>
      <Box>
        <BoxText>
        Get Tier 3 In Quest
        </BoxText>
        <BoxText>
          + 10 Points
        </BoxText>
      </Box>
      <Box>
        <BoxText>
        Get Tier 2 In Quest
        </BoxText>
        <BoxText>
        + 20 Points per mo
        </BoxText>
      </Box>
      <Box>
        <BoxText>
        Get Tier 1 In Quest
        </BoxText>
        <BoxText>
        + 35 Points
        </BoxText>
      </Box>
    </PointsContainer>
</Modal>
}

const Top = styled.div`
display:flex;
align-items:center;
font: normal normal bold 24px/20px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 20px/36px SF PRO, serif;
letter-spacing: 0px;
color: #979797;
margin-bottom:16px;
`;

const Text = styled.div`
text-align: left;
font: normal normal normal 16px/24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const PointsContainer = styled.div`
display:grid;
grid-template-columns: repeat(3, 131px);
grid-row-gap:16px;
grid-column-gap:32px;
`;

const Box = styled.div`
border-bottom:3px solid #979797;
`;

const BoxText = styled.div`
text-align: left;
font: normal normal 600 16px/36px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
`;


export default withRouter(PointsDetailModal);