import React, { useEffect, useMemo, useState }from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import Countdown from 'react-countdown';
import { Mixpanel } from '../../util/mixpanel';
import ReactPlayer from "react-player";
import { calcFunGameModePlacement, calcFunGameModeReward} from "../../util/helper";

const UserInfoAfterStart = ({quest, sortedPlayers, tiers, history, isDailyQuest, isPromoed, productInfo, companyInfo}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const isActiveSub = useMemo(() => {
    if(loggedIn && profile && profile.multiplier){
      const multiplier = profile.multiplier;
      if(multiplier.renewAt && moment(multiplier.renewAt).isAfter(moment()) && !multiplier.requestCancel){
        return true
      }
    }
    return false;
  },[profile, loggedIn]);


  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Soon</span>
    } else {
      // Render a countdown
      return <span>{minutes}:{seconds}</span>;
    }
  };

  const renderPlacement = (obj) => {
    if(obj == null){
      return "-"
    }else {
      const start = obj.start;
      const end = obj.end;
      return `${start} - ${end}`
    }
  }

  const mykills = useMemo(() => {
    const target = quest.players.find(item => item.userId === userId);
    if(target){
      return target.currentKills - target.startKills
    }else {
      return 0;
    }
  }, [quest, userId]);


  const nextUpdateTime = useMemo(() => {
    if(quest.lastUpdatedAt && quest.updatedIndex !== null){
      if(isDailyQuest){
        const time = moment(quest.lastUpdatedAt).add(30, "m").toDate();
        const key = moment(quest.lastUpdatedAt).unix();
        return {
          time,
          key
        }
      }else {
        const time = quest.updatedIndex === 0 ? moment(quest.lastUpdatedAt).add(15, "m").toDate():moment(quest.lastUpdatedAt).add(5, "m").toDate();
        const key = moment(quest.lastUpdatedAt).unix();
        return {
          time,
          key
        }
      }
    }
  }, [quest.lastUpdatedAt, isDailyQuest, quest.updatedIndex]);


  const myPlacement = useMemo(() => {
    if(mykills === 0){
      return -1
    }else if(sortedPlayers){
      const index = sortedPlayers.findIndex(item => item.userId === userId);
      if(index > -1){
        return index + 1;
      }else {
        return -1
      }
    }else {
      return -1
    }
  },[sortedPlayers, userId, mykills]);

  const myTier = useMemo(() => {
    if(mykills === 0){
      return "-"
    }else if(myPlacement > -1 && tiers) {
      if(myPlacement >= tiers.firstTier.placement.start && myPlacement <= tiers.firstTier.placement.end ){
        return 1;
      }else if(myPlacement >= tiers.secondTier.placement.start && myPlacement <= tiers.secondTier.placement.end){
        return 2;
      }else if(myPlacement >= tiers.thirdTier.placement.start && myPlacement <= tiers.thirdTier.placement.end){
        return 3;
      }else {
        return "-"
      }
    }else {
      return "-"
    }
  },[myPlacement, tiers, mykills]);

  const mixPanelTrackVideoPlay = () => {
    Mixpanel.track("Quest Promo Product Video Plays", {
      productName:productInfo.name,
      productId:productInfo.productId,
      partnerName:companyInfo.name,
      partnerId:companyInfo.partnerId,
    })
  }

  const isFunGameMode = useMemo(() => {
    if(quest.isFunGameMode){
      return true;
    }
    return false;
  }, [quest]);

  const funGameModePlacement = useMemo(() => {
    if(quest.isFunGameMode){
      return calcFunGameModePlacement(quest)
    }
    return null;
  }, [quest]);

  const funGameModeReward = useMemo(() => {
    if(quest.isFunGameMode){
      return calcFunGameModeReward(quest);
    }
    return null;
  }, [quest])


  return <Container>
     {
      isPromoed &&  <ReactPlayer
      url={productInfo?.video??""}
      playing={false}
      width="100%"
      height="auto"
      controls
      style={{marginBottom:24}}
      onStart={mixPanelTrackVideoPlay}
    />
     }
     <TopBox>
            
                <Name>{profile?.first_name?? "-"}</Name>
                <UserInfo>
                  <div>
                  {
                    isActiveSub ? "Multipler Subscriber":"Free User"
                  }
                  </div>
                  {
                    !isActiveSub && <Upgrade onClick={() => {
                      Mixpanel.track("Redirect to multiplier from creator quest page")
                      history.push("/multiplier")
                    }}>
                        Upgrade to Get One Coin for Every Two Kills
                    </Upgrade>
                  }
                </UserInfo>
          </TopBox>
          <BoxsContainer>
            <DataBox isFunGameMode={isFunGameMode}>
              <DataEntry>
                {myPlacement > -1 ? myPlacement: "-"}
              </DataEntry>
              <DataTitle>
                Placement
              </DataTitle>
            </DataBox>
            <DataBox isFunGameMode={isFunGameMode}>
              <DataEntry>
                {mykills}
              </DataEntry>
              <DataTitle>
                Kills
              </DataTitle>
            </DataBox>
            {
              !isFunGameMode &&  <DataBox style={{width:220, marginLeft:'auto', marginRight:0}} >
              <DataEntry>
                  {myTier}
                </DataEntry>
                <DataTitle>
                  Your Tier
                </DataTitle>
              </DataBox>
            }
          </BoxsContainer>
         <BoxsContainer>
          <TimeBox>
            <DataEntry>
              {moment(quest.lastUpdatedAt?? "").format("LT")}
            </DataEntry>
            <DataTitle>
            {
                  quest.updatedIndex === 0 ? "LeaderBoard Initialized" :"Last Leaderboard Update"
                }
            </DataTitle>
        </TimeBox> 
          <TimeBox style={{marginLeft:'auto'}}>
          <DataEntry style={{color:"#F72375"}}>
          <Countdown date={nextUpdateTime?.time??""} renderer={renderer} key={nextUpdateTime?.key??""}/>
              </DataEntry>
              <DataTitle>
                Next Leaderboard Update
              </DataTitle>
          </TimeBox>
         </BoxsContainer>
         {
          isFunGameMode ? <>
          <TierBox>
              <TierData>
                <DataEntry>
                  {
                    renderPlacement(funGameModePlacement?? null)
                  }
                </DataEntry>
                <DataTitle>
                 Position
                </DataTitle>
              </TierData>
              <TierData>
                <DataEntry style={{color:"#F72375"}}>
                  {funGameModeReward?? 0}
                </DataEntry>
                <DataTitle>
                <img
                src="../assets/Coin.svg"
                alt=""
                style={{
                  height: "12px",
                  marginRight: "4px",
                }}
              />   Coins They'll Win
                </DataTitle>
              </TierData>
         </TierBox>
          </> : <>
           <TierBox>
              <TierData>
                <DataEntry>
                  {
                    renderPlacement(tiers?.firstTier?.placement?? null)
                  }
                </DataEntry>
                <DataTitle>
                  Tier 1 Placement
                </DataTitle>
              </TierData>
              <TierData>
                <DataEntry style={{color:"#F72375"}}>
                  {tiers?.firstTier?.reward?? 0}
                </DataEntry>
                <DataTitle>
                <img
                src="../assets/Coin.svg"
                alt=""
                style={{
                  height: "12px",
                  marginRight: "4px",
                }}
              />   Coins They'll Win
                </DataTitle>
              </TierData>
         </TierBox>
         <TierBox>
         <TierData>
                <DataEntry>
                  {
                    renderPlacement(tiers?.secondTier?.placement?? null)
                  }
                </DataEntry>
                <DataTitle>
                  Tier 2 Placement
                </DataTitle>
              </TierData>
              <TierData>
                <DataEntry style={{color:"#F72375"}}>
                  {tiers?.secondTier?.reward?? 0}
                </DataEntry>
                <DataTitle>
                <img
                src="../assets/Coin.svg"
                alt=""
                style={{
                  height: "12px",
                  marginRight: "4px",
                }}
              />  Coins They'll Win
                </DataTitle>
              </TierData>
         </TierBox>
         <TierBox style={{marginBottom:0}}>
         <TierData>
                <DataEntry>
                  {
                    renderPlacement(tiers?.thirdTier?.placement?? null)
                  }
                </DataEntry>
                <DataTitle>
                  Tier 3 Placement
                </DataTitle>
              </TierData>
              <TierData>
                <DataEntry style={{color:"#F72375"}}>
                  {tiers?.thirdTier?.reward?? 0}
                </DataEntry>
                <DataTitle>
                <img
                src="../assets/Coin.svg"
                alt=""
                style={{
                  height: "12px",
                  marginRight: "4px",
                }}
              />  Coins They'll Win
                </DataTitle>
              </TierData>
         </TierBox>
          </>
         }
        
  </Container>
}

const Container = styled.div`
width:440px;
display:flex;
flex-direction:column;
margin-bottom:32px;
`;

const TopBox = styled.div`
width: 100%px;
height: 92px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
padding:16px;
margin-bottom:24px;
display:flex;
flex-direction:column;
`;

const Name = styled.div`
font: normal normal 600 26px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const UserInfo = styled.div`
display:flex;
width:100%;
justify-content:space-between;
font: normal normal medium 12px/18px SF PRO, serif;;
letter-spacing: 0px;
color: #B7B7B7;
margin-top:auto;
`;

const Upgrade = styled.div`
text-align: right;
text-decoration: underline;
font: normal normal medium 12px/18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
cursor:pointer;
`;

const BoxsContainer = styled.div`
width:100%;
display:flex;
margin-bottom:24px;
`;

const DataBox = styled.div`
width: 102px;
height: 67px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
margin-right:16px;
padding:6px 12px;
${p => p.isFunGameMode && `width:50%;`};
`;

const DataEntry = styled.div`
text-align: left;
font: normal normal 600 28px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const DataTitle = styled.div`
text-align: left;
font: normal normal 12px/25px SF PRO, serif;
letter-spacing: 0px;
color: #B7B7B7;
`;

const TimeBox = styled.div`
width: 215px;
height: 67px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
padding:6px 12px;
`;

const TierBox = styled.div`
height: 67px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
margin-bottom:24px;
display:flex;
padding:6px 12px;
`;

const TierData = styled.div`
display:flex;
flex-direction:column;
width:50%;
`;

export default withRouter(UserInfoAfterStart);