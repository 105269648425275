/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, Modal, Input, message, List, Typography} from "antd";
import styled from "styled-components"
import * as API from "../../util/api";
import axios from "axios";
import moment from "moment"

const TournamentPlayers = ({tournament}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const isInviteOnly = useMemo(() => {
    if(tournament.tournament_type === 1){
      return true;
    }
    return false;
  }, [tournament])


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const invitePlayer = async () => {
    try {
      if(email){
        const requestBody = {
          tournamentId:tournament.tournamentId,
          email
        }
        const url = API.TOURNAMENT_INVITE;
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          setEmail();
          setIsModalOpen(false);
          message.success("Email is added to invite list ")
        }else {
          message.error("Failed to add this email, please try again")
        }
      }else {
        message.error("Please enter email")
      }
    }catch(err){
      message.error("Failed to add this email, please try again")
    }
  }
  const removePlayer = async (item) => {
    try {
      const requestBody = {
        tournamentId:tournament.tournamentId,
        email:item
      }
      const url = API.TOURNAMENT_REMOVEINVITE;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        message.success("Email is removed from invite list ")
      }else {
        message.error("Failed to remove email, please try again")
      }
    }catch(err){
      message.error("Failed to remove email, please try again")
    }
  }

  const setTeams = async () => {
    if(tournament.teamsUp && selectedRowKeys.length === tournament.playerPerTeam) {
      try {
        const requestBody = {
          players:selectedRowKeys,
          tournamentId:tournament.tournamentId
        }
        const url = API.TOURNAMENT_TEAMSUP;
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          setSelectedRowKeys([]);
          message.success("You have added players to a team")
        }else {
          message.error("Failed to add players to a team, please try again")
        }
      }catch(err){
        message.error("Failed to add players to a team, please try again")
      }
    }else {
      message.error("Please select correct players")
    }
  }

  const checkStatus = (email) => {
    if(tournament.verifiedPlayers.some((item) => 
      item.email === email
    )){
      return true;
    }else {
      return false;
    }
  }


  const pagination = {
    pageSize: 10,
  };

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'userName',
      key:'userName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key:"email"
    },
    {
      title: 'checkIn At',
      key:'checkInAt',
      render:(record) => {
        return <text>
          {moment(record.checkInAt).format("LL")}
        </text>
      }
    },
  ];


  const teamscolumns = [
    {
      title: 'User Name',
      dataIndex: 'userName',
      key:'userName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key:"email"
    },
    {
      title: 'checkIn At',
      key:'checkInAt',
      render:(record) => {
        return <text>
          {moment(record.checkInAt).format("LL")}
        </text>
      }
    },
    {
      title: 'Has Team',
      key:'hasTeam',
      render:(record) => {
        return <text>
          {record.teamIndex ? 'Yes':"No"}
        </text>
      },
      filters: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      onFilter: (value, record) => {
        if(value){
          return record.teamIndex
        }else {
          return !record.teamIndex
        }
      },
    },
    {
      title: 'Team Number',
      key:'teamIndex',
      dataIndex:'teamIndex'
    },
  ];

  const rowSelection = {
    selectedRowKeys,
   onChange: onSelectChange,
  getCheckboxProps: (record) => ({
      disabled: record.teamIndex,
    }),
  };

  const renderEmail = (players) => {
    const emails = players.map((id, index) => {
      const target = tournament.verifiedPlayers.find(item => item.userId === id);
      if(target){
        if(index < (players.length -1))
        {
          return target.email + " & "
        }else {
          return target.email
        }
      }else {
        return "";
      }
    });
    return emails;
  }




  return <Container>
  <>  
  {
    isInviteOnly && <TopContainer isInviteOnly={isInviteOnly}>
    <h1>
      Invited Players
    </h1>
    <Button type="primary" onClick={() => {
      showModal()
    }}>
      Invite New
    </Button>
    </TopContainer>
  }
    <TopContainer isInviteOnly={isInviteOnly}>
       <div style={{display:'flex', flexDirection:'column', alignItems:'left'}}>
        {
          isInviteOnly && <List
          header={<div>Invited Players</div>}
          bordered
          pagination={pagination}
          dataSource={tournament.invitedList}
          style={{marginBottom:24}}
          renderItem={(item) => (
            <List.Item actions={!checkStatus(item) ? [<a onClick={() => {
              removePlayer(item)
            }}>Remove</a>]:[<a>Checked In</a>]}>
              <Typography.Text mark>{item}</Typography.Text> 
        
            </List.Item>
          )}
        />
        }
      {
        tournament.teamsUp && <List
        header={<div>Teams</div>}
        bordered
        pagination={pagination}
        dataSource={tournament.teamsList}
        renderItem={(item) => (
          <List.Item >
            <Typography.Text mark style={{marginRight:8}}>Team {item.index} : </Typography.Text> 
            {item.players && renderEmail(item.players)}
          </List.Item>
        )}
      />
      }
       </div>
      {
        tournament.teamsUp ? <div style={{display:'flex', flexDirection:'column'}}>
        <Table
        rowSelection={{
          ...rowSelection,
        }}
        pagination = {
          {
            pageSize: 20,
          }
        }
        columns={teamscolumns}
        dataSource={tournament.verifiedPlayers}
      /> 
      {
        selectedRowKeys && selectedRowKeys.length === tournament.playerPerTeam && <Button style={{width:'88px', marginLeft:'auto'}} type="primary" onClick={() => {
          setTeams()
        }}>Set Team</Button>
}
        </div>: <Table
      pagination = {
        {
          pageSize: 20,
        }
      }
      columns={columns}
      dataSource={tournament.verifiedPlayers}
    /> 

      }
  
      </TopContainer>
  </>
   
    <Modal  visible={isModalOpen} 
    title="Invite Player With Email"
    onCancel={handleCancel}
    footer={[
      <Button key="back" onClick={handleCancel}>
        Return
      </Button>
    ]}>
      <TopContainer>
      <Input autoComplete="off" value={email} onChange={(e) => {
          setEmail(e.target.value)
      }}/> <Button type="primary" style={{marginLeft:16}} onClick={() => {
        invitePlayer()
      }}>
        Invite
      </Button>
      </TopContainer>
      </Modal>
  </Container>
}

const Container = styled.div`
.ant-checkbox-wrapper {
  width:auto !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890FF !important;
  border-color: #1890FF !important;
}

.ant-table {
  min-width:780px;
}
`;

const TopContainer = styled.div`
display:flex;
${p => p.isInviteOnly && `justify-content:space-between;` }
margin-bottom:24px;

`;

export default TournamentPlayers;
