import React, {  useState, useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { List, Tooltip } from "antd";
import moment from "moment";
import Countdown from 'react-countdown';
import StatsModal from "./statsModal";
import { withRouter } from "react-router-dom";
import ReactPlayer from "react-player";
import { calcCreatorQuestPool, calcFunGameModePlacement, calcFunGameModeReward} from "../../util/helper";
import { Mixpanel } from '../../util/mixpanel';

const QuestRanking = ({quest, joined, tiers, sortedPlayers, ended, isDailyQuest, history, isPromoed, companyInfo, productInfo, canClaimBouns, getClickBouns}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const isActiveSub = useMemo(() => {
    if(loggedIn && profile && profile.multiplier){
      const multiplier = profile.multiplier;
      if(multiplier.renewAt && moment(multiplier.renewAt).isAfter(moment()) && !multiplier.requestCancel){
        return true
      }
    }
    return false;
  },[profile, loggedIn]);

  const questPrizePool = useMemo(() => {
    if(quest){
      return calcCreatorQuestPool(quest);
    }
    return 0;
  }, [quest]);

  const renderItem = (user, index) => {
    const { userName, joinedAt, userId} = user;
    return (
      <ItemContainer><div
      style={{
        width: "5%",
        font: "normal normal 600 16px SF PRO, serif",
        color: "#F72375",
        textAlign: "left",
      }}
    >
    {index + 1}
    </div>
        <div
          style={{
            width: "45%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#F72375",
            textAlign: "left",
            cursor:"pointer"
          }}
          onClick={() => {
            history.push(`/profile/${userId}`)
           }}
        >
       
      <Tooltip placement="right" title="Click to View User Profile">
      {userName}
        </Tooltip>
        </div>
        <div
          style={{
            width: "50%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#FFFFFF",
            textAlign: "left",
          }}
        >
          {moment(joinedAt).format('LLL')}
        </div>
        </ItemContainer> 
    );
  };

  const renderItemRanking = (user, index) => {
    const { userName, kills, tier} = user;
    const hasKillsDetail = user.startKillsDetail && user.currentKillsDetail;
    return (
      <ItemContainer>
        <div
      style={{
        width: "5%",
        font: "normal normal 600 16px SF PRO, serif",
        color: "#F72375",
        textAlign: "left",
      }}
    >
    {index + 1}</div>
    <TierName tier={tier} onClick={() => {
        if(hasKillsDetail){
          setUserDetail(user);
          setModalOpen(true);
        }}}
        style={{cursor:'pointer'}}>
      {userName}
    </TierName>
        <div
          style={{
            width: "30%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          {kills}
        </div>
        <div
          style={{
            width: "30%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#FFFFFF",
            textAlign: "right",
          }}
        >
          {
            isFunGameMode ? ((tier && tier > 0) ? "Reward" : "-") : ((tier && tier > 0) ? tier : "-")
          }
        </div>
      </ItemContainer>
    );
  };

  const renderItemRankingResult = (user, index) => {
    const { userName, kills, tier} = user;
    const hasKillsDetail = user.startKillsDetail && user.finalKillsDetail;
    return (
      <ItemContainer >
        <div
      style={{
        width: "5%",
        font: "normal normal 600 16px SF PRO, serif",
        color: "#F72375",
        textAlign: "left",
      }}
    >
    {index + 1}</div>
    <TierName tier={tier} onClick={() => {
        if(hasKillsDetail){
          setUserDetail(user);
          setModalOpen(true);
        }}}
        style={{cursor:'pointer'}}
        >
    {
      hasKillsDetail ? <Tooltip placement="right" title="Click to See Detail Performance">
      {userName}
        </Tooltip>: <>{userName}</>
    }
    </TierName> 
        <div
          style={{
            width: "30%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          {
            isFunGameMode ? ((tier && tier > 0) ? "Reward" : "-") : ((tier && tier > 0) ? tier : "-")
          }
        </div>
        <div
          style={{
            width: "30%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#FFFFFF",
            textAlign: "right",
          }}
        >
          {user?.coinsWon?? 0}
        </div>
      </ItemContainer>
    );
  };

  const isFunGameMode = useMemo(() => {
    if(quest.isFunGameMode){
      return true;
    }
    return false;
  }, [quest]);


  const rankingList = useMemo(() => {
    if(isFunGameMode){
      const rewardPlacement = calcFunGameModePlacement(quest);
      if(sortedPlayers){
        const list = sortedPlayers.map((item, index) => {
          const kills = (item.currentKills && item.startKills) ? item.currentKills - item.startKills : 0;
          let tier = 0;
          if(item.tier){
            tier = item.tier
          }else if(kills === 0){
            tier = -1
          }else {
            const placement = index + 1;
            if(placement >= rewardPlacement.start && placement <= rewardPlacement.end){
              tier = 1;
            }
          }
          return {
            ...item,
            kills,
            tier
          }
        });
        return list;
      }else {
        return [];
      }
    }else {
      if(sortedPlayers && tiers){
        const list = sortedPlayers.map((item, index) => {
          const kills = (item.currentKills && item.startKills) ? item.currentKills - item.startKills : 0;
          let tier = 0;
          if(item.tier){
            tier = item.tier
          }else if(kills === 0){
            tier = -1
          }else {
            const placement = index + 1;
            if(placement >= tiers.firstTier.placement.start && placement <= tiers.firstTier.placement.end){
              tier = 1;
            }else if(placement >= tiers.secondTier.placement.start && placement <= tiers.secondTier.placement.end){
              tier = 2;
            }else if(placement >= tiers.thirdTier.placement.start && placement <= tiers.thirdTier.placement.end){
              tier = 3;
            }else {
              tier = -1;
            }
          }
          return {
            ...item,
            kills,
            tier
          }
        });
        return list;
      }else {
        return [];
      }
    }
  },[sortedPlayers, tiers, isFunGameMode, quest]);

  const rankingListResult = useMemo(() => {
    if(sortedPlayers && tiers){
      const list = sortedPlayers.map((item, index) => {
        const kills = (item.finalKills && item.startKills) ? item.finalKills - item.startKills : 0; 
        return {
          ...item,
          kills,
        }
      });
      return list;
    }else {
      return [];
    }
  },[sortedPlayers, tiers]);


  const renderPlacement = (obj) => {
    if(obj == null){
      return "-"
    }else {
      const start = obj.start;
      const end = obj.end;
      return `${start} - ${end}`
    }
  }


  const nextUpdateTime = useMemo(() => {
    if(quest.lastUpdatedAt && quest.updatedIndex !== null){
      if(isDailyQuest){
        const time = moment(quest.lastUpdatedAt).add(30, "m").toDate();
      const key = moment(quest.lastUpdatedAt).unix();
      return {
        time,
        key
      }
      }else {
        const time = quest.updatedIndex === 0 ? moment(quest.lastUpdatedAt).add(15, "m").toDate():moment(quest.lastUpdatedAt).add(5, "m").toDate();
        const key = moment(quest.lastUpdatedAt).unix();
        return {
          time,
          key
        }
      }
      
    }
  }, [quest.lastUpdatedAt, isDailyQuest, quest.updatedIndex]);

  const mykills = useMemo(() => {
    const target = quest.players.find(item => item.userId === userId);
    if(target){
      return target.currentKills - target.startKills
    }else {
      return 0;
    }
  }, [quest, userId]);


  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Soon</span>
    } else {
      // Render a countdown
      return <span>{minutes}:{seconds}</span>;
    }
  };

  const myPlacement = useMemo(() => {
    if(mykills === 0){
      return -1;
    }else if(sortedPlayers){
      const index = sortedPlayers.findIndex(item => item.userId === userId);
      if(index > -1){
        return index + 1;
      }else {
        return -1
      }
    }else {
      return -1
    }
  },[sortedPlayers, userId, mykills]);


  const myTier = useMemo(() => {
    if(mykills === 0){
      return "-"
    }else if(myPlacement > -1 && tiers) {
      if(myPlacement >= tiers.firstTier.placement.start && myPlacement <= tiers.firstTier.placement.end ){
        return 1;
      }else if(myPlacement >= tiers.secondTier.placement.start && myPlacement <= tiers.secondTier.placement.end){
        return 2;
      }else if(myPlacement >= tiers.thirdTier.placement.start && myPlacement <= tiers.thirdTier.placement.end){
        return 3;
      }else {
        return "-"
      }
    }else {
      return "-"
    }
  },[myPlacement, tiers, mykills]);

  const CoinsWon = useMemo(() => {
    if(quest.resultGenerated && quest.players){
      const target = quest.players.find((item) => item.userId === userId);
      if(target && target.coinsWon){
        return target.coinsWon
      }else {
        return 0;
      }
    }else {
      return "-"
    }
  }, [quest, userId]);

  const allModes = ["defaultsolo", "nobuildbr_solo", "nobuildbr_duo", "defaultduo", "trios", "nobuildbr_trio", 
  "defaultsquad", "nobuildbr_squad", "showdownalt_solo", "showdownalt_duos", "showdownalt_trios", "showdownalt_squads"]

  const acceptModes = (quest.gameModeSelected  && quest.gameModes )? quest.gameModes: allModes;

  const mixPanelTrackVideoPlay = () => {
    Mixpanel.track("Quest Promo Product Video Plays", {
      productName:productInfo.name,
      productId:productInfo.productId,
      partnerName:companyInfo.name,
      partnerId:companyInfo.partnerId,
    })
  }

  const mixPaneltrackBanner = () => {
    if(companyInfo && productInfo){
      Mixpanel.track("Quest Promo Product Banner Clicks", {
        productName:productInfo.name,
        productId:productInfo.productId,
        partnerName:companyInfo.name,
        partnerId:companyInfo.partnerId,
      })
    }
  }

  

  const TierExpression = (tier, promoBox)  => {
    let placement = null;
    let reward = 0;
    if(tier === 1){
      placement = renderPlacement(tiers?.firstTier?.placement?? null);
      reward = tiers?.firstTier?.reward?? 0;
    }else if(tier === 2){
      placement = renderPlacement(tiers?.secondTier?.placement?? null);
      reward = tiers?.secondTier?.reward?? 0;
    }else if(tier === 3){
      placement = renderPlacement(tiers?.thirdTier?.placement?? null);
      reward = tiers?.thirdTier?.reward?? 0;
    }
    return   <TierContainer promoBox={promoBox}>
    <TierData>
            <DataEntry>
              {
                placement
              }
            </DataEntry>
            <DataTitle>
              Tier {tier} Placement
            </DataTitle>
          </TierData>
          <TierData>
            <DataEntry style={{color:"#F72375"}}>
              {reward}
            </DataEntry>
            <DataTitle>
            <img
            src="../assets/Coin.svg"
            alt=""
            style={{
              height: "12px",
              marginRight: "4px",
            }}
          />   Coins They'll Win
            </DataTitle>
          </TierData>
    </TierContainer>
  }

  const FunGameModeExpression = () => {
    const funGameModePlacement = calcFunGameModePlacement(quest);
    const reward = calcFunGameModeReward(quest);
    const placement = renderPlacement(funGameModePlacement?? null);
    return   <TierContainer >
    <TierData>
            <DataEntry>
              {
                placement
              }
            </DataEntry>
            <DataTitle>
            Reward Position
            </DataTitle>
          </TierData>
          <TierData>
            <DataEntry style={{color:"#F72375"}}>
              {reward}
            </DataEntry>
            <DataTitle>
            <img
            src="../assets/Coin.svg"
            alt=""
            style={{
              height: "12px",
              marginRight: "4px",
            }}
          />   Coins They'll Win
            </DataTitle>
          </TierData>
    </TierContainer>
  }

  return <Container>
    {
      isPromoed && <PromoTop>
        <PromoLeft>
          {(quest.status === 1 ||!joined) ? <UserBox>
                <Name>{profile?.first_name?? "-"}</Name>
                <UserInfo>
                  <div>
                  {
                    isActiveSub ? "Multipler Subscriber":"Free User"
                  }
                  </div>
                  {
                    !isActiveSub && <Upgrade onClick={() => {
                      history.push("/multiplier")
                    }}>
                        Upgrade to Get One Coin for Every Two Kills
                    </Upgrade>
                  }
                </UserInfo>
          </UserBox> : <BoxsContainer style={{marginBottom:12}}>
            <DataBox>
              <DataEntry>
                {myPlacement > -1 ? myPlacement: "-"}
              </DataEntry>
              <DataTitle>
                Placement
              </DataTitle>
            </DataBox>
            <DataBox>
              <DataEntry>
                {mykills}
              </DataEntry>
              <DataTitle>
                Kills
              </DataTitle>
            </DataBox>
            <DataBox style={{width:220, marginLeft:'auto', marginRight:0}} >
            <DataEntry>
                {myTier}
              </DataEntry>
              <DataTitle>
                Your Tier
              </DataTitle>
            </DataBox>
          </BoxsContainer>}
          {TierExpression(1, true)}
          {TierExpression(2, true)}
          {TierExpression(3, true)}
        </PromoLeft>
        <ReactPlayer
      url={productInfo?.video??""}
      playing={false}
      width="456px"
      height="326px"
      controls
      onStart={mixPanelTrackVideoPlay}
    />
    <PromoRight>
      {
        (!joined || quest.status === 1) ? <>
        <QuestDetailBox>
          <DetailText style={{color:"#F72375"}}>
          <img
                src="../assets/Coin.svg"
                alt=""
                style={{
                  height: "18px",
                  marginRight: "4px",
                }}
              /> {questPrizePool}
          </DetailText>
         <DetailText>
         {quest?.players?.length?? 0}
          </DetailText>
          <TitleText>
            Total Prizes
          </TitleText>
          <TitleText>
            Players
            </TitleText>
      </QuestDetailBox>
      <ViewButton onClick={() => {
        history.push("/quest")
      }}>
        View Next Quests
      </ViewButton>
        </>:<>
        <TimeContainer style={{width:220}}>
          {
            ended ? <>
             <DataEntry style={{color:"#F72375"}}>
               {CoinsWon}
              </DataEntry>
              <DataTitle>
              <img
                src="../assets/Coin.svg"
                alt=""
                style={{
                  height: "12px",
                  marginRight: "4px",
                }}
              />  Coins You Won
              </DataTitle>
            </>: <> 
            <DataEntry>
                {moment(quest.lastUpdatedAt?? "").format("LT")}
              </DataEntry>
              <DataTitle>
                {
                  quest.updatedIndex === 0 ? "LeaderBoard Initialized" :"Last Leaderboard Update"
                }
              </DataTitle>
            </>
          }
        </TimeContainer>
        <TimeContainer style={{width:220}}>
          {
            ended ? <><DataEntry >
            {quest.resultGenerated? 'Finalize':"Processing"}
              </DataEntry>
              <DataTitle>
                Result Status
              </DataTitle></>
              :<><DataEntry style={{color:"#F72375"}}>
          <Countdown date={nextUpdateTime?.time??""} renderer={renderer} key={nextUpdateTime?.key??""}/>
              </DataEntry>
              <DataTitle>
                Next Leaderboard Update
              </DataTitle></>
          }
        </TimeContainer>
        </>
      }
      
      <ProductImg image={productInfo?.productUrl?? ""} onClick={() => {
         mixPaneltrackBanner()
         window.open(productInfo.link)
         getClickBouns()
      }}>
        {
          canClaimBouns && <ClaimBouns>
          GET 5 COINS WHEN YOU CLICK
      </ClaimBouns>
        }
      </ProductImg>
    </PromoRight>
      </PromoTop>
    }
    {
      joined && (quest.status !== 1) && !isPromoed && !isFunGameMode && <TopContainer>
        <BoxsContainer>
            <DataBox>
              <DataEntry>
                {myPlacement > -1 ? myPlacement: "-"}
              </DataEntry>
              <DataTitle>
                Placement
              </DataTitle>
            </DataBox>
            <DataBox>
              <DataEntry>
                {mykills}
              </DataEntry>
              <DataTitle>
                Kills
              </DataTitle>
            </DataBox>
            <DataBox style={{width:220, marginLeft:'auto', marginRight:0}} >
            <DataEntry>
                {myTier}
              </DataEntry>
              <DataTitle>
                Your Tier
              </DataTitle>
            </DataBox>
          </BoxsContainer>
       {TierExpression(2, false)}
        <TimeContainer>
          {
            ended ? <>
             <DataEntry style={{color:"#F72375"}}>
               {CoinsWon}
              </DataEntry>
              <DataTitle>
              <img
                src="../assets/Coin.svg"
                alt=""
                style={{
                  height: "12px",
                  marginRight: "4px",
                }}
              />  Coins You Won
              </DataTitle>
            </>: <> 
            <DataEntry>
                {moment(quest.lastUpdatedAt?? "").format("LT")}
              </DataEntry>
              <DataTitle>
                {
                  quest.updatedIndex === 0 ? "LeaderBoard Initialized" :"Last Leaderboard Update"
                }
              </DataTitle>
            </>
          }
        </TimeContainer>
        {TierExpression(1, false)}
        {TierExpression(3, false)}
        <TimeContainer>
          {
            ended ? <><DataEntry >
            {quest.resultGenerated? 'Finalize':"Processing"}
              </DataEntry>
              <DataTitle>
                Result Status
              </DataTitle>
              </> :<><DataEntry style={{color:"#F72375"}}>
          <Countdown date={nextUpdateTime?.time??""} renderer={renderer} key={nextUpdateTime?.key??""}/>
              </DataEntry>
              <DataTitle>
                Next Leaderboard Update
              </DataTitle>
              </>
          }
        </TimeContainer>
        
      </TopContainer>
    }

{
      joined && (quest.status !== 1) && !isPromoed && isFunGameMode && <TopContainer>
        <BoxsContainer>
            <DataBox>
              <DataEntry>
                {myPlacement > -1 ? myPlacement: "-"}
              </DataEntry>
              <DataTitle>
                Placement
              </DataTitle>
            </DataBox>
            <DataBox>
              <DataEntry>
                {mykills}
              </DataEntry>
              <DataTitle>
                Kills
              </DataTitle>
            </DataBox>
            <TimeContainer>
          {
            ended ? <><DataEntry >
            {quest.resultGenerated? 'Finalize':"Processing"}
              </DataEntry>
              <DataTitle>
                Result Status
              </DataTitle>
              </> :<><DataEntry style={{color:"#F72375"}}>
          <Countdown date={nextUpdateTime?.time??""} renderer={renderer} key={nextUpdateTime?.key??""}/>
              </DataEntry>
              <DataTitle>
                Next Leaderboard Update
              </DataTitle>
              </>
          }
        </TimeContainer>
          </BoxsContainer>
       {FunGameModeExpression()}
        <TimeContainer>
          {
            ended ? <>
             <DataEntry style={{color:"#F72375"}}>
               {CoinsWon}
              </DataEntry>
              <DataTitle>
              <img
                src="../assets/Coin.svg"
                alt=""
                style={{
                  height: "12px",
                  marginRight: "4px",
                }}
              />  Coins You Won
              </DataTitle>
            </>: <> 
            <DataEntry>
                {moment(quest.lastUpdatedAt?? "").format("LT")}
              </DataEntry>
              <DataTitle>
                {
                  quest.updatedIndex === 0 ? "LeaderBoard Initialized" :"Last Leaderboard Update"
                }
              </DataTitle>
            </>
          }
        </TimeContainer>
        
        
      </TopContainer>
    }
    <Title>
    LeaderBoard
    </Title>
    {
      quest.status === 1 &&  <PlayBox>
      <Header>
              <HeaderItem style={{ width: "5%" }}></HeaderItem>
              <HeaderItem style={{ width: "45%" }}>USER NAME</HeaderItem>
              <HeaderItem style={{ width: "50%" }}>DATE JOINED</HeaderItem>
        </Header>
        <List
                dataSource={quest.players}
                renderItem={(item, index) => (
                  <List.Item key={index} style={{ padding: 0 }}>
                    {renderItem(item, index)}
                  </List.Item>
                )}
              ></List>
      </PlayBox>
    }
    {
      (quest.status === 2) && <PlayBox>
        <Header>
              <HeaderItem style={{ width: "5%" }}></HeaderItem>
              <HeaderItem style={{ width: "35%" }}>USER NAME</HeaderItem>
              <HeaderItem style={{ width: "30%", textAlign:'center' }}>CURRENT KILLS</HeaderItem>
              <HeaderItem style={{ width: "30%", textAlign:'right' }}>{isFunGameMode ? 'Current Position':'Current Tier'}</HeaderItem>
        </Header>
        <List
                dataSource={rankingList}
                renderItem={(item, index) => (
                  <List.Item key={index} style={{ padding: 0 }}>
                    {renderItemRanking(item, index)}
                  </List.Item>
                )}
              ></List>
      </PlayBox> 
    }
    {
      (quest.status === 3 && !quest.resultGenerated) && <PlayBox>
        <Header>
              <HeaderItem style={{ width: "5%" }}></HeaderItem>
              <HeaderItem style={{ width: "35%" }}>USER NAME</HeaderItem>
              <HeaderItem style={{ width: "30%", textAlign:'center' }}>CURRENT KILLS</HeaderItem>
              <HeaderItem style={{ width: "30%", textAlign:'right' }}>{isFunGameMode ? 'Position':'Tier'}</HeaderItem>
        </Header>
        <List
                dataSource={rankingList}
                renderItem={(item, index) => (
                  <List.Item key={index} style={{ padding: 0 }}>
                    {renderItemRanking(item, index)}
                  </List.Item>
                )}
              ></List>
      </PlayBox> 
    }
    {
      (quest.status === 3 && quest.resultGenerated) && <PlayBox>
        <Header>
              <HeaderItem style={{ width: "5%" }}></HeaderItem>
              <HeaderItem style={{ width: "35%" }}>USER NAME</HeaderItem>
              <HeaderItem style={{ width: "30%", textAlign:'center' }}>{isFunGameMode ? 'Position':'Tier'}</HeaderItem>
              <HeaderItem style={{ width: "30%", textAlign:'right' }}>CoinsWon</HeaderItem>
        </Header>
        <List
                dataSource={rankingListResult}
                renderItem={(item, index) => (
                  <List.Item key={index} style={{ padding: 0 }}>
                    {renderItemRankingResult(item, index)}
                  </List.Item>
                )}
              ></List>
      </PlayBox> 
    }
    {
      modalOpen && userDetail && <StatsModal modalOpen={modalOpen} userDetail={userDetail} 
      key={userDetail.userId ?? ""}
      acceptModes={acceptModes}
      questFinalized={quest.resultGenerated && quest.status === 3}
      closeModal={() => {
        setModalOpen(false);
        setUserDetail(null);
      }}
      />
    }
  </Container>
}

const Container = styled.div`
width:100%;
padding-bottom:36px;
`;

const PlayBox = styled.div`
width: 100%;
min-height: 600px;
/* UI Properties */
background: #232526 0% 0% no-repeat padding-box;
border-radius: 7px;
padding:24px;
.ant-list-item, .ant-list-item:last-child {
  border-bottom:1px solid #707070 !important;
}
.
`;

const Title = styled.div`
text-align: left;
font: normal normal bold 22px/25px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:24px;
`;

const Header = styled.div`
  display: flex;
  width:100%;
  margin-bottom:12px;
`;

const HeaderItem = styled.div`
text-align: left;
font: normal normal bold 18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
`;

const TopContainer = styled.div`
display:grid;
grid-template-columns: 410px 410px 200px;
justify-content:space-between;
grid-row-gap:24px;
width: 100%;
margin:0px auto 48px;
@media (min-width: 1480px) {
  grid-template-columns: 450px 450px 220px;
}
`;

const TierContainer = styled.div`
width:410px;
height: 67px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
@media (min-width: 1480px) {
  width:450px;
}
padding:6px 12px;
display:flex;
${p => p.promoBox  && `margin-bottom:12px`}
`;

const TimeContainer = styled.div`
width:200px;
height: 67px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
@media (min-width: 1480px) {
  width:220px;
}
padding:6px 12px;
`;

const DataEntry = styled.div`
text-align: left;
font: normal normal 600 28px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const DataTitle = styled.div`
text-align: left;
font: normal normal 12px/25px SF PRO, serif;
letter-spacing: 0px;
color: #B7B7B7;
`;


const TierData = styled.div`
display:flex;
flex-direction:column;
width:50%;
`;

const BoxsContainer = styled.div`
width:410px;
display:flex;
@media (min-width: 1480px) {
  width:450px;
}
`;

const DataBox = styled.div`
width: 102px;
height: 67px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
margin-right:16px;
padding:6px 12px;
`;

const ItemContainer = styled.div`
display: flex;
width: 100%;
height: 41px;
align-items: center;
border-bottom:1px solid #707070;
`;

const TierName = styled.div`
width:35%;
font: normal normal 600 16px SF PRO, serif;
color: #F72375;
textAlign: left;
color: #D1D1D1;
${p => p.tier === 1 && `color:#F72375`}
${p => p.tier === 2 && `color:#FFFFFF`}
`;

const PromoTop = styled.div`
width:100%;
display:flex;
justify-content:space-between;
margin-bottom:24px;
`;

const PromoLeft = styled.div`
width: 410px;
@media (min-width: 1480px) {
  width:450px;
}
`;

const PromoRight = styled.div`
width:220px;
display:flex;
flex-direction:column;
align-items:center;
justify-content:space-between;
`;

const QuestDetailBox = styled.div`
display:grid;
grid-template-columns: repeat(2, 100px);
justify-content:space-around;
`;

const DetailText = styled.div`
text-align: left;
font: normal normal bold 18px/25px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const TitleText = styled.div`
text-align: left;
font: normal normal normal 14px/25px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const ViewButton = styled.div`
width: 162px;
height: 48px;
/* UI Properties */
background: #F42A76 0% 0% no-repeat padding-box;
border-radius: 12px;
text-align: center;
font: normal normal 600 16px/48px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
cursor:pointer;
`;

const ProductImg = styled.div`
width: 220px;
height: 160px;
border-radius: 7px;
background: url(${(p) => p.image}); 
background-size: cover;
position:relative;
cursor:pointer;
`;

const UserBox = styled.div`
width: 100%;
height: 77px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
padding:12px;
margin-bottom:12px;
display:flex;
flex-direction:column;
justify-content:space-between;
`;

const Name = styled.div`
font: normal normal 600 26px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const UserInfo = styled.div`
display:flex;
width:100%;
justify-content:space-between;
font: normal normal medium 12px/18px SF PRO, serif;;
letter-spacing: 0px;
color: #B7B7B7;
margin-top:auto;
`;

const Upgrade = styled.div`
text-align: right;
text-decoration: underline;
font: normal normal medium 12px/18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
cursor:pointer;
`;

const ClaimBouns = styled.div`
width: 220px;
height: 28px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 0px 0px 7px 7px;
position:absolute;
bottom:0px;
font: normal normal bold 12px/28px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
text-transform: uppercase;
text-align:center;
`;

export default withRouter(QuestRanking);