import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import { Input, message} from "antd";
import axios from "axios";
import * as API from "../../../util/api";
//firebase
import Firebase from "../../../util/firebase";
import { debounce } from "lodash";
import {RightOutlined } from '@ant-design/icons';

const EventChat = ({ chatId, size}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const [history, setHistory] = useState([]);
  const [text, setText] = useState("");

  const mesRef = useRef(null);
  const listenerRef = useRef(null);

  useEffect(() => {
    const fetchChat = async () => {
      listenerRef.current = Firebase.firestore()
        .collection("liveShoppingChat")
        .doc(chatId)
        .onSnapshot((doc) => {
          const historyData = doc.data().messages;
          setHistory(historyData);
        });
    };

    fetchChat();

    return () => {
      if (listenerRef.current) {
        listenerRef.current();
      }
    };
  }, [chatId]);

  useEffect(() => {
      scrollToBottom();
  }, [history]);

  const scrollToBottom = () => {
    if (mesRef.current) {
      mesRef.current.scrollTop = mesRef.current.scrollHeight;
    }
  };

  const debounceSendChat = debounce(()=> {
    sendToChat()
  }, 500)

  const sendToChat = async () => {
   try {
    const name = profile?.first_name;
    const requestBody = {
      chatId,
      message:text,
      userId,
      userName:name
    }
    const url = API.SEND_EVENT_CHAT;
    const res = await axios.post(url, requestBody);
    if(res.status === 200){
      setText("")
    }else {
      message.error("Failed to send message, please try again")
    }
   }catch(err){
    message.error("Failed to send message, please try again")
   }
   
  };

  const keyOperation = (event) => {
    if (event.key === "Enter" && text !== "") {
      debounceSendChat()
    }
  };

  const messageCompare = (a, b) => {
    const time1 = a.time;
    const time2 = b.time;
    if (moment(time1).isBefore(time2)) {
      return -1;
    }
    if (moment(time1).isAfter(time2)) {
      return 1;
    }
    return 0;
  };

  const messages = history.sort(messageCompare);

  return (
    <Container size={size}>
        <ChatBox ref={mesRef} size={size}>
          {messages.map((item, index) => (
            <Message key={index}>
              <UserName>{item?.userName}:</UserName>
              <MessageText>{item?.message}</MessageText>
            </Message>
          ))}
        </ChatBox>
      {loggedIn && (
          <StyledInput
            placeholder="Say something good"
            onChange={(e) => {
              setText(e.target.value);
            }}
            value={text}
            onKeyPress={keyOperation}
            suffix={
                <RightOutlined
                  style={{
                color:"#F72375"
                  }}
                  onClick={() => {
                    debounceSendChat()
                  }}
                />
            }
          />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 344px;
  border-radius: 10px;
  background: #000;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;

  .ant-input-affix-wrapper > .ant-input {
    color:white;
    background:#1c1d20;
  }

  ${p => p.size === 'small' ? `height:447px`:'height: 606px;'};
`;



const ChatBox = styled.div`
  width: 100%;
  height: 524px;
  margin-bottom: 16px;
  overflow-y: auto;

  ${p => p.size === 'small' && `height:366px`}
`;


const StyledInput = styled(Input)`
  height: 38px;
  border-radius: 5px;
background: #1C1D20;
border:none;

color: #FFF;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
`;

const Message = styled.div`
  margin-bottom: 4px;
`;

const UserName = styled.span`
color: #5EA9EF;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
margin-right:4px;
`;

const MessageText = styled.span`
color: #FFF;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
`;


export default EventChat;
