import React from 'react';
import { withRouter } from "react-router-dom";
import {
    Button,
    Form,
    Input,
    InputNumber,
    Select,
    message,
    DatePicker,
    Checkbox,
    Upload
  } from 'antd';
  import { useSelector } from "react-redux";

import * as API from "../util/api";
import axios from "axios";
import Firebase from "../util/firebase";
import firebase from "firebase/app";
import { UploadOutlined } from '@ant-design/icons';
import moment from "moment";
const { RangePicker } = DatePicker;

const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

const { TextArea } = Input;
const { Option } = Select;

const CreateTournament = ({history}) => {
    const { profile, id: userId} = useSelector((state) => state.user);


    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const date = values.date;
        const startDate = moment(date[0]).format();
        const endDate = moment(date[1]).format();
        try {
            const requestBody = {
                ...values,
                createdBy:userId,
                startDate,
                endDate
            }
            const endpoint = API.CREATE_TOURNAMENT;
            const res = await axios.post(endpoint, requestBody);
            if(res.status === 200){
                message.success('Tournament created successfully, You will be directed');
                form.resetFields();
                const {tournamentId} = res.data;
                setTimeout(() => {
                    history.push(`/tournament_admin/tournaments/${tournamentId}`)
                }, 1000)
            }else {
                message.error("System Error, Please Try Again")
            }
        }catch(err){
             message.error("System Error, Please Try Again")
            console.log(err);
        }

    }

    const isTeamsUp = Form.useWatch("teamsUp", form);

    const tournamentType = Form.useWatch("tournament_type", form);

    console.log(isTeamsUp, tournamentType, 'boo')


    const customRequestBannerUrl = async ({ onError, onSuccess, file }) => {
        const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
        const fileName = file.name.replace(pattern, "");
        const uploadTask = Firebase.storage()
          .ref()
          .child("productBanner/" + fileName)
          .put(file);
        try {
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {},
            (error) => {
              console.log(error);
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                form.setFieldsValue({
                  image:downloadURL
                });
              });
            }
          );
          onSuccess(() => console.log("success"));
        } catch (err) {
          onError(err);
          console.log(err);
        }
      };

      const handleChange = async (info) => {
        if (info.file.status === "done") {
          console.log("done");
        }
        if (info.file.status === "error") {
          console.log("upload error");
        }
      };

    return <>
    <h1>Create Tournament</h1>
    <Form
      name="validate_other"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      style={{maxWidth:800}}
      form={form}
      initialValues={{
        teamsUp:false,
        inviteOnly:false
      }
      }
    >
      <Form.Item
        name="title"
        label="Title"
        labelAlign='Right'
        rules={[{ required: true,  message: "this field is required" }]}
      >
        <Input autoComplete="off" />
      </Form.Item>

      <Form.Item
          name="date"
          label="DURATION"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <RangePicker disabledDate={disabledDate} />
      </Form.Item>

      <Form.Item
        name="externalLink"
        label="External Link"
        labelAlign='Right'
      >
        <Input autoComplete="off" />
      </Form.Item>

      <Form.Item name="tournament_type" label="Tournament Type"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}>
        <Select placeholder="Tournament Type">
          <Option value={1}>Invite Only</Option>
          <Option value={2}>Subs Only</Option>
          <Option value={3}>All Access(free for subs)</Option>
        </Select>
      </Form.Item>

      {
        tournamentType === 3 &&  <Form.Item
        name="entryFee"
        hidden={(tournamentType !== 3)}
        rules={[{ required: tournamentType === 3, message: "entry fee is required" }]}
      >
        <Input autoComplete="off" placeholder="Entry Fee" type="number"/>
      </Form.Item>
      }
      
      {
        tournamentType !== 1 && <Form.Item
        name="prizePool"
        hidden={(tournamentType === 1)}
        rules={[{ required: tournamentType !== 1, message: "prize Pool is required" }]}
      >
        <Input autoComplete="off" placeholder="Prize Pool" type="number"/>
      </Form.Item>
      }

      <Form.Item name="teamsUp" valuePropName="checked" >
        <Checkbox>Teams Up</Checkbox>
      </Form.Item>

  {
    isTeamsUp &&  <Form.Item
    name="playerPerTeam"
    hidden={!isTeamsUp}
    label="Player Per Team"
    rules={[
      {
        required: (isTeamsUp),
      },
    ]}
  >
      <Select placeholder="Player Per Team">
      <Option value={1}>1</Option>
      <Option value={2}>2</Option>
      <Option value={3}>3</Option>
      <Option value={4}>4</Option>
    </Select>
  </Form.Item> 
}

    <Form.Item
        name="image"
        label="Tournament Image"
        labelAlign='Right'
        style={{marginBottom:0}}
      >
        <Input autoComplete="off" />
      </Form.Item>

      <Upload 
        onChange={handleChange}
        accept="image/*"
        customRequest={customRequestBannerUrl}
        showUploadList={false}>
          <Button icon={<UploadOutlined />} style={{marginTop:8, marginBottom:16}}>Upload from local</Button>
      </Upload>

      <Form.Item
        name="description"
        label="Description"
        labelAlign='Right'
      >
         <TextArea
          type="text"
          showCount
          autoSize={{ minRows: 3, maxRows: 8 }}
          maxLength={320}
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          span: 12
        }}
        style={{marginTop:32}}
      >
        <Button type="primary" htmlType="submit" >
          Create New
        </Button>
      </Form.Item>
      </Form>
    </>
}

export default withRouter(CreateTournament)