import React, { Component } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ReverifyModal extends Component {
  unlinkAndRedirect = () => {
    this.props.confirmUnlink();
  };

  render() {
    const customStyles = {
      content: {
        top: "20%",
        margin: "0 auto",
        bottom: "auto",
        width: "588px",
        height: "379px",
        background: "#FAFAFA 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "24px",
      },
    };

    return (
      <Modal
        isOpen={this.props.open}
        style={customStyles}
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
      >
        <Detail>
          <Title>Change Epic Account</Title>
          <SubTitle>
            Warning! If you click confirm you’ll epic account will be unlinked
            and you’ll need to verify your epic account. Once you proceed you
            will have to go through the entire verification process again. If
            you still want to confirm click confirm.
          </SubTitle>
          <Operations>
            <Cancel onClick={this.props.closeModal}>Cancel</Cancel>
            <Home onClick={this.unlinkAndRedirect}>Confirm</Home>
          </Operations>
        </Detail>
      </Modal>
    );
  }
}

const Operations = styled.div`
  width: 350px;
  display: flex;
  justify-content: space-between;
`;

const SubTitle = styled.div`
  width: 456px;
  margin: 40px auto;
  text-align: center;
  font: normal 18px/23px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
`;

const Home = styled.div`
  width: 122px;
  height: 27px;
  background: #ff5f93 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font: bold 12px/17px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
`;

const Cancel = styled.div`
  width: 122px;
  height: 27px;
  border-radius: 6px;
  font: bold 12px/17px SF PRO, serif;
  background: grey 0% 0% no-repeat padding-box;
  letter-spacing: 0px;
  color: #ffffff;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
`;

const Title = styled.div`
  font: bold 35px/38px SF PRO, serif;
  letter-spacing: 0px;
  font-weight: 900;
  color: #000000;
`;

const Detail = styled.div`
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const mapStateToProps = ({ user, matches }) => ({ user, matches });

export default withRouter(connect(mapStateToProps)(ReverifyModal));

