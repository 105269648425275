import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from "react-router-dom";
import Firebase from "../util/firebase";
import moment from "moment";
import { Table, Button, message} from "antd";
import * as API from "../util/api";
import axios from "axios";

const Tournaments = ({history}) => {
    const [tournaments, setTournaments] = useState([]);
    useEffect(() => {
        const fetchTournaments = async () => {
            Firebase.firestore().collection('tournament').onSnapshot(querySnapshot => {
            let result = []
              querySnapshot.docs.forEach(doc => {
              const obj = {
                ...doc.data(),
                tournamentId: doc.id
              }
              result.push(obj);
            });
            setTournaments(result)
          });
          }
          fetchTournaments()
    }, []);

    const dataSource = useMemo(() => {
        const arr = tournaments.reduce((acc, curr) => {
            const {startDate, endDate, verifiedPlayers, title, published, currentRoundIndex, invitedList, tournamentId} = curr;
            const obj = {
                start:moment(startDate).format("LL"),
                end:moment(endDate).format("LL"),
                checkInCount:verifiedPlayers?.length??0,
                title,
                published,
                currentRoundIndex,
                invitedPlayers:invitedList?.length??0,
                tournamentId
            }
            acc.push(obj);
            return acc;
        }, []);
        return arr;
    }, [tournaments]);

    const publishTournament = async (record) => {
        try {
        const requestBody = {
            tournamentId:record.tournamentId
        }
        const url = API.PUBLISH_TOURNAMENT;
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
            message.success("Tournament is published")
        }else {
            message.error("Failed to publish tournament, please try again")
        }
        }catch(err){
            console.log(err);
            message.error("Failed to publish tournament, please try again")
        }
    }

    const columns = [
        {
          title:"Title",
          dataIndex:"title",
          key:"title"
        },
        {
          title:"Plan Start Date",
          dataIndex:"start",
          key:"start"
        },
        {
          title: "Plan End Date",
          dataIndex: "end",
          key: "end"
        },
        {
            title: "Invited Players",
            dataIndex: "invitedPlayers",
            key: "invitedPlayers"
        },
        {
            title: "CheckedIn Players",
            dataIndex: "checkInCount",
            key: "checkInCount"
        },
        {
            title: "",
            key: "publish",
            render:(record) => {
                if(record.published){
                    return <Button disabled={true}>
                        Published
                    </Button>
                }else {
                    return <Button type="primary" onClick={() => {
                        publishTournament(record);
                    }}>
                        Publish
                    </Button>
                }
            }
        },
        {
            title: "",
            key: "tournamentId",
            render:(record) => {
                return <Button 
                type="primary"
                onClick={() => {
                    history.push(`/tournament_admin/tournaments/${record.tournamentId}`)
                }}>
                    View
                </Button>
            }
        },

    ]
    return <>
    <h1>Tournaments</h1>
    <Table columns={columns} dataSource={dataSource} />
    </>
}

export default withRouter(Tournaments)