import React from "react";
import styled from "styled-components";

const ProfileActivities = ({activities, followed}) => {

  const generateText = (item) => {
    if(item.type === "JOIN_QUEST") {
      return `Joined ${item.questName} (Quest)`
    }else if(item.type === "FOLLOW_PLAYER"){
      return `Followed ${item.followName}`
    }else if(item.type === "UNOLLOW_PLAYER"){
      return `Unfollowed ${item.followName}`
    }else if(item.type === "WON_QUEST"){
      return `Won  ${item.questName} (Quest)`
    }else if(item.type === "CASH_OUT"){
      return `PayPal Cash out $${item.value}`;
    }
  };

  return <Container>
    {
      followed ? <>
       {
        activities.map((item, index) => {
          return <Box key={index}>
            <BoxItem style={{width:'60%'}}>  
              <Content>
                {generateText(item)}
              </Content>
              <Title>
                Action
              </Title>
            </BoxItem>
            <BoxItem style={{width:'60%'}}>
            <Content>
            <img
             src="../assets/Coin.svg"
             alt=""
             style={{
               height: "24px",
               marginRight: "8px",
             }}
           />
           {item?.transAmount?? "-"}
              </Content>
              <Title>
                Transaction
              </Title>
            </BoxItem>
          </Box>
        })
      }
      </> : <TopTitle>
      Please Follow To See Player's Activities!
      </TopTitle>
    }
     
  </Container>
}

const TopTitle = styled.div`
text-align: left;
font: normal normal bold 24px/51px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const Container = styled.div`
width:100%;
`;

const Box = styled.div`
width: 100%;
height: 79px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 11px;
margin-bottom:16px;
padding:12px 24px;
display:flex;
align-items:center;
`;


const BoxItem = styled.div`
display:flex;
flex-direction:column;
`;

const Content = styled.div`
text-align: left;
font: normal normal bold 24px/24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:6px;
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 12px SF PRO, serif;
letter-spacing: 0px;
color: #6A6A6A;
`;


export default ProfileActivities;