import React from "react";
import styled from "styled-components";

const EpicProfile = ({epicProfile}) => {
  const {hasAccess, killsDetail} = epicProfile;

  const boxDataDisplay = (mode) => {
    const detail = killsDetail[mode];
      return <>
      <BoxContent>
          {detail?.kills?? "-"}
      </BoxContent>
      <BoxSub>
          Kills
      </BoxSub>
      <BoxContent>
        {detail?.kd?? "-"}
      </BoxContent>
      <BoxSub>
          K/D
      </BoxSub>
      <BoxContent>
        {detail?.matchesPlayed?? "-"}
      </BoxContent>
      <BoxSub>
          Matches Played
      </BoxSub>
      <BoxContent>
        {detail?.winRate?? "-"}
      </BoxContent>
      <BoxSub>
        Win Rate
      </BoxSub>
      </>
  }

  return <>
    {
      (hasAccess && killsDetail) && <Container>
        <div>
          <Title>
            Solos
          </Title>
          <ModeBoxes>
            <Box>
              <BoxTitle>
                No BUILD
              </BoxTitle>
              {boxDataDisplay("nobuildbr_solo")}
            </Box>
            <Box>
            <BoxTitle>
                BUILD
              </BoxTitle>
              {boxDataDisplay("defaultsolo")}
            </Box>
            <Box>
            <BoxTitle>
                ARENA
              </BoxTitle>
              {boxDataDisplay("showdownalt_solo")}
            </Box>
          </ModeBoxes>
        </div>
        <div>
        <Title>
            Duos
          </Title>
          <ModeBoxes>
            <Box>
            <BoxTitle>
                No BUILD
              </BoxTitle>
              {boxDataDisplay("nobuildbr_duo")}
            </Box>
            <Box>
            <BoxTitle>
                BUILD
              </BoxTitle>
              {boxDataDisplay("defaultduo")}
            </Box>
            <Box>
            <BoxTitle>
                ARENA
              </BoxTitle>
              {boxDataDisplay("showdownalt_duos")}
            </Box>
            </ModeBoxes>
        </div>
        <div>
        <Title>
            Trios
          </Title>
          <ModeBoxes>
            <Box>
            <BoxTitle>
                No BUILD
              </BoxTitle>
              {boxDataDisplay("nobuildbr_trio")}
            </Box>
            <Box>
            <BoxTitle>
                BUILD
              </BoxTitle>
              {boxDataDisplay("trios")}
            </Box>
            <Box>
            <BoxTitle>
                ARENA
              </BoxTitle>
              {boxDataDisplay("showdownalt_trios")}
            </Box>
            </ModeBoxes>
        </div>
        <div>
        <Title>
            Squads
          </Title>
          <ModeBoxes>
            <Box>
            <BoxTitle>
                No BUILD
              </BoxTitle>
              {boxDataDisplay("nobuildbr_squad")}
            </Box>
            <Box>
            <BoxTitle>
                BUILD
              </BoxTitle>
              {boxDataDisplay("defaultsquad")}
            </Box>
            <Box>
            <BoxTitle>
                ARENA
              </BoxTitle>
              {boxDataDisplay("showdownalt_squads")}
            </Box>
            </ModeBoxes>
        </div>
      </Container>

    }
  </>
}

const Container = styled.div`
display:grid;
grid-template-columns: repeat(2, 496px);
grid-row-gap:36px;
grid-column-gap:36px;
margin-bottom:16px;
`;

const ModeBoxes = styled.div`
display:grid;
grid-template-columns: repeat(3, 150px);
grid-column-gap:24px;
`;

const Box = styled.div`
width: 150px;
height: 222px;
/* UI Properties */
background: #232526 0% 0% no-repeat padding-box;
border-radius: 11px;
padding:12px 18px;
display:flex;
flex-direction:column;
`

const Title = styled.div`
text-align: left;
font: normal normal bold 24px/51px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const BoxTitle = styled.div`
text-align: left;
font: normal normal 600 12px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
text-transform: uppercase;
margin-bottom:16px;
`;

const BoxContent = styled.div`
text-align: left;
font: normal normal 600 18px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:4px;
`;

const BoxSub = styled.div`
text-align: left;
font: normal normal 600 12px SF PRO, serif;
letter-spacing: 0px;
color: #6A6A6A;
margin-bottom:4px;
`;


export default EpicProfile;

