import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, Modal, Input, message, List,  Card, Col, Row, Form, DatePicker, Checkbox, Select} from "antd";
import * as API from "../../util/api";
import axios from "axios";
import moment from "moment";

const QuestPlanModal = ({planModalOpen, handleCancel, roundId}) => {
  const [form] = Form.useForm();
  const [gameModeSelected, setGameModeSelected] = useState(false);
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  const addNewPlan = async (values) => {
    const {date, modes} = values;
    const newPlan = {
      date
    }
    if(gameModeSelected){
      newPlan.gameModeSelected = true;
      newPlan.modes = modes;
    }
    try {
      const requestBody = {
        newPlan,
        roundId
      }
      const url = API.TOURNAMENT_ROUND_ADDPLAN;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        form.resetFields();
        message.success("Successfully add quest plan");
        handleCancel()
      }else {
        message.error("Failed to add quest plan");
      }
    }catch(err){
      message.error("Failed to add quest plan");
    }
  }

  const modeOptions = [
    {
      label:"Build Solos",
      value:"defaultsolo"
    },
    {
      label:"No Build Solos",
      value:"nobuildbr_solo"
    },
    {
      label:"Arena Solos",
      value:"showdownalt_solo"
    },
    {
      label:"Build Duos",
      value:"defaultduo"
    },
    {
      label:"No Build Duos",
      value:"nobuildbr_duo"
    },
    {
      label:"Arena Duos",
      value:"showdownalt_duos"
    },
    {
      label:"Build Trios",
      value:"trios"
    },
    {
      label:"No Build Trios",
      value:"nobuildbr_trio"
    },
    {
      label:"Arena Trios",
      value:"showdownalt_trios"
    },
    {
      label:"Build Squads",
      value:"defaultsquad"
    },
    {
      label:"No Build Squads",
      value:"nobuildbr_squad"
    },
    {
      label:"Arena Squads",
      value:"showdownalt_squads"
    },
  ];

  return <Modal  visible={planModalOpen} 
  title="Add Plan"
  onCancel={handleCancel}
  footer={[
    <Button key="back" onClick={handleCancel}>
      Return
    </Button>
  ]}>
    <Form
      name="validate_other"
      onFinish={addNewPlan}
      labelCol={{ span: 24 }}
      form={form}
    >
      <Form.Item
        name="date"
        label="Select Date"
        rules={[{ required: true, message: "Date is required" }]}
      >
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" placeholder="Select Time"/>
      </Form.Item>
      <Checkbox onChange={(e)=> {
        setGameModeSelected(e.target.checked)
      }} defaultChecked={false}>
          Only Accept Specific Game Modes
      </Checkbox>
      <Form.Item 
      name="modes"
      hidden={!gameModeSelected}
      rules={[
          {
            required: gameModeSelected,
            message: 'please choose game modes',
          },
        ]}
      >
      <Select
      mode="multiple"
      style={{
        width: '100%',
      }}
      placeholder="Please select game mode"
      options={modeOptions}
    />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary" >Add New Plan</Button>
      </Form.Item>
    </Form>
  </Modal>

}

export default QuestPlanModal;