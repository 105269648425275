import React, {useMemo} from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Menu, Dropdown, Popover, Skeleton, List, Empty} from "antd";
import {
  BellOutlined, AreaChartOutlined
} from '@ant-design/icons'
import { useDispatch } from "react-redux";

const InfluencerNavBar = ({activeTab, history, isDarkMode}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const token_count = useMemo(() => {
    return profile?.token_count??0;
  }, [profile]);

  const isAdmin = useMemo(() => {
    const permission_level = profile?.permission_level??0;
    if(permission_level === 3 && profile.influencerId){
      return true;
    }
    if(permission_level === 5 && profile.adminOfInfluencer){
      return true;
    }
    return false;
  }, [profile]);

  const dispatch = useDispatch();

  const logout = () => ({
    type:"LOG_OUT",
    payload:null
  });


  const menu = (
    <StyledMenu>
      {
        isAdmin && <Menu.Item
        onClick={() => {
          window.location.replace("https://club.arcadequest.app/")
        }}
      >
        <MenuBox>
        <Item>Influencer Portal</Item>
        <AreaChartOutlined  style={{
          fontSize:20
        }}/>
        </MenuBox>
      </Menu.Item>
      }

      <Menu.Item
        onClick={() => {dispatch(logout())}}
      >
        <MenuBox>
        <Item>Logout</Item>
        <img
          src="/assets/nav-logout.png"
          alt=""
          style={{
            height: "20px",
            width: "20px",

          }}
        />
        </MenuBox>
      </Menu.Item>
    </StyledMenu>
  );
  return <Container isDarkMode={isDarkMode}>
  <Left>
    <Logo onClick={() =>{
      history.push("/")
    }} src="../assets/aq_logo.png">
    </Logo>
    <NavButton onClick={() => {
      history.push("/")
    }} isActive={activeTab === 'home'} isDarkMode={isDarkMode}>Home</NavButton>
    <NavButton onClick={() => {
      history.push("/quest")
    }} isActive={activeTab === 'quest'} isDarkMode={isDarkMode}>Quests</NavButton>
    {
      isAdmin &&  <NavButton onClick={() => {
        window.location.replace("https://club.arcadequest.app/")
      }} isDarkMode={isDarkMode}>Dashboard</NavButton>
    }
    {/* <NavButton onClick={toTournament} isActive={activeTab === 'tournament'} isDarkMode={isDarkMode}>Exchange</NavButton> */}
  </Left>
  <Right>
  <TokenCount isDarkMode={isDarkMode}>
            <img
              src="/assets/ic_mytokens.png"
              alt=""
              style={{
                height: "16px",
                width: "20px",
                marginRight: "8px",
              }}
            />
            {token_count}
          </TokenCount>
          <Dropdown
            overlay={menu}
            placement="bottomLeft"
            onClick={(e) => e.preventDefault()}
            trigger={["click"]}
          >
            <Profile isDarkMode={isDarkMode} />
          </Dropdown>
  </Right>
</Container>
}

const Container = styled.div`
  height: 78px;
  padding: 15px 40px;
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 7px #57575729;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  ${p => p.isDarkMode && `background: #18181B 0% 0% no-repeat padding-box;`}
`;

const NavButton = styled.div`
  width: auto;
  margin-right: 24px;
  font: normal normal 600 20px SF PRO, serif;
  color: #484848;
  cursor: pointer;
  ${(props) =>
    props.isDarkMode && `color: #FAFAFA;`}

  ${(props) =>
    props.isActive && `color: #F72375;`}
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
width: auto;
height: 40px;
margin-right: 48px;
cursor: pointer;
`;

const Profile = styled.img`
  background: url("/assets/adaptive-icon-large.png") no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  cursor: pointer;
  ${(props) =>
    props.isDarkMode && `border:1px solid #18181B`}
`;

const TokenCount = styled.div`
  font: normal normal bold 16px SF PRO, serif;
  letter-spacing: 0px;
  color: #484848;
  margin-right: 32px;
  ${(props) =>
    props.isDarkMode && `color: #FAFAFA;`}
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  border-left: 2px solid #bfbfbf;
  padding-left: 32px;
`;

const StyledMenu = styled(Menu)`
  width: 245px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  padding: 8px 8px;
`;

const MenuBox = styled.div`
width: 225px;
height: 54px;
display: flex;
align-items: center;
justify-content: space-between;
padding:0 32px 0 16px;
`;

const Item = styled.div`
  font: normal normal 600 18px SF PRO, serif;
  letter-spacing: 0px;
  color: #484848;
`;

export default withRouter(InfluencerNavBar)

