import React, { useEffect, useState, useMemo } from "react";
import PrivateNavBar from "../../nav/privateNavBar";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Firebase from "../../util/firebase";
import axios from "axios";
import * as API from "../../util/api";
import Loading from "../../util/loading";
import { ArrowLeftOutlined  } from "@ant-design/icons";
import moment from "moment";
import { List } from "antd";
import { message } from "antd";
import Countdown from 'react-countdown';
import { calcProLeaguePool } from "../../util/helper";
import {
  DatePicker,
  Button
} from "antd";

const killsSortOngoing = (a, b) => {
  const killsA = (a.currentKills && a.startKills) ? a.currentKills - a.startKills : 0;
  const killsB = (b.currentKills && b.startKills) ? b.currentKills - b.startKills : 0;
  if(killsA < killsB){
    return 1;
  }else {
    return -1;
  }
}

const killsSortEnded = (a, b) => {
  const killsA = (a.finalKills && a.startKills) ? a.finalKills - a.startKills : 0;
  const killsB = (b.finalKills && b.startKills) ? b.finalKills - b.startKills : 0;
  if(killsA < killsB){
    return 1;
  }else {
    return -1;
  }
}

const ProLeagueControl = ({history, match}) => {
  const [quest, setQuest] = useState(null);
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const [rescheduleOpen, setRescheduleOpen] = useState(false);
  const [newStartTime, setNewStartTime] = useState(null);
  useEffect(() => {
    const proLeagueId =  match.params.id;
    let listener = null;
    const fetchQuest = () => {
      listener = Firebase.firestore().collection("pro_league").doc(proLeagueId).onSnapshot((doc) => {
        const {streamerId} = doc.data();
        if(!streamerId || streamerId !== userId){
          history.push("/")
        }else {
          const obj = {...doc.data(), proLeagueId};
          setQuest(obj);
        }
       })
    }
    if(!loggedIn || !profile || profile.permission_level === 0){
      history.push("/")
    }else {
      fetchQuest();
    }
    return () => {
      if(listener){
        listener();
      }
    }
  }, [loggedIn, match, profile, history, userId]);

  const prizePool = useMemo(() => {
    if(quest){
      return calcProLeaguePool(quest);
    }else {
      return 0;
    }
  }, [quest])

  const showSuccess = () => {
    message.success("You have successfully start quest");
  }

  const renderItem = (user) => {
    const { userName, joinedAt} = user;
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: 41,
          alignItems: "center",
          padding:"0 12px",
          borderBottom:"1px solid #707070"
        }}
      >
        <div
          style={{
            width: "50%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#F72375",
            textAlign: "left",
          }}
        >
          {userName}
        </div>
        <div
          style={{
            width: "50%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#FFFFFF",
            textAlign: "left",
          }}
        >
          {moment(joinedAt).format('LLL')}
        </div>
      </div>
    );
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Ended</span>
    } else {
      // Render a countdown
     /*  const mins = hours * 60 + minutes; */
     const hrs = days*24 + hours;
      return <span>{hrs}:{minutes}:{seconds}</span>;
    }
  };


  const sortedPlayers= useMemo(() => {
    if(quest){
      const players = quest?.players??[];
      if(quest.status === 2 || (quest.status === 3 && !quest.resultGenerated)){
        return players.sort(killsSortOngoing);
      }else if(quest.status === 3 && quest.resultGenerated){
        return players.sort(killsSortEnded);
      }else {
        return players;
      }
    }else {
      return []
    }
  },[quest]);

  const rankingList = useMemo(() => {
    if(sortedPlayers !== []) {
      const list = sortedPlayers.map((item, index) => {
        const kills = (item.currentKills && item.startKills) ? item.currentKills - item.startKills : 0;
        
        return {
          ...item,
          kills,
          placement: index+1
        }
      });
      return list;
    }else {
      return []
    }
  },[sortedPlayers,]);

  const rankingListResult = useMemo(() => {
    if(sortedPlayers){
      const list = sortedPlayers.map((item, index) => {
        const kills = (item.finalKills && item.startKills) ? item.finalKills - item.startKills : 0; 
        return {
          ...item,
          kills,
          placement: index+1
        }
      });
      return list;
    }else {
      return [];
    }
  },[sortedPlayers]);


  const renderItemRanking = (user, index) => {
    const { userName, kills, placement, isAccountPrivate} = user;
    return (
      <ItemContainer>
        <div
      style={{
        width: "5%",
        font: "normal normal 600 16px SF PRO, serif",
        color: "#F72375",
        textAlign: "left",
      }}
    >
    {index + 1}</div>
    <TierName tier={placement}>
      {userName} {isAccountPrivate && `(AccountPrivate)`}
    </TierName>
        <div
          style={{
            width: "30%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          {kills}
        </div>
        <div
          style={{
            width: "30%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#FFFFFF",
            textAlign: "right",
          }}
        >
          {kills >0 ? placement:"-"}
        </div>
      </ItemContainer>
    );
  };

  const renderItemRankingResult = (user, index) => {
    const { userName, kills,placement, isAccountPrivate} = user;
    return (
      <ItemContainer>
        <div
      style={{
        width: "5%",
        font: "normal normal 600 16px SF PRO, serif",
        color: "#F72375",
        textAlign: "left",
      }}
    >
    {index + 1}</div>
    <TierName tier={placement}>
      {userName} {isAccountPrivate && `(AccountPrivate)`}
    </TierName>
        <div
          style={{
            width: "20%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          {kills}
        </div>
        <div
          style={{
            width: "20%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          {placement}
        </div>
        <div
          style={{
            width: "20%",
            font: "normal normal 600 16px SF PRO, serif",
            color: "#FFFFFF",
            textAlign: "right",
          }}
        >
          {user?.coinsWon??0}
        </div>
      </ItemContainer>
    );
  };

  const cancelQuest = async () => {

    try {
      const requestBody = {
        proLeagueId: quest.proLeagueId,
        userId
      }
      const endpoint = API.CANCEL_PROLEAGUE;
      const res = await axios.post(endpoint, requestBody);
      if(res.status === 200){
        message.success("This quest is Cancel, all the entry fee is refunded")
      }else {
        message.error("System error, please try agin")
      }
    }catch(err){
      message.error("System error, please try agin")
    }
  }

  const confirmReschedule = async () => {
    if(newStartTime){
      try {
        const requestBody = {
          proLeagueId: quest.proLeagueId,
          newStartTime: newStartTime
        }
        const endpoint = API.RESCHEDULE_PROLEAGUE;
        const res = await axios.post(endpoint, requestBody);
        if(res.status === 200){
          message.success("This quest is Reschedule");
          setRescheduleOpen(false);
          setNewStartTime(null);
        }else {
          message.error("System error, please try agin")
        }
      }catch(err){
        message.error("System error, please try agin")
      }
    }else {
      message.error("Please select time")
    }
  }


  return <Container>
    <PrivateNavBar isDarkMode={true}/>
    {
      quest ? <Content>
        <Top>
          <TopLeft>
            <Title>
            <ArrowLeftOutlined style={{marginRight:8, display:'inline-block'}} onClick={() => {
              history.push("/creatorProLeagueEntry")
            }}/> {quest.questName}
            </Title>
            <Time>
              {moment(quest.startTime).format("YYYY-M-D  h:mm a")}
            </Time>
          </TopLeft>
          {
            quest.status === 1 && <CountDownBox>
               Quest starts In: <Countdown date={moment(quest.startTime).toDate()} renderer={renderer} daysInHours={true} />
               </CountDownBox>
          }
          {
            quest.status === 2 && <CountDownBox>
               Quest Ends In: <Countdown date={moment(quest.closeAt).toDate()} renderer={renderer}/>
            </CountDownBox>
          }
          {
            quest.status === 3 && <CountDownBox>
               Ended At: {moment(quest.closeAt).format("LLL")}
            </CountDownBox>
          }
          {
            quest.status === -1 && <CountDownBox>
               Quest Cancelled
            </CountDownBox>
          }
        </Top>
        {
          quest.status === 1 && <Cancel onClick={() => {
            cancelQuest();
          }}>
            Cancel Quest
          </Cancel>
        }
        {
          quest.status === 1 && !rescheduleOpen &&  <Cancel onClick={() => {
            setRescheduleOpen(true)
          }}>
            Reschedule
          </Cancel>
        }
        {
          rescheduleOpen && <RescheduleBox>
          <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" placeholder="Start Time" 
           disabledDate={(current) => {
            let customDate = moment().format("YYYY-MM-DD");
            return current && current < moment(customDate, "YYYY-MM-DD");
          }} 
          onChange={(date, dateString) => {
            const newDate = moment(date).format();
            setNewStartTime(newDate) 
          }}/>
          <Button style={{marginLeft:16}} onClick={confirmReschedule}>
            Confirm
          </Button>
          <Button style={{marginLeft:16}} onClick={() => {
            setRescheduleOpen(false);
            setNewStartTime(null)
          }}>
            Back
          </Button>
          </RescheduleBox>
        }
        <DataContainer>
          <Data>
            <DataContent>
              {quest.entryFee}
            </DataContent>
            <DataTitle>
              Entry Fee
            </DataTitle>
          </Data>
          <Data>
            <DataContent>
            <img src="../assets/coin.png" alt="" style={{width:24, height:24, marginRight:4}} />
            {prizePool}
            </DataContent>
            <DataTitle>
              Prize Pool
            </DataTitle>
          </Data>
          <Data>
            <DataContent style={{color:"#FFFFFF"}}>
              {quest.players?.length?? 0}
            </DataContent>
            <DataTitle>
              Players
            </DataTitle>
          </Data>
          <Data>
            <DataContent style={{color:"#FFFFFF"}}>
            {(quest?.duration?? 0)/60} hrs
            </DataContent>
            <DataTitle>
                Duration
            </DataTitle>
          </Data>
        </DataContainer>
        {
          quest.status === 1 && <>
           <Header>
            <HeaderItem style={{ width: "50%" }}>USER NAME</HeaderItem>
            <HeaderItem style={{ width: "50%" }}>DATE JOINED</HeaderItem>
      </Header>
      <PlayerBox>
      <List
              dataSource={quest.players}
              renderItem={(item, index) => (
                <List.Item key={index} style={{ padding: 0 }}>
                  {renderItem(item)}
                </List.Item>
              )}
            ></List>
      </PlayerBox>
          </>
        }
        {
          (quest.status === 2 || (quest.status === 3 && !quest.resultGenerated)) && <><Header>
          <HeaderItem style={{ width: "5%" }}></HeaderItem>
          <HeaderItem style={{ width: "35%" }}>USER NAME</HeaderItem>
          <HeaderItem style={{ width: "30%", textAlign:'center' }}>CURRENT KILLS</HeaderItem>
          <HeaderItem style={{ width: "30%", textAlign:'right' }}>PLACEMENT</HeaderItem>
    </Header>
    <PlayerBox>
    <List
    dataSource={rankingList}
    renderItem={(item, index) => (
      <List.Item key={index} style={{ padding: 0 }}>
        {renderItemRanking(item, index)}
      </List.Item>
    )}
  ></List>
    </PlayerBox></>
        }
         
        {
          (quest.status === 3 && quest.resultGenerated) && <><Header>
          <HeaderItem style={{ width: "5%" }}></HeaderItem>
          <HeaderItem style={{ width: "35%" }}>USER NAME</HeaderItem>
          <HeaderItem style={{ width: "20%", textAlign:'center' }}>TOTAL KILLS</HeaderItem>
          <HeaderItem style={{ width: "20%", textAlign:'center' }}>PLACEMENT</HeaderItem>
          <HeaderItem style={{ width: "20%", textAlign:'right' }}>COINS WON</HeaderItem>
    </Header>
    <PlayerBox>
    <List
    dataSource={rankingListResult}
    renderItem={(item, index) => (
      <List.Item key={index} style={{ padding: 0 }}>
        {renderItemRankingResult(item, index)}
      </List.Item>
    )}
  ></List>
    </PlayerBox>
    </>
        }
      </Content>:<Content><Loading /></Content>
    }
  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#000000 0% 0% no-repeat padding-box;
  position: relative;
  min-height:calc(100vh);
  padding-top:108px;
  padding-bottom:48px;
`;

const Content = styled.div`
  margin-left:10%;
  width:748px;
`;

const Top = styled.div`
width:100%;
display:flex;
justify-content:space-between;
`;

const TopLeft = styled.div`
height:53px;
display:flex;
flex-direction:column;
justify-content:space-between;
`;

const StartButton = styled.div`
width: 260px;
height: 53px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
text-align: center;
font: normal normal 18px/53px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
cursor:pointer;
`;

const Title = styled.div`
text-align: left;
font: normal normal bold 22px/24px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
`;

const Time = styled.div`
text-align: left;
font: normal normal normal 16px/16px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
`;

const DataContainer = styled.div`
margin:24px 0px 36px;
display:flex;
`;

const Data = styled.div`
display:flex;
flex-direction:column;
margin-right:48px;
`;

const DataContent = styled.div`
text-align: left;
font: normal normal 600 28px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
margin-bottom:16px;
`;

const DataTitle = styled.div`
text-align: left;
font: normal normal normal 16px SF PRO, serif;
letter-spacing: 0px;
color: #D1D1D1;
`;
const Header = styled.div`
  display: flex;
  margin-bottom:12px;
  margin-top:36px;
  width:748px;
`;

const HeaderItem = styled.div`
text-align: left;
font: normal normal bold 18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
`;

const PlayerBox = styled.div`
width: 741px;
min-height: 633px;
background: #2E2F30 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 2px;
padding:12px;
.ant-list-item, .ant-list-item:last-child {
  border-bottom:1px solid #707070 !important;
}
`;

const CountDownBox = styled.div`
width: auto;
height: 53px;
/* UI Properties */
border: 1px solid #FFFFFF;
font: normal normal 18px/53px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
text-align:center;
padding:0 16px;
`;

const ItemContainer = styled.div`
display: flex;
width: 100%;
height: 41px;
align-items: center;
border-bottom:1px solid #707070;
`;

const TierName = styled.div`
width:35%;
font: normal normal 600 16px SF PRO, serif;
color: #F72375;
textAlign: left;
color: #D1D1D1;
${p => p.tier === 1 && `color:#F72375`}
${p => p.tier === 2 && `color:#FFFFFF`}
`;

const Cancel = styled.div`
text-align: left;
text-decoration: underline;
font: normal normal medium 18px SF PRO, serif;
letter-spacing: 0px;
color:#ffffff;
cursor:pointer;
margin-top:12px;
`;

const RescheduleBox = styled.div`
display:flex;
margin:16px 0px;
`;

export default withRouter(ProLeagueControl);