import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
import styled from 'styled-components';
import { List} from "antd";

const LeaderBoard = ({quest, sortedPlayers, joined}) => {
  const renderRanking = (item, index) => {
    const {userName, killsCount} = item;
    
    return <PlayerContainer>
        <PlayerItem style={{width:'25%'}}>
          {index + 1}
        </PlayerItem>
        <PlayerItem style={{width:'50%'}}>
        {userName}
        </PlayerItem>
        <PlayerItem style={{width:'25%'}}>
          {killsCount}
          </PlayerItem>
    </PlayerContainer>
  }

  const renderPlayers = (item, index) => {
    const {userName} = item;
    
    return <PlayerContainer>
        <PlayerItem style={{width:'50%'}}>
          {index + 1}
        </PlayerItem>
        <PlayerItem style={{width:'50%'}}>
        {userName}
        </PlayerItem>
    </PlayerContainer>
  }

  return <Container joined={joined}>
    <Title>
      {quest.status === 1 ? 'Players':'Leaderboard'}
    </Title>
    {
      quest.status === 1 && <>
      <Header>
      <HeaderItem style={{ width: "50%" }}></HeaderItem>
      <HeaderItem style={{ width: "50%" }}>User Name</HeaderItem>
</Header>
    <List
            dataSource={quest.players}
            renderItem={(item, index) => (
              <List.Item key={index} >
                {renderPlayers(item, index)}
              </List.Item>
            )}
          />
      </>

    }
    {
      (quest.status === 2 || quest.status === 3) && <>
      <Header>
      <HeaderItem style={{ width: "25%" }}>Rank</HeaderItem>
      <HeaderItem style={{ width: "50%" }}>User Name</HeaderItem>
      <HeaderItem style={{ width: "25%" }}>
       Kills
      </HeaderItem>
</Header>
      <List
            dataSource={sortedPlayers}
            renderItem={(item, index) => (
              <List.Item key={index} >
                {renderRanking(item, index)}
              </List.Item>
            )}
          />
      </>
    }
  </Container>
}

const Container = styled.div`
width:330px;
display:flex;
flex-direction:column;
font-family: SF PRO, serif;
.ant-list-item {
  border-bottom:none;
}
${p => !p.joined && `width:880px;`}
`;

const Title = styled.div`
color: #FFF;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom:16px;
color:#FFFFFF;
`;

const Header = styled.div`
  display: flex;
  justify-content:start;
  margin:12px 0px;
`;

const HeaderItem = styled.div`
text-align: left;
color: #A5A5A5;
font-size: 16px;
font-style: normal;
font-weight: 510;
line-height: normal;
`;

const PlayerContainer = styled.div`
display:flex;
justify-content:space-between;
border-bottom:1px solid #2E2F30;
font-size: 16px;
font-style: normal;
font-weight: 500;
padding:4px 0px;
color:#F9F9F9;
width:100%;
`;


const PlayerItem = styled.div`
font-size: 16px;
font-style: normal;
font-weight: 500;
color:#F9F9F9;
`;
export default LeaderBoard;
