import React, {useMemo, useState}  from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment";
import { debounce } from "lodash";
import * as API from "../util/api";
import axios from "axios";
import { Button } from 'antd';
import {CardElement} from '@stripe/react-stripe-js';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import { ToastContainer, toast } from "react-toastify";

const MultiplierStatus = ({memberStatus, subDetail}) => {
  const {  profile, id: userId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const expiredAt = useMemo(() => {
    if(profile && profile.multiplier && profile.multiplier.renewAt){
      return moment(profile.multiplier.renewAt).format('MMMM Do ,YYYY');
    }
    return "";
  },[profile]);

  const stripe = useStripe();
  const elements = useElements();

  const debounceCancelMultiplier = debounce(() => {
    cancelMultiplier();
  }, 500);

  const debounceReactiveMultiplier = debounce(() => {
    reactiveMultiplier();
  }, 500);

  const debounceResub = debounce(() => {
    reSub()
  },500)

  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        'color': '#32325d',
        'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
        'fontSmoothing': 'antialiased',
        'fontSize': '16px',
        'backgroundColor':"#FFFFFF",
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const cancelMultiplier = async() => {
    const subId = profile.multiplier.subId;
    if(subId){
      const requestBody = {
        subId,
        userId
      }
      const endpoint = API.STRIPE_UNSUB_MULTIPLIER;
      try {
        const res = await axios.post(endpoint, requestBody);
        if(res.status === 200){
          setLoading(false)
        }
      }catch(err){
        
      }
    }
  };

  const reactiveMultiplier = async () => {
    const subId = profile.multiplier.subId;
    if(subId){
      const requestBody = {
        subId,
        userId
      }
      const endpoint = API.STRIPE_REACTIVE_MULTIPLIER;
      try {
        const res = await axios.post(endpoint, requestBody);
        if(res.status === 200){
          setLoading(false)
        }
      }catch(err){

      }
    }
  }

  const reSub = async() => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }else {
      try {
        const email = profile.email;
        const name = profile.first_name;
    
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email: email,
          },
        });
    
        if (result.error) {
          setLoading(false);
          toast.error(result.error.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        } else {
          const endpoint = API.STRIPE_MULTIPLIER_SUB;
          const res = await axios.post(endpoint, {'payment_method': result.paymentMethod.id, 'email': email, 'name':name});
          // eslint-disable-next-line camelcase
          const {client_secret, status, platformSubId, renewAt, startAt } = res.data;
    
          if (status === 'requires_action') {
            stripe.confirmCardPayment(client_secret).then(async function(result) {
              if (result.error) {
                setLoading(false);
                //failed
                toast.error("Payment Error, please try again or contact support", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                });
              } else {
                //success
                const requestBody = {
                  platformSubId,
                  renewAt:moment(renewAt*1000).format(),
                  startAt:moment(startAt*1000).format(),
                  userId
                }
                const subEndpoint = API.STORE_MULTIPLIER_SUB;
                const res = await axios.post(subEndpoint, requestBody);
                if(res.status === 200){
                  setLoading(false);
                }
              }
            });
          } else {
            //success
            const requestBody = {
              platformSubId,
              renewAt:moment(renewAt*1000).format(),
              startAt:moment(startAt*1000).format(),
              userId
            }
            const subEndpoint = API.STORE_MULTIPLIER_SUB;
            const res = await axios.post(subEndpoint, requestBody);
            if(res.status === 200){
              setLoading(false);
            }
          }
        }
      }catch(err){
        console.log(err);
      }
    }
  }

  return <Container>
    <ToastContainer />
  {
    memberStatus === 1 && <div >
      <Title>
    Get Multiplier
    </Title>
      {subDetail}
      <Box>
      Subscription renews on {expiredAt}
      </Box>
      <CancelButton onClick={() => {
        if(!loading){
          setLoading(true);
          debounceCancelMultiplier()
        }
      }}
      disabled={loading}
      >
        {
          loading ? "Loading...":"Cancel"
        }
      </CancelButton>
    </div>
  }
  {
    memberStatus === 2 && <div>
      <Title>
    Get Multiplier
    </Title>
      {subDetail}
      <Box>
      Subscription expires on {expiredAt}
      </Box>
      <Tips>
        You have cancel your subscription , if you want to keep your subscription after expire date, please reactivate
       </Tips>
      <ReactiveButton onClick={() => {
        if(!loading){
          setLoading(true);
          debounceReactiveMultiplier();
        }
      }}
      disabled={loading}
      >
        {
          loading ? "Loading...":"Reactive"
        }
      </ReactiveButton>
    </div>
  }
  {
    memberStatus === 3 && <div>
      <Title>
    Get Multiplier
    </Title>
      {subDetail}
      <Box>
      Subscription expires on {expiredAt}
      </Box>
      <Prices>
          <OriginPrice>
          $5.99 USD/mo
          </OriginPrice>
          <DiscountPrice>
            $2.99 USD/mo
            </DiscountPrice>
        </Prices>
      <Tips>
        Your subscription expired on {expiredAt}, if you want to keep your subscription after expire date, please reactivate
       </Tips>
       <CardContainer>
       <CardElement options={CARD_ELEMENT_OPTIONS} />
       </CardContainer>
       <ResubTips>
      <div>
      Guaranteed <b>safe & secure</b> checkout
      </div>
    <ResubBox>
      Powered by <b>Stripe</b>
    </ResubBox>
    </ResubTips>
      <ReactiveButton onClick={() => {
        if(!loading){
          setLoading(true);
          debounceResub();
        }
      }}
      disabled={loading}
      >
        {
          loading ? "Loading...":"Reactive"
        }
      </ReactiveButton>
    </div>
  }
  <CoinsImg src="../assets/coins_bg.png" />
  </Container>
}

const Title = styled.div`
  text-align: left;
  font: normal normal bold 38px SF PRO, serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-bottom:36px;
`;

const Box = styled.div`
width:289px;
padding:0 12px;
height: 30px;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 7px;
text-align: center;
font: normal normal bold 12px/30px SF PRO, serif;
letter-spacing: 0px;
color: #2A2A2A;
margin-bottom:24px;
`;

const CancelButton = styled(Button)`
width: 144px;
height: 49px;
/* UI Properties */
background: #727272 0% 0% no-repeat padding-box;
border-radius: 11px;
text-align: center;
font: normal normal bold 18px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
cursor:pointer;
border:none;
&:hover {
  background: #727272 0% 0% no-repeat padding-box;
  border:none;
  color: #FFFFFF;
}
`;

const ReactiveButton = styled(Button)`
width: 144px;
height: 49px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
box-shadow: 0px 12px 24px #F723757B;
border-radius: 11px;
border:none;
text-align: center;
font: normal normal bold 18px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
cursor:pointer;
${p => p.loading && `background: #F72375 0% 0% no-repeat padding-box;`}
&:hover {
  background: transparent linear-gradient(180deg, #F72375 0%, #F71C76 100%) 0% 0% no-repeat padding-box;
  border:none;
  color: #FFFFFF;
}
`;

const Tips = styled.div`
font: normal normal normal 14px SF PRO, serif;
letter-spacing: 0px;
color: #FF0000;
margin-bottom:12px;
`;
const Container = styled.div`
display:flex;
justify-content:space-between;
padding-right:10%;
align-items:start;
`;

const CoinsImg = styled.img`
width:335px;
`;

const CardContainer = styled.div`
width:435px;
margin-top:16px;
padding:12px;
background-color:#FFFFFF;
border-radius:5px;
margin-bottom:24px;
`;

const ResubTips = styled.div`
text-align: left;
font: normal normal normal 18px/23px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
display:flex;
align-items:center;
margin-bottom:16px;
`;
const ResubBox = styled.div`
margin-left:8px;
width: auto;
height: 34px;
border:1px solid #FFFFFF;
border-radius:5px;
padding:0px 8px;
text-align:center;
line-height:34px;
`;

const Prices = styled.div`
display:flex;
margin-bottom:12px;
`;

const DiscountPrice = styled.div`
font: normal normal bold 32px/41px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
`;

const OriginPrice = styled.div`
text-decoration: line-through;
font: normal normal bold 32px/41px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-right:12px;
`;

export default MultiplierStatus;