import { Layout, Menu, Button } from 'antd';
import React, { useState, useEffect, useMemo} from 'react';
import { withRouter } from "react-router-dom";
import { Route , Link} from "react-router-dom";
import Tournaments from './tournaments';
import CreateTournament from "./create";
import TournamentControl from "./tournamentControl"
import styled from 'styled-components';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

const {  Sider, Content, Header } = Layout;

const Dashboard = ({location, history}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [option, setOption] = useState("tournaments");
  console.log(option)

  useEffect(() => {
    const pathName = location.pathname;
    const arr = pathName?.split("/")?? [];
    if(arr && arr[2]){
      setOption(arr[2]);
    }else {
      setOption("tournaments")
    }
  }, [location])

  return <Layout style={{height:"100vh"}}>
  <Sider trigger={null} collapsible collapsed={collapsed}>
  <div
            style={{
              height: 54,
              display: "flex",
              alignItems: "center",
              justifyContent:'center',
              color:'white',
              backgroundColor:'grey',
              margin:'12px',
            }}
          >
      Tournament
          </div>
         <div style={{display:"flex", flexDirection:"column"}}>
          <Link to="/tournament_admin/tournaments">
             <Option>
                <Title selected={option === 'tournaments'}>
                  Tournaments
                </Title>
             </Option>
          </Link>
          <Link to="/tournament_admin/create">
             <Option>
              <Title selected={option === 'create'}>
                  Create New
                </Title>
             </Option>
          </Link>
          </div>
  </Sider>
  <Layout className="site-layout">
  <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        >
           {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
          <Button style={{height:42, marginLeft:16}} onClick={
            () => {
              history.push("/")
            }
          }>Back To Main Site</Button>
        </Header>
  <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            maxHeight:1000,
            overflowY:"scroll"
          }}
        >
       
      <Route exact path={"/tournament_admin/tournaments"} 
       component={() => <Tournaments />} />
       <Route exact path={"/tournament_admin/tournaments/:tournamentId"} 
       component={() => <TournamentControl />} />
       <Route exact path={"/tournament_admin/create"} 
       component={() => <CreateTournament />} />
    </Content>
    </Layout>
  </Layout>

}

const Option = styled.div`
margin-bottom:16px;
padding:0px 12px;
`;

const Title = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
${p => p.selected && `background-color:#1890ff`};
${p => p.selected ?`color: white`:
`color: #A8A8A8;`};
padding: 8px 24px;
`;

export default withRouter(Dashboard);