import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Firebase from "../../util/firebase";
import QuestCard from "./questCard";
import moment from 'moment';

const PastQuests = () => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const [quests, setQuests] = useState([]);
  useEffect(() => {
    let listener = null;
    const fetchQuests = async () => {
        listener = Firebase.firestore()
          .collection("creator_quest")
          .where("status", "==", 3)
          .onSnapshot(querySnapshot => {
            let quests = [];
            querySnapshot.forEach(function (doc) {
              const obj = {
                ...doc.data(),
               questId: doc.id,
              };
              quests.push(obj);
            });
            setQuests(quests);
          },
          error => {
            console.log(error);
          }
          )
    }
    fetchQuests();
    return () => {
      if(listener){
        listener();
      }
    }
  },[] );

  const timeSort = (a, b) => {
    const timeA = moment(a.startTime);
    const timeB = moment(b.startTime);
    if(timeA.isBefore(timeB)){
      return 1;
    }else {
      return -1;
    }
  }

  const joinedQuest = useMemo(() => {
    if(!userId || !quests || !profile.joinedCreatorQuest){
      return [];
    }else {
      const joinedCreatorQuest = profile?.joinedCreatorQuest?? [];
      return quests.filter((item) => joinedCreatorQuest.includes(item.questId)).sort(timeSort);
    }
  }, [userId, quests, profile]);

  const moreQuests = useMemo(() => {
    if(!userId || !quests || !profile.joinedCreatorQuest){
      return quests;
    }else {
      const joinedCreatorQuest = profile?.joinedCreatorQuest?? [];
      return quests.filter((item) => !joinedCreatorQuest.includes(item.questId)).sort(timeSort);
    }
  }, [userId, quests, profile]);


  return <Content>
    <Title>
    <span style={{color:"#F72375"}}>View</span> Result
    </Title>
    <Subtitle>
    View results from the past Quests
    </Subtitle>
    <QuestDivider />
    <Cate>
      Your Past Quests
    </Cate>
    <QuestContainer style={{marginBottom:36}}>
    {
        joinedQuest.length > 0 ? <QuestContainer>
          {
            joinedQuest.map((item) => {
              return  <QuestCard quest={item} joined={true} />
            })
          }
        </QuestContainer> :<QuestContainer>
        <Subtitle>
    You Don't Have Any Past Quests Yet!
    </Subtitle>
        </QuestContainer>
      }
    </QuestContainer>
    <QuestDivider />
    <Cate>
      More Past Quests
    </Cate>
      {
        moreQuests.length > 0 ? <QuestContainer>
          {
            moreQuests.map((item) => {
              return  <QuestCard quest={item} joined={false} />
            })
          }
        </QuestContainer> :<>
        </>
      }
    <QuestContainer>
    </QuestContainer>
  </Content>
}

const Content = styled.div`
width: 1180px;
margin:0px auto 48px;
@media (min-width: 1480px) {
  width: 1280px;
}
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 60px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const Subtitle = styled.div`
text-align: left;
font: normal normal 600 32px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const QuestDivider = styled.div`
height:0px;
width:100%;
border-bottom:2px solid #707070;
margin:36px 0px 18px;
`;

const Cate = styled.div`
text-align: left;
font: normal normal 600 32px SF PRO, serif;
letter-spacing: 0px;
color: #D6D6D6;
width:1120px;
margin:18px auto 36px;
`;

const QuestContainer = styled.div`
display:grid;
grid-template-columns: repeat(3, 343px);
justify-content:space-between;
grid-row-gap:24px;
width:1120px;
margin:0 auto;
`;

export default withRouter(PastQuests);
