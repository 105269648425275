import React, { useEffect, useMemo } from "react";
import PrivateNavBar from "../nav/privateNavBar";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ArrowRightOutlined} from "@ant-design/icons";
import { Mixpanel } from "../util/mixpanel";

const LinkAccount = ({history, location}) => {
  const { loggedIn, profile, verify_from} = useSelector((state) => state.user);
  useEffect(() => {
    if(!loggedIn){
      history.push("/login")
    }else if(loggedIn && profile){
      const {verify_status, savedEpicId} = profile;
      if(verify_status === 2 && savedEpicId){
        history.push("/")
      }
    }
    Mixpanel.track("[WEB] Reach Link Account Step")
  }, [profile, history, loggedIn]);

  const tryAgain = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const tryAgain = searchParams.get('tryAgain');
    if(tryAgain){
      return true;
    }else {
      return false;
    }
  }, [location])


  const verifyAccount = () => {
    Mixpanel.track("[WEB] Link Account")
    window.location.href = 'https://www.epicgames.com/id/authorize?client_id=xyza78919l2Tb2NxeRDnMnOj1ItKGkC2&response_type=code&scope=basic_profile&redirect_uri=https://play.arcadequest.app/linkAccountSuccess'; 
  }

  const dispatch = useDispatch();

  const setVerifyFrom = () => ({
    type:"SET_VERIFY_FROM",
    payload:null
  });

  const skipNow = () => {
    if(verify_from){
      const {target, target_matchId} = verify_from;
      if(target === 'home'){
        history.push("/");
        dispatch(setVerifyFrom())
      }
      if(target === 'match'){
        history.push(`/matchDetail/${target_matchId}`)
        dispatch(setVerifyFrom())
      }
      if(target === 'account'){
        history.push("/myAccount");
        dispatch(setVerifyFrom())
      }
      if(target === 'allMatches'){
        history.push("/allMatches");
        dispatch(setVerifyFrom())
      }
      if(target === "quest") {
        history.push("/quest");
        dispatch(setVerifyFrom())
      }
      if(target === 'proLeague'){
        history.push(`/proLeagueCustomer/${target_matchId}`);
        dispatch(setVerifyFrom())
      }
      if(target === 'creatorQuest'){
        history.push(`/creatorQuestCustomer/${target_matchId}`);
        dispatch(setVerifyFrom())
      }

    }else {
      history.push("/");
      dispatch(setVerifyFrom())
    }
  };

  return <Container>
    <PrivateNavBar isDarkMode={true}/>
    <Content>
    <Title>
      <span style={{color:"#F72375"}}>Link</span> Account
      </Title>
      <Subtitle>
      Link your epic games account to get rewarded for playing Fortnite, and get
      </Subtitle>
      <Bouns>
      3 Free Entries to Cash Prize Quests
      </Bouns>
        <Tip>
        CLICK THE BUTTON BELOW AND LOGIN TO YOUR EPIC GAMES ACCOUNT
        </Tip>
        <LinkButton onClick={verifyAccount}>
        Link Account  <ArrowRightOutlined />
        </LinkButton>
        {
          tryAgain && <>
          <Wrong>
          Something Went Wrong
          </Wrong>
          <Tip>
          PLEASE TRY AGAIN TO LINK YOUR EPIC GAMES ACCOUNT
          </Tip>
          </>
        }
        <Skip onClick={skipNow}>Skip for now</Skip>
    </Content>
  </Container>
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  background: #000000 0% 0% no-repeat padding-box;
  height:calc(100vh);
`;

const Content = styled.div`
width: 1180px;
margin:148px auto 0px;
@media (min-width: 1480px) {
  width: 1280px;
}
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 95px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:24px;
`;

const Subtitle = styled.div`
text-align: left;
font: normal normal 600 32px SF PRO, serif;
letter-spacing: 0px;
color: #D6D6D6;
margin-bottom:24px;
`;

const Bouns = styled.div`
text-align: left;
font: normal normal 600 65px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
margin-bottom:36px;
`;

const Tip = styled.div`
text-align: left;
font: normal normal bold 16px/28px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
text-transform: uppercase;
`;

const LinkButton = styled.div`
width: 172px;
height: 49px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
box-shadow: 0px 12px 24px #F7237559;
border-radius: 11px;
text-align: center;
font: normal normal bold 18px/49px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
text-align:center;
cursor:pointer;
margin-top:12px;
`;

const Wrong = styled.div`
text-align: left;
font: normal normal bold 24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin:16px 0px;
`;

const Skip = styled.div`
  text-align: left;
  text-decoration: underline;
  font: normal normal medium 12px/20px SF PRO;
  letter-spacing: 0px;
  color: #f72375;
  cursor: pointer;
  margin-top:16px;
`;

export default withRouter(LinkAccount);


