import React from "react";
import styled from "styled-components";

const MobileVersion = () => {
  return (
    <MobileContainer>
      <Top>
        <img
          src="assets/aq_logo.png"
          style={{ height: "40px", margin: "0 auto" }}
          alt="logo"
        />
      </Top>
      <Middle>
        <Title>What is ArcadeQuest?</Title>
        <SubTitle>
          We’re the first platform to reward streamers and viewers for playing
          Fortnite together.
        </SubTitle>
        <img
          src="../assets/1375_Tokens.png"
          style={{ height: 83, width: 125, marginBottom: 16 }}
          alt="Tokens"
        />
        <SubTitle style={{ width: 190 }}>
          Sign up today & Get Up To Coins for free
        </SubTitle>
        <Group>
          <a
            target="_blank"
            href="https://apps.apple.com/ca/app/arcadequest/id1563074649"
            rel="noopener noreferrer"
          >
            <img
              src="../assets/app_store_icon.png"
              alt=""
              style={{
                height: "40px",
                width: "135px",
                marginRight: 16,
                cursor: "pointer",
              }}
            />
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.arcadequest.app"
            rel="noopener noreferrer"
          >
            <img
              src="../assets/google-play-icon.png"
              alt=""
              style={{
                height: "40px",
                width: "154px",
                cursor: "pointer",
              }}
            />
          </a>
        </Group>
      </Middle>
      <Footer>
        <Connect>Connect with us!</Connect>
        <GroupIcons>
          <a
            target="_blank"
            href="https://www.instagram.com/arcadequestapp/"
            rel="noopener noreferrer"
          >
            <img
              src="../assets/ins_icon.png"
              alt=""
              style={{
                height: "28px",
                width: "28px",
                cursor: "pointer",
              }}
            />
          </a>
          <a
            target="_blank"
            href="https://www.twitch.tv/arcadequestofficial"
            rel="noopener noreferrer"
          >
            <img
              src="../assets/twitch_icon.png"
              alt=""
              style={{
                height: "28px",
                width: "28px",
                cursor: "pointer",
              }}
            />
          </a>
          <a
            target="_blank"
            href="https://discord.gg/vKtCx92hta"
            rel="noopener noreferrer"
          >
            <img
              src="../assets/discord_icon.png"
              alt=""
              style={{
                height: "28px",
                width: "28px",
                cursor: "pointer",
              }}
            />
          </a>
          <a
            target="_blank"
            href="https://twitter.com/arcadequestapp"
            rel="noopener noreferrer"
          >
            <img
              src="../assets/twitter_icon.png"
              alt=""
              style={{
                height: "28px",
                width: "28px",
                cursor: "pointer",
              }}
            />
          </a>
        </GroupIcons>
        <Copy>
          ™ & © 2021 ArcadeQuest, Inc. ArcadeQuest and all related logos,
          characters, names and distinctive likenesses thereof are exclusive
          property of ArcadeQuest, Inc. All Rights Reserved.
        </Copy>
      </Footer>
    </MobileContainer>
  );
};

const MobileContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  overflow-y: auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  background: url("assets/mobile_background.png") no-repeat;
  background-size: cover;
`;

const Title = styled.div`
  font: normal normal 900 36px/40px SF PRO, serif;
  text-align: center;
  margin-bottom: 16px;
  width: 246px;
  color: #ffffff;
  margin-top: 16px;
`;

const SubTitle = styled.div`
  width: 278px;
  text-align: center;
  font: normal normal bold 18px/28px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 16px;
`;

const Top = styled.div`
  width: 100%;
  padding: 16px 0px;
  background: transparent linear-gradient(180deg, #ffffffe3 0%, #ffffff02 100%)
    0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Group = styled.div`
  width: 278px;
  display: flex;
`;

const Middle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 12px;
`;

const Footer = styled.div`
  width: 100%;
  height: 117px;
  padding-bottom: 12px;
  background: transparent
    linear-gradient(180deg, #ffffff00 0%, #f8716c 92%, #80808000 100%) 0% 0%
    no-repeat padding-box;
`;

const Connect = styled.div`
  text-align: center;
  font: normal normal normal 12px/28px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
`;

const GroupIcons = styled.div`
  width: 198px;
  display: flex;
  justify-content: space-between;
  margin: 12px auto;
`;

const Copy = styled.div`
  height: 13px;
  width: 352px;
  margin: 0 auto;
  text-align: center;
  font: normal normal bold 8px SF PRO, serif;
  letter-spacing: 0px;
  color: #484848;
`;

export default MobileVersion;
