import React, {  useState, useMemo} from "react";
import styled from "styled-components";
import QuestBasic from "./questBasic";
import QuestRanking from "./questRanking";
import QuestChat from "./questChat";
import UserInfoAfterStart from "./userInfoAfterStart";
import { calcCreatorQuestTier, calcCreatorQuestTierPlacement } from "../../util/helper";


const OngoingStatus = ({joined, quest, isDailyQuest, isPromoed, companyInfo, productInfo, canClaimBouns, getClickBouns}) => {
  const [showOption, setShowOption] = useState(1);

  const killsSort = (a, b) => {
    const killsA = (a.currentKills && a.startKills) ? a.currentKills - a.startKills : 0;
    const killsB = (b.currentKills && b.startKills) ? b.currentKills - b.startKills : 0;
    if(killsA < killsB){
      return 1;
    }else {
      return -1;
    }
  }
  const sortedPlayers = useMemo(() => {
    const players = quest.players??[];
    return players.sort(killsSort);
  },[quest]);

  const tiers = useMemo(() => {
    if(quest){
      const rewardPerTier = calcCreatorQuestTier(quest);
      const placementPerTier = calcCreatorQuestTierPlacement(quest);
      const firstTier = {
        placement:placementPerTier.tier1,
        reward:rewardPerTier.tier1
      }
      const secondTier = {
        placement:placementPerTier.tier2,
        reward:rewardPerTier.tier2
      }
      const thirdTier = {
        placement:placementPerTier.tier3,
        reward:rewardPerTier.tier3
      }
      return {
        firstTier,
        secondTier,
        thirdTier
      }
    }else {
      return {}
    }
  }, [quest]);

  return <>
      {joined && <Choices isDarkMode={true}>
          <Choice active={showOption === 1} isDarkMode={true} onClick={() => {
              setShowOption(1)
            }}>
               Quests
          </Choice>
          <Choice active={showOption === 2} isDarkMode={true} onClick={() => {
              setShowOption(2)
            }}>
               Ranking
               </Choice>
        </Choices>}
        {
          (joined && showOption === 1) ? <Main>
            <QuestBasic quest={quest} isDailyQuest={isDailyQuest} isPromoed={isPromoed} />
            <UserInfoAfterStart quest={quest} sortedPlayers={sortedPlayers} tiers={tiers} isDailyQuest={isDailyQuest} 
             isPromoed={isPromoed} productInfo={productInfo} companyInfo={companyInfo}
            />
            <QuestChat quest={quest} joined={joined}/>
          </Main> : <QuestRanking quest={quest} sortedPlayers={sortedPlayers} joined={joined} isDailyQuest={isDailyQuest}
          tiers={tiers} ended={false} isPromoed={isPromoed} productInfo={productInfo} companyInfo={companyInfo}
          canClaimBouns={canClaimBouns}
          getClickBouns={getClickBouns}
          />
        }
  </>
}


const Choices = styled.div`
width: 100%;
margin:12px auto 24px;
display:flex;
height:34px;
border-bottom:3px solid #FAFAFA;
`;

const Choice = styled.div`
width: auto;
padding:0 12px;
height: 34px;
/* UI Properties */
font: normal normal bold 20px/24px SF PRO, serif;
letter-spacing: 0px;
text-align:center;
margin-right:18px;
cursor:pointer;
color: #000000;
${(p) =>
  p.isDarkMode && !p.active 
    && `color: #FAFAFA;`}
${(p) =>
   p.active
        ? `color:#F72375; border-bottom:3px solid #F72375;`
        : ``}
`;

const Main = styled.div`
display:flex;
width:100%;
justify-content:space-between;
margin-top:36px;
`;


export default OngoingStatus;