import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, Modal, Input, message, List,  Card, Col, Row, Typography} from "antd";
import moment from "moment";
import Firebase from "../../util/firebase";
import QuestDetail from './questDetail';
const { Title } = Typography;

const ConfirmedRound = ({currentRound, tournament}) => {
  const [quests, setQuests] = useState([]);

  const [view, setView] = useState("round");

  const [currentQuestId, setCurrentQuestId] = useState(null);

  useEffect(() => {
    const fetchQuest = async () => {
      Firebase.firestore().collection('tournament_quest').where("roundId", "==", currentRound.roundId).onSnapshot(querySnapshot => {
        let result = []
          querySnapshot.docs.forEach(doc => {
          const obj = {
            ...doc.data(),
            questId: doc.id
          }
          result.push(obj);
        });
       setQuests(result)
      });
    }
    fetchQuest()
  }, [currentRound.roundId]);

  const upcomingQuests = useMemo(() => {
    return quests.filter(item => item.status === 1)
  }, [quests]);

  const otherQuests = useMemo(() => {
    return quests.filter(item => item.status !== 1)
  }, [quests])

 
 
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Current Kills',
      dataIndex: 'currentKills',
      key: 'currentKills',
    },
  ]


  const currentQuest = useMemo(() => {
    const target = quests.find(item => item.questId === currentQuestId);
    return target;
  }, [quests, currentQuestId])


  return <>
  {
    view === "round"  &&   <Row gutter={16} style={{marginTop:24}}>
    <Col span={12}>
      <Title level={4}>All Quests</Title>
        <Title level={5}>Upcoming</Title>
        <Row gutter={16} style={{marginBottom:16}}>
        {upcomingQuests.map(item => {
          return <Col span={12}>
            <Card
          title={item.title}
          extra={<Button type="primary" onClick={() => {
            setView("quest");
            setCurrentQuestId(item.questId);
          }}>View Detail</Button>}
        >
          <p>Start Time:{moment(item.startTime).format("LL")}</p>
          <p>Players:{item.players.length}</p>
          <p>Duration:{(item.duration)/60} HR</p>
        </Card>
          </Col>
        })}
        </Row>
        <Title level={5}>Past Or Ongoing</Title>
        <Row gutter={16} style={{marginBottom:16}}>
        {otherQuests.map(item => {
          return <Col span={12}>
            <Card
          title={item.title}
          extra={<Button type="primary" onClick={() => {
            setView("quest");
            setCurrentQuestId(item.questId);
          }}>View Detail</Button>}
        >
          <p>Start Time:{moment(item.startTime).format("LL")}</p>
          <p>Players:{item.players.length}</p>
          <p>Duration:{(item.duration)/60} HR</p>
        </Card>
          </Col>
        })}
        </Row>
    </Col>

    <Col span={12}>
      <Title level={4}>LeaderBoard</Title>
      <Table columns={columns} dataSource={currentRound.killDatas} />
    </Col>
  </Row>
  }
  {
    view === 'quest' && currentQuest && <QuestDetail quest={currentQuest} currentRound={currentRound}
    backToRoundDetail= {() => {
      setView("round");
      setCurrentQuestId(null)
    }}
    teamsUp={tournament.teamsUp}
    />
  }
  </>
}

export default ConfirmedRound;