import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
import styled from 'styled-components';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Firebase from "../../util/firebase";
import PrivateNavBar from "../../nav/privateNavBar";
import PublicNavBar from "../../nav/publicNavBar";
import { useSelector } from "react-redux";
import Loading from "../../util/loading";
import { Mixpanel } from '../../util/mixpanel';
import { CloseOutlined } from '@ant-design/icons';
import QuestCard from '../creatorquests/questCard';
import ReactPlayer from 'react-player';
import { LeftCircleOutlined  } from "@ant-design/icons";
import {Helmet} from "react-helmet";

const BrandSessionCustomer = ({history, match, location}) => {
  const { items: partners} = useSelector((state) => state.partners);
  const { items: products} =  useSelector((state) => state.products);
  const [session, setSession] = useState(null);
  const [quests, setQuests] = useState([]);
  const [influencer, setInfluencer] = useState(null);

  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  useEffect(() => {
    let sessionListener = null;
    let questsListener = null;
    const sessionId = match.params.id;
    const fetchSession = async (sessionId) => {
      sessionListener = Firebase.firestore().collection("brand_session").doc(sessionId).onSnapshot((doc) => {
        const obj = {...doc.data(), sessionId};
        setSession(obj);
     })
    };

    const fetchQuests = (sessionId) => {
      questsListener = Firebase.firestore().collection('creator_quest').where("brand_sessionId", "==", sessionId).onSnapshot(querySnapshot => {
        let result = []
          querySnapshot.docs.forEach(doc => {
          const obj = {
            ...doc.data(),
            questId: doc.id
          }
          result.push(obj);
        });
       setQuests(result)
      });
    }

    fetchSession(sessionId);
    fetchQuests(sessionId);
    return () => {
      if(sessionListener){
        sessionListener();
      }
      if(questsListener){
        questsListener()
      }
    }
  }, [match]);

  useEffect(() => {
    const fetchInfluencer = async (influencerId) => {
      const ref = Firebase.firestore()
      .collection("influencer")
      .doc(influencerId);
      const doc = await ref.get();
      const data = doc.data();
      const result = {
        ...data,
        influencerId
      }
      setInfluencer(result) 
    }

    if(session?.influencerId){
      fetchInfluencer(session.influencerId);
    }
  }, [session])

  const productInfo = useMemo(() => {
    if(session?.productId){
      const product = products.find(item => item.productId === session.productId);
      if(product) return product;
    }
    return null;
  }, [session, products]);

  const partnerInfo = useMemo(() => {
    if(session?.partnerId){
      const company = partners.find(item => item.partnerId === session.partnerId);
      if(company) return company;
    }
    return null;
  },[partners, session]);

  const mixPaneltrack = () => {
    if(partnerInfo && productInfo){
      Mixpanel.track("Quest Promo Product Banner Clicks", {
        productName:productInfo.name,
        productId:productInfo.productId,
        partnerName:partnerInfo.name,
        partnerId:partnerInfo.partnerId,
      })
    }
  }

  const mixPanelTrackVideoPlay = () => {
    Mixpanel.track("Quest Promo Product Video Plays", {
      productName:productInfo.name,
      productId:productInfo.productId,
      partnerName:partnerInfo.name,
      partnerId:partnerInfo.partnerId,
    })
  }

  const sortedQuest = useMemo(() => {
    const result = quests.sort((a, b) => {
      if(moment(a.startTime).isBefore(moment(b.startTime))){
        return 1;
      }else {
        return -1;
      }
    })
    return result;
  }, [quests])

  const capitalize =(s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  }


  return <Container>
    <Helmet>
      <title>{`${capitalize(influencer?.name??"Influencer")} and ${capitalize(partnerInfo?.name??"Brand")} Cash Prize Competitions`}</title>
      <meta name="description" content={`All the sponsored Quests from ${capitalize(influencer?.name??"Influencer")} and ${capitalize(partnerInfo?.name??'Brand')} collaboration in one place on ArcadeQuest.`} />
      <meta name="keywords" content={`${capitalize(partnerInfo?.name??"Brand")}, ${capitalize(influencer?.name??"Influencer")}, cash prize, competitions, ArcadeQuest `} />
    </Helmet>
    {
      loggedIn ? <PrivateNavBar  isDarkMode={true}/>:<PublicNavBar isDarkMode={true}/>
    }
  <Content>
      {
        (session && productInfo) ? <>
        <Title>
       <LeftCircleOutlined onClick={() => {
        history.push(`/quest`)
       }}
       style={{marginRight:12}}
       /> 
       Sponsored Event
       </Title>
        <Banner image={productInfo?.bannerUrl} onClick={() => {
            mixPaneltrack()
            window.open(productInfo?.link)
          }} />
        <div style={{display:'flex', alignItems:'center', margin:"-48px 0px"}}>
        <Title>
          {influencer?.name} 
          </Title>
            <Stroke>
              X
            </Stroke>
            <Title>
            {partnerInfo?.name} Quests
              </Title>
              {
                influencer &&  <InfluencerBox>
                <ClubImage src={influencer?.profile_image_url?? ""} alt="avatar" />
                  <ClubMiddle style={{marginLeft:16}}>
                    <Name>
                      {influencer?.name?? ""}
                    </Name>
                    <Followers> 
                    <img src="/assets/twitch_icon.png" alt="twitch" style={{width:14, height:14, marginRight:6}}/>{influencer?.metricsData?.followers?? 0} Followers
                    </Followers>
                  </ClubMiddle>
                  {
                    (session.liveStreamLink || influencer.twitchUrl) && <ViewButton onClick={() => {
                      if(session.liveStreamLink){
                        window.open(session.liveStreamLink)
                      }else if(influencer.twitchUrl){
                        window.open(influencer.twitchUrl)
                      }
                    }}>
                     Watch Live
                    </ViewButton>
                  }
                </InfluencerBox>
              }
             
              </div>
             
          <FlexBox>
            <div>
              <QuestContainer>
                {sortedQuest.map((item) => {
                  return <QuestCard quest={item} joined={(profile?.joinedCreatorQuest?? []).includes(item.questId)}/>
                })}
              </QuestContainer>
              <PromoBanner image={productInfo?.lowerBannerUrl}  onClick={() => {
         mixPaneltrack()
         window.open(productInfo?.link)
      }}
      line={(quests.length <= 2) ? 1 :2}
      ></PromoBanner>
            </div>
            <div style={{width:548}}>
              <FlexBox style={{marginBottom:24}}>
                <Box>
                  <span style={{display:'block', color:"#FFF", fontSize:64, fontWeight:700}}>
                  <img
              src="/assets/Coin.svg"
              alt=""
              style={{
                height: "32px",
                marginRight:4
              }}

            />
                    {session?.totalPrize??0}
                  </span>
                  <span style={{display:'block', color:"#FFF", fontSize:24, fontWeight:700}}>
                 TOTAL PRIZE POOL
                  </span>
                </Box>
                <ProductImg image={productInfo?.productUrl?? ""} onClick={() => {
         mixPaneltrack()
         window.open(productInfo?.link)
      }}/>
              </FlexBox>
              <ReactPlayer
      url={productInfo?.video??""}
      playing={false}
      width="548px"
      height="363px"
      controls
      onStart={mixPanelTrackVideoPlay}
    />
            </div>
          </FlexBox>
        </> : <Loading />
      }
  </Content>
  </Container>

}

const Container = styled.div`
  width: 100%;
  background:#000000 0% 0% no-repeat padding-box;
  position: relative;
  min-height:calc(100vh);
  padding-top:108px;
  font-family:SF PRO, serif;
`;

const Content = styled.div`
width: 1280px;
margin:0px auto 48px;
padding-bottom:48px;
`;

const Banner = styled.div`
width:100%;
margin:24px 0px 0px;
cursor:pointer;
position:relative;
border-radius: 11px;
background: url(${(p) => p.image}); 
background-size: cover;
height:134px;
`;

const Title = styled.div`
color: #FFF;
font-size: 32px;
font-style: normal;
font-weight: 900;
text-transform: uppercase;
`;

const Stroke = styled.span`
display:inline-block;
font-size: 160px;
font-style: normal;
text-transform: uppercase;
-webkit-text-stroke: white 1px;
color:transparent;
font-family: sans-serif;
margin:0 16px;
`;

const FlexBox = styled.div`
display:flex;
width:100%;
justify-content:space-between;
`;

const QuestContainer = styled.div`
display:grid;
grid-template-columns: repeat(2, 343px);
grid-row-gap:36px;
grid-column-gap:36px;
`;

const Box = styled.div`
width: 269px;
height: 181px;
flex-shrink: 0;
border-radius: 10px;
background: #1C1D20;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
`;

const ProductImg = styled.div`
width: 247px;
height: 181px;
border-radius: 7px;
background: url(${(p) => p.image}); 
background-size: cover;
position:relative;
cursor:pointer;

`;

const PromoBanner = styled.div`
height: 173px;
width:718px;
background: url(${(p) => p.image}); 
background-size: cover;
position:relative;
border-radius:11px;
cursor:pointer;
margin-top:24px;
${p => p.line === 1 && `margin-top:108px;`}
`;

const InfluencerBox = styled.div`
margin-left:auto;
width:383px;
height:96px;
border-radius: 10px;
background: #1C1D20;
display:flex;
align-items:center;
padding:0 12px;
`;

const ClubImage = styled.img`
height:61px;
width:61px;
border-radius: 7px;
`;

const ClubMiddle = styled.div`
display:flex;
justify-content:space-between;
text-align:left;
flex-direction:column;
`;

const Name = styled.div`
font: normal normal 600 20px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
`;

const Followers = styled.div`
font: normal normal 600 18px/29px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
`;

const ViewButton = styled.div`
width: auto;
height: 30px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 7px;
font: normal normal bold 16px/30px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
text-align:center;
cursor:pointer;
padding:0 8px;
margin-left:auto;
`;

export default withRouter(BrandSessionCustomer)