import React, { Component } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Button, Input } from "antd";

//firebase
import Firebase from "../../util/firebase";

class ForgetPasswordModal extends Component {
  state = {
    view: 1,
    email: "",
    errorMessage: "",
    showError: false,
    loading: false,
  };
  finish = () => {
    this.setState({
      view: 1,
      email: "",
      errorMessage: "",
      showError: false,
      loading: false,
    });
    this.props.closeModal();
  };
  changeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  resetEmail = () => {
    let me = this;
    const auth = Firebase.auth();
    const { email } = this.state;
    this.setState({
      loading: true,
    });
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        me.setState({
          view: 2,
          loading: false,
        });
      })
      .catch(function (error) {
        me.setState({
          errorMessage: error.message,
          loading: false,
        });
        setTimeout(function () {
          me.setState({ errorMessage: "" });
        }, 5000);
      });
  };

  render() {
    const { view, errorMessage } = this.state;
    const customStyles = {
      content: {
        top: "20%",
        margin: "0 auto",
        bottom: "auto",
        width: "638px",
        height: "379px",
        background: "#FAFAFA 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "24px",
      },
    };

    return (
      <Modal
        isOpen={this.props.open}
        style={customStyles}
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
      >
        <Detail>
          {view === 1 && (
            <>
              <Title>Forgot Password</Title>
              <EmailContainer>
                <Enter>Enter Your Email Address:</Enter>
                <Email>
                  <img
                    src="../assets/email.png"
                    alt=""
                    style={{
                      height: "22px",
                      width: "22px",
                      marginRight: "16px",
                    }}
                  />
                  <Input
                    style={{
                      width: 330,
                      height: 28,
                      background: "#FAFAFA 0% 0% no-repeat padding-box",
                      border: "none",
                    }}
                    value={this.state.email}
                    onChange={this.changeEmail}
                    type="email"
                  />
                </Email>
                <Error>{errorMessage}</Error>
              </EmailContainer>
              <ResetButton
                disabled={this.state.email.length === 0 || this.state.loading}
                onClick={this.resetEmail}
              >
                Reset Password
              </ResetButton>
            </>
          )}
          {view === 2 && (
            <>
              <Title>Forgot Password</Title>
              <SubTitle>
                Thank you! If there’s an email address associated with this
                account you will get reset instructions sent to your inbox.
              </SubTitle>
              <Home onClick={this.finish}>Finish</Home>
            </>
          )}
        </Detail>
      </Modal>
    );
  }
}

const SubTitle = styled.div`
  width: 456px;
  margin: 40px auto;
  text-align: center;
  font: normal 18px/23px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
`;

const Home = styled(Button)`
  width: 122px;
  height: 27px;
  background: #ff5f93 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font: bold 12px/17px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
`;

const Error = styled.span`
  font: normal 12px SF PRO, serif;
  color: red;
  text-align: red;
`;

const Email = styled.div`
  width: 379px;
  border-bottom: 3px solid #70707030;
  height: 40px;
  display: flex;
  align-items: center;
`;

const EmailContainer = styled.div`
  width: 379px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Enter = styled.div`
  font: normal 18px/23px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 10px;
`;

const ResetButton = styled(Button)`
  width: 225px;
  height: 27px;
  background: #ff5f93 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font: bold 12px/17px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
`;

const Title = styled.div`
  font: bold 35px/38px SF PRO, serif;
  letter-spacing: 0px;
  font-weight: 900;
  color: #000000;
`;

const Detail = styled.div`
  padding: 60px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default ForgetPasswordModal;