import React, {  useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import * as API from "../../../util/api";
import axios from "axios";
import { LeftCircleOutlined  } from "@ant-design/icons";
import { message } from "antd";
import moment from 'moment';
import { debounce } from "lodash";

const CheckInModal = ({modalOpen, closeModal, tournament}) => {
  
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const debounceJoin = debounce(() => {
    joinTournament()
}, 500);


  const joinTournament = () => {
    if(tournament.tournament_type === 2 ){
      if(!isActiveSub){
        setLoading(false);
        closeModal();
        message.error("You don't have access to multiplier only tournament")
      }else {
        joinAsSub();
      }
    }else if(tournament.tournament_type === 3){
      if(isActiveSub) {
        joinAsSub()
      }else {
        if(profile.token_count < tournament.entryFee){
          setLoading(false);
          closeModal();
          message.error("You don't have enough coins")
        }else {
          joinWithCoins()
        }
      }
    }
  }

  const joinAsSub = async () => {
    try {
      const requestBody = {
        tournamentId:tournament.tournamentId,
        userId
      }
      const url = API.TOURNAMENT_JOINASSUB;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        setLoading(false);
        closeModal();
        message.success("You have successfully join the tournament")
      }else {
        setLoading(false);
        closeModal();
        message.error("Failed to join tournament, please try again")
      }
    }catch(err){
      console.log(err);
      setLoading(false);
      closeModal();
      message.error("Failed to join tournament, please try again")
    }
  }

  const joinWithCoins = async () => {
    try {
      const requestBody = {
        tournamentId:tournament.tournamentId,
        userId,
        transId:profile.transId
      }
      const url = API.TOURNAMENT_JOINWITHCOINS;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        setLoading(false);
        closeModal();
        message.success("You have successfully join the tournament")
      }else {
        setLoading(false);
        closeModal();
        message.error("Failed to join tournament, please try again")
      }
    }catch(err){
      console.log(err);
      setLoading(false);
      closeModal();
      message.error("Failed to join tournament, please try again")
    }
  }

  const isActiveSub = useMemo(() => {
    if(loggedIn && profile && profile.multiplier){
      const multiplier = profile.multiplier;
      if(multiplier.renewAt && moment(multiplier.renewAt).isAfter(moment()) && !multiplier.requestCancel){
        return true
      }
    }
    return false;
  },[profile, loggedIn]);
  
  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: "613px",
      height:'auto',
      padding:'24px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "24px",
      zIndex: "10000",
    },
  };
  return <Modal
  isOpen={modalOpen}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
>
  <Title>
    <LeftCircleOutlined onClick={closeModal}/> Join Tournament
  </Title>
  <Content>
    <div style={{width:'45%'}}>
      <SubTitle>
        Entry Fee
      </SubTitle>
      <Detail>
        <span style={{display:'inline-block', color:"#F72375"}}>
          {(tournament.tournament_type === 2 || isActiveSub) ?`FREE`: (tournament.entryFee??0)}
        </span>
        <Icon src="../assets/Coin.svg" alt="icon"/>
      </Detail>
    </div>
    <div style={{width:'45%'}}>
      <SubTitle>
        Prize Pool
      </SubTitle>
      <Detail>
        <span style={{display:'inline-block', color:"#F72375"}}>
            {tournament.prizePool??0}
        </span>
        <Icon src="../assets/Coin.svg" alt="icon"/>
      </Detail>
    </div>
  </Content>
  <JoinButton onClick={() => {
    if(!loading){
      setLoading(true);
      debounceJoin()
    }
  }}>
  {(tournament.tournament_type === 2 || isActiveSub) ?`Join`: `Pay & Join`}
  </JoinButton>
</Modal>
}

const Title = styled.div`
color: #FFF;
font-family: SF PRO, serif;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom:24px;
`;

const Content = styled.div`
display:flex;
width:100%;
justify-content:space-between;
`;

const SubTitle = styled.div`
color: #979797;
font-family: SF PRO, serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

const Detail = styled.div`
margin-top:12px;
padding:12px 0px;
width:100%;
border-bottom:2px solid #FFFFFF;
display:flex;
font-family: SF PRO, serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

const Icon = styled.img`
width:18px;
height:18px;
margin-left:auto;
`;

const JoinButton = styled.div`
border-radius: 10px;
background: #F72375;
width:100%;
height:39px;
margin:24px 0px 12px;
cursor:pointer;
color: #FFF;
text-align: center;
font-family: SF PRO, serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 39px;
text-align:center;
`;



export default CheckInModal