import React, { useMemo, useEffect, useState }from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Progress } from "antd";
import moment from "moment";
import Countdown from 'react-countdown';
import { useSelector } from "react-redux";
import { calcProLeagueReward, calcProLeaguePool } from "../../util/helper";

const QuestBasic = ({quest}) => {
  const { profile, id: userId} = useSelector((state) => state.user);
  const [initial, setInitial] = useState(0);
  useEffect(() => {
    let timer = null; 
      if(quest.actualStartedAt && quest.closeAt){
        const past = moment().diff(quest.actualStartedAt, 'second');
        const duration = moment(quest.closeAt).diff(moment(quest.actualStartedAt), 'second');
        setInitial(past);
        timer = setInterval(() => {
          setInitial(prev => {
            if(prev < duration){
              return prev + 1;
            }
          });
        }, 1000);
  }
    return () => {
      if(timer){
        clearInterval(timer);
      }
    }
  }, [quest.actualStartedAt, quest.closeAt, quest.startTime]);



  const timePercent = useMemo(() => {
    if(quest && quest.startTime && quest.createdAt){
      const duration = moment(quest.startTime).diff(quest.createdAt, "seconds");
      const now = moment();
      const initial = now.diff(quest.createdAt, "seconds");
      return ((initial) / duration) * 100;
    }
  },[quest]);


  const remaining = useMemo(() => {
    if(quest && quest.startTime){
      const remains = moment(quest.startTime).diff(moment(), "second");
      if(remains <= 0){
        return "Starting Soon"
      }else if (remains > 86400){
        const days = Math.floor(remains / 86400);
        return `${days} DAYS`
      }else if (remains < 60 * 60 * 24 && remains > 60 * 60){
        const hours = Math.floor(remains / 3600);
        return `${hours} HRS`
      }else if( remains < 60 * 60 && remains > 60){
        const minutes = Math.floor(remains / 60);
        return `${minutes} MINS`
      }else if(remains > 0 && remains< 60){
        return `${remains} SESC`
      }
    }
    return ""
  }, [quest]);

  const questPrizePool = useMemo(() => {
    if(quest){
      return calcProLeaguePool(quest);
    }
    return 0;
  }, [quest]);

  const afterStartPercent = () => {
    const duration = moment(quest.closeAt).diff(moment(quest.actualStartedAt), 'second');
    if(initial < duration){
      return (initial/duration)*100;
    }else {
      return 100;
    }
  }


  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Ended</span>
    } else {
      // Render a countdown
      const mins = hours * 60 + minutes;
      return <span>{mins} MINS</span>;
    }
  };

  const entryFee = useMemo(() => {
    const isActiveSub = profile && profile.multiplier && profile.multiplier.renewAt && moment(profile.multiplier.renewAt).isAfter((moment()));
    if(isActiveSub){
      return quest.entryFee* 0.85
    }else {
      return quest.entryFee
    }
  }, [quest, profile]);

  const prizePoolMode = useMemo(() => {
    let mode = 1;
    if(quest){
      if(quest.prizePoolMode === 2 && quest.prizePool > 0 ) {
        mode = 2;
      }else if(quest.prizePoolMode === 3 && quest.prizePool > 0 ){
        mode = 3;
      }
    }
    return mode;
  }, [quest]);

  const variableMessage = `Prize pool is VARIABLE, the prize pool is based off of entries! \n Every player who enters adds to the prize pool. \n  Top 3 players win the big prize.\n There’s no limit to the size of the prize pool. \n First leaderboard update is at 15 mins. \n  Leaderboard updates 5 minutes after your match ends. \n  Entry closes when the Quest starts! \n.`;
  const fixedMessage = `Prize pool is FIXED, the prize pool stays the same as players join!  Top 3 players win the big prize. \n First leaderboard update is at 15 mins. \n  Leaderboard updates 15 minutes after your match ends. \n  Entry closes when the Quest starts! \n  `;
  const hybridMessage = `Prize pool is HYBRID, there’s a guaranteed prize pool and each player who joins adds to the prize pool! \n   Top 3 players win the big prize. \n There’s no limit to the size of the prize pool. \n First leaderboard update is at 15 mins. \n  Leaderboard updates 5 minutes after your match ends. \n  Entry closes when the Quest starts! \n `;

  const modeOptions = [
    {
      label:"Build Solos",
      value:"defaultsolo"
    },
    {
      label:"No Build Solos",
      value:"nobuildbr_solo"
    },
    {
      label:"Arena Solos",
      value:"showdownalt_solo"
    },
    {
      label:"Build Duos",
      value:"defaultduo"
    },
    {
      label:"No Build Duos",
      value:"nobuildbr_duo"
    },
    {
      label:"Arena Duos",
      value:"showdownalt_duos"
    },
    {
      label:"Build Trios",
      value:"trios"
    },
    {
      label:"No Build Trios",
      value:"nobuildbr_trio"
    },
    {
      label:"Arena Trios",
      value:"showdownalt_trios"
    },
    {
      label:"Build Squads",
      value:"defaultsquad"
    },
    {
      label:"No Build Squads",
      value:"nobuildbr_squad"
    },
    {
      label:"Arena Squads",
      value:"showdownalt_squads"
    },
  ]

  const reward = useMemo(() => {
    if(quest){
      const result = calcProLeagueReward(quest);
      return {
        first:result.first,
        second:result.second,
        third:result.third,
      }
    }else {
      return {}
    }
  }, [quest]);

  

  return <Container>
    {
      quest.status === 1 && <>
      <Progress
      percent={timePercent}
      showInfo={false}
      strokeColor="#F72375"
      strokeWidth={5}
    />
        <Time>
          <span style={{font:"normal normal medium 12px/25px SF PRO, serif", color:"#B7B7B7", display:'inline-block'}}>
            STARTS IN:
          </span>
          <span style={{font:"normal normal medium 12px/25px SF PRO, serif", color:"#FFFFFF", display:'inline-block', textTransform:"uppercase"}}>
            {remaining}
          </span>
        </Time>
      </>
    }
    {
      quest.status === 2 && <>
  
        <Progress
      percent={afterStartPercent()}
      showInfo={false}
      strokeColor="#F72375"
      strokeWidth={5}
    />
     <Time>
          <span style={{font:"normal normal medium 12px/25px SF PRO, serif", color:"#B7B7B7", display:'inline-block'}}>
            ENDS IN:
          </span>
          <span style={{font:"normal normal medium 12px/25px SF PRO, serif", color:"#FFFFFF", display:'inline-block', textTransform:"uppercase"}}>
          <Countdown date={moment(quest.closeAt).toDate()} renderer={renderer}/>
          </span>
        </Time>
      </>
    }
        <Detail>
          <BasicItem >
          <ItemContent>
            <img
                src="../assets/Coin.svg"
                alt=""
                style={{
                  height: "18px",
                  marginRight: "4px",
                }}
              /> {questPrizePool}
            </ItemContent>
            <ItemTitle>
              Total Prizes
            </ItemTitle>
          </BasicItem>
          <BasicItem >
          <ItemContent style={{color: "#FFFFFF", textAlign:'center'}} >
              {quest?.players?.length?? 0}
            </ItemContent>
            <ItemTitle style={{textAlign:'center'}}>
              Players
            </ItemTitle>
          </BasicItem>
          <BasicItem style={{background:"#2E2F30 0% 0% no-repeat padding-box", borderRadius:7}}>
          <ItemContent style={{color: "#FFFFFF", textAlign:'center'}} >
            {
              `${(quest.duration)/60} HR`
            }
            </ItemContent>
            <ItemTitle style={{textAlign:'center'}}>
              Duration
            </ItemTitle>
          </BasicItem>
          <BasicItem >
          <ItemContent style={{textAlign:'right'}}>
            {entryFee}
            </ItemContent>
            <ItemTitle style={{textAlign:'right'}}>
              Entry Fee
            </ItemTitle>
          </BasicItem>
        </Detail>
        <Prizes>
        <Prize>
            <PrizeLeft>
            <img
                      src="../assets/firstPrize.png"
                      alt=""
                      style={{
                        width: 15,
                        height: 12,
                        marginRight: 12,
                      }}
                    />{" "}
                1st Place
            </PrizeLeft>
            <PointBox>
              +35 Points
            </PointBox>
            <PrizeRight>
                {reward.first}
            </PrizeRight>
          </Prize>
        <Prize style={{borderTop:'1px solid #D1D1D1'}}>
            <PrizeLeft>
            <img
                      src="../assets/secondPrize.png"
                      alt=""
                      style={{
                        width: 15,
                        height: 12,
                        marginRight: 12,
                      }}
                    />{" "}
               2nd Place
            </PrizeLeft>
            <PointBox>
              +20 Points
            </PointBox>
            <PrizeRight>
            {reward.second}
            </PrizeRight>
          </Prize>
          <Prize style={{borderTop:'1px solid #D1D1D1'}}>
            <PrizeLeft>
            <img
                      src="../assets/thirdPrize.png"
                      alt=""
                      style={{
                        width: 15,
                        height: 12,
                        marginRight: 12,
                      }}
                    />{" "}
               3rd Place
            </PrizeLeft>
            <PointBox>
              +10 Points
            </PointBox>
            <PrizeRight>
            {reward.third}
            </PrizeRight>
          </Prize>
          </Prizes>
          <Update>
            {
              quest.status === 1 && <><Item style={{width:'35%'}}>
              <ItemContent style={{color:"#FFFFFF"}}>
                Kills
              </ItemContent>
              <ItemTitle style={{fontSize:14}}>
                Points
              </ItemTitle>
            </Item>
            <Item style={{width:'65%'}}>
              <ItemContent style={{color:"#FFFFFF"}}>
              {moment(quest.startTime).format('LLL')}
              </ItemContent>
              <ItemTitle style={{textAlign:'left', fontSize:14}}>
              Start Time
              </ItemTitle>
            </Item></>
            }
            {
              quest.status === 2 && <><Item style={{width:'50%'}}>
              <ItemContent style={{color:"#FFFFFF"}}>
                Kills
              </ItemContent>
              <ItemTitle style={{fontSize:14}}>
                Points
              </ItemTitle>
            </Item>
            <Item style={{width:'50%'}}>
              <ItemContent style={{color:"#FFFFFF"}}>
               Every 5 mins
              </ItemContent>
              <ItemTitle style={{textAlign:'left', fontSize:14}}>
              Kills updated
              </ItemTitle>
            </Item></>
            }
          </Update>
          <Intro>
         {prizePoolMode === 1 && `${variableMessage}`}
         {prizePoolMode === 2 && `${fixedMessage}`}
         {prizePoolMode === 3 && `${hybridMessage}`}
          </Intro>
          
  </Container>
}

const Container = styled.div`
display:flex;
flex-direction:column;
width:360px;
margin-bottom:24px;
`;

const Time = styled.div`
display:flex;
justify-content:space-between;
`;

const Detail = styled.div`
margin:24px 0px;
width:100%;
/* grid-template-columns: repeat(4, 90px);
justify-content:space-between;
grid-row-gap:8px;
display:grid; */
display:flex;
`;

const BasicItem = styled.div`
display:flex;
flex-direction:column;
width:25%;
 height:65px;
 justify-content:space-between; 
 padding:5px 0px;
`;

const Item = styled.div`
display:flex;
flex-direction:column;
`;

const ItemContent = styled.div`
font: normal normal bold 18px/18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
margin-bottom:12px;
text-align:left;
`;

const ItemTitle = styled.div`
font: normal normal normal 18px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const Prize = styled.div`
display:flex;
width:100%;
padding:8px 12px;
`;

const PrizeLeft = styled.div`
display:flex;
font: normal normal medium 12px SF PRO, serif;
letter-spacing: 0px;
color: #9D9C9E;
align-items:center;
`;

const PointBox = styled.div`
width: auto;
height: 24px;
padding:0 8px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 8px;
text-align: left;
font: normal normal bold 12px/24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-left:auto;
margin-right:16px;
`;

const PrizeRight = styled.div`
font: normal normal medium 12px SF PRO, serif;
letter-spacing: 0px;
color: #9D9C9E;
width:40px;
`;

const Prizes = styled.div`
display:flex;
flex-direction:column;
margin:12px 0px 24px;
width:100%;
`;

const Update = styled.div`
display:flex;
margin-bottom:24px;
width:100%;
justify-content:space-between;
`;

const Intro = styled.div`
text-align: left;
font: normal normal 14px/20px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
white-space: pre-line;
`;

const ModeBox = styled.div`
width: 100%;
height: 168px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
margin-top:12px;
padding:12px;
display:flex;
flex-direction:column;
`;

const Modes = styled.div`
display:grid;
grid-template-columns: repeat(3, 120px);
grid-row-gap:8px;
`;

const ModeText = styled.div`
text-align: left;
font: normal normal 16px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const ModeSub = styled.div`
text-align: left;
font: normal normal 12px SF PRO, serif;
letter-spacing: 0px;
color: #B7B7B7;
margin-top:auto;
`;

const IntroBox = styled.div`
width: 100%;
height: 118px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
margin-top:12px;
padding:12px;
display:flex;
flex-direction:column;
`;

const IntroText = styled.div`
text-align: left;
font: normal normal 14px/18px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;


export default withRouter(QuestBasic);