import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
import styled from 'styled-components';
import Countdown from 'react-countdown';
import { Progress } from "antd";

const QuestBasic = ({quest, joined}) => {
  const [initial, setInitial] = useState(0);

  useEffect(() => {
    let timer = null; 
      if(quest.actualStartedAt && quest.closeAt){
        const past = moment().diff(quest.actualStartedAt, 'second');
        const duration = moment(quest.closeAt).diff(moment(quest.actualStartedAt), 'second');
        setInitial(past);
        timer = setInterval(() => {
          setInitial(prev => {
            if(prev < duration){
              return prev + 1;
            }
          });
        }, 1000);
  }
    return () => {
      if(timer){
        clearInterval(timer);
      }
    }
  }, [quest.actualStartedAt, quest.closeAt, quest.startTime]);

  const timePercent = useMemo(() => {
    if(quest && quest.startTime && quest.createdAt){
      const duration = moment(quest.startTime).diff(quest.createdAt, "seconds");
      const now = moment();
      const initial = now.diff(quest.createdAt, "seconds");
      return ((initial) / duration) * 100;
    }
  },[quest]);


  const remaining = useMemo(() => {
    if(quest && quest.startTime){
      const remains = moment(quest.startTime).diff(moment(), "second");
      if(remains <= 0){
        return "Starting Soon"
      }else if (remains > 86400){
        const days = Math.floor(remains / 86400);
        return `${days} DAYS`
      }else if (remains < 60 * 60 * 24 && remains > 60 * 60){
        const hours = Math.floor(remains / 3600);
        return `${hours} HRS`
      }else if( remains < 60 * 60 && remains > 60){
        const minutes = Math.floor(remains / 60);
        return `${minutes} MINS`
      }else if(remains > 0 && remains< 60){
        return `${remains} SESC`
      }
    }
    return ""
  }, [quest]);

  const afterStartPercent = () => {
    const duration = moment(quest.closeAt).diff(moment(quest.actualStartedAt), 'second');
    if(initial < duration){
      return (initial/duration)*100;
    }else {
      return 100;
    }
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Ended</span>
    } else {
      // Render a countdown
      const mins = hours * 60 + minutes;
      return <span>{mins} MINS</span>;
    }
  };

  return <Container>
    <SubTitle>
      COUNTDOWN
    </SubTitle>
    {
      quest.status === 1 && <>
      <Progress
      percent={timePercent}
      showInfo={false}
      strokeColor="#F72375"
      strokeWidth={5}
    />
        <Time>
          <span style={{font:"normal normal medium 12px/25px SF PRO, serif", color:"#FFFFFF", display:'inline-block'}}>
            STARTS IN:
          </span>
          <span style={{font:"normal normal medium 12px/25px SF PRO, serif", color:"#FFFFFF", display:'inline-block', textTransform:"uppercase"}}>
            {remaining}
          </span>
        </Time>
      </>
    }
    {
      quest.status === 2  && <>
        <Progress
      percent={afterStartPercent()}
      showInfo={false}
      strokeColor="#F72375"
      strokeWidth={5}
    />
     <Time>
          <span style={{font:"normal normal medium 12px/25px SF PRO, serif", color:"#FFFFFF", display:'inline-block'}}>
            ENDS IN:
          </span>
          <span style={{font:"normal normal medium 12px/25px SF PRO, serif", color:"#FFFFFF", display:'inline-block', textTransform:"uppercase"}}>
          <Countdown date={moment(quest.closeAt).toDate()} renderer={renderer}/>
          </span>
        </Time>
      </>
    }
     {
      quest.status === 3  && <>
        <Progress
      percent={100}
      showInfo={false}
      strokeColor="#F72375"
      strokeWidth={5}
    />
     <Time>
          <span style={{font:"normal normal medium 12px/25px SF PRO, serif", color:"#FFFFFF", display:'inline-block', marginLeft:'auto'}}>
            Ended
          </span>
        </Time>
        
      </>
    }
    <Update>
            {
              (quest.status === 1 || quest.status === 3) && <><Item style={{width:'35%'}}>
              <ItemContent style={{color:"#FFFFFF"}}>
                Kills
              </ItemContent>
              <ItemTitle style={{fontSize:14}}>
                Points
              </ItemTitle>
            </Item>
            <Item style={{width:'65%'}}>
              <ItemContent style={{color:"#FFFFFF"}}>
              {moment(quest.startTime).format('LLL')}
              </ItemContent>
              <ItemTitle style={{textAlign:'left', fontSize:14}}>
              Start Time
              </ItemTitle>
            </Item></>
            }
            {
              quest.status === 2 && <><Item style={{width:'50%'}}>
              <ItemContent style={{color:"#FFFFFF"}}>
                Kills
              </ItemContent>
              <ItemTitle style={{fontSize:14}}>
                Points
              </ItemTitle>
            </Item>
            <Item style={{width:'50%'}}>
              <ItemContent style={{color:"#FFFFFF"}}>
               Every 5 mins
              </ItemContent>
              <ItemTitle style={{textAlign:'left', fontSize:14}}>
              Kills updated
              </ItemTitle>
            </Item></>
            }
          </Update>
          {
            joined &&  <Intro>
            The players with the most kills win the Quest. Each Quest victory will add to your overall points in the round. At the end of the round you’ll find out who advances to the next round and which tier you are in.
            </Intro>
          }
  </Container>
}

const Container = styled.div`
width:305px;
display:flex;
flex-direction:column;
`;

const Time = styled.div`
display:flex;
justify-content:space-between;
`;

const SubTitle = styled.div`
font-size: 14px;
font-style: normal;
font-weight: 700;
color:#FFFFFF;
`;

const Update = styled.div`
display:flex;
margin:24px 0px;
width:100%;
justify-content:space-between;
`;

const Item = styled.div`
display:flex;
flex-direction:column;
`;

const ItemContent = styled.div`
font: normal normal bold 18px/18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
margin-bottom:12px;
text-align:left;
`;

const ItemTitle = styled.div`
font: normal normal normal 18px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const Intro = styled.div`
color: #FFF;
font-family: SF PRO, serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin:24px 0px;
`;

export default QuestBasic;