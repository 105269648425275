import React,{ Component } from "react";
import styled from "styled-components";
import { Input } from "antd";
import { connect } from "react-redux";
//firebase
import Firebase from "../../util/firebase";
import * as API from "../../util/api";
import moment from "moment";
import axios from "axios";
import { debounce } from "lodash";

class QuestChat extends Component {
  constructor() {
    super();
    this.sendToChat  = debounce(this.sendToChat, 200);
    this.listener = null;
  }

  state = {
    text:"",
    history: [],
    monthlyChatKey:null
  }
  async componentDidMount() {
    await this.fetchChat();
  }
  scrollToBottom = () => {
    if(this.mesRef){
      this.mesRef.current.scrollTop = this.mesRef.current.scrollHeight;
    }
	};
  fetchChat = async () => {
    const {quest} = this.props;
    const {chatId} = quest;
    try {
    this.listener = Firebase.firestore()
      .collection("quest_chat")
      .doc(chatId)
      .onSnapshot((doc) => {
        const history = doc.data().messages;
        this.setState({
          history
        });
      });
    }catch(err){
      console.log(err)
    }
    
  };
  componentDidUpdate = () => {
      this.scrollToBottom();
  };

  componentWillUnmount = () => {
    this.listener();
  }

  sendToChat = async (message) => {
    const { user, quest } = this.props;
    const {chatId} = quest;
    const { id, profile } = user;
    const { founderBadeg, first_name, renewAt } = profile;

    let role = "PLAYER";
    if(moment(renewAt).isAfter(moment())) {
      role = 'SUBSCRIBER'
    }
    else if (founderBadeg) {
      role = "FOUNDER";
    }
    try {
      const url = API.SEND_CREATORQUEST_CHAT;
      const requestBody = {
        role,
        message,
        userName:first_name,
        userId: id,
        chatId
      };
      await axios.post(url, requestBody);
    } catch (err) {
      console.log(err, "error");
    }
  }

  keyOperation = async (event) => {
    const { text } = this.state;
    const message = text;
    if (event.key === "Enter" && text !== "") {
      this.setState({
        text:""
      })
     this.sendToChat(message);
    }
  };

  render(){
    const {history} = this.state;
    const { user, joined, quest } = this.props;
    const { id, loggedIn } = user;

    const messageCompare = (a, b) => {
      const time1 = a.time;
      const time2 = b.time;
      if (moment(time1).isBefore(time2)) {
        return -1;
      }
      if (moment(time1).isAfter(time2)) {
        return 1;
      }
      return 0;
    };

    const messages = history.sort(messageCompare);
    return <Container>
      {
        joined ? <Content ref={this.mesRef}>
                {messages.map((item, index) => (
                  <Message key={index}>
                    {item.role === "FOUNDER" && (
                      <img
                        src="../assets/founder-badge.svg"
                        alt=""
                        style={{
                          width: 14,
                          height: 14,
                          marginRight: 4,
                        }}
                      />
                    )}
                    {item.role === "OWNER" && (
                      <img
                        src="../assets/streamer.png"
                        alt=""
                        style={{
                          width: 14,
                          height: 14,
                          marginRight: 4,
                        }}
                      />
                    )}
                    {
                      item.role === 'SUBSCRIBER' && (
                        <img
                          src="../assets/subscriber_badge.svg"
                          alt=""
                          style={{
                            width: 14,
                            height: 14,
                            marginRight: 4,
                          }}
                        />
                      )}
                    <UserName isMe={item.userId === id}>{item.userName}:</UserName>
                    <MessageText>{item.message}</MessageText>
                  </Message>
                ))}
        </Content> : <EmptyPlaceHolder>
              <EmptyText>
              Join to view chat
              </EmptyText>
        </EmptyPlaceHolder>
      }
    

     <MessageBox
      onChange={(e) => {
        this.setState({
          text: e.target.value,
        });
      }}
      placeholder={(joined && loggedIn)? "Message the chat":"Locked"}
      value={this.state.text}
      onKeyPress={this.keyOperation}
      disabled={!loggedIn || !joined}
    />
  </Container>
  }
}

const Container = styled.div`
width: 319px;
height: 570px;
/* UI Properties */
background: #232526 0% 0% no-repeat padding-box;
border-radius: 11px;
padding:12px;
.ant-input-disabled {
  background: #40444B 0% 0% no-repeat padding-box !important;
}
`;

const Content = styled.div`
height: 503px;
/* UI Properties */
border-radius: 7px;
width:100%;
margin-bottom:8px;
padding:8px;
overflow-y: auto;
`;

const MessageBox = styled(Input)`
width:100%;
height: 32px;
/* UI Properties */
border-radius: 7px;
border:none;
background: #40444B 0% 0% no-repeat padding-box;
color: #DCDDDE;
`;

const Message = styled.div`
  margin-bottom: 4px;
`;

const UserName = styled.span`
  margin-right: 4px;
  ${(props) =>
    props.isMe
      ? `
    text-align: left;
font: normal normal normal 12px  SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
`
      : `text-align: left;
    font: normal normal normal 12px SF PRO, serif;
    letter-spacing: 0px;
    color: #B7B7B7;`}
`;

const MessageText = styled.span`
  text-align: left;
  font: normal normal normal 12px SF PRO, serif;
  letter-spacing: 0px;
  color: #DCDDDE;
`;

const EmptyPlaceHolder = styled.div`
height: 503px;
/* UI Properties */
border-radius: 7px;
width:100%;
margin-bottom:8px;
display:flex;
flex-direction:column;
align-items:center;
`;

const EmptyText = styled.div`
margin:auto;
font: normal normal 600 18px/17px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(QuestChat));
