import styled from "styled-components";
import React, { useEffect, useState, useMemo } from "react";
import {Divider, Carousel} from "antd";
import moment from 'moment'

const EventGameDetail = ({game}) => {
  const genreList = (item) => {
    let genres = [];

    if (Array.isArray(item.genre)) {
      genres = item.genre.map((tag) => {
        return tag;
      });
    } else if (typeof item.genre === 'string') {
      genres = [item.genre];
    }
    return genres;
  }

  return <Container>
    <div style={{width:944}}>
    <Carousel autoplay>
    {
        game?.productImages?.map((item) => {
          return <img src={item} style={{width:944, height:606}} alt="gameImage"/>
        })
      }
    </Carousel>
    </div>
    <div style={{width:344}}>
      <Description>
        {game?.short_description}
      </Description>
      <Divider />
                <Detail>
                  <DetailTitle>
                    Publisher
                  </DetailTitle>
                  <DetailContent>
                    {game?.publisher}
                  </DetailContent>
                </Detail>
                <Divider />
                <Detail>
                  <DetailTitle>
                    Genres
                  </DetailTitle>
                  <DetailContent>
                    {game && genreList(game).map(tag => <span style={{marginRight:4}}>
                      {tag}
                    </span>)}
                  </DetailContent>
                </Detail>
                <Divider />
                <Detail>
                  <DetailTitle>
                    Date Created
                  </DetailTitle>
                  <DetailContent>
                    {moment(game?.createdDate).format("MMMM Do, YYYY")}
                  </DetailContent>
                </Detail>
                <Divider />
                <Detail>
                  <DetailTitle>
                    Platform
                  </DetailTitle>
                  <DetailContent>
                    <img src="/assets/steamGame.svg" alt="steamIcon"/>
                  </DetailContent>
                </Detail>
                <Divider />
    </div>
  </Container>
}

const Container = styled.div`
margin-top:24px;
display:flex;
justify-content:space-between;

.ant-divider-horizontal {
  border-top: 1px solid rgba(255, 255, 255, 0.29);
}
`;

const Description = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;


const Detail = styled.div`
display:flex;
width:100%;
justify-content:space-between;
`;

const DetailTitle = styled.div`
color: rgba(255, 255, 255, 0.80);
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const DetailContent = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

export default EventGameDetail