import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import PublisherNavBar from "../nav/publisherNavBar";

const PublisherEntry = ({history}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  const isAdmin = useMemo(() => {
    const permission_level = profile?.permission_level??0;
    if(permission_level === 4 && profile.adminOfPartner){
      return true;
    }
    return false;
  }, [profile]);

  const role = useMemo(() => {
    if(profile){
      if(profile.role === 'influencer'){
        return 'influencer'
      }
      if(profile.role === 'publisher'){
        return 'publisher'
      }
    }
    return 'player'
  }, [profile]);

  return <Container>
  <PublisherNavBar activeTab="home" isDarkMode={true} />
  <Content>
    <Top>
      <TopTitle>
      Connect with The Influencers who love your games
      </TopTitle>
      <Desc>
      Share your game keys with influencers on our platform and they’ll redeem it as they like. Each time they redeem a game you’ll be able to see their profile, contact info and social media stats. From there its up to you what you do!
      </Desc>
    <div style={{display:'flex', marginBottom:48}}>
      {
        isAdmin ? <PrimaryButton style={{marginRight:24}} onClick={() => {
          window.location.replace("https://game.arcadequest.app/")
        }}>
        Dashboard
      </PrimaryButton> : <SecondaryButton onClick={() => {
        window.open("https://cal.com/daniel-baraghoush-mcp8yh/30-mins-meeting")
      }}>
        Contact Us
      </SecondaryButton>
  }
    </div>
    <div style={{display:'flex'}}>
      <GameImage src="/assets/influencerEntry/steam.png" alt="game"/>
      <GameImage src="/assets/influencerEntry/twitch.png" alt="game"/>
      <GameImage src="/assets/influencerEntry/xbox.png" alt="game"/>
    </div>
    </Top>
    <TopTitle style={{marginLeft:24}}>
    What’s next?
    </TopTitle>
    <ContentBox>
      <div style={{width:326}}>
        <ContentTitle>
        expand your reach
        </ContentTitle>
        <ContentText>
        Grow your community when you give influencers access to your games. They can try new games and even stream it if they want. 
        </ContentText>
      </div>
      <div style={{width:326}}>
        <ContentTitle>
        See who owns your game
        </ContentTitle>
        <ContentText>
        With our influencer search tool get access to influencers with over 100M combined followers. Search based on games they own on Steam. 
        </ContentText>
      </div>
      <div style={{width:326}}>
        <ContentTitle>
        increase revenue
        </ContentTitle>
        <ContentText>
        Our live shopping platform will drive more sales to drops and releases. Use your influencer community to drive measurable returns.
        </ContentText>
      </div>
    </ContentBox>
    </Content>
    {
      loggedIn && role === 'publisher' && <Box>
        <BoxTitle>
        We’ve moved the publisher portal!
        </BoxTitle>
        <BoxContent>
        The publisher portal is now on a different site but you can use the same login information to access.
        </BoxContent>
        <div style={{display:'flex'}}>
          
          <PrimaryButton onClick={() => {
            window.location.replace("https://game.arcadequest.app/")
          }}>
            Go To Publisher Portal
          </PrimaryButton>
        </div>
      </Box>
    }
    </Container>
}

const Container = styled.div`
  width: 100%;
  background:#000000 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  padding-top:108px;
  overflow-y:auto;
  position:relative;
`;

const Box = styled.div`
display: flex;
width: 471px;
border-radius: 8px;
background: rgba(247, 35, 117, 0.18);
backdrop-filter: blur(45px);
position:fixed;
top:68px;
right:24px;
padding:24px;
height:auto;
z-index:100;
flex-direction:column;
`;

const BoxTitle = styled.div`
color: #FAFAFA;
font-family: SF Pro;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom:16px;
`;

const BoxContent = styled.div`
color: #FAFAFA;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
margin-bottom:16px;
`;

const Content = styled.div`
width:1260px;
margin:0px auto 48px;
`;

const Top = styled.div`
width: 100%;
height: 545px;
flex-shrink: 0;
border-radius: 24px;
background: url("/assets/influencerEntry/topBackground.png");
/* filter: blur(4px); */
padding:36px;
margin-bottom:36px;
`;

const TopTitle = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 48px;
font-style: normal;
font-weight: 800;
line-height: normal;
text-transform: uppercase;
width:700px;
white-space:wrap;
`;

const Desc = styled.div`
color: #FAFAFA;
font-family: SF Pro;
font-size: 20px;
font-style: normal;
font-weight: 510;
line-height: normal;
margin:24px 0px 36px;
width:600px;
`;

const PrimaryButton = styled.div`
display: flex;
width: 194px;
height: 45px;
justify-content: center;
align-items: center;
border-radius: 8px;
background: #F72375;
cursor:pointer;

color:#FAFAFA;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 45px;
`;

const SecondaryButton = styled.div`
display: flex;
width: 194px;
height: 45px;
justify-content: center;
align-items: center;
cursor:pointer;
border-radius: 8px;
border: 1px solid #F72375;


color: #F72375;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 45px;
`;

const GameImage = styled.img`
height:40px;
margin-right:36px;
`;

const ContentBox = styled.div`
width: 100%;
height: 170px;
border-radius: 10px;
background: #383838;
display:flex;
padding:24px;
justify-content:space-between;
margin:24px 0px;
`;

const ContentTitle = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 22px;
font-style: normal;
font-weight: 800;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
`;

const ContentText = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;



export default withRouter(PublisherEntry)