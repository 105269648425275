import React, {  useState, useMemo } from "react";
import styled from "styled-components";
import QuestBasic from "./questBasic";
import UserInfoBeforeStart from "./userInfoBeforeStart";
import QuestChat from "../creatorquests/questChat";
import ProLeagueRanking from "./proLeagueRanking";
/* import QuestRanking from "./questRanking"; */

const OpenStatus = ({quest, joined}) => {
  const [showOption, setShowOption] = useState(1);
  return <>
     <Choices isDarkMode={true}>
          <Choice active={showOption === 1} isDarkMode={true} onClick={() => {
              setShowOption(1)
            }}>
               Pro League
          </Choice>
          <Choice active={showOption === 2} isDarkMode={true} onClick={() => {
              setShowOption(2)
            }}>
               Ranking
               </Choice>
          </Choices>
          {
            showOption === 1 && <Main>
              <QuestBasic quest={quest}  />
            <UserInfoBeforeStart quest={quest}/>
            <QuestChat quest={quest} joined={joined}/>
            </Main>
          }
          {
            showOption === 2 && <ProLeagueRanking quest={quest} joined={joined} 
            ended={false}
             />
          }
  </>
}

const Choices = styled.div`
width: 100%;
margin:12px auto 24px;
display:flex;
height:34px;
border-bottom:3px solid #FAFAFA;
`;

const Choice = styled.div`
width: auto;
padding:0 12px;
height: 34px;
/* UI Properties */
font: normal normal bold 20px/24px SF PRO, serif;
letter-spacing: 0px;
text-align:center;
margin-right:18px;
cursor:pointer;
color: #000000;
${(p) =>
  p.isDarkMode && !p.active 
    && `color: #FAFAFA;`}
${(p) =>
   p.active
        ? `color:#F72375; border-bottom:3px solid #F72375;`
        : ``}
`;

const Main = styled.div`
display:flex;
width:100%;
justify-content:space-between;
margin-top:36px;
`;

const PromoBox = styled.div`
display:flex;
width:100%;
justify-content:space-between;
flex-direction:column;
margin-left:32px;
margin-bottom:32px;
@media (min-width: 1480px) {
  margin-left: 84px;
}
`;

const PromoTop = styled.div`
display:flex;
width:100%;
justify-content:space-between;
`;

const PromoBanner = styled.div`
height: 194px;
width:100%;
background: url(${(p) => p.image}); 
background-size: cover;
position:relative;
border-radius:11px;
cursor:pointer;
`;


export default OpenStatus;