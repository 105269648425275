import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
import styled from 'styled-components';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Firebase from "../../util/firebase";
import PrivateNavBar from "../../nav/privateNavBar";
import PublicNavBar from "../../nav/publicNavBar";
import { useSelector } from "react-redux";
import Loading from "../../util/loading";
import { LeftCircleOutlined  } from "@ant-design/icons";
import QuestBasic from './component/questBasic';
import UserInfo from './component/userInfo';
import LeaderBoard from './component/leaderboard';

const TournamentQuest = ({history,match}) => {
  const [quest, setQuest] = useState(null);

  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  useEffect(() => {
    let listener = null;
    const fetchTournamentQuest = async (questId) => {
      listener = Firebase.firestore().collection("tournament_quest").doc(questId).onSnapshot((doc) => {
        const obj = {...doc.data(), questId};
        setQuest(obj)
     })
    }
    const questId = match.params.id;
    fetchTournamentQuest(questId);
    return () => {
      if(listener){
        listener();
      }
    }
  }, [match]);

  const joined = useMemo(() => {
    if(quest && userId){
      const {players} = quest;
      if(players.some(item => item.userId === userId)){
        return true;
      }
    }
    return false;
  }, [quest, userId]);

  const sortedPlayers = useMemo(() => {

    if(quest){
      if(quest.status === 2){
        return quest.players.map((item) => {
          const killsCount = (item.currentKills && item.startKills) ? item.currentKills - item.startKills : 0;
          return {
            killsCount,
            ...item
          }
        }).sort((a, b) => {
          return b.killsCount - a.killsCount
        })
      }else if(quest.status === 3){
        return quest.players.map((item) => {
          const killsCount = (item.finalKills && item.startKills) ? item.finalKills - item.startKills : 0;
          return {
            killsCount,
            ...item
          }
        }).sort((a, b) => {
          return b.killsCount - a.killsCount
        })
      }else {
        return quest.players;
      }
    }
    return []
  }, [quest])

  return <Container>
     {
      loggedIn ? <PrivateNavBar activeTab="tournament" isDarkMode={true}/>:<PublicNavBar activeTab="tournament" isDarkMode={true}/>
    }
    <Content>
      {quest ? <>
        <Title>
       <LeftCircleOutlined onClick={() => {
        history.push(`/tournamentRound/${quest.roundId}`)
       }}/> {quest.title}
       </Title>
       {
        joined ? <>
        <Main joined={joined}>
          <QuestBasic quest={quest} joined={joined} />
          <UserInfo quest={quest} sortedPlayers={sortedPlayers} />
       <LeaderBoard quest={quest} sortedPlayers={sortedPlayers}/>
       </Main>
        </> : <>
          <div style={{display:'flex', justifyContent:'space-between', marginTop:24}}>
          <QuestBasic quest={quest} joined={joined} />
           <UserInfo quest={quest} sortedPlayers={sortedPlayers} />
          </div>
           <LeaderBoard quest={quest} sortedPlayers={sortedPlayers} joined={joined}/>
        </>
       }
      </> : <Loading />}
    </Content>
  </Container>

}

const Container = styled.div`
  width: 100%;
  background:#000000 0% 0% no-repeat padding-box;
  position: relative;
  min-height:calc(100vh);
  padding-top:108px;
  font-family:SF PRO, serif;
`;

const Content = styled.div`
width: 1180px;
margin:0px auto 48px;
@media (min-width: 1480px) {
  width: 1280px;
}
${p => !p.joined && `width: 880px;
@media (min-width: 1480px) {
  width: 880px;
}`}
`;

const Title = styled.div`
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom:36px;
color: #FFF;
`;

const Main = styled.div`
display:flex;
width:100%;
margin-top:24px;
justify-content:space-between;
`;


export default withRouter(TournamentQuest)