import React, {useEffect, useState, useMemo} from 'react';
import { withRouter } from "react-router-dom";
import PrivateNavBar from "../nav/privateNavBar";
import styled from "styled-components";
import * as API from "../util/api";
import axios from "axios";
import Modal from "react-modal";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Skeleton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ArrowRightOutlined} from "@ant-design/icons";
import QuestCard from "./creatorquests/questCard";
import { Mixpanel } from '../util/mixpanel';

const LinkAccountSuccess = ({history, location}) => {
  const { loggedIn, profile, id: userId, verify_from } = useSelector((state) => state.user);
  const [processing, setProcessing] = useState(false);
  const [recommendQuests, setRecommendQuests] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  
  useEffect(() => {
    const updateVerifyStatus = async (code) => {
      try {
        const requestBody = {
          code,
          userId
        }
        const endPoint = API.LINK_ACCOUNT;
        const res = await axios.post(endPoint, requestBody);
        if(res.status === 200){
          setProcessing(false);
          setModalOpen(true);
          Mixpanel.track("[WEB] Link Account Success")
        }else {
          history.push("/linkAccount?tryAgain=true")
        }
       }catch(err){
        history.push("/linkAccount?tryAgain=true")
       }
    };



    if(!loggedIn || !userId){
      history.push("/login");
    }else {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');
      if(code){
        updateVerifyStatus(code);
      }
    }
    const fetchRecommendQuest = async() => {
      try {
        const endPoint = API.GET_RECOMMEND_QUESTS;
        const res = await axios.get(endPoint);
        if(res.status === 200){
          const quests = res.data;
          setRecommendQuests(quests);
        }
      }catch(err){
        console.log(err);
      }
    };
    fetchRecommendQuest();

  }, [location, loggedIn, userId, history])


  const antIcon = (
    <LoadingOutlined style={{ fontSize: 40, color: "#F72375" }} spin />
  );

  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: "613px",
      height:'auto',
      padding:'24px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "24px",
      zIndex: "10000",
    },
  };

  const dispatch = useDispatch();

  const setVerifyFrom = () => ({
    type:"SET_VERIFY_FROM",
    payload:null
  });

  const toNextStep = () => {
    if(verify_from){
      const {target, target_matchId} = verify_from;
      if(target === 'home'){
        history.push("/quest");
        dispatch(setVerifyFrom())
      }
      if(target === 'match'){
        history.push(`/matchDetail/${target_matchId}`)
        dispatch(setVerifyFrom())
      }
      if(target === 'account'){
        history.push("/myAccount");
        dispatch(setVerifyFrom())
      }
      if(target === 'allMatches'){
        history.push("/allMatches");
        dispatch(setVerifyFrom())
      }
      if(target === 'proLeague'){
        history.push(`/proLeagueCustomer/${target_matchId}`);
        dispatch(setVerifyFrom())
      }
      if(target === 'creatorQuest'){
        history.push(`/creatorQuestCustomer/${target_matchId}`);
        dispatch(setVerifyFrom())
      }
    }
   }

  return <Container>
    <PrivateNavBar isDarkMode={true}/>
    <Content>
        {
          processing ? <>
          <Title>
          <span style={{color:"#F72375"}}>Linking</span> Account
          </Title>
          <Text>
          We Are Linking Your Epic Account. PLEASE DO NOT LEAVE THIS PAGE!
          </Text>
          <LoadingContainer>
            <Spin
                  indicator={antIcon}
                  style={{ textAlign: "left", marginBottom: 32, marginTop:32 }}
                />
                <LoadingText>Loading</LoadingText>
                <Skeleton active={true} style={{width:'90%'}}/>
              </LoadingContainer>
          </>:<>
          <Title>
          <span style={{color:"#F72375"}}>Start</span> Winning
          </Title>
          <Text>
          Congrats, your account is linked! Join three Quests for FREE!
          </Text>
          <Main>
            {
              recommendQuests.map((item) => {
                return  <QuestCard quest={item} joined={false} />
              })
            }
            </Main>
          <NextButton
                style={{ marginLeft: 24, marginTop: 12 }}
                onClick={toNextStep}
              >
               Next <ArrowRightOutlined style={{ marginLeft: 12 }} />
           </NextButton>
          </>
        }
    </Content>
        <Modal isOpen={modalOpen}
        style={customStyles}
        onRequestClose={() => {
          setModalOpen(false);
        }}
        ariaHideApp={false}>
          <ModalTitle>
          Warning!
          </ModalTitle>
          <ModalText style={{marginBottom:24}}>
          You <b>MUST</b> do this so we can track your kills
          </ModalText>
          <ModalText>
          Open up your Fortnite settings in game 
          </ModalText>
          <ModalText>
          Head over to the last section called “Account and Privacy”
          </ModalText>
          <ModalText>
          Scroll down to “Gameplay Privacy”
          </ModalText>
          <ModalText>
          Turn on the “Show on Career Leaderboard” option
          </ModalText>
          <ContinueButton onClick={() => {
            setModalOpen(false);
          }}>
          Continue
    </ContinueButton>
        </Modal>
  </Container>
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  background: #000000 0% 0% no-repeat padding-box;
  height:calc(100vh);
`;

const Content = styled.div`
width: 1180px;
margin:148px auto 0px;
@media (min-width: 1480px) {
  width: 1280px;
}
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 95px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:24px;
`;

const Text = styled.div`
text-align: left;
font: normal normal bold 24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:24px;
`;

const LoadingText = styled.span`
  display: block;
  font: normal normal bold 24px/28px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 32px;
`;

const LoadingContainer = styled.div`
width:80%;
display:flex;
flex-direction:column;
`;

const Main = styled.div`
display:grid;
grid-template-columns: repeat(3, 343px);
justify-content:space-between;
margin:48px 0px;
`;



const ModalTitle = styled.div`
text-align: left;
font: normal normal bold 24px/20px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:12px;
`;

const ModalText = styled.div`
text-align: left;
font: normal normal normal 20px/28px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const ContinueButton = styled.div`
width: 100%;
height: 49px;
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 12px;
text-align: center;
font: normal normal 600 16px/49px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
cursor:pointer;
margin-top:32px;
`;

const NextButton = styled.div`
  width: 172px;
  height: 49px;
  background: #f72375 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 24px #f7237559;
  border-radius: 11px;
  cursor: pointer;
  font: normal normal bold 18px/49px SF PRO;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 32px;
  margin-top:32px;
`;



export default withRouter(LinkAccountSuccess)

