import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from "react-router-dom";
import Firebase from "../util/firebase";
import TournamentEntry from './tournamentEntry';

const TournamentControl = ({match, history}) => {
  const [tournament, setTournament] = useState(null);

  useEffect(() => {
    let listener = null;
    const fetchTournament = async (tournamentId) => {
      listener = Firebase.firestore().collection("tournament").doc(tournamentId).onSnapshot((doc) => {
        const obj = {...doc.data(), tournamentId};
        setTournament(obj);
     })
    }
    const tournamentId = match.params.tournamentId;
    fetchTournament(tournamentId);
    return () => {
      if(listener){
        listener();
      }
    }
  }, [match])
  return <>
    <h1> {tournament?.title??""} </h1>
   <TournamentEntry tournament={tournament} />
  </>
}

export default withRouter(TournamentControl);