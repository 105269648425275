import React, { useEffect, useState, useMemo } from "react";
import PrivateNavBar from "../../nav/privateNavBar";
import PublicNavBar from "../../nav/publicNavBar";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Firebase from "../../util/firebase";
import Loading from "../../util/loading";
import { ArrowLeftOutlined  } from "@ant-design/icons";
import moment from "moment";
import { message } from "antd";
import JoinQuestModal from "./joinCreatorQuestModal";
import LeaveCreatorQuestModal from "./leaveCreatorQuestModal";
import OpenStatus from "./openStatus";
import OngoingStatus from "./ongoingStatus";
import EndStatus from "./endStatus";
import AdVideo from "./adVideo";
import { Mixpanel } from '../../util/mixpanel';
import { CopyToClipboard } from "react-copy-to-clipboard";
import qs from 'query-string';
import axios from "axios";
import * as API from "../../util/api";
import {
  LockOutlined
} from '@ant-design/icons';
import {Helmet} from "react-helmet";

const CreatorQuestsCustomer = ({history, match, location}) => {
  const [quest, setQuest] = useState(null);
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const [joinQuestModalOpen, setJoinQuestModalOpen] = useState(false);
  const [leaveQuestModalOpen, setLeaveQuestModalOpen] = useState(false);
  const [referralCode, setReferralCode] = useState(null);
  const { items: partners} = useSelector((state) => state.partners);
  const { items: products} =  useSelector((state) => state.products);
  const [showAd, setShowAd] = useState(false);
  
  useEffect(() => {
    const questId =  match.params.id;
    let listener = null;
    const fetchQuest = () => {
      listener = Firebase.firestore().collection("creator_quest").doc(questId).onSnapshot((doc) => {
          const obj = {...doc.data(), questId};
          setQuest(obj);
       })
    }
    fetchQuest();
    const fetchReferralCode = async () => {
      try {
        if(profile.referral_Id){
          const referralRef = Firebase.firestore().collection("referral").doc(profile.referral_Id);
          const referralDoc = await referralRef.get();
          const referralData = referralDoc.data();
          const {code} = referralData;
          const currentCode = code[0];
          setReferralCode(currentCode);
        }
      }catch(err){
        console.log('fetch referral code error')
      }
    }
    if(loggedIn && profile){
      fetchReferralCode();
    }
    Mixpanel.track("[WEB] Quest Detail Page")
    return () => {
      if(listener){
        listener();
      }
    }

  }, [match, profile, loggedIn]);


  const joined = useMemo(() => {
    const questId =  match.params.id;
    if(loggedIn && profile && profile.joinedCreatorQuest && profile.joinedCreatorQuest.includes(questId)){
      return true;
    }else {
      return false;
    }
  }, [profile, loggedIn, match]);

  const showSuccessJoin = () => {
    message.success("You have successfully join the next quest");
  }

  const showSuccessLeave = () => {
    message.success("You have successfully leave the next quest");
  }

  const questShareLink = useMemo(() => {
    const questId =  match.params.id;
    return  `https://play.arcadequest.app/creatorQuestCustomer/${questId}?referralCode=${referralCode}`;
  }, [referralCode, match]);

  const prizePoolMode = useMemo(() => {
    let mode = 0;
    if(quest){
      if((quest.isFixedPricePool && quest.fixedPricePool && quest.fixedPricePool > 0) || (quest.prizePoolMode === 2 && quest.prizePool > 0) ) {
        mode = 2;
      }else if(quest.prizePoolMode === 3 && quest.prizePool > 0 ){
        mode = 3;
      }
    }
    return mode;
  }, [quest]);

  const isPromoed = useMemo(() => {
    if(quest && quest.isPromoed){
      return true;
    }else {
      return false;
    }
  }, [quest]);

  const companyInfo = useMemo(() => {
    if(isPromoed && quest.companyId){
      const company = partners.find(item => item.partnerId === quest.companyId);
      if(company) return company;
    }
    return null;
  },[quest, partners, isPromoed]);

  const isActiveSub = useMemo(() => {
    return profile && profile.multiplier && profile.multiplier.renewAt && moment(profile.multiplier.renewAt).isAfter((moment()));
  }, [profile])

  const productInfo = useMemo(() => {
    if(isPromoed && quest.productId){
      const product = products.find(item => item.productId === quest.productId);
      if(product) return product;
    }
    return null;
  }, [quest, products, isPromoed]);

  useEffect(() => {
    if(quest && quest.isPromoed && companyInfo && productInfo){
        Mixpanel.track("View Promo Product Quest", {
          productName:productInfo.name,
          productId:productInfo.productId,
          partnerName:companyInfo.name,
          partnerId:companyInfo.partnerId,
        })
    }
  }, [quest, companyInfo, productInfo ]);

  const mixPaneltrack = () => {
    if(companyInfo && productInfo){
      Mixpanel.track("Quest Promo Product Banner Clicks", {
        productName:productInfo.name,
        productId:productInfo.productId,
        partnerName:companyInfo.name,
        partnerId:companyInfo.partnerId,
      })
    }
  }

  const canClaimBouns = useMemo(() => {
    if(quest && quest.isPromoed && userId && joined){
      const bounsList = quest?.bounsList?? [];
      if(!bounsList.includes(userId)){
        return true;
      }
    }
    return false;
  }, [quest, userId, joined]);

  const getClickBouns = async () => {
    if(canClaimBouns){
      const {transId} = profile;
      const requestBody = {
        questId:quest.questId,
        userId,
        transId
      }
      const url = API.GET_PROMO_BOUNS;
      try {
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          message.success("You have successfully claim the bouns")
        }
      }catch(err){
        console.log(err);
      }
    }else {
      message.error("You have already claimed the bouns")
    }
  }

  const redirectToAd = () => {
    if(!joined){
      setShowAd(true);
    }
  };

  const rediectFromAd = () => {
    if(showAd){
      setShowAd(false);
    }
  };


  return <Container>
    <Helmet>
      <title>{`${quest?.questName??"Quest"}: Join ${quest?.streamerName??"Influencers"}'s Fortnite Competition - ArcadeQuest`}</title>
      <meta name="description" content={`${quest?.streamerName??"Influencers"}’s cash prize Fortnite competition on ArcadeQuest. Join and win prizes today.`} />
      <meta name="keywords" content={`${quest?.streamerName??"Influencers"}, Fortnite, competition, ArcadeQuest, cash prize`} />
    </Helmet>
     {
      loggedIn ? <PrivateNavBar activeTab="quest" isDarkMode={true}/>:<PublicNavBar activeTab="quest" isDarkMode={true}/>
    }
    <Content>
      {
        quest ? <>
              <Top>
          <ArrowLeftOutlined style={{fontSize:24, marginRight:8, cursor:'pointer'}} onClick={() => {
            history.push('/quest?option=quests')
          }}/>
         <TopLeft>
            <QuestName>
              {quest.questName}
            </QuestName>
            {
              prizePoolMode !== 0 &&  <QuestMode prizePoolMode={prizePoolMode}>
                {
                  prizePoolMode === 1 && `Variable Prize Pool`
                }
                {
                  prizePoolMode === 2 && `Fixed Prize Pool`
                }
                {
                  prizePoolMode === 3 && `Hybird Prize Pool`
                }
              </QuestMode>
            }
         </TopLeft>
          {
            quest.status === 1 && <> 
             {
              joined ? <>
                <ButtonContainer>
                      <CopyToClipboard
                          text={questShareLink}
                          onCopy={() =>
                            message.success("Successfully copy the quest link with your referral code")
                          }
                        >
                          <ShareButton>
                            Share Quest
                          </ShareButton>
                        </CopyToClipboard>
                          <JoinButton joined={joined} onClick={() => {
                        setLeaveQuestModalOpen(true);
                      }}>
                        Leave Quest
                      </JoinButton>
                      </ButtonContainer>
              </> : <>
              {
                quest.isMultiplierOnly ? <>
                      {
                    isActiveSub ? <JoinButton onClick={() => {
                      setJoinQuestModalOpen(true);
                    }}>
                      Join Multiplier Only Quest
                    </JoinButton> : <JoinButton onClick={() => {
                      setJoinQuestModalOpen(true);
                    }}>
                      <LockOutlined style={{marginRight:8}}/>Multiplier Only
                    </JoinButton>
                  }
                      </>
                    : <>
                  {
                   !loggedIn && <JoinButton joined={joined} onClick={() => {
                      const redirectParams = qs.stringify({
                        ...(qs.parse(location.search)),
                        page:"creatorQuest",
                        id:quest.questId
                    });
                      history.push(`/login?${redirectParams}`)
                    }}>
                        Log In And Join
                    </JoinButton>
                  }
                  {
                    !showAd && loggedIn && <JoinButton joined={joined} onClick={() => {
                      setJoinQuestModalOpen(true);
                    }}>
                      Join Quest
                    </JoinButton>
                  }
                  {
                    showAd && loggedIn && <JoinButton joined={joined} disabled={true}>
                    Joining Quest...
                  </JoinButton>
                  }
                </>
              }
              </>
             }
            </>
          }
         {/*  {
            quest.status === 1 && !loggedIn && <JoinButton joined={joined} onClick={() => {
              const redirectParams = qs.stringify({
                ...(qs.parse(location.search)),
                page:"creatorQuest",
                id:quest.questId
            });
              history.push(`/login?${redirectParams}`)
            }}>
                Log In And Join
            </JoinButton>
          }
          {
            quest.status === 1 && !joined && !showAd && loggedIn && <JoinButton joined={joined} onClick={() => {
              setJoinQuestModalOpen(true);
            }}>
              Join Quest
            </JoinButton>
          }
          {
            quest.status === 1 && !joined && showAd && loggedIn && <JoinButton joined={joined} disabled={true}>
              Joining Quest...
            </JoinButton>
          }
          {
              quest.status === 1 && joined && <ButtonContainer>
              <CopyToClipboard
                  text={questShareLink}
                  onCopy={() =>
                    message.success("Successfully copy the quest link with your referral code")
                  }
                >
                  <ShareButton>
                    Share Quest
                  </ShareButton>
                </CopyToClipboard>
                  <JoinButton joined={joined} onClick={() => {
                setLeaveQuestModalOpen(true);
              }}>
                Leave Quest
              </JoinButton>
              </ButtonContainer>
          } */}
          {
              quest.status === 3 && quest.closeAt && <EndText>
                Ended: {moment(quest.closeAt).format("LLL")}
              </EndText>
          }
        </Top>
        {
          isPromoed && productInfo && !showAd  && <Banner image={productInfo.bannerUrl} onClick={() => {
            mixPaneltrack()
            window.open(productInfo.link)
            getClickBouns();
          }}>
            {
              canClaimBouns && <ClaimBouns>
                  GET 5 COINS WHEN YOU CLICK
              </ClaimBouns>
            }
          </Banner>
        }
        {
          quest.status === 1 && showAd && <AdVideo quest={quest} isPromoed={isPromoed} 
          companyInfo={companyInfo}
          productInfo={productInfo}
            rediectFromAd={rediectFromAd}>
          </AdVideo>
        }
        {
          quest.status === 1 && !showAd && <OpenStatus joined={joined} quest={quest} isPromoed={isPromoed} 
          companyInfo={companyInfo}
          productInfo={productInfo}
          canClaimBouns={canClaimBouns}
          getClickBouns={getClickBouns}
          />
        }
        {
          quest.status === 2 && <OngoingStatus joined={joined} quest={quest} isPromoed={isPromoed} 
          companyInfo={companyInfo}
          productInfo={productInfo}
          canClaimBouns={canClaimBouns}
          getClickBouns={getClickBouns}
          />
        }
        {
          quest.status === 3 && <EndStatus joined={joined} quest={quest} isPromoed={isPromoed} 
          companyInfo={companyInfo}
          productInfo={productInfo}
          canClaimBouns={canClaimBouns}
          getClickBouns={getClickBouns}
          />
        }
        </> :<Loading />
      }

    </Content>
    {
      quest && <JoinQuestModal 
      closeModal={() => {
        setJoinQuestModalOpen(false)
      }}
      quest={quest}
      modalOpen={joinQuestModalOpen}
      showSuccess={showSuccessJoin}
      isPromoed={isPromoed}
      redirectToAd={redirectToAd}
      />
    }
   {
    quest &&  <LeaveCreatorQuestModal 
    closeModal={() => {
      setLeaveQuestModalOpen(false)
    }}
    quest={quest}
    modalOpen={leaveQuestModalOpen}
    showSuccess={showSuccessLeave}
    />
   }

  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#000000 0% 0% no-repeat padding-box;
  position: relative;
  min-height:calc(100vh);
  padding-top:108px;
`;

const Content = styled.div`
width: 1180px;
margin:0px auto 48px;
@media (min-width: 1480px) {
  width: 1280px;
}
`;

const Banner = styled.div`
width:100%;
margin:24px 0px;
cursor:pointer;
position:relative;
border-radius: 11px;
background: url(${(p) => p.image}); 
background-size: cover;
height:134px;
`;

const ClaimBouns = styled.div`
width: 221px;
height: 28px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 11px 0px;
position:absolute;
bottom:0px;
right:0px;
font: normal normal bold 12px/28px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
text-transform: uppercase;
text-align:center;
`;

const Top = styled.div`
text-align: left;
letter-spacing: 0px;
color: #FFFFFF;
display:flex;
align-items:center;
`;

const TopLeft = styled.div`
display:flex;
flex-direction:column;
`;


const QuestName = styled.div`
text-align: left;
font: normal normal bold 18px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
margin-bottom:8px;
`;

const QuestMode = styled.div`
text-align: center;
font: normal normal bold 12px/16px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
height: 16px;
padding:0 8px;
width:auto;
${p => p.prizePoolMode === 1 && `background:#FF8A44 0% 0% no-repeat padding-box;` }
${p => p.prizePoolMode === 2 && `background:#F72375 0% 0% no-repeat padding-box;` }
${p => p.prizePoolMode === 3 && `background: #2B23F7 0% 0% no-repeat padding-box;` }
`;


const ButtonContainer = styled.div`
display:flex;
margin-left:auto;
align-items:center;
`;


const JoinButton = styled.div`
width: 335px;
height: 47px;
/* UI Properties */
border-radius: 7px;
margin-left:auto;
font: normal normal bold 20px/47px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
text-align:center;
cursor:pointer;
${p => (p.joined || p.disabled) ? `background: #6A6A6A 0% 0% no-repeat padding-box;`:`background: #F72375 0% 0% no-repeat padding-box;`}
`;



const EndText = styled.div`
font: normal normal bold 18px/25px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
margin-left:auto;
`;

const ShareButton = styled.div`
width: 167px;
height: 47px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 7px;
margin-right:24px;
font: normal normal bold 20px/47px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
cursor:pointer;
text-align:center;
`;


export default withRouter(CreatorQuestsCustomer);

