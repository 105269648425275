import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, Modal, Input, message, List,  Card, Col, Row, Typography} from "antd";
import * as API from "../../util/api";
import axios from "axios";

const AddPlayerModal = ({playerModalOpen, handleCancel, availableList, teamsUp, roundId}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
   onChange: onSelectChange,
  };

  const addPlayers = async () => {
    try {
      const requestBody = {
        roundId,
        selectedIds:selectedRowKeys
      }
      const url = API.TOURNAMENT_ROUND_ADD_PLAYER;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        setSelectedRowKeys([]);
        handleCancel()
        message.success("Successfully add the players");
      }else {
        message.error("Failed to add players, please try again")
      }
    }catch(err){
      message.error("Failed to add players, please try again")
    }
  }

  const selectedList = useMemo(() => {
    const list = selectedRowKeys.map((id) => {
      const target = availableList.find(item => item.userId === id) 
      if(target){
        return target.email
      }else {
        return null;
      }
    });
    return list;
  }, [selectedRowKeys, availableList]);

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'userName',
      key:'userName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key:"email"
    },
  ];

  const teamscolumns = [
    {
      title: 'User Name',
      dataIndex: 'userName',
      key:'userName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key:"email"
    },

    {
      title: 'Team Number',
      key:'teamIndex',
      dataIndex:'teamIndex'
    },
  ];

  const dataColumn = teamsUp? teamscolumns:columns;

  return <Modal  visible={playerModalOpen} 
      title="Add Player"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>
      ]}>
      <Table
        rowSelection={{
        ...rowSelection,
      }}
        pagination = {
          {
            pageSize: 20,
          }
        }
        columns={dataColumn}
        dataSource={availableList}
      /> 
      <List
        header={<div>Selected Players List</div>}
        bordered
        dataSource={selectedList}
        style={{marginBottom:24}}
        renderItem={(item) => (
          <List.Item >
            {item}
          </List.Item>
        )}
      />
      <Button onClick={() => {
        addPlayers()
      }}
      type="primary"
      >Confirm Add</Button>
    </Modal>
}

export default AddPlayerModal;