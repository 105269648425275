import React, { useState, useEffect, useMemo } from 'react';
import {  Button, List, Card, Descriptions} from "antd";
import styled from "styled-components"
import moment from "moment"
import Firebase from "../../util/firebase";
import { withRouter } from "react-router-dom";
import CreateRound from './createRound';
import RoundDetail from './roundDetail';

const TournamentRounds = ({tournament}) => {
  const [option, setOption] = useState("basic");
  const [rounds, setRounds] = useState([]);
  const [currentRoundId, setCurrentRoundId] = useState(null);
  const backToMain = () => {
    setOption('basic')
  }
  useEffect(() => {
    let listener = null;
    const fetchRounds = async () => {
      const id = tournament.tournamentId;
      Firebase.firestore().collection('tournament_round').where("tournamentId", "==", id).onSnapshot(querySnapshot => {
        let result = []
          querySnapshot.docs.forEach(doc => {
          const obj = {
            ...doc.data(),
            roundId: doc.id
          }
          result.push(obj);
        });
       setRounds(result)
      });
    };
    fetchRounds();
    return () => {
      if(listener){
        listener();
      }
    }
  }, [tournament.tournamentId]);

  const roundsWithIndex = useMemo(() => {
    const result = new Map();
    rounds.forEach((item) => {
      const index = item.roundIndex;
      if(result.has(index)){
        const current = result.get(index);
        current.push(item);
      }else {
        const newArr = [item];
        result.set(index, newArr)
      }
    });
    const sortMap = new Map([...result.entries()].sort());
    const sortResult = [...sortMap].map(([index, rounds]) => ({
      index,
      rounds
    }));
    return sortResult;
  }, [rounds]);

  const currentRound = useMemo(() => {
    const target = rounds.find(item => item.roundId === currentRoundId);
    return target;
  }, [rounds, currentRoundId])

  return <>
   {
    option === 'basic'  &&  <><TopContainer>
    <h1>Tournament Rounds</h1>
    <Button type="primary" onClick={() => {
      setOption("create")
    }}>
      Create New
    </Button>
  </TopContainer>
    {
      roundsWithIndex.length > 0 ? <div>
        {
          roundsWithIndex.map((roundItem) => {
            return <div style={{marginBottom:24}}>
              <h3>Round {roundItem.index} Groups : </h3>
              <List
                grid={{
                  gutter: 16,
                  column: 2,
                }}
                dataSource={roundItem.rounds}
                renderItem={(item) => (
                  <List.Item>
                    <Card title={<div style={{display:'flex', justifyContent:'space-between'}}>
                      <h3>{item.title}</h3>
                      {
                        item.status === 0 ? <Button type='primary' onClick={() => {
                         setCurrentRoundId(item.roundId);
                          setOption("detail")
                        }}>View & Update</Button> : <Button type='primary' onClick={() => {
                          setCurrentRoundId(item.roundId);
                          setOption("detail")
                        }}>View Detail</Button>
                      }
                    </div>}>
                      <Descriptions title="Details" bordered>
                      <Descriptions.Item label="Start Date" span={2}>{moment(item.startDate).format("LL")}</Descriptions.Item>
                      <Descriptions.Item label="End Date" span={2}>{moment(item.endDate).format("LL")}</Descriptions.Item>
                      <Descriptions.Item label="Players" span={2}>{item.selectedPlayers.length}</Descriptions.Item>
                      <Descriptions.Item label="Status" span={2}>{
                        item.status === 0 ? 'Preparing':'Ongoing'
                      }</Descriptions.Item>
                      </Descriptions>
                      <h3 style={{marginTop:12}}>
                        Selected Quest Date:
                      </h3>
                    </Card>
                  </List.Item>
                )}
              />
              </div>
          })
        }
      </div> : <h1>
        Not Rounds Data Now
      </h1>
    }
  </>
   }
   {
    option === 'create' && <CreateRound tournament={tournament} backToMain={backToMain}/>
   }
   {
    option === "detail" && currentRound && <RoundDetail tournament={tournament} currentRound={currentRound} />
   }
  </>
}

const TopContainer = styled.div`
display:flex;
justify-content:space-between;
margin-bottom:24px;
`;

export default withRouter(TournamentRounds);