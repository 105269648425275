import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import "antd/dist/antd.css";
import { PersistGate } from "redux-persist/integration/react";
import HHRouter from "./hhRouter";
import Intercom from "react-intercom";
import { Helmet } from "react-helmet";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Helmet>
          <title>ArcadeQuest: Gaming with Influencers & Communities</title>
          <meta
            name="description"
            content="Connecting influencers and their community through interactive games on ArcadeQuest. Join the gaming revolution."
          />
          <meta
            name="keywords"
            content="ArcadeQuest, influencers, community, interactive games, gaming"
          />
        </Helmet>
        <HHRouter />
        <Intercom appID="rehk1hxf" />
      </PersistGate>
    </Provider>
  );
};

export default App;

