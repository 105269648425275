import * as ActionTypes from "../action/type";
import { v4 as uuidv4 } from 'uuid';


const initialState = {
  loggedIn: false,
  verify_from:null,
  vistorUserId: uuidv4()
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_VERIFY_FROM:
      return {
        ...state,
        verify_from:action.payload
      }
    case ActionTypes.LOG_IN:
      return {
        ...state,
        loggedIn: true,
        id: action.id,
        profile: action.profile,
      };

    case ActionTypes.REGISTER:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        password: action.payload.password,
      };
    case ActionTypes.UPDATE_TOKEN:
      return {
        ...state,
        profile: {
          ...state.profile,
          token_count: action.payload,
        },
      };
    case ActionTypes.UPDATE_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case ActionTypes.UPDATE_EPICNAME:
      return {
        ...state,
        profile: {
          ...state.profile,
          savedEpicName: action.payload,
        },
      };
    case ActionTypes.LOG_OUT:
      return {
        loggedIn: false,
      };
    default: {
      return state;
    }
  }
}

export default userReducer;
