import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, Modal, Input, message, List,  Card, Col, Row, Typography} from "antd";
import styled from "styled-components"
import * as API from "../../util/api";
import axios from "axios";
import AddPlayerModal from './addPlayerModal';
import QuestPlanModal from './questPlanModal';
import moment from "moment";
const { Title } = Typography;

const EditAbleRound  = ({currentRound, tournament}) => {
  const [playerModalOpen, setPlayerModalOpen] = useState(false);
  const [planModalOpen, setPlanModalOpen] = useState(false);

  const currentPlayersList = useMemo(() => {
    const selectedIds = currentRound.selectedPlayers;
    const list = selectedIds.map((id) => {
      const target = tournament.verifiedPlayers.find(item => item.userId === id) 
      if(target){
        return {
          email:target.email,
          userId: target.userId
        } 
      }else {
        return null;
      }
    });
    return list;
  }, [currentRound, tournament]);

  const unSelectedList = useMemo(() => {
    const arr = tournament.verifiedPlayers.filter(item => !currentRound.selectedPlayers.includes(item.userId));
    return arr;
  },[tournament.verifiedPlayers, currentRound.selectedPlayers])


  const removePlayer = async (userId) => {
    try {
      const requestBody = {
        roundId:currentRound.roundId,
        userId
      }
      const url = API.TOURNAMENT_ROUND_REMOVE_PLAYER;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        message.success("Successfully remove the player")
      }else {
        message.success("Failed to remove the player, please try again")
      }
    }catch(err){
      console.log(err)
      message.success("Failed to remove the player, please try again")
    }
  }

  const displayGameModes = (modes) => {
    const result = modes.reduce((arr, curr, index) => {
      if(index === 0){
        return arr  + getLabel(curr);
      }else {
        return arr  +  " & " + getLabel(curr);
      }
    }, '');
    return result;
  }

  const getLabel = (mode) => {
    const target = modeOptions.find((item) => item.value === mode);
    return target.label;
  }


  const modeOptions = [
    {
      label:"Build Solos",
      value:"defaultsolo"
    },
    {
      label:"No Build Solos",
      value:"nobuildbr_solo"
    },
    {
      label:"Arena Solos",
      value:"showdownalt_solo"
    },
    {
      label:"Build Duos",
      value:"defaultduo"
    },
    {
      label:"No Build Duos",
      value:"nobuildbr_duo"
    },
    {
      label:"Arena Duos",
      value:"showdownalt_duos"
    },
    {
      label:"Build Trios",
      value:"trios"
    },
    {
      label:"No Build Trios",
      value:"nobuildbr_trio"
    },
    {
      label:"Arena Trios",
      value:"showdownalt_trios"
    },
    {
      label:"Build Squads",
      value:"defaultsquad"
    },
    {
      label:"No Build Squads",
      value:"nobuildbr_squad"
    },
    {
      label:"Arena Squads",
      value:"showdownalt_squads"
    },
  ];


  const removeQuestPlan = async (item) => {
    console.log('remove')
    const key = item.key;
    try {
      const requestBody = {
        planKey:key,
        roundId:currentRound.roundId
      }
      const url = API.TOURNAMENT_ROUND_REMOVEPLAN;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        message.success("Remove Plan Successfully")
      }else {
        message.error("Failed to remove plan, please try again")
      }
    }catch(err){
      message.error("Failed to remove plan, please try again")
    }
  }

  return  <>
  <Row gutter={16} style={{marginTop:24}}>
  <Col span={12}>
    <Title level={4}>Players</Title>
    <List
        header={<div>Selected Players List</div>}
        bordered
        dataSource={currentPlayersList}
        style={{marginBottom:24}}
        renderItem={(item) => (
          <List.Item style={{display:'flex'}}>
            {item.email} <Button type="primary" style={{marginLeft:'auto'}} onClick={() => {
              removePlayer(item.userId)
            }}>
              Remove
            </Button>
          </List.Item>
        )}
      />
      <Button type="primary" onClick={() => {
        setPlayerModalOpen(true);
      }}>Add New Player</Button>
  </Col>
  <Col span={12}>
    <Title level={4}>Quests Plan</Title>
    <List
        header={<div>Planned Quests</div>}
        bordered
        dataSource={currentRound.questsPlan}
        style={{marginBottom:24}}
        renderItem={(item) => (
          <List.Item style={{display:'flex'}}>
            <div>
            <Typography>
            {moment(item.date).format("LLL")}
              </Typography>
              <Typography>
           Game modes: {item.gameModeSelected? displayGameModes(item.modes):"All"}
              </Typography>
            </div>
             <Button type="primary" style={{marginLeft:'auto'}} onClick={() => {
              removeQuestPlan(item)
             }}>
              Remove
            </Button>
          </List.Item>
        )}
      />
      <Button type="primary" onClick={() => {
        setPlanModalOpen(true);
      }}>Add New Quest Plan</Button>
  </Col>
</Row>
<AddPlayerModal playerModalOpen={playerModalOpen} handleCancel={() => {
  setPlayerModalOpen(false)
}} 
availableList={unSelectedList}
teamsUp={tournament.teamsUp}
roundId={currentRound.roundId}
/>
<QuestPlanModal planModalOpen={planModalOpen} handleCancel={() => {
  setPlanModalOpen(false);
}} 
roundId={currentRound.roundId}
/>
  </>
}


export default EditAbleRound;