export const winCalculationSolos = (entries, fee) => {
    if(entries < 17){
      const prize1 = fee;
      const prize2 = fee;
      const prize3 = fee;
      const giveaway = 0;
      return {
        prize1,
        prize2,
        prize3,
        giveaway
      }
    }else if(entries >= 17 && entries <= 40){
      const totalPot = (entries * fee) * 0.45;
      const prize1 = Math.ceil(totalPot*0.3);
      const prize2 = Math.ceil(totalPot*0.25);
      const prize3 = Math.ceil(totalPot*0.2);
      const giveaway = Math.ceil(totalPot*0.25);
      return {
        prize1,
        prize2,
        prize3,
        giveaway
      }
    }else if(entries > 40 && entries <= 60){
      const totalPot = (entries * fee) * 0.35;
      const prize1 = Math.ceil(totalPot*0.3);
      const prize2 = Math.ceil(totalPot*0.25);
      const prize3 = Math.ceil(totalPot*0.2);
      const giveaway = Math.ceil(totalPot*0.25);
      return {
        prize1,
        prize2,
        prize3,
        giveaway
      }
    }else if(entries > 60 && entries <= 80){
      const totalPot = (entries * fee) * 0.3;
      const prize1 = Math.ceil(totalPot*0.3);
      const prize2 = Math.ceil(totalPot*0.25);
      const prize3 = Math.ceil(totalPot*0.2);
      const giveaway =Math.ceil(totalPot*0.25);
      return {
        prize1,
        prize2,
        prize3,
        giveaway
      }
    }else if(entries > 80){
      const totalPot = (entries * fee) * 0.25;
      const prize1 = Math.ceil(totalPot*0.3);
      const prize2 = Math.ceil(totalPot*0.25);
      const prize3 = Math.ceil(totalPot*0.2);
      const giveaway =Math.ceil(totalPot*0.25);
      return {
        prize1,
        prize2,
        prize3,
        giveaway
      }
    }
}

export const winCalculationDuos = (entries, fee) => {
  if(entries < 20){
    const prize1 = fee;
    const prize2 = fee;
    const giveaway = 0;
    return {
      prize1,
      prize2,
      giveaway
    }
  }else if(entries >= 20 && entries <= 40){
    const totalPot = (entries * fee) * 0.45;
    const prize1 = Math.ceil(totalPot*0.65*0.5);
    const prize2 = 0;
    const giveaway = 0;
    return {
      prize1,
      prize2,
      giveaway
    }
  }else if(entries > 40 && entries <= 60){
    const totalPot = (entries * fee) * 0.35;
    const prize1 = Math.ceil(totalPot*0.45*0.5);
    const prize2 = Math.ceil(totalPot*0.3*0.5);
    const giveaway = Math.ceil(totalPot*0.25);
    return {
      prize1,
      prize2,
      giveaway
    }
  }else if(entries > 60 && entries <= 80){
    const totalPot = (entries * fee) * 0.3;
    const prize1 = Math.ceil(totalPot*0.45*0.5);
    const prize2 = Math.ceil(totalPot*0.3*0.5);
    const giveaway = Math.ceil(totalPot*0.25);
    return {
      prize1,
      prize2,
      giveaway
    }
  }else if(entries > 80){
    const totalPot = (entries * fee) * 0.25;
    const prize1 = Math.ceil(totalPot*0.45*0.5);
    const prize2 = Math.ceil(totalPot*0.3*0.5);
    const giveaway = Math.ceil(totalPot*0.25);
    return {
      prize1,
      prize2,
      giveaway
    }
  }
}

export const winCalculationTrios = (entries, fee) => {
  if(entries < 20){
    const prize1 = fee;
    const giveaway = 0;
    return {
      prize1,
      giveaway
    }
  }else if(entries >= 20 && entries <= 40){
    const totalPot = (entries * fee) * 0.45;
    const prize1 = Math.ceil(totalPot*0.75*0.33);
    const giveaway = 0;
    return {
      prize1,
      giveaway
    }
  }else if(entries > 40 && entries <= 60){
    const totalPot = (entries * fee) * 0.35;
    const prize1 = Math.ceil(totalPot*0.75*0.33);
    const giveaway = Math.ceil(totalPot*0.25);
    return {
      prize1,
      giveaway
    }
  }else if(entries > 60 && entries <= 80){
    const totalPot = (entries * fee) * 0.3;
    const prize1 = Math.ceil(totalPot*0.75*0.33);
    const giveaway = Math.ceil(totalPot*0.25);
    return {
      prize1,
      giveaway
    }
  }else if(entries > 80){
    const totalPot = (entries * fee) * 0.25;
    const prize1 = Math.ceil(totalPot*0.75*0.33);
    const giveaway = Math.ceil(totalPot*0.25);
    return {
      prize1,
      giveaway
    }
  }
}

export const winCalculationSquads = (entries, fee) => {
  if(entries < 20){
    const prize1 = fee;
    const giveaway = 0;
    return {
      prize1,
      giveaway
    }
  }else if(entries >= 20 && entries <= 40){
    const totalPot = (entries * fee) * 0.45;
    const prize1 = Math.ceil(totalPot*0.75*0.25);
    const giveaway = 0;
    return {
      prize1,
      giveaway
    }
  }else if(entries > 40 && entries <= 60){
    const totalPot = (entries * fee) * 0.35;
    const prize1 = Math.ceil(totalPot*0.75*0.25);
    const giveaway = Math.ceil(totalPot*0.25);
    return {
      prize1,
      giveaway
    }
  }else if(entries > 60 && entries <= 80){
    const totalPot = (entries * fee) * 0.3;
    const prize1 = Math.ceil(totalPot*0.75*0.25);
    const giveaway = Math.ceil(totalPot*0.25);
    return {
      prize1,
      giveaway
    }
  }else if(entries > 80){
    const totalPot = (entries * fee) * 0.25;
    const prize1 = Math.ceil(totalPot*0.75*0.25);
    const giveaway = Math.ceil(totalPot*0.25);
    return {
      prize1,
      giveaway
    }
  }
};

export const calcPrizePool = (match) => {
  const style = match.match_style;

  let prizePool = {}
  if(style === "SOLOS" || style === "ARENA SOLOS" || style === "NB SOLOS" || style === "CREATIVE 50 LIMITS"){
    prizePool = soloPricePool(match);
  }
  if(style === "DUOS" || style === "ARENA DUOS" || style === "NB DUOS"){
    prizePool = duosPricePool(match);
  }
  if(style === "TRIOS" || style === "ARENA TRIOS" || style === "NB TRIOS"){
    prizePool = triosPricePool(match);
  }
  if(style === "SQUADS" || style === "NB SQUADS"){
    prizePool = squadsPricePool(match);
  }
  return prizePool;
}

export const calcTotalPrize = (match) => {
  let total = 0;
  const { token_cost} = match;
  if(token_cost === 0){
    total = 196;
  }else {
    total = token_cost * 8;
  }
  return total;
}



const soloPricePool = (match) => {
  const {status, participant_ids, token_cost, freeEntryList} = match;
  if(token_cost === 0){
    return {
      prize1:69,
      prize2:45,
      prize3:38
    }
  }else {
    let playersCount;
    if(status === 1){
      playersCount = participant_ids.length;
    }else {
       playersCount = participant_ids.length - freeEntryList?.length?? 0;
    }
    if(playersCount >=8){
      return {
        prize1:Math.ceil(token_cost*3.5),
        prize2:Math.ceil(token_cost*2.5),
        prize3:Math.ceil(token_cost*2.),
      }
    }else {
      return {
        prize1:token_cost,
        prize2:token_cost,
        prize3:token_cost
      }
    }
  }
};

const duosPricePool = (match) => {
  const {status, participant_ids, token_cost, freeEntryList} = match;
  if(token_cost === 0){
    return {
      prize1:69,
      prize2:45,
    }
  }else {
    let playersCount;
    if(status === 1){
      playersCount = participant_ids.length;
    }else {
       playersCount = participant_ids.length - freeEntryList?.length?? 0;
    }
    if(playersCount >= 8){
      return {
        prize1:Math.ceil(token_cost*2.25),
        prize2:Math.ceil(token_cost*1.75)
      }
    }else {
      return {
        prize1:token_cost,
        prize2:token_cost
      }
    }
  }
};

const triosPricePool = (match) => {
  const {status, participant_ids, token_cost, freeEntryList} = match;
  if(token_cost === 0){
    return {
      prize1:69
    }
  }else {
    let playersCount;
    if(status === 1){
      playersCount = participant_ids.length;
    }else {
       playersCount = participant_ids.length - freeEntryList?.length?? 0;
    }
    if(playersCount >= 8){
      return {
        prize1:Math.ceil((token_cost*8)/3)
      }
    }else {
      return {
        prize1:token_cost,
      }
    }
  }
}

const squadsPricePool = (match) => {
  const {status, participant_ids, token_cost, freeEntryList} = match;
  if(token_cost === 0){
    return {
      prize1:69,
      prize2:45,
    }
  }else {
    let playersCount;
    if(status === 1){
      playersCount = participant_ids.length;
    }else {
       playersCount = participant_ids.length - freeEntryList?.length?? 0;
    }
    if(playersCount >= 8){
      return {
        prize1:Math.ceil(token_cost*2)
      }
    }else {
      return {
        prize1:token_cost,
      }
    }
  }
}

export const calcWeeklyQuestPool = (quest) => {
  const {player, entryFee} = quest;
  if(player.length >= 6){
    return Math.ceil(player.length * entryFee * 0.8)
  }else {
    return player.length * entryFee
  }
}

export const calcWeeklyQuestTierPlacement = (quest) => {
  const {player} = quest;
  const playersCount = player.length;
  if(playersCount >= 6){
    const userPerTier = Math.round((playersCount/10));
    const tier1 = {start:1, end:userPerTier};
    const tier2 = {start:userPerTier + 1, end: userPerTier * 2};
    const tier3 = {start:userPerTier * 2 + 1, end: userPerTier * 3};
    return {
      tier1,
      tier2,
      tier3
    }
  }else {
    const tier1 = {start:1, end:1};
    const tier2 = {start:2, end: 2};
    const tier3 = {start:3, end: 3};
    return {
      tier1,
      tier2,
      tier3
    }
  }
}

export const calcWeeklyQuestTier = (quest) => {
  const {player, entryFee} = quest;
  const playersCount = player.length;
  if(playersCount >= 6){
    const pool = Math.ceil(playersCount * entryFee);
    const userPerTier = Math.round((playersCount/10));
    const tier1 = Math.ceil((pool * 0.38)/userPerTier);
    const tier2 = Math.ceil((pool * 0.24)/userPerTier);
    const tier3 = Math.ceil((pool * 0.18)/userPerTier);
    return {
      tier1,
      tier2,
      tier3
    }
  } else {
    return {
      tier1:entryFee,
      tier2:entryFee,
      tier3:entryFee
    }
  }
}

export const calcCreatorQuestPool = (quest) => {
  const {players, entryFee} = quest;
  let prizePoolMode = 1;
  if((quest.isFixedPricePool && quest.fixedPricePool && quest.fixedPricePool > 0) || (quest.prizePoolMode === 2 && quest.prizePool > 0) ) {
    prizePoolMode = 2;
  }else if(quest.prizePoolMode === 3 && quest.prizePool > 0 ){
    prizePoolMode = 3;
  }
  if(prizePoolMode === 1){
    return Math.ceil(players.length * entryFee * 0.8)
  }else if(prizePoolMode === 2){
    if(quest.fixedPricePool && quest.fixedPricePool){
      return quest.fixedPricePool;
    }else {
      return quest.prizePool ?? 0;
    }
  }else {
    return (quest.prizePool?? 0) + Number(players.length * entryFee)
  }
}

export const calcFunGameModeReward = (quest) => {
  const {players} = quest;
  const playersCount = players.length;
  const rewardsCount = playersCount >= 3 ? (Math.round(playersCount * 0.3)) : 1;
  const prizePool = quest.prizePoolMode === 2 ? quest.prizePool :0;
  return Math.ceil(((prizePool)/rewardsCount)??0);
}

export const calcFunGameModePlacement = (quest) => {
  const {players } = quest;
  const playersCount = players.length;
  if(playersCount <=3 ){
    return {start:1, end:1}
  }else {
    const rewardsCount = Math.round(playersCount * 0.3);
    return {
      start:1, end:rewardsCount
    }
  }
}

export const calcCreatorQuestTier = (quest) => {
  const {players, entryFee} = quest;
  const playersCount = players.length;
  const isFixedOrHybrid = (quest.isFixedPricePool && quest.fixedPricePool && quest.fixedPricePool > 0) || (quest.prizePoolMode === 2 && quest.prizePool > 0) || (quest.prizePoolMode === 3 && quest.prizePool > 0);
  if(playersCount >= 10){
    const pool = calcCreatorQuestPool(quest);
    const userPerTier = Math.round((playersCount/10));

    const tier1 = isFixedOrHybrid? Math.ceil((pool * 0.5)/userPerTier) : Math.ceil((pool * 0.4)/userPerTier);
    const tier2 = isFixedOrHybrid? Math.ceil((pool * 0.3)/userPerTier) : Math.ceil((pool * 0.24)/userPerTier);
    const tier3 = isFixedOrHybrid? Math.ceil((pool * 0.2)/userPerTier): Math.ceil((pool * 0.16)/userPerTier);
    return {
      tier1,
      tier2,
      tier3
    }
  } else {
    if(isFixedOrHybrid){
      const pool = calcCreatorQuestPool(quest);
      return {
        tier1:Math.ceil(pool * 0.5),
        tier2:Math.ceil(pool * 0.3),
        tier3:Math.ceil(pool * 0.2)
      }
    }else {
      return {
        tier1:entryFee,
        tier2:entryFee,
        tier3:entryFee
      }
    }
  }
}

export const calcCreatorQuestTierPlacement = (quest) => {
  const {players} = quest;
  const playersCount = players.length;
  if(playersCount >= 10){
    const userPerTier = Math.round((playersCount/10));
    const tier1 = {start:1, end:userPerTier};
    const tier2 = {start:userPerTier + 1, end: userPerTier * 2};
    const tier3 = {start:userPerTier * 2 + 1, end: userPerTier * 3};
    return {
      tier1,
      tier2,
      tier3
    }
  }else {
    const tier1 = {start:1, end:1};
    const tier2 = {start:2, end: 2};
    const tier3 = {start:3, end: 3};
    return {
      tier1,
      tier2,
      tier3
    }
  }
}

export const getLevel = (points) => {
  let currentLevel = 0;
  let nextLevel = 1;
  let nextLevelPoints = 150;
  if(points < 150){
    return {
      currentLevel,
      nextLevel,
      nextLevelPoints
    }
  }else if(points >= 150 && points < 400){
    currentLevel = 1;
    nextLevel = 2;
    nextLevelPoints = 400;
  }else if(points >= 400 && points < 1000 ){
    currentLevel = 2;
    nextLevel = 3;
    nextLevelPoints = 1000;
  }else if(points >= 1000 && points < 2500 ){
    currentLevel = 3;
    nextLevel = 4;
    nextLevelPoints = 2500;
  }else if(points >= 2500 && points < 5000 ){
    currentLevel = 4;
    nextLevel = 5;
    nextLevelPoints = 5000;
  }else if(points >= 5000 ){
    currentLevel = 5;
    nextLevel = 5;
    nextLevelPoints = 5000;
  }

  return {
    currentLevel,
    nextLevel,
    nextLevelPoints
  }
}

export const calcProLeagueReward = (quest) => {
  const {players, entryFee, prizePoolMode} = quest; 
  const playersCount = players.length;
  let prizePool = Math.round(playersCount * entryFee * 0.8);
  if(prizePoolMode === 2){
    prizePool = quest?.prizePool??0;
  }else if(prizePool === 3){
    prizePool = (quest.prizePool?? 0) + Number(players.length * entryFee);
  }

  return {
    first:Math.round(prizePool* 0.5),
    second:Math.round(prizePool* 0.3),
    third: Math.round(prizePool * 0.2)
  }
}

export const calcProLeaguePool = (quest) => {
  const {players, entryFee, prizePoolMode} = quest; 
  const playersCount = players.length;
  let prizePool = Math.round(playersCount * entryFee * 0.8);
  if(prizePoolMode === 2){
    prizePool = quest?.prizePool??0;
  }else if(prizePool === 3){
    prizePool = (quest.prizePool?? 0) + Number(players.length * entryFee);
  }
  return prizePool;
}

