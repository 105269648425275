import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Firebase from "../../util/firebase";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { calcProLeaguePool } from "../../util/helper";

const QuestsDashboard = ({history}) => {
  const [quests, setQuests] = useState([]);
  const [option, setOption] = useState(2);
  const { profile, id: userId} = useSelector((state) => state.user);
  useEffect(() => {
    let listener = null;
    const fetchQuests = async () => {
        listener = Firebase.firestore()
          .collection("pro_league")
          .where("streamerId", "==", userId)
          .onSnapshot(querySnapshot => {
            let quests = [];
            querySnapshot.forEach(function (doc) {
              const obj = {
                ...doc.data(),
               proLeagueId: doc.id,
              };
              quests.push(obj);
            });
            setQuests(quests);
          },
          error => {
            console.log(error);
          }
          )
    }
    if(profile && userId){
      fetchQuests();
    }else {
      history.push("/")
    }
    return () => {
      if(listener){
        listener();
      }
    }
  },[userId, history, profile]);



  const showQuest = useMemo(() => {
    const timeSort = (a, b) => {
      const timeA = moment(a.startTime);
      const timeB = moment(b.startTime);
      if(timeA.isBefore(timeB)){
        return 1;
      }else {
        return -1;
      }
    }
    if(quests && option) {
      const result = quests.filter(item => item.status === option);
      return result.sort(timeSort)
    }
    return [];

  }, [quests, option]);


  return <Container>
    <Title>
    Your Pro League
    </Title>
    <SubTitle>
    View all the Pro League you’ve created
    </SubTitle>
    <Choices isDarkMode={true}>
          <Choice active={option === 2} isDarkMode={true} onClick={() => {
            setOption(2);
          }}>
              Live
            </Choice>
            <Choice active={option === 1} isDarkMode={true} onClick={() => {
              setOption(1)
            }}>
               Upcoming
            </Choice>
            <Choice active={option === 3} isDarkMode={true} onClick={() => {
              setOption(3)
            }}>
               Past
    </Choice>
    </Choices>
    <QuestsContainer>
        {
          showQuest && showQuest.map((item) => (<QuestBox key={item.questId} onClick={() => {
            history.push(`/proLeagueControl/${item.proLeagueId}`)
          }}>
             <Time style={{fontWeight:'bold', fontSize:18}}>{item.questName}</Time>
              <Time>{moment(item.startTime).format('YYYY-M-D  h:mm a')}</Time>
              <TimeText>Start Time</TimeText>
              <DataContainer>
                <Data style={{width:'30%'}}>
                  <DataEntry>
                    {item?.players?.length?? 0}
                    </DataEntry>
                  <DataTitle>
                    Players
                  </DataTitle>
                </Data>
                <Data style={{width:'30%'}}>
                  <DataEntry>
                    {(item?.duration?? 0)/60} hrs
                    </DataEntry>
                  <DataTitle>
                    Time
                  </DataTitle>
                </Data>
                <Data style={{width:'40%'}}>
                  <DataEntry>
                    <img src="../assets/coin.png" alt="" style={{width:15, marginRight:4, marginBottom:2}}/>
                    {calcProLeaguePool(item)}
                    </DataEntry>
                  <DataTitle>
                    Prize Pool
                  </DataTitle>
                </Data>
              </DataContainer>
          </QuestBox>))
        }
    </QuestsContainer>
  </Container>
}

const Container = styled.div`
padding:48px;
`;

const Title = styled.div`
text-align: left;
font: normal normal bold 20px/24px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
margin-bottom:16px;
`;

const SubTitle = styled.div`
text-align: left;
font: normal normal normal 16px/20px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
`;

const Choices = styled.div`
width: 100%;
margin:24px auto;
display:flex;
height:29px;
border-bottom:2px solid #F9F9F9;
`;

const Choice = styled.div`
width: auto;
padding:0 12px;
height: 29px;
/* UI Properties */
font: normal normal bold 20px/24px SF PRO, serif;
letter-spacing: 0px;
text-align:center;
margin-right:18px;
cursor:pointer;
color: #000000;
${(p) =>
  p.isDarkMode && !p.active 
    && `color: #BFBFBF;`}
${(p) =>
   p.active
        ? `color:#F72375; border-bottom:2px solid #F72375;`
        : ``}
`;

const QuestsContainer = styled.div`
display:grid;
grid-template-columns: repeat(2, 194px);
justify-content:space-between;
grid-row-gap:24px;
width:100%;
`;

const QuestBox = styled.div`
width: 194px;
height: 143px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
display:flex;
padding:16px 12px;
flex-direction:column;
cursor:pointer;
`;

const Time = styled.div`
text-align: left;
font: normal normal normal 14px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
margin-bottom:6px;
`;

const TimeText = styled.div`
text-align: left;
font: normal normal normal 11px SF PRO, serif;
letter-spacing: 0px;
color: #D1D1D1;
`;

const DataContainer = styled.div`
display:flex;
margin-top:auto;
width:100%;
`;

const Data = styled.div`
display:flex;
flex-direction:column;
`;

const DataEntry = styled.div`
text-align: left;
font: normal normal normal 15px/15px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
margin-bottom:4px;
`;

const DataTitle = styled.div`
text-align: left;
font: normal normal normal 12px SF PRO, serif;
letter-spacing: 0px;
color: #D1D1D1;
`;



export default withRouter(QuestsDashboard);