import React, {  useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import * as API from "../../util/api";
import axios from "axios";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import { debounce } from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { calcProLeaguePool } from "../../util/helper";
import { Mixpanel } from '../../util/mixpanel';
import { Input } from 'antd';


const JoinQuestModal = ({quest, modalOpen, closeModal, history, showSuccess}) => {
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [accountPrivate, setAccountPrivate] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);

  useEffect(() => {
    const checkAccountPrivate = async () => {

      if(profile){
        const {verify_status, savedEpicId} = profile;
        if(verify_status === 2 && savedEpicId){
          const url = API.CHECK_ACCOUNT_PRIVATE;
          try {
            const res = await axios.get(url, {
              params:{
                epicId:profile.savedEpicId
              }
            });
            if(res.status === 200){
              const {isAccountPrivate} = res.data;
              if(isAccountPrivate){
                setAccountPrivate(true)
              }
            }
          }catch(err){
            console.log(err)
          }
    
        }
      }
    };
    checkAccountPrivate()
  }, [profile]);
  

  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: "613px",
      height:'auto',
      padding:'24px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "24px",
      zIndex: "10000",
    },
  };

  const hasEnoughCoins = useMemo(() => {
    if(profile && quest) {
      const {entryFee} = quest;
      const {token_count} = profile;
      if(token_count >= entryFee){
        return true
      }
    }
    return false;
  },[profile, quest]);

  const hasVerifyStatus = useMemo(() => {
    if(profile){
      const {verify_status, savedEpicId} = profile;
      if(verify_status === 2 && savedEpicId){
        return true;
      }
    }
    return false;
  },[profile])

  const isActiveSub = useMemo(() => {
    if(loggedIn && profile && profile.multiplier){
      const multiplier = profile.multiplier;
      if(multiplier.renewAt && moment(multiplier.renewAt).isAfter(moment()) ){
        return true
      }
    }
    return false;
  },[profile, loggedIn]);



  const balance = useMemo(() => {
    if(loggedIn && profile && profile.token_count){
      return profile.token_count;
    }
    return 0;
  }, [profile, loggedIn]);


  const debounceJoin = debounce(() => {
      joinProleague()
  }, 500);

  const joinProleague = async() => {
      const requestBody = {
        proLeagueId:quest.questId,
        userId,
        transId:profile.transId,
        entryFee:quest.entryFee
      }
      const url = API.JOIN_PROLEAGUE;
      try {
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          setLoading(false);
          Mixpanel.track("Join Creator Quest")
          closeModal();
          showSuccess();
          }else {
            setLoading(false);
            closeModal();
        }
      }catch(err){
        setLoading(false);
        console.log(err)
      }
  }

  const setVerifyFrom = () => ({
    type:"SET_VERIFY_FROM",
    payload: {
      target:"proLeague",
      target_matchId:quest.questId
    }
  });

  const dispatch = useDispatch()

  const toVerifyAccount = () => {
    dispatch(setVerifyFrom())
    history.push("/linkAccount")
  }


  const prizePool = useMemo(() => {
    return calcProLeaguePool(quest);
  }, [quest]);


  const entryFee = useMemo(() => {
    if(isActiveSub){
      return Math.ceil(quest.entryFee* 0.85);
    }else {
      return quest.entryFee
    }
  }, [quest, isActiveSub])



  return <Modal
  isOpen={modalOpen}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
>
<Top>
      <ArrowLeftOutlined style={{cursor:'pointer', marginRight:8}} onClick={closeModal}/><span>Join Quest</span>
      <PointBox>
          + 5 Points
        </PointBox>
    </Top>
    <Title>
    Est. Prize Pool
    </Title>
    <Data>
      <Coin>{prizePool}</Coin>
      <Icon src="../assets/Coin.svg" alt="icon"/>
    </Data>
    <Datas>
      <DataItem>
      <Title>
    Balance
    </Title>
    <Data>
      <Coin>{balance}</Coin>
      <Icon src="../assets/Coin.svg" alt="icon"/>
    </Data>
      </DataItem>
      <DataItem>
      <Title>
    Quest Entry Fee
    </Title>
    <Data>
      <Coin style={{color:"#F72375"}}>{entryFee}</Coin>
      <Icon src="../assets/Coin.svg" alt="icon"/>
    </Data>
      </DataItem>
    </Datas>
    {
      hasVerifyStatus ?  <JoinButton onClick={() => {
        if(!loading && hasEnoughCoins){
          setLoading(true);
          debounceJoin()
        }
      }}
      disabled={loading || !hasEnoughCoins}
      >
        {
          loading  && "Loading..." 
        }
        {
          !loading && hasEnoughCoins  && "Pay & Join "
        }
        {
          !loading && !hasEnoughCoins  && "You Don't Have Enough Coins "
        }
      </JoinButton> :  <>
      <Title style={{color:"#FFFFFF",textAlign:'center'}}>
    Please verify your epic account before joining the quest!
    </Title>
    <JoinButton style={{margin:"24px auto 0px", width:"60%"}} onClick={toVerifyAccount}>
      Verify Epic Account
    </JoinButton>
      </>
    }
      {
      !isActiveSub && <Tip onClick={() => {
        Mixpanel.track("Redirect to multiplier from join creator modal")
        history.push("/multiplier")
      }}>
        Get 200 Coins with the Multiplier
      </Tip>
    }
</Modal>
}


const Top = styled.div`
display:flex;
align-items:center;
font: normal normal bold 24px/20px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const PointBox = styled.div`
width: 94px;
height: 36px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 11px;
text-align: center;
font: normal normal bold 14px/34px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-left:auto;
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 20px/36px SF PRO, serif;
letter-spacing: 0px;
color: #979797;
text-transform: capitalize;
`;

const Data = styled.div`
width:100%;
display:flex;
justify-content:space-between;
border-bottom:3px solid #979797;
align-items:center;
height:42px;
`;

const Icon = styled.img`
width:20px;
`;

const Coin = styled.div`
text-align: left;
font: normal normal bold 24px/24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const Datas = styled.div`
display:flex;
margin:12px 0px 24px;
width:100%;
justify-content:space-between;
`;

const DataItem = styled.div`
width:258px;
`;

const JoinButton = styled.div`
margin-top:24px;
text-align: center;
font: normal normal 600 16px/49px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
width:100%;
height:49px;
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 12px;
cursor:pointer;
${(p) =>
  p.disabled
    && `background: #B7B7B7 0% 0% no-repeat padding-box;
    cursor:not-allowed;
    `}
`;

const Tip = styled.div`
width:100%;
text-align: center;
text-decoration: underline;
font: normal normal normal 18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
margin-top:12px;
cursor:pointer;
`;

const StyledInput = styled(Input)`
margin-top:8px;
height: 37px;
background: #40444B 0% 0% no-repeat padding-box;
border: none;
border-radius: 5px;
color:#FFFFFF;
`;

export default withRouter(JoinQuestModal);