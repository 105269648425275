import React, { useMemo, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { message, List } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from 'moment'

const OrderModal = ({modalOpen, closeModal, orders}) => {
  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: "613px",
      height:'auto',
      padding:'24px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "12px",
      zIndex: "10000",
    },
  };
  return <Modal
  isOpen={modalOpen}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
> 
<Top>
      <ArrowLeftOutlined style={{cursor:'pointer', marginRight:8}} onClick={closeModal}/><span>My Orders</span> 
    </Top>
    <List
              dataSource={orders}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <OrderContainer>
            <div>
              <Name style={{marginBottom:12}}>
                {item.gameName}
              </Name>
              <Date>
                {moment(item.createdAt).format("L")}
              </Date>
            </div>
          <Code>
            {item.code}
          </Code>
          <CopyToClipboard
                  text={item?.code}
                  onCopy={() =>
                    message.success("Code is copied")
                  }
                >
             <img src="/assets/copy.svg" alt="back" style={{marginLeft:12, cursor:'pointer'}}/>
         </CopyToClipboard>
          </OrderContainer>
                </List.Item>
              )}
            ></List>
</Modal>
}

const Top = styled.div`
display:flex;
align-items:center;
font: normal normal bold 24px/20px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;


const OrderContainer = styled.div`
border-bottom: 2px solid rgba(255, 255, 255, 0.29);
display:flex;
width:100%;
padding:12px 0px;
align-items:center;
`;

const Name = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

const Date = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const Code = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left:auto;
`;

export default OrderModal;

