import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import InfluencerNavBar from "../nav/influencerNavBar";
import axios from "axios";
import * as API from "../util/api";

const InfluencerEntry = ({history}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  const isAdmin = useMemo(() => {
    const permission_level = profile?.permission_level??0;
    if(permission_level === 3 && profile.influencerId){
      return true;
    }
    if(permission_level === 5 && profile.adminOfInfluencer){
      return true;
    }
    return false;
  }, [profile]);

  const role = useMemo(() => {
    if(profile){
      if(profile.role === 'influencer'){
        return 'influencer'
      }
      if(profile.role === 'publisher'){
        return 'publisher'
      }
    }
    return 'player'
  }, [profile]);

  const [games, setGames] = useState([]);
  useEffect(() => {
    const fetchGameShortList = async () => {
      const url = API.GET_GAME_SHORTLIST;
      try {
        const res = await axios.get(url);
        if(res.status === 200){
          const {gamesList} = res.data;
          setGames(gamesList);
        }
      }catch(err){
        console.log(err)
      }
    }
    fetchGameShortList();
  }, []);

  
  return <Container>
    <InfluencerNavBar activeTab="home" isDarkMode={true} />
    <Content>
      <Top>
        <TopTitle>
        Unlock Exclusive Games and Connect  with Top Game Publishers
        </TopTitle>
        <Desc>
        Every game you redeem sends a notification to the publisher, putting you on the radar of leading industry publishers. Get access to our database of over 200+ that’s growing every week. Get discovered by the creators of the games you love.
        </Desc>
      <div style={{display:'flex', marginBottom:48}}>
        {
          isAdmin ? <PrimaryButton style={{marginRight:24}} onClick={() => {
            window.location.replace("https://club.arcadequest.app/")
          }}>
          View All Games
        </PrimaryButton> : <PrimaryButton style={{marginRight:24}} onClick={() =>{
          history.push("/previewGames")
        }}>
          Preview Games
        </PrimaryButton>
        }
        <SecondaryButton onClick={() => {
          window.open("https://cal.com/daniel-baraghoush-mcp8yh/30-mins-meeting")
        }}>
          Contact Us
        </SecondaryButton>
      </div>
      <div style={{display:'flex'}}>
        <GameImage src="/assets/influencerEntry/steam.png" alt="game"/>
        <GameImage src="/assets/influencerEntry/twitch.png" alt="game"/>
        <GameImage src="/assets/influencerEntry/xbox.png" alt="game"/>
      </div>
      </Top>
      <div style={{width:'100%', padding:"0 36px"}}>
        <TopTitle style={{width:480}}>
        level up your creator business
        </TopTitle>
        <div style={{display:'flex', justifyContent:"space-between", width:'100%', margin:'36px 0px'}}>
          <div style={{display:'flex', flexDirection:"column", width: 330, alignItems:'center'}}>
            <DetailImage src="/assets/influencerEntry/chanel.png" alt="game"/>
            <DetailTitle>
            Grow your channel
            </DetailTitle>
            <DetailContent>
            Find new games to play. We give you access to all the publisher’s games on our platform and you can stream them anytime you want. 
            </DetailContent>
          </div>
          <div style={{display:'flex', flexDirection:"column" , width: 330, alignItems:'center'}}>
            <DetailImage src="/assets/influencerEntry/publisher.png" alt="game"/>
            <DetailTitle>
            Connect with publishers
            </DetailTitle>
            <DetailContent>
            You’ll be discovered by the top game publishers in the industry and indie developers whose games you authentically love. 
            </DetailContent>
          </div>
          <div style={{display:'flex', flexDirection:"column" , width: 330, alignItems:'center'}}>
            <DetailImage src="/assets/influencerEntry/payment.png" alt="game"/>
            <DetailTitle>
            Paid on time
            </DetailTitle>
            <DetailContent>
            We help with payments to make sure all your funds are available in time and you’re paid for each milestone. No more waiting for payments.
            </DetailContent>
          </div>
        </div>
        <TopTitle style={{width:480}}>
        Featured games
        </TopTitle>
        <GameContainer>
        {
          games.map(item => {
            return <Game key={item.gameId}>
              <GameBackground src={item.image} alt="productImage" />
              <GameName>
                {item.name}
              </GameName>
              <PublisherName>
                {item.publisher}
              </PublisherName>
            </Game>
          })
        }
      </GameContainer>
      </div>
    </Content>
    {
      loggedIn && role === 'influencer' && <Box>
        <BoxTitle>
        We’ve moved the influencer portal!
        </BoxTitle>
        <BoxContent>
        The influencer portal is now on a different site but you can use the same login information to access.
        </BoxContent>
        <div style={{display:'flex'}}>
          
          <PrimaryButton onClick={() => {
            window.location.replace("https://club.arcadequest.app/")
          }}>
            Go To Publisher Portal
          </PrimaryButton>
        </div>
      </Box>
    }
  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#000000 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  padding-top:108px;
  overflow-y:auto;
  position:relative;
`;

const Box = styled.div`
display: flex;
width: 471px;
border-radius: 8px;
background: rgba(247, 35, 117, 0.18);
backdrop-filter: blur(45px);
position:fixed;
top:68px;
right:24px;
padding:24px;
height:auto;
z-index:100;
flex-direction:column;
`;

const BoxTitle = styled.div`
color: #FAFAFA;
font-family: SF Pro;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom:16px;
`;

const BoxContent = styled.div`
color: #FAFAFA;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
margin-bottom:16px;
`;

const Content = styled.div`
width:1260px;
margin:0px auto 48px;
`;

const Top = styled.div`
width: 100%;
height: 545px;
flex-shrink: 0;
border-radius: 24px;
background: url("/assets/influencerEntry/topBackground.png");
/* filter: blur(4px); */
padding:36px;
margin-bottom:36px;
`;

const TopTitle = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 48px;
font-style: normal;
font-weight: 800;
line-height: normal;
text-transform: uppercase;
width:700px;
white-space:wrap;
`;

const Desc = styled.div`
color: #FAFAFA;
font-family: SF Pro;
font-size: 20px;
font-style: normal;
font-weight: 510;
line-height: normal;
margin:24px 0px 36px;
width:600px;
`;

const PrimaryButton = styled.div`
display: flex;
width: 194px;
height: 45px;
justify-content: center;
align-items: center;
border-radius: 8px;
background: #F72375;
cursor:pointer;

color:#FAFAFA;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 45px;
`;

const SecondaryButton = styled.div`
display: flex;
width: 194px;
height: 45px;
justify-content: center;
align-items: center;
cursor:pointer;
border-radius: 8px;
border: 1px solid #F72375;


color: #F72375;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 45px;
`;

const GameImage = styled.img`
height:40px;
margin-right:36px;
`;

const DetailImage = styled.img`
height:315px;
width:315px;
margin-bottom:16px;
`;


const DetailTitle = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 22px;
font-style: normal;
font-weight: 800;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
text-align:left;
width:100%;
`;


const DetailContent = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const GameContainer = styled.div`
display: flex;
overflow-x: auto;
margin-top:24px;
white-space: nowrap;
`;

const Game = styled.div`
flex: 0 0 auto;
width: 325px;
height: auto;
padding-bottom:16px;
border-radius: 10px;
background: #2E2E2E;
margin-right:24px;
display:flex;
flex-direction:column;
`;

const GameBackground = styled.img`
width:100%;
height:141px;
border-radius: 10px 10px 0px 0px;
margin-bottom:16px;
`;

const GameName = styled.div`
color: #FAFAFA;
font-family: SF Pro;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left:24px;
margin-bottom:24px;
white-space: normal;
`;

const PublisherName = styled.div`
color: #FFF;
font-family: SF Pro;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-left:24px;
`;

export default withRouter(InfluencerEntry)