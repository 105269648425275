import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
import styled from 'styled-components';
import { useSelector } from "react-redux";
import Countdown from 'react-countdown';

const UserInfo = ({quest, joined, sortedPlayers}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  const modeOptions = [
    {
      label:"Build Solos",
      value:"defaultsolo"
    },
    {
      label:"No Build Solos",
      value:"nobuildbr_solo"
    },
    {
      label:"Arena Solos",
      value:"showdownalt_solo"
    },
    {
      label:"Build Duos",
      value:"defaultduo"
    },
    {
      label:"No Build Duos",
      value:"nobuildbr_duo"
    },
    {
      label:"Arena Duos",
      value:"showdownalt_duos"
    },
    {
      label:"Build Trios",
      value:"trios"
    },
    {
      label:"No Build Trios",
      value:"nobuildbr_trio"
    },
    {
      label:"Arena Trios",
      value:"showdownalt_trios"
    },
    {
      label:"Build Squads",
      value:"defaultsquad"
    },
    {
      label:"No Build Squads",
      value:"nobuildbr_squad"
    },
    {
      label:"Arena Squads",
      value:"showdownalt_squads"
    },
  ]

  const allLabels = modeOptions.map((item) => item.label);

  const generateLabels = (modes) => {
    const result = modes.reduce((acc, curr) => {
      const target = modeOptions.find((item) => item.value === curr);
      if(target){
        acc.push(target.label)
      }
      return acc;
    }, [])
    return result;
  }

  const myKills = useMemo(() => {
    if((quest.status === 2 || quest.status ===3 ) && quest.players){
      const target = quest.players.find(item => item.userId === userId);
      if(target && quest.status === 2){
        return (target.currentKills??0) - (target.startKills??0)
      }
      if(target && quest.status === 3){
        return (target.finalKills??0) - (target.startKills??0)
      }
    }
    return "-";
  }, [quest, userId]);

  const myRank = useMemo(() => {
    const index = sortedPlayers.findIndex(item => item.userId === userId);
    if(index > 0){
      return index + 1;
    }else {
      return "-"
    }
  }, [sortedPlayers, userId])

  const acceptModes = (quest.gameModeSelected  && quest.gameModes )? generateLabels(quest.gameModes) : allLabels;

  const nextUpdateTime = useMemo(() => {
   
    const time = quest.updatedIndex === 0 ? moment(quest.lastUpdatedAt).add(15, "m").toDate():moment(quest.lastUpdatedAt).add(5, "m").toDate();
    const key = moment(quest.lastUpdatedAt).unix();
    return {
      time,
      key
    }
}, [quest.lastUpdatedAt,quest.updatedIndex]);

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>Soon</span>
  } else {
    // Render a countdown
    return <span>{minutes}:{seconds}</span>;
  }
};

  return <Container>
    {
      (quest.status === 3 ) &&  <>
        <div style={{display:'flex', justifyContent:'space-between', marginBottom:24}} >
          <DataBox>
              <DataContent>
                {myRank}
              </DataContent>
              <DataTitle>
                Rank
              </DataTitle>
          </DataBox>
          <DataBox>
              <DataContent>
                {myKills}
              </DataContent>
              <DataTitle>
                Your Kills
              </DataTitle>
          </DataBox>
          <DataBox style={{width:200}}>
              <DataContent >
                {quest.resultGenerated? 'Finalized' : "Processing"}
              </DataContent>
              <DataTitle>
                Result
              </DataTitle>
          </DataBox>
        </div>
      </>
    }
    {
      (quest.status === 2 ) &&  <>
        <div style={{display:'flex', justifyContent:'space-between', marginBottom:24}} >
          <DataBox>
              <DataContent>
                {myRank}
              </DataContent>
              <DataTitle>
                Rank
              </DataTitle>
          </DataBox>
          <DataBox>
              <DataContent>
                {myKills}
              </DataContent>
              <DataTitle>
                Your Kills
              </DataTitle>
          </DataBox>
          <DataBox style={{width:200}}>
              <DataContent >
                <Countdown date={nextUpdateTime?.time??""} renderer={renderer} key={nextUpdateTime?.key??""}/>
              </DataContent>
              <DataTitle>
                Next Update
              </DataTitle>
          </DataBox>
        </div>
        <div style={{display:'flex', justifyContent:'space-between', marginBottom:24}} >
          <DataBox>
            <DataContent>
              {(quest.duration)/60} HR
            </DataContent>
            <DataTitle>
              Duration
            </DataTitle>
          </DataBox>
          <DataBox style={{width:300}}>
            <DataContent>
              {moment(quest.lastUpdatedAt).format("LLL")}
            </DataContent>
            <DataTitle>
              Last Update
            </DataTitle>
          </DataBox>
        </div>
      </>
    }
    <ModeContainer>
      <Modes>
        {
                acceptModes.map((item) => {
                  return <ModeText>{item}</ModeText>
                })
              }
      </Modes>
      <ModeSub>
            Accepted Game Modes
            </ModeSub>
    </ModeContainer>
  </Container>
}

const Container = styled.div`
width:536px;
display:flex;
flex-direction:column;
`;

const ModeContainer = styled.div`
width:100%;
border-radius: 10px;
background: #1B1B1B;
height:160px;
display:flex;
flex-direction:column;
padding:12px;
`;

const Modes = styled.div`
display:grid;
grid-template-columns: repeat(3, 120px);
grid-row-gap:8px;
`;

const ModeText = styled.div`
text-align: left;
font: normal normal 16px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const ModeSub = styled.div`
text-align: left;
font: normal normal 12px SF PRO, serif;
letter-spacing: 0px;
color: #B7B7B7;
margin-top:auto;
`;

const DataBox = styled.div`
width: 140px;
height: 87px;
border-radius: 10px;
background: #1B1B1B;
padding:12px;
display:flex;
flex-direction:column;
`;

const DataContent = styled.div`
color: #FFF;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

const DataTitle = styled.div`
color: #A7A7A7;
font-family: SF PRO, serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 16px;
margin-top:auto;
`;

export default UserInfo;