import React, { useMemo, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import * as API from "../../util/api";
import axios from "axios";
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { debounce } from "lodash";
import { withRouter } from "react-router-dom";

const LeaveCreatorQuestModal = ({history, modalOpen, closeModal, quest, showSuccess, isDailyQuest}) =>{
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: "613px",
      height:'auto',
      padding:'24px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "24px",
      zIndex: "10000",
    },
  };


  const debounceConfirmLeave = debounce(() => {
    if(isDailyQuest){
      confirmLeaveDaily()
    }else {
      confirmLeave()
    }
  }, 500);


  const confirmLeaveDaily = async () => {
    const {transId} = profile;
    try {
      const requestBody = {
        questId:quest.questId,
        userId,
        isFreeEntry:joinMethod !== 3,
        transId,
        entryFee:quest.entryFee
      }
      const url = API.LEAVE_QUEST_DAILY;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        setLoading(false);
        closeModal();
        showSuccess();
      }else {
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log(err);
    }
  }


  const confirmLeave = async() => {
    if(joinMethod === 1){
      try {
        const requestBody = {
          questId:quest.questId,
          userId,
        }
        const url = API.LEAVE_CREATORQUEST_AS_SUB;
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          setLoading(false);
          closeModal();
          showSuccess()
        }else {
          setLoading(false);
        }
      }catch(err){
        setLoading(false);
        console.log(err);
      }
    }else if(joinMethod === 2){
      const {questId} = quest;
      try {
        const requestBody = {
          questId,
          userId,
        }
        const url = API.LEAVE_CREATORQUEST_WITH_TICKET;
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          closeModal();
          showSuccess()
        }else {
          setLoading(false);
        }
      }catch(err){
        setLoading(false);
        console.log(err);
      }
    }else if(joinMethod === 3){
      try {
        const requestBody = {
          questId:quest.questId,
          userId,
        }
        const url = API.LEAVE_CREATORQUEST_WITH_AD;
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          setLoading(false);
          closeModal();
          showSuccess()
        }else {
          setLoading(false);
        }
      }catch(err){
        setLoading(false);
        console.log(err);
      }
    }else {
      const {questId, entryFee} = quest;
       const {transId} = profile;
    try {
      const requestBody = {
        questId,
        transId,
        userId,
        entryFee
      }
      const url = API.LEAVE_CREATORQUEST;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        closeModal();
        showSuccess()
      }else {
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log(err);
    }
    }
  }

  const joinMethod = useMemo(() => {
    const target = quest.players.find(item => item.userId === userId);
    if(target){
      if(target.join_as_sub){
        return 1;
      }else if(target.join_with_ticket) {
        return 2;
      }else if(target.join_with_ad){
        return 3;
      }else {
        return 4;
      }
    }else {
      return 0;
    }
  }, [quest, userId])

  return <Modal
  isOpen={modalOpen}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
> 
<Top>
      <ArrowLeftOutlined style={{cursor:'pointer', marginRight:8}} onClick={closeModal}/><span>Leave Quest</span> 
    </Top>
    <Tip>
      {
        (joinMethod ===1 || joinMethod === 3) ? "By clicking confirm you will leave the quest.": " By clicking confirm you will leave the match and get your Coins Or Tickets refunded."
      }
    </Tip>
    {
      (joinMethod === 4 ) && <>
          <Subtitle>
      Refund
    </Subtitle>
    <Box>
    <Coin>
      {quest?.entryFee?? 0}
    </Coin>
    <img
                src="../assets/Coin.svg"
                alt=""
                style={{
                  height: "20px"
                }}
      /> 
    </Box>
      </>
    }
    {
      (joinMethod === 2 ) && <>
          <Subtitle>
      Refund
    </Subtitle>
    <Box>
    <Coin>
      1 Ticket
    </Coin>
    </Box>
      </>
    }
    <LeaveButton onClick={() => {
      if(!loading){
        setLoading(true);
        debounceConfirmLeave()
      }
    }}
    disabled={loading}
    >
      {loading ? "Loading...":"Leave Quest"}
    </LeaveButton>
</Modal>
}

const Top = styled.div`
display:flex;
align-items:center;
font: normal normal bold 24px/20px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const Tip = styled.div`
text-align: left;
font: normal normal normal 18px/24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin:24px 0px;
`;

const Subtitle = styled.div`
text-align: left;
font: normal normal 600 20px/36px SF PRO, serif;
letter-spacing: 0px;
color: #979797;
text-transform: capitalize;
`;

const Box = styled.div`
width:100%;
display:flex;
height:42px;
align-items:center;
border-bottom:3px solid #979797;
justify-content:space-between;
`;

const Coin = styled.div`
text-align: left;
font: normal normal bold 24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const LeaveButton = styled.div`
height: 49px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 12px;
width:100%;
text-align: center;
font: normal normal 600 16px/49px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
cursor:pointer;
margin-top:24px;

${(p) =>
  p.disabled
    && `background: #B7B7B7 0% 0% no-repeat padding-box;
    cursor:not-allowed;
    `}
`;

export default withRouter(LeaveCreatorQuestModal);