import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
import styled from 'styled-components';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Firebase from "../../util/firebase";
import PrivateNavBar from "../../nav/privateNavBar";
import PublicNavBar from "../../nav/publicNavBar";
import { useSelector } from "react-redux";
import Loading from "../../util/loading";
import TournamentCard from './component/tournamentCard';

const Tournaments = () => {
  const [tournaments, setTournaments] = useState([]);

  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  useEffect(() => {
    const fetchTournaments = async () => {
        Firebase.firestore().collection('tournament').where("published", "==", true).onSnapshot(querySnapshot => {
        let result = []
          querySnapshot.docs.forEach(doc => {
          const obj = {
            ...doc.data(),
            tournamentId: doc.id
          }
          result.push(obj);
        });
        setTournaments(result)
      });
      }
      fetchTournaments()
}, []);

  return <Container>
     {
      loggedIn ? <PrivateNavBar activeTab="tournament" isDarkMode={true}/>:<PublicNavBar activeTab="tournament" isDarkMode={true}/>
    }
    <Content>
      <div style={{width:'100%', position:'relative'}}>
      <Top>
        BATTLES
      </Top>
      <Title>
          Tournaments
        </Title>
      </div>
      <TournamentsContainer>
          {
            tournaments.map((item) => {
              return <TournamentCard tournament={item} key={item.tournamentId}/>
            })
          }
      </TournamentsContainer>
    </Content>
  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#000000 0% 0% no-repeat padding-box;
  position: relative;
  min-height:calc(100vh);
  padding-top:108px;
  font-family:SF PRO, serif;
`;

const Content = styled.div`
width: 1180px;
margin:0px auto 48px;
`;

const Top = styled.div`
font-size: 200px;
font-style: normal;
font-weight: 700;
line-height:150%;
text-transform: uppercase;
opacity:0.15;
color:#FFFFFF;
letter-spacing: -7px;
z-index:-1;
`;

const Title = styled.div`
margin:auto 0px;
font-size: 32px;
font-style: normal;
font-weight: 900;
line-height: 150%; /* 48px */
text-transform: uppercase;
color: #FFF;
z-index:1;
margin-top:-178px;
`;

const TournamentsContainer = styled.div`
width:100%;
display:grid;
grid-template-columns: repeat(3, 348px);
grid-row-gap:32px;
grid-column-gap:64px;
margin-top:108px;
`;

export default withRouter(Tournaments)