import React, {useState, useEffect, useMemo} from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import * as API from "../util/api";
import axios from "axios";
import PublicNavBar from "../nav/publicNavBar";
import PrivateNavBar from "../nav/privateNavBar";
import Loading from "../util/loading";
import { useSelector } from "react-redux";
import EpicProfile from "./component/epicProfile";
import Firebase from "../util/firebase";
import { Progress, message, Tooltip } from "antd";
import ProfileActivities from "./component/profileActivities";
import { debounce } from "lodash";
import { getLevel } from "../util/helper";
import { InfoCircleOutlined } from '@ant-design/icons';
import PointsModal from "./component/pointsModal";

const Profile = ({history, match}) => {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);
  const [epicProfile, setEpicProfile] = useState(null);
  const [showOption, setShowOption] = useState(1);
  const [userAddsOn, setUserAddsOn] = useState(null);
  const [followLoading, setFollowLoading] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  useEffect(() => {
    
    let listener = null;
    const fetchAddsOn = (addsOnId) => {
      listener = Firebase.firestore()
      .collection("userAddsOn")
      .doc(addsOnId)
      .onSnapshot((doc) => {
        const obj = {
          ...doc.data(),
        };
        setUserAddsOn(obj)
      });
    }
    const fetchBasicProfile = async ()=> {
      const id = match.params.id;
      const endpoint = API.FETCH_USER_PROFILE_BASIC;
      try { 
        const res = await axios.get(endpoint, {
          params:{
            userId:id
          }
        });
        if(res.status === 200){
          const data = res.data;
          setUserProfile(data);
          const {addsOnId} = data;
          if(addsOnId){
            fetchAddsOn(addsOnId);
          }
        }
      }catch(err){
        console.log(err);
      }
    }
    const fetchEpicProfile = async () => {
      const id = match.params.id;
      const endpoint = API.FETCH_USER_PROFILE_EPIC;
      try { 
        const res = await axios.get(endpoint, {
          params:{
            userId:id
          }
        });
        if(res.status === 200){
          const data = res.data;
          setEpicProfile(data);
        }
      }catch(err){
        console.log(err);
      }
    }
    const fetchProfile = async () => {
      try {
        await fetchBasicProfile();
        await fetchEpicProfile();
        setLoading(false);
      }catch(err){

      }
    }
    const resetStatus = () => {
      setLoading(true);
      setShowOption(1)
    }
    resetStatus()
    fetchProfile();

    return () => {
      if(listener){
        listener();
      }
    }
  }, [match]);


  const isProfileOwner = useMemo(() => {
    const id = match.params.id;
    if(loggedIn && id === userId){
      return true
    }else {
      return false;
    }
  },[match, userId, loggedIn]);

  const debounceFollower = debounce(() => {
    followPlayer();
  },500)

  const followPlayer = async () => {
    const id = match.params.id;
    if(userId && id ) {
      const endpoint = API.FOLLOW_USER;
      const requestBody = {
      followerId:userId,
      followId:id 
    }
    try {
      const res = await axios.post(endpoint, requestBody);
      if(res.status === 200){
        setFollowLoading(false);
        message.success("Successfully Follow the Player")
      }else {
        setFollowLoading(false);
        message.error("System Error, Try Again")
      }
    }catch(err){
      setFollowLoading(false);
      console.log(err);
      message.error("System Error, Try Again")
    }
    }
  }

  const debounceUnFollow =  debounce(() => {
    unfollowPlayer()
  },500)

  const unfollowPlayer = async () => {
    const id = match.params.id;
    if(userId && id ) {
      const endpoint = API.UNFOLLOW_USER;
      const requestBody = {
      followerId:userId,
      followId:id 
    }
    try {
      const res = await axios.post(endpoint, requestBody);
      if(res.status === 200){
        setFollowLoading(false);
        message.success("Successfully Unfollow the Player")
      }else {
        setFollowLoading(false);
        message.error("System Error, Try Again")
      }
    }catch(err){
      setFollowLoading(false);
      message.error("System Error, Try Again")
    }
    }
  }

  const followStatus = useMemo(() => {
    if(!loggedIn || isProfileOwner){
      return 1;
      // followed
    }else  if(userAddsOn && userId && userAddsOn.followers && userAddsOn.followers.includes(userId)){
      return 2;
      // unfollowed
    }else {
      return 3;
    }
  }, [isProfileOwner, userAddsOn, loggedIn, userId]);

  const activeTab = isProfileOwner ? "profile":"";

  const pointsData = useMemo(() => {
    if(userProfile && userProfile.points){
      const level = getLevel(userProfile.points);
      return {...level, points:userProfile.points};
    }else {
      return null;
    }
  }, [userProfile]);


  return <Container>
    {loggedIn ? <PrivateNavBar activeTab={activeTab} isDarkMode={true}/>:<PublicNavBar isDarkMode={true}/>}
    <Content>
      {
        (loading)? <Loading /> :<>
        <TopContainer>
            <TopItem style={{paddingLeft:0}}>
              <TopContent>
                {userProfile?.name?? "-"}
              </TopContent>
              <TopTitle>
                Epic Name:{userProfile?.epicName?? "-"}
              </TopTitle>
            </TopItem>
            {
              pointsData && <>
              <TopItem style={{border:'none'}}>
                <TopContent style={{color: "#F72375"}}>
                {pointsData.currentLevel}
              </TopContent>
              <TopTitle>
                Level
              </TopTitle>
              </TopItem>
              <PointsContainer>
                <PointsData>
                  <Points>
                    {pointsData.points} Points
                  </Points>
                  <Tooltip placement="top" title="Click to View Points Detail">
                  <InfoCircleOutlined style={{color:"#6A6A6A", cursor:'pointer'}} onClick={() => {
                    setInfoModalOpen(true);
                  }}/>
                </Tooltip>
                  
                </PointsData>
              <Progress
          percent={(pointsData.points/pointsData.nextLevelPoints) * 100}
          showInfo={false}
          strokeColor="#F72375"
          strokeWidth={3}
        />
         <PointsData>
                <Level>
                {pointsData.currentLevel}
                </Level>
                <Level>
                  {pointsData.nextLevel}
                </Level>
              </PointsData>
              </PointsContainer>
              </>
            }
            <TopItem>
              <TopContent>
                {userProfile?.questJoinedCount?? 0}
              </TopContent>
              <TopTitle>
               Quests Played
              </TopTitle>
            </TopItem>
            <TopItem>
              <TopContent>
                {userProfile?.coinsWonFromQuest?? 0}
              </TopContent>
              <TopTitle>
              Coins Won From Quests
              </TopTitle>
            </TopItem>
            <TopItem style={{border:'none'}}>
              <TopContent>
              {userProfile?.coinsWonFromKill?? 0}
              </TopContent>
              <TopTitle>
              Coins Won From Kills
              </TopTitle>
            </TopItem>

            {
              followStatus === 3 &&  <FollowingButton onClick={() => {
                if(!followLoading){
                  setFollowLoading(true);
                  debounceFollower()
                }
              }}>
              {followLoading ? 'Loading...':"Follow"}
          </FollowingButton>
          }
           {
              followStatus === 2 &&  <FollowingButton onClick={() => {
                if(!followLoading){
                  setFollowLoading(true);
                  debounceUnFollow()
                }
              }}>
              {loggedIn ? 'UnFollow': "Log In To Follow"}
          </FollowingButton>
          }
        </TopContainer>

        <Choices isDarkMode={true}>
          <Choice active={showOption === 1} isDarkMode={true} onClick={() => {
              setShowOption(1)
            }}>
               Stats
          </Choice>
          <Choice active={showOption === 2} isDarkMode={true} onClick={() => {
              setShowOption(2)
            }}>
               Activity
          </Choice>
          {
            isProfileOwner &&  <Choice active={showOption === 3} isDarkMode={true} onClick={() => {
              setShowOption(3)
            }}>
               Following
            </Choice>
          }
    </Choices>
    {
      showOption === 1 && <EpicProfile epicProfile={epicProfile} />
    }
    {
      showOption === 2 && <ProfileActivities activities={userAddsOn?.activities?? []} followed={followStatus === 2 || isProfileOwner} />
    }
    {
      showOption === 3 && <FollowingContainer>
          {
            (userAddsOn?.following?? []).map((item) => {
              return <FollowingBox>
                  <FollowName>
                    {item.name}
                  </FollowName>
                  <ViewButton onClick={() => {
                    history.push(`/profile/${item.userId}`)
                  }}>
                    View
                  </ViewButton>
              </FollowingBox>
            })
          }
      </FollowingContainer>
    }
        </>
}

    </Content>
    <PointsModal modalOpen={infoModalOpen} closeModal={() =>{
      setInfoModalOpen(false)
    }}/>
  </Container>
}

const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  background: #000000 0% 0% no-repeat padding-box;
  position: relative;
  min-height:calc(100vh);
`;

const Content = styled.div`
margin:128px auto 48px;
width:1120px;
`;

const TopContainer = styled.div`
display:flex;
margin-bottom:36px;
align-items:center;
`;

const PointsContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
width:189px;
border-right:1px solid #707070;
padding-right:36px;
height:63px;
`;

const PointsData = styled.div`
display:flex;
width:100%;
justify-content:space-between;
`;

const Points = styled.div`
text-align: left;
font: normal normal 600 12px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const Level = styled.div`
text-align: left;
font: normal normal 600 12px SF PRO, serif;
letter-spacing: 0px;
color: #6A6A6A;
`;

const TopItem = styled.div`
display:flex;
flex-direction:column;
border-right:1px solid #707070;
padding:0 36px;
`;

const TopContent = styled.div`
text-align: left;
font: normal normal bold 30px/43px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const TopTitle = styled.div`
text-align: left;
font: normal normal 600 12px/20px SF PRO, serif;
letter-spacing: 0px;
color: #6A6A6A;
`;

const FollowingButton = styled.div`
width: auto;
height: 34px;
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 7px;
margin-left:64px;
font: normal normal 600 15px/34px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
cursor:pointer;
padding:0 24px;
`;

const Choices = styled.div`
width:100%;
margin:36px auto 16px;
display:flex;
height:34px;
border-bottom:3px solid #FAFAFA;
`;

const Choice = styled.div`
width: auto;
padding:0 12px;
height: 34px;
/* UI Properties */
font: normal normal bold 20px/24px SF PRO, serif;
letter-spacing: 0px;
text-align:center;
margin-right:18px;
cursor:pointer;
color: #000000;
${(p) =>
  p.isDarkMode && !p.active 
    && `color: #BFBFBF;`}
${(p) =>
   p.active
        ? `color:#F72375; border-bottom:3px solid #F72375;`
        : ``}
`;

const FollowingContainer = styled.div`
display:grid;
grid-template-columns: repeat(4, 234px);
grid-row-gap:16px;
justify-content:space-between;
`;

const FollowingBox = styled.div`
width: 234px;
height: 56px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 11px;
display:flex;
justify-content:space-between;
align-items:center;
padding:0 16px;
`;

const FollowName = styled.div`
text-align: left;
font: normal normal bold 16px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const ViewButton = styled.div`
width: 59px;
height: 26px;
/* UI Properties */
background: #F42A76 0% 0% no-repeat padding-box;
border-radius: 7px;
font: normal normal bold 12px/26px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
text-align:center;
cursor:pointer;
`;


export default withRouter(Profile);
