import React, {  useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import * as API from "../../../util/api";
import axios from "axios";
import { LeftCircleOutlined  } from "@ant-design/icons";
import { message } from "antd";

const CheckInModal = ({modalOpen, closeModal, tournamentId}) => {
  
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);
  const tournamentCheckIn = async () => {
    try {
      const url = API.TOURNAMENT_CHECKIN;
      const requestBody = {
        userId,
        tournamentId
      }
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        closeModal();
        message.success("Checkin successfully, You will be assigned to quests")
      }else {
        closeModal();
        message.error("Failed to Checkin, please try again")
      }
    }catch(err){
      closeModal();
        message.error("Failed to Checkin, please try again")
    }
  }
  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: "613px",
      height:'auto',
      padding:'24px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "24px",
      zIndex: "10000",
    },
  };
  return <Modal
  isOpen={modalOpen}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
>
  <Title>
    <LeftCircleOutlined onClick={closeModal}/> Check In
  </Title>
  <Content> 
  Click the button below to check in for this tournament. 
  </Content>
  <ConfirmButton onClick={() => {
    tournamentCheckIn()
  }}>
    Confirm CheckIn
  </ConfirmButton>
</Modal>
}

const Title = styled.div`
color: #FFF;
font-family: SF PRO, serif;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom:24px;
`;

const Content = styled.div`
color: #FFF;
font-family: SF PRO, serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom:24px;
`;

const ConfirmButton = styled.div`
width: 158px;
height: 39px;
flex-shrink: 0;
border-radius: 10px;
background: #F72375;
color: #FFF;
font-family: SF PRO, serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 39px;
text-align:center;
cursor:pointer;
`;

export default CheckInModal
