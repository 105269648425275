import React, {useMemo, useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PrivateNavBar from "../nav/privateNavBar";
import PublicNavBar from "../nav/publicNavBar";
import axios from "axios";
import * as API from "../util/api";
import Loading from "../util/loading";
import { useSelector} from "react-redux";
import qs from 'query-string';
import { message } from "antd";
import Sidebar from "./sidebar";
import { ArrowLeftOutlined } from '@ant-design/icons';

const GetTicketSuccessCoins = ({location, history}) => {
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [ questId, setQuestId] = useState(null);

  useEffect(() => {
    const joinQuest = async (questId) => {
      try {
        const requestBody = {
          questId:questId,
          userId
        }
        const url = API.JOIN_CREATORQUEST_WITH_TICKETS;
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
         message.success("Successfully Join Quest With Ticket");
        }else {
          message.error("Failed to Join Quest, Please Join Quest After Redirect")
        }
      }catch(err){
        console.log(err, 'err')
        message.error("Failed to Join Quest, Please Join Quest After Redirect")
      }finally {
        setLoading(false);
        setTimeout(() => {
          history.push(`/creatorQuestCustomer/${questId}`)
        }, 3000)
      }
    }

    const checkQuest = async() => {
      try {
      const searchParams = qs.parse(location.search);
      const questId = searchParams.questId;
        if(questId){
          setQuestId(questId)
          joinQuest(questId);
        }else {
          setLoading(false);
          setTimeout(() => {
            history.push("/quest")
          }, 3000)
        }
      }catch(err){
        console.log(err)
      }finally {
        setLoading(false);
      }
    }
    
    checkQuest();

  }, [location, userId, history]);


  return <Container>
     {loggedIn ? <PrivateNavBar isDarkMode={true}/> : <PublicNavBar isDarkMode={true}/>}
     <Content>
            <Left>
              <Sidebar option="tickets" />
              </Left>
              <Right>
              <Top>
      <ArrowLeftOutlined style={{cursor:'pointer', marginRight:8}} />Get Your Tickets
    </Top>
    <Title>
    THANK YOU! YOUR PURCHASE HAS BEEN PROCESSED
    </Title>
    <SubTitle>
      {
        questId ? `You’re being added to the quest and redirected automatically` : `You’re being redirected to the quests page`
      }
    </SubTitle>
    <SubTitle style={{color:"#484848"}}>
    Have Fun!
    </SubTitle>
    <LoadingContainer>
          <Loading />
          </LoadingContainer>
              </Right>
              </Content>
              </Container>

}

const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  background: #000000 0% 0% no-repeat padding-box;
  position: absolute;
  min-height: calc(100vh);
  .ant-divider {
   border-bottom:2px solid #707070;
  }
`;

const Content = styled.div`
  width:100%;
  display:flex;
`;

const Left = styled.div`
width:190px;
`;

const Right = styled.div`
width:780px;
padding-left:64px;
padding-top:108px;
margin-bottom:300px;
@media (min-width: 1480px) {
  padding-left:180px;
}
`;

const Top = styled.div`
display:flex;
align-items:center;
font: normal normal bold 24px/20px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const Title = styled.div`
text-align: left;
font: normal normal bold 18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
text-transform: uppercase;
margin-bottom:16px;
`;

const SubTitle = styled.div`
text-align: left;
font: normal normal 600 16px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const LoadingContainer = styled.div`
width: 577px;
height: 262px;
`;

export default withRouter(GetTicketSuccessCoins) 