import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Firebase from "../../util/firebase";
import moment from 'moment';

import * as API from "../../util/api";
import axios from "axios";
import Loading from "../../util/loading";


const SpouseEvent = ({history}) => {
  const [session, setSession] = useState([]);
  const [influencer, setInfluencer] = useState(null);
  const [loading, setLoading] = useState(true)

  const { items: partners} = useSelector((state) => state.partners);
  const { items: products} =  useSelector((state) => state.products);

   useEffect(() => {
    let listener = null;

    const fetchSessionId = async () => {
      try {
        const url = API.GET_PRIMARY_SESSION;
        const res = await axios.get(url);
        if(res.status === 200){
          const {sessionId} = res.data;
          listener = Firebase.firestore().collection("brand_session").doc(sessionId).onSnapshot((doc) => {
            console.log(doc.data(), doc.exists)
            const obj = {...doc.data(), sessionId};
            console.log(obj)
            setSession(obj);
         })
         setLoading(false);
        }
      }catch(err){
        console.log(err);
      }
    }

    fetchSessionId()

    return () => {
      if(listener){
        listener();
      }
    }
   }, [])

   useEffect(() => {
    const fetchInfluencer = async (influencerId) => {
      const ref = Firebase.firestore()
      .collection("influencer")
      .doc(influencerId);
      const doc = await ref.get();
      const data = doc.data();
      const result = {
        ...data,
        influencerId
      }
      setInfluencer(result) 
    }

    if(session?.influencerId){
      fetchInfluencer(session.influencerId);
    }
  }, [session])

  const partnerInfo = useMemo(() => {
    if(session?.partnerId){
      const company = partners.find(item => item.partnerId === session.partnerId);
      if(company) return company;
    }
    return null;
  },[partners, session]);

  const productInfo = useMemo(() => {
    if(session?.productId){
      const product = products.find(item => item.productId === session.productId);
      if(product) return product;
    }
    return null;
  }, [session, products]);

   return <Container>
    <>
    {
      loading ? <Loading /> : <>
      <Title>
    Sponsored Events
    </Title>
    <SessionBox>
      <Banner src={productInfo?.lowerBannerUrl} onClick={() => {
            /* mixPaneltrack() */
            window.open(productInfo?.link)
          }} />
        <SessionTitle>
        {influencer?.name} X {partnerInfo?.name} 
        </SessionTitle>
        <MatchDetail>
                <DetailContent style={{borderRight:"1px solid #F0F0F0"}}>
                  <DetailText>
                    {session?.totalJoin??0}
                  </DetailText>
                  <DetailIntro>
                    <ProfileIcon src="../assets/profile_style_white.svg" alt="icon"/>Players
                  </DetailIntro>
                </DetailContent>
                <DetailContent style={{borderRight:"1px solid #F0F0F0"}}>
                  <DetailText>
                  Ongoing
                  </DetailText>
                  <DetailIntro>
                  <ProfileIcon src="../assets/profile_time_white.svg" alt="icon"/>Status
                  </DetailIntro>
                </DetailContent>
                <DetailContent>
                  <DetailText style={{color:"#F72375"}}>
                  {session?.questsList?.length??0}
                  </DetailText>
                  <DetailIntro>
                  Quests
                  </DetailIntro>
                </DetailContent>
                </MatchDetail>
                <MatchBottom>
                <div style={{font:"normal normal medium 14px SF PRO, serif", color:"#BFBFBF", marginRight:8}}>
                  Prize
                </div>
                <img
              src="/assets/Coin.svg"
              alt=""
              style={{
                height: "14px",
                marginRight:8
              }}

            />
            <div style={{font:"normal normal bold 18px/25px SF PRO, serif", color:"#FAFAFA", marginRight:8}}>{session?.totalPrize??0}</div>
            <ViewButton onClick={() => {
              history.push(`/brandSession/${session.sessionId}`)
            }}>
              View
            </ViewButton>
            </MatchBottom>
      </SessionBox>
      </>
    }
    </>
   </Container>

}

const Container = styled.div`
width:570px;
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 32px SF PRO, serif;
letter-spacing: 0px;
color: #D6D6D6;
margin-bottom:16px;
`;

const SessionBox = styled.div`
width: 570px;
height: 318px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 24px;
padding:12px;
`;


const Banner = styled.img`
width:100%;
height:112px;
margin-bottom:12px;
`;

const SessionTitle = styled.div`
text-align: left;
font: normal normal bold 24px/25px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
text-transform: uppercase;
`;

const MatchDetail = styled.div`
display:flex;
justify-content:space-between;
width:100%;
margin-top:24px;
width:280px;
`;

const DetailContent = styled.div`
display:flex;
flex-direction:column;
width:30%;
`;

const DetailText = styled.div`
text-align: left;
font: normal normal bold 18px/25px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
margin-bottom:8px;
`;

const DetailIntro = styled.div`
text-align: left;
font: normal normal medium 14px/25px SF PRO, serif;
letter-spacing: 0px;
color: #898A8A;
`;

const ProfileIcon = styled.img`
height:14px;
width:14px;
margin-right:6px;
`;

const MatchBottom = styled.div`
display:flex;
width:100%;
align-items:center;
margin-top:12px;
`;

const ViewButton = styled.div`
width: 182px;
height: 48px;
/* UI Properties */
background: #F42A76 0% 0% no-repeat padding-box;
border-radius: 12px;
font: normal normal 600 16px/48px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
text-align:center;
cursor:pointer;
margin-left:auto;
${p => p.ended && `background: #6A6A6A 0% 0% no-repeat padding-box;`}
`;

export default withRouter(SpouseEvent)