import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
import styled from 'styled-components';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from "react-redux";


const TournamentCard = ({tournament, history}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const backgroundImage = tournament.image?? "https://firebasestorage.googleapis.com/v0/b/arcadequest-dev.appspot.com/o/productBanner%2Ffortnite_banenr.jpeg?alt=media&token=46851bf2-61d7-4ffa-8445-5722b99b50c2";

  const status = useMemo(() => {
    if(tournament.status === 0){
      return 'OPEN'
    }else if(tournament.status === 1){
      return 'CLOSED'
    }else {
      return 'ENDED'
    }
  }, [tournament.status]);

  const joined = useMemo(() => {
    const joinedTournaments = profile.joinedTournaments?? [];
    if(joinedTournaments.includes(tournament.tournamentId)){
      return true;
    }
    return false;
  }, [profile, tournament.tournamentId])

  return <Container onClick={() => {
    history.push(`/tournament/${tournament.tournamentId}`)
  }}>
    <TournamentImage background={backgroundImage}>
        {
          joined && <JoinMark>
            Joined
          </JoinMark>
        }
        {
          !joined && tournament.tournament_type === 1 && <Mark type={1}>
            Invite Only
          </Mark>
        }
        {
          !joined && tournament.tournament_type === 2 && <Mark type={2}>
            multiplier only
          </Mark>
        }
        {
          !joined && tournament.tournament_type === 3 && <Mark type={3}>
            all access
          </Mark>
        }
    </TournamentImage>
    <Time>
      starts: {moment(tournament.startTime).format("LLL")}
    </Time>
    <Title>
      {tournament.title}
    </Title>
    <Tip>
    Kill Race • Players with the most kills win 
    </Tip>
    <Status>
      STATUS : {status}
    </Status>
  </Container>
}

const Container = styled.div`
width:348px;
display:flex;
flex-direction:column;
font-family: SF PRO, serif;
cursor:pointer;
`;

const TournamentImage = styled.div`
background-image: url(${(p) => p.background});
background-position: center;
background-size: cover;
position:relative;
width:100%;
height:200px;
`;

const Time = styled.div`
width:100%;
background: #F72375;
height:28px;
color: #FFF;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 28px; /* 18px */
letter-spacing: 2.58px;
text-transform: uppercase;
text-align:center;
margin:12px 0px;
`;

const Title = styled.div`
color: #FFF;
font-family: SF PRO, serif;
font-size: 24px;
font-style: normal;
font-weight: 700;
`;

const Tip = styled.div`
color: #FFF;
font-family: SF PRO, serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
opacity:0.6;
`;

const Status = styled.div`
color: #FFF;
font-size: 16px;
font-style: normal;
font-weight: 700;
`;

const JoinMark = styled.div`
position:absolute;
top:0px;
right:0px;
width:107px;
height:20px;
border: 1px solid #F72375;
background: #000;
color: #F72375;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px;
text-align:center;
text-transform: uppercase;
`;

const Mark = styled.div`
position:absolute;
top:0px;
right:0px;
width:107px;
height:20px;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px;
text-align:center;
text-transform: uppercase;
color: #FFF;
${p => p.type === 1 && `background: #F72375;`};
${p => p.type === 2 && `background: #CF0FFF;`};
${p => p.type === 3 && `background: #16B9FF;`};
`;

export default withRouter(TournamentCard)