import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Firebase from "../../util/firebase";
import moment from 'moment';
import ProLeagueCard from "./proLeagueCard";

const CustomerEntry = () => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const [quests, setQuests] = useState([]);
  useEffect(() => {
    let listener = null;
    const fetchQuests = async () => {
        listener = Firebase.firestore()
          .collection("pro_league")
          .onSnapshot(querySnapshot => {
            let quests = [];
            querySnapshot.forEach(function (doc) {
              const obj = {
                ...doc.data(),
               proLeagueId: doc.id,
              };
              quests.push(obj);
            });
            setQuests(quests);
          },
          error => {
            console.log(error);
          }
          )
    }
    fetchQuests();
    return () => {
      if(listener){
        listener();
      }
    }
  },[] );

  const upcomings = useMemo(() => {
    return quests.filter(item => item.status === 1);
  }, [quests]);

  const ongoings = useMemo(() => {
    return quests.filter(item => item.status === 2);
  }, [quests]);

  const pasts = useMemo(() => {
    return quests.filter(item => item.status === 3);
  }, [quests]);

  const timeSort = (a, b) => {
    const timeA = moment(a[0]);
    const timeB = moment(b[0]);
    if(timeA.isBefore(timeB)){
      return -1;
    }else {
      return 1;
    }
  }

  const upcomingQuestsWithDate = useMemo(() => {
    const result = new Map();
    upcomings.forEach(item => {
     const startDate = moment(item.startTime).format("LL");
     if(result.has(startDate)){
       const current = result.get(startDate);
       current.push(item);
     }else {
       const newArr = [item];
       result.set(startDate, newArr);
     }
    })
   const sortMap = new Map([...result.entries()].sort(timeSort));
   const sortResult = [...sortMap].map(([date, quests]) => ({
     date,
     quests
   }));
   return sortResult;
 }, [upcomings]);

 const joinedProleague = profile?.joinedProLeagues?? [];

  
  return <Content>
    <Title>
    <span style={{color:"#F72375"}}>Pro</span> League
    </Title>
    <Subtitle>
    Bigger stakes, bigger prizes, top 3 players win. Welcome to the pros.
    </Subtitle>
    <QuestDivider />
    {
      ongoings.length > 0 && <>
       <Cate>
      Ongoing Pro League
      </Cate>
      <QuestContainer>
            {ongoings.map(item => {
               return <ProLeagueCard quest={item} joined={joinedProleague.includes(item.proLeagueId)} />
            })}
            </QuestContainer>
      <QuestDivider />
      </>
    }
    <Cate>
      Upcoming Pro League
      </Cate>
    {
      upcomingQuestsWithDate.length > 0 ? <QuestWithDate> 
      {
        upcomingQuestsWithDate.map(item => {
          return <div style={{marginBottom:24}} key={item.proLeagueId}>
            <Date>
              {moment(item.date).format("MMMM D")}
            </Date>
            <QuestDivider />
            <QuestContainer>
            {item.quests.map(item => {
               return <ProLeagueCard quest={item} joined={joinedProleague.includes(item.proLeagueId)} />
            })}
            </QuestContainer>
          </div>
        } )
      }
     </QuestWithDate>: <>
      <Subtitle style={{width:1120,
margin:"0 auto"}}>
  No Upcoming Quests Yet!
  </Subtitle>
      </>
    }
    <QuestDivider />
    <Cate>
      Past Pro League
      </Cate>
      {
        pasts.length > 0 ? <>
         <>
         <QuestContainer>
            {pasts.map(item => {
               return <ProLeagueCard quest={item} joined={joinedProleague.includes(item.proLeagueId)} />
            })}
            </QuestContainer>
      </>
        </> :  <>
      <Subtitle style={{width:1120,
margin:"0 auto"}}>
  No Past Pro League Yet!
  </Subtitle>
      </>
      }
    </Content>
}


const Content = styled.div`
width: 1180px;
margin:0px auto 48px;
@media (min-width: 1480px) {
  width: 1280px;
}
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 60px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const Subtitle = styled.div`
text-align: left;
font: normal normal 600 32px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const QuestDivider = styled.div`
height:0px;
width:100%;
border-bottom:2px solid #707070;
margin:18px 0px;
`;

const Cate = styled.div`
text-align: left;
font: normal normal 600 32px SF PRO, serif;
letter-spacing: 0px;
color: #D6D6D6;
width:1120px;
margin:0px auto 36px;
`;

const QuestContainer = styled.div`
display:grid;
grid-template-columns: repeat(3, 343px);
justify-content:space-between;
grid-row-gap:24px;
width:1120px;
margin:0 auto;
`;

const QuestWithDate = styled.div`
width:1120px;
margin:0px auto 36px;
`;

const Date = styled.div`
text-align: left;
font: normal normal bold 24px/29px SF PRO, serif;
letter-spacing: 0px;
color: #BFC6CD;
text-transform: uppercase;
`;

export default withRouter(CustomerEntry);

