import Modal from "react-modal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import * as API from "../../util/api";
import axios from "axios";
import styled from "styled-components";
import { Input, Button, Skeleton } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { debounce } from "lodash";

const ExchangeModal = ({modalOpen, closeModal, current}) => {
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);

  const [step, setStep] = useState(0);

  const [loading, setLoading]= useState(false);

  const [inputValue, setInputValue] = useState("");

  const [twitter, setTwitter] =useState("");

  const defaultEmail = useMemo(() => {
    if(profile && profile.email){
      return profile.email
    }else {
      return null
    }
 },[profile])

 useEffect(() => {
   if(defaultEmail){
     setInputValue(defaultEmail)
   }
 },[defaultEmail]);


 const debounceConfirmOrder = debounce(() => {
  confirmOrder()
}, 500);


const confirmOrder = async () => {
   const {transId} = profile;
   const {option, coins, value} = current;
   if(inputValue && inputValue !== ""){
    const requestBody = {
      userId,
      transId,
      option,
      coins,
      value,
      email:inputValue,
      twitter
    }
    const endpoint = API.CREATE_EXCHANGE_ORDER;
    try {
     const res = await axios.post(endpoint, requestBody);
     if(res.status === 200){
       setStep(1);
     }
    }catch(err){
       console.log(err);
       setLoading(false)
    }
   }
}

  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: "613px",
      height:'auto',
      padding:'24px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "24px",
      zIndex: "10000",
    },
  };
  
  return <Modal
  isOpen={modalOpen}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
>
{
  step === 0 && <>
   <Top>
      <ArrowLeftOutlined style={{cursor:'pointer', marginRight:8}} onClick={closeModal}/><span>Exchange Coins</span> 
    </Top>
    <Text>
    Enter your PayPal email to receive the funds
    </Text>
    <Box>
      <BoxTitle>
      PayPal Email
      </BoxTitle>
      <StyledInput value={inputValue}  onChange={(e) => {
          setInputValue(e.target.value)
        }}/>
    </Box>
    <Box>
      <BoxTitle>
      Twitter Name
      </BoxTitle>
      <StyledInput value={twitter}  onChange={(e) => {
          setTwitter(e.target.value)
        }}/>
    </Box>
    <ConfirmButton 
    disabled={loading}
    onClick={() => {
      if(!loading){
        setLoading(true);
        debounceConfirmOrder()
      }
    }}>
      {
        loading ? "Loading...":"Confirm Email"
      }
    </ConfirmButton>
  </>
}
  {
    step === 1 && <>
      <Top>
      <ArrowLeftOutlined style={{cursor:'pointer', marginRight:8}} /><span>Exchange Coins</span> 
      </Top>
      <Text>
      Your exchange has been processed! You’ll receive a PayPal transfer within 48 hrs.
    </Text>
    <Skeleton active={true} />
    <ConfirmButton 
    onClick={closeModal}
    >
      Done
    </ConfirmButton>
    
    </>
  }
</Modal>
}

const Top = styled.div`
display:flex;
align-items:center;
font: normal normal bold 24px/20px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const Text = styled.div`
text-align: left;
font: normal normal normal 16px/24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const Box = styled.div`
border-bottom: 3px solid #979797;
`;

const BoxTitle = styled.div`
text-align: left;
font: normal normal 600 20px/36px SF PRO, serif;
letter-spacing: 0px;
color: #979797;
`;

const StyledInput = styled(Input)`
margin-top:8px;
height: 37px;
background: #40444B 0% 0% no-repeat padding-box;
border: none;
border-radius: 5px;
color:#FFFFFF;
margin-bottom:8px;
`;

const ConfirmButton = styled(Button)`
height: 49px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 12px;
width:565px;
font: normal normal 600 16px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-top:24px;
border:none;
&:hover {
  background: #F72375 0% 0% no-repeat padding-box;
  color: #FFFFFF;
}
`;

export default ExchangeModal;
