import React, { useMemo, useEffect, useState }from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Mixpanel } from '../../util/mixpanel';
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import * as API from "../../util/api";
import axios from "axios";
import { message } from "antd";

const AdVideo = ({isPromoed, productInfo, companyInfo, quest, rediectFromAd}) => {
  const { id: userId } = useSelector((state) => state.user);

  const joinWithAd = async () => {
    const requestBody = {
      questId:quest.questId,
      userId
    }
    try {
      const url = API.JOIN_CREATORQUEST_WITH_AD;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        Mixpanel.track("Join Creator Quest");
        message.success("You have successfully join the quest");
        rediectFromAd()
        }else {
          message.error("Failed to join quest, please try again");
          rediectFromAd()
      }
    }catch(err){
      message.error("Failed to join quest, please try again");
      rediectFromAd()
    }

  }

  return <Container>
    <Title>
    Finish Watching This Ad to Join For Free. You’ll be redirected automatically.
    </Title>
    <ReactPlayer
      url={productInfo?.video??""}
      playing={true}
      width="100%"
      height="auto"
      controls
      style={{marginBottom:24}}
      onEnded={() => {
        joinWithAd()
      }}
      onPause={() => {
        message.error("Please finish watching ad to get free access to the quest")
      }}
    />
  </Container>
}

const Container = styled.div`
width:100%;
margin-top:24px;
`;

const Title = styled.div`
width:100%;
text-align:center;
font: italic normal bold 16px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
margin-bottom:24px;
`;

export default AdVideo;