import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
import styled from 'styled-components';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Firebase from "../../util/firebase";
import PrivateNavBar from "../../nav/privateNavBar";
import PublicNavBar from "../../nav/publicNavBar";
import { useSelector } from "react-redux";
import Loading from "../../util/loading";
import {  Col, Row, Timeline, List} from "antd";
import CheckInModal from './component/checkInModal';
import JoinModal from "./component/joinModal";
import { LeftCircleOutlined  } from "@ant-design/icons";

const TournamentCustomer = ({match, history}) => {
  const [tournament, setTournament] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [joinModalOpen, setJoinModalOpen] = useState(false);

  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  const joined = useMemo(() => {
    const joinedTournaments = profile.joinedTournaments??[];
    const tournamentId = match.params.id;
    if(joinedTournaments.includes(tournamentId)){
      return true;
    }else {
      return false
    }
  }, [profile, match])

  const isActiveSub = useMemo(() => {
    if(loggedIn && profile && profile.multiplier){
      const multiplier = profile.multiplier;
      if(multiplier.renewAt && moment(multiplier.renewAt).isAfter(moment()) && !multiplier.requestCancel){
        return true
      }
    }
    return false;
  },[profile, loggedIn]);

  useEffect(() => {
    let listener = null;
    const fetchTournament = async (tournamentId) => {
      listener = Firebase.firestore().collection("tournament").doc(tournamentId).onSnapshot((doc) => {
        const obj = {...doc.data(), tournamentId};
        setTournament(obj);
     })
    }
    const fetchRounds = async (tournamentId) => {
      Firebase.firestore().collection('tournament_round').where("tournamentId", "==", tournamentId).onSnapshot(querySnapshot => {
        let result = []
          querySnapshot.docs.forEach(doc => {
          const obj = {
            ...doc.data(),
            roundId: doc.id
          }
          result.push(obj);
        });
       setRounds(result)
      });
    }
    const tournamentId = match.params.id;
    fetchTournament(tournamentId);
    fetchRounds(tournamentId);
    return () => {
      if(listener){
        listener();
      }
    }
  }, [match]);

  const checkInAvailable = useMemo(() => {
    if(userId && profile && tournament){
      if(tournament.status === 0 && tournament.tournament_type === 1){
        const {verifiedPlayers, invitedList} = tournament;
        if(invitedList.includes(profile.email) && !verifiedPlayers.has(item => item.userId === userId)) {
          return true;
        }
      }
    }
    return false;
  }, [userId, profile, tournament]);

  

  const roundsWithIndex = useMemo(() => {
    const map = new Map();
    rounds.forEach((item) => {
      const index = item.roundIndex;
      if(map.has(index)){
          const curr = map.get(index);
          curr.push(item);
      }else {
        const newArr = [item];
        map.set(index, newArr)
      }
    });

    const sortMap = new Map([...map.entries()].sort());
    let sortResult = [...sortMap].map(([index, rounds]) => {
      const round = rounds[0];
      const startDate = moment(round.startDate).format("LL");
      const endDate = moment(round.endDate).format("LL")
      return {
          roundIndex:index,
          startDate,
          endDate,
          rounds
        }
    });
    return sortResult;
  }, [rounds]);


  const renderItem = (item, index) => {
    const {startDate, endDate, rounds, roundIndex} = item;
    return <RoundContainer>
        <RoundItem>
          {roundIndex === 1 && 'ONE'}
          {roundIndex === 2 && 'TWO'}
          {
            roundIndex === 3 && 'THREE'
          }
          {
            roundIndex === 4 && 'FOUR'
          }
          {
            roundIndex === 5 && 'FIVE'
          }
        </RoundItem>
        <RoundItem>
          {startDate}
        </RoundItem>
        <RoundItem>
          {endDate}
        </RoundItem>
        <RoundItem>
          <ViewDetail onClick={() => {
            console.log('click')
            console.log(rounds, rounds.length, rounds[0].roundId)
            if(rounds.length === 1){
              const roundId = rounds[0].roundId;
              history.push(`/tournamentRound/${roundId}`)
            }else {

            }
          }}>
              View Detail
          </ViewDetail>
        </RoundItem>
    </RoundContainer>
  }

  const renderPlayersItem = (item, index) => {
    const {userName, checkedInAt} = item;
    return <RoundContainer style={{padding:'12px 0px'}}>
      <RoundItem style={{width:'30%'}}>
        {index + 1}
      </RoundItem>
      <RoundItem style={{width:'40%'}}>
        {userName}
      </RoundItem>
      <RoundItem style={{width:'30%'}}>
        {moment(checkedInAt).format("LL")}
      </RoundItem>
    </RoundContainer>
  }

  return <Container>
      {
      loggedIn ? <PrivateNavBar activeTab="tournament" isDarkMode={true}/>:<PublicNavBar activeTab="tournament" isDarkMode={true}/>
    }
    <Content>
      {tournament ? <>
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:32}}>
          <Title>
          <LeftCircleOutlined onClick={() => {
        history.push(`/tournaments`)
       }}/> {tournament.title}
          </Title>
          {
            (!joined && loggedIn) && <>
               {
            checkInAvailable && <CheckInButton onClick={() => {
              setModalOpen(true)
            }}>
              Check In
            </CheckInButton>
          }
          {
            tournament.tournament_type === 2 && isActiveSub && <CheckInButton onClick={() => {
              setJoinModalOpen(true)
            }}>
              Join Tournament
            </CheckInButton>
          }
           {
            tournament.tournament_type === 2 && !isActiveSub && <CheckInButton>
              Multiplier Only
            </CheckInButton>
          }
          {
            tournament.tournament_type === 3 && <CheckInButton onClick={() => {
              setJoinModalOpen(true)
            }}>
              Join Tournament
            </CheckInButton>
          }
            </>
          }
         
        </div>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div style={{width:'70%'}}>
            <SubTitle>
              Rules
            </SubTitle>
            <Rules>
              {tournament.description}
            </Rules>
            <SubTitle>
              TimeLine
            </SubTitle>
            <div style={{display:'flex', alignItems:'center'}}>
            <Timeline>
              <Timeline.Item color="red">Check In</Timeline.Item>
              <Timeline.Item color="gray">Tournament Live</Timeline.Item>
              <Timeline.Item color="gray">
                  Processing
              </Timeline.Item>
              <Timeline.Item color="gray">
                Result Finalized
              </Timeline.Item>
              </Timeline>
              <div style={{display:'flex', flexDirection:'column', marginLeft:72}}>
                <TimeContent>
                  {moment(tournament.startDate).format("LL")}
                </TimeContent>
                <TimeTitle>
                  Start Date
                </TimeTitle>
                <TimeContent>
                  {moment(tournament.endDate).format("LL")}
                </TimeContent>
                <TimeTitle>
                  End Date
                </TimeTitle>
              </div>
            </div>
            <SubTitle>
              Rounds
            </SubTitle>
            <div style={{width:720}}>
            <Header>
            <HeaderItem style={{ width: "25%" }}></HeaderItem>
            <HeaderItem style={{ width: "25%" }}>Start Date</HeaderItem>
            <HeaderItem style={{ width: "25%" }}>
              End Date
            </HeaderItem>
            <HeaderItem style={{ width: "25%" }}>
             
            </HeaderItem>
          </Header>
          <List
                dataSource={roundsWithIndex}
                renderItem={(item, index) => (
                  <List.Item key={index} style={{ padding: 0 }}>
                    {renderItem(item, index)}
                  </List.Item>
                )}
              ></List>
            </div>
            
          </div>
          <div style={{width:'30%'}}>
            <SubTitle>
              Players
            </SubTitle>
            <Header>
            <HeaderItem style={{ width: "30%" }}></HeaderItem>
            <HeaderItem style={{ width: "40%" }}>User Name</HeaderItem>
            <HeaderItem style={{ width: "30%" }}>Check In</HeaderItem>
            
            </Header>
            <List
                dataSource={tournament.verifiedPlayers}
                renderItem={(item, index) => (
                  <List.Item key={index} style={{ padding: 0 }}>
                    {renderPlayersItem(item, index)}
                  </List.Item>
                )}
              ></List>
          </div>
        </div>
      </> : <Loading />}
    </Content>

    <CheckInModal modalOpen={modalOpen} closeModal={() => {
      setModalOpen(false)
    }} tournamentId={tournament?.tournamentId??""} />

    {
      tournament &&  <JoinModal modalOpen={joinModalOpen} closeModal={() => {
        setJoinModalOpen(false)
      }} tournament={tournament} />
    }
  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#000000 0% 0% no-repeat padding-box;
  position: relative;
  min-height:calc(100vh);
  padding-top:108px;
  font-family:SF PRO, serif;
  .ant-timeline-item-content {
    font-family: SF PRO, serif;
    font-size: 16px;
    color: #FFF;
  }
  .ant-timeline-item-last {
    padding-bottom:0px !important;
  }
  .ant-list-item {
    border-bottom:none;
  }
`;

const Content = styled.div`
width: 1180px;
margin:0px auto 48px;
@media (min-width: 1480px) {
  width: 1280px;
}
`;

const Title = styled.div`
 font-size:32px;
 color:#FFFFFF;
`;

const CheckInButton = styled.div`
border-radius: 10px;
background: #F72375;
width: 138px;
height: 39px;
flex-shrink: 0;
color: #FFF;
font-size: 16px;
font-style: normal;
line-height: 39px;
text-align:center;
cursor:pointer;
`;

const SubTitle = styled.div`
color: #FFF;
font-size: 24px;
font-style: normal;
font-weight: 700;
margin:16px 0px;
`;

const Rules = styled.div`
color: #FFF;
font-size: 16px;
font-style: normal;
font-weight: 400;
`;

const Header = styled.div`
  display: flex;
  justify-content:start;
  margin-bottom:12px;
`;

const HeaderItem = styled.div`
text-align: left;
color: #A5A5A5;
font-size: 16px;
font-style: normal;
font-weight: 510;
line-height: normal;
`;

const RoundContainer = styled.div`
border-bottom:1px solid #BFBFBF;
display:flex;
alignItems:center;
padding:16px 0px;
width:100%;
`;

const RoundItem = styled.div`
font-size: 16px;
font-style: normal;
font-weight: 510;
line-height: normal;
width:25%;
color:#FAFAFA;

`;

const ViewDetail = styled.div`
color: #F72375;
font-size: 16px;
font-style: normal;
font-weight: 510;
line-height: normal;
text-decoration-line: underline;
cursor:pointer;
`;

const TimeContent = styled.div`
font-size: 16px;
font-style: normal;
font-weight: 510;
line-height: normal;
text-transform: uppercase;
color:#FAFAFA;
margin-bottom:24px;
`;

const TimeTitle = styled.div`
font-size: 16px;
font-style: normal;
font-weight: 510;
line-height: normal;
text-transform: capitalize;
color:#A5A5A5;
margin-bottom:24px;
`;

export default withRouter(TournamentCustomer)