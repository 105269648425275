import React, { useMemo }from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import { Mixpanel } from '../../util/mixpanel';
import ReactPlayer from "react-player";

const UserInfoBeforeStart = ({quest, history, isPromoed, productInfo,companyInfo}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const isActiveSub = useMemo(() => {
    if(loggedIn && profile && profile.multiplier){
      const multiplier = profile.multiplier;
      if(multiplier.renewAt && moment(multiplier.renewAt).isAfter(moment()) && !multiplier.requestCancel){
        return true
      }
    }
    return false;
  },[profile, loggedIn]);



  const modeOptions = [
    {
      label:"Build Solos",
      value:"defaultsolo"
    },
    {
      label:"No Build Solos",
      value:"nobuildbr_solo"
    },
    {
      label:"Arena Solos",
      value:"showdownalt_solo"
    },
    {
      label:"Build Duos",
      value:"defaultduo"
    },
    {
      label:"No Build Duos",
      value:"nobuildbr_duo"
    },
    {
      label:"Arena Duos",
      value:"showdownalt_duos"
    },
    {
      label:"Build Trios",
      value:"trios"
    },
    {
      label:"No Build Trios",
      value:"nobuildbr_trio"
    },
    {
      label:"Arena Trios",
      value:"showdownalt_trios"
    },
    {
      label:"Build Squads",
      value:"defaultsquad"
    },
    {
      label:"No Build Squads",
      value:"nobuildbr_squad"
    },
    {
      label:"Arena Squads",
      value:"showdownalt_squads"
    },
  ]

  const allLabels = modeOptions.map((item) => item.label);

  const generateLabels = (modes) => {
    const result = modes.reduce((acc, curr) => {
      const target = modeOptions.find((item) => item.value === curr);
      if(target){
        acc.push(target.label)
      }
      return acc;
    }, [])
    return result;
  }

  const acceptModes = (quest.gameModeSelected  && quest.gameModes )? generateLabels(quest.gameModes) : allLabels;

  const mixPanelTrackVideoPlay = () => {
    Mixpanel.track("Quest Promo Product Video Plays", {
      productName:productInfo.name,
      productId:productInfo.productId,
      partnerName:companyInfo.name,
      partnerId:companyInfo.partnerId,
    })
  }

  return <Container>
    {
      isPromoed &&  <ReactPlayer
      url={productInfo?.video??""}
      playing={false}
      width="100%"
      height="auto"
      controls
      style={{marginBottom:24}}
      onStart={mixPanelTrackVideoPlay}
    />
    }
          <TopBox>
            {
              loggedIn ? <>
                <Name>{profile?.first_name?? "-"}</Name>
                <UserInfo>
                  <div>
                  {
                    isActiveSub ? "Multipler Subscriber":"Free User"
                  }
                  </div>
                  {
                    !isActiveSub && <Upgrade onClick={() => {
                      Mixpanel.track("Redirect to multiplier from creator quest page")
                      history.push("/multiplier")
                    }}>
                        Upgrade to Get One Coin for Every Two Kills
                    </Upgrade>
                  }
                </UserInfo>
              </>:<>
              <Name>-</Name>
              </>
            }
          </TopBox>
          <BoxsContainer>
            <DataBox>
              <DataEntry>
                -
              </DataEntry>
              <DataTitle>
                Placement
              </DataTitle>
            </DataBox>
            <DataBox>
              <DataEntry>
                -
              </DataEntry>
              <DataTitle>
                Kills
              </DataTitle>
            </DataBox>
            <DataBox>
            <DataEntry>
                -
              </DataEntry>
              <DataTitle>
                Your Tier
              </DataTitle>
            </DataBox>
          </BoxsContainer>
          {
            !isPromoed && <>
             <EmptyPlaceHolder>
          <img src="../assets/sword.png" style={{
        width:35,
        height:35,
        display:'block',
        marginBottom:12
      }}
      alt="sword"
      />
      This Quest hasn’t started yet!
          </EmptyPlaceHolder>
         <ModeBox>
            <Modes>
              {
                acceptModes.map((item) => {
                  return <ModeText>{item}</ModeText>
                })
              }
            </Modes>
            <ModeSub>
            Accepted Game Modes
            </ModeSub>
         </ModeBox>
         <IntroBox>
          <IntroText>
          When you Epic Games account with your ArcadeQuest account we can track your kills. Kill data updates 5 to 10 minutes after the Fortnite match ends. End your last match ends 5 mins before the end of the Quest.
          </IntroText>
          <ModeSub>
          How Kills Are Tracked
            </ModeSub>
         </IntroBox>
            </>
          }
         
  </Container>
}

const Container = styled.div`
width:440px;
display:flex;
flex-direction:column;
`;

const TopBox = styled.div`
width: 100%px;
height: 92px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
padding:16px;
margin-bottom:16px;
display:flex;
flex-direction:column;
`;

const BoxsContainer = styled.div`
width:100%;
display:flex;
`;

const DataBox = styled.div`
width: 102px;
height: 67px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
margin-right:16px;
padding:6px 12px;
`;

const Name = styled.div`
font: normal normal 600 26px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const UserInfo = styled.div`
display:flex;
width:100%;
justify-content:space-between;
font: normal normal medium 12px/18px SF PRO, serif;;
letter-spacing: 0px;
color: #B7B7B7;
margin-top:auto;
`;

const Upgrade = styled.div`
text-align: right;
text-decoration: underline;
font: normal normal medium 12px/18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
cursor:pointer;
`;

const DataEntry = styled.div`
text-align: left;
font: normal normal 600 28px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const DataTitle = styled.div`
text-align: left;
font: normal normal medium 12px/25px SF PRO, serif;
letter-spacing: 0px;
color: #B7B7B7;
`;

const EmptyPlaceHolder = styled.div`
margin:12px auto;
font: normal normal normal 12px/17px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
display:flex;
flex-direction:column;
align-items:center;
`;

const ModeBox = styled.div`
width: 100%;
height: 168px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
margin-top:12px;
padding:12px;
display:flex;
flex-direction:column;
`;

const Modes = styled.div`
display:grid;
grid-template-columns: repeat(3, 120px);
grid-row-gap:8px;
`;

const ModeText = styled.div`
text-align: left;
font: normal normal 16px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const ModeSub = styled.div`
text-align: left;
font: normal normal 12px SF PRO, serif;
letter-spacing: 0px;
color: #B7B7B7;
margin-top:auto;
`;

const IntroBox = styled.div`
width: 100%;
height: 118px;
/* UI Properties */
background: #2E2F30 0% 0% no-repeat padding-box;
border-radius: 7px;
margin-top:12px;
padding:12px;
display:flex;
flex-direction:column;
`;

const IntroText = styled.div`
text-align: left;
font: normal normal 14px/18px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;


export default withRouter(UserInfoBeforeStart);

