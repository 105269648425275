import React, { useEffect, useState } from 'react';

function Countdown({ endDate, style }) {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const targetDate = new Date(endDate).getTime();

    const countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const timeRemaining = targetDate - now;

      if (timeRemaining <= 0) {
        clearInterval(countdownInterval);
        setCountdown('Countdown expired');
        return;
      }

      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));

      const countdownText = `${days}d ${hours}h ${minutes}m`;
      setCountdown(countdownText);
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [endDate]);

  return (
    <div style={style}>
      <p>{countdown}</p>
    </div>
  );
}

export default Countdown;