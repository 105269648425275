import React, {useMemo} from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

const PaySidebar = ({option, history}) => {
  const { loggedIn, profile} = useSelector((state) => state.user);

  const showPremium = useMemo(() =>{
    if(loggedIn && profile){
      if(profile.platformSubId){
        return true;
      }
    }
    return false;
  }, [loggedIn, profile])

  return (
    <SideBar>
      <Title>OPTIONS</Title>
      <Option isSelected={option === "multiplier"} onClick={() => {
          history.push("/multiplier")
      }}>
      {option === "multiplier" ? (
          <OptionImg
            src="../assets/board-financials-white.png"
          />
        ) : (
          <OptionImg
            src="../assets/board-financials.png"
          />
        )}
        Get Multiplier
      </Option>

    </SideBar>
  )
}

const SideBar = styled.div`
  background: #232526 0% 0% no-repeat padding-box;
  width: 190px;
  position: fixed;
  inset: 0%;
  z-index: 20;
`;

const Title = styled.div`
  padding-top: 120px;
  padding-left: 53px;
  text-align: left;
  font: normal normal bold 16px/53px SF PRO, serif;
  letter-spacing: 0px;
  color: #ee486d;
`;

const Option = styled.div`
  padding-left: 23px;
  text-align: left;
  font: normal normal bold 16px SF PRO, serif;
  letter-spacing: 0px;
  height: 53px;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 53px;
  ${(p) =>
    p.isSelected
      ? `color: #FFFFFF; background: #F72375 0% 0% no-repeat padding-box;
border-radius: 5px;`
      : `color: #7B7A7A;`}
`;

const OptionImg = styled.img`
  margin-left: 4px;
  width: 12px;
  margin-right: 16px;
`;

export default withRouter(PaySidebar);
