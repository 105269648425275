import React, {  useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import * as API from "../../util/api";
import axios from "axios";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import { debounce } from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { calcCreatorQuestPool } from "../../util/helper";
import { Mixpanel } from '../../util/mixpanel';
import { Input } from 'antd';


const JoinQuestModal = ({quest, modalOpen, closeModal, history, showSuccess, isPromoed, redirectToAd}) => {
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [accountPrivate, setAccountPrivate] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);

  useEffect(() => {
    const checkAccountPrivate = async () => {

      if(profile){
        const {verify_status, savedEpicId} = profile;
        if(verify_status === 2 && savedEpicId){
          const url = API.CHECK_ACCOUNT_PRIVATE;
          try {
            const res = await axios.get(url, {
              params:{
                epicId:profile.savedEpicId
              }
            });
            if(res.status === 200){
              const {isAccountPrivate} = res.data;
              if(isAccountPrivate){
                setAccountPrivate(true)
              }
            }
          }catch(err){
            console.log(err)
          }
    
        }
      }
    };
    checkAccountPrivate()
  }, [profile]);

  const isInviteOnly = useMemo(() => {
    if(quest.inviteOnly && quest.inviteCode){
      return true;
    }else {
      return false;
    }
  }, [quest])
  

  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: "613px",
      height:'auto',
      padding:'24px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "24px",
      zIndex: "10000",
    },
  };

  const hasEnoughCoins = useMemo(() => {
    if(profile && quest) {
      const {entryFee} = quest;
      const {token_count} = profile;
      if(token_count >= entryFee){
        return true
      }
    }
    return false;
  },[profile, quest]);

  const hasVerifyStatus = useMemo(() => {
    if(profile){
      const {verify_status, savedEpicId} = profile;
      if(verify_status === 2 && savedEpicId){
        return true;
      }
    }
    return false;
  },[profile])

  const isActiveSub = useMemo(() => {
    if(loggedIn && profile && profile.multiplier){
      const multiplier = profile.multiplier;
      if(multiplier.renewAt && moment(multiplier.renewAt).isAfter(moment()) ){
        return true
      }
    }
    return false;
  },[profile, loggedIn]);

  const hasFreeTickets = useMemo(() => {
    if(profile && profile.questFreeTickets && profile.questFreeTickets > 0){
      return true;
    }else {
      return false;
    }
  },[profile]);

  const freeTicketsCount = useMemo(() => {
    if(profile && profile.questFreeTickets && profile.questFreeTickets > 0){
      return profile.questFreeTickets;
    }else {
      return 0;
    }
  },[profile]);

  const prizePool = useMemo(() => {
    if(quest){
      return calcCreatorQuestPool(quest);
    }
    return 0;
  }, [quest]);

  const balance = useMemo(() => {
    if(loggedIn && profile && profile.token_count){
      return profile.token_count;
    }
    return 0;
  }, [profile, loggedIn]);


  const debounceJoin = debounce(() => {
      if(isInviteOnly){
        const {inviteCode} = quest;
        if(code === inviteCode){
          joinQuest();
        }else {
          setCodeError(true);
          setLoading(false);
        }
      }else {
        joinQuest()
      }
  }, 500);

  const joinQuest = async() => {
    if(isActiveSub){
      const requestBody = {
        questId:quest.questId,
        userId
      }
      const url = API.JOIN_CREATORQUEST_AS_SUB;
      try {
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          setLoading(false);
          Mixpanel.track("Join Creator Quest")
          closeModal();
          showSuccess();
          }else {
            setLoading(false);
            closeModal();
        }
      }catch(err){
        setLoading(false);
        console.log(err)
      }
    }else if(hasFreeTickets && freeTicketsCount > 0){
      const requestBody = {
        questId:quest.questId,
        userId
      }
      const url = API.JOIN_CREATORQUEST_WITH_TICKETS;
      try {
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          setLoading(false);
          Mixpanel.track("Join Creator Quest")
          closeModal();
          showSuccess();

          }else {
            setLoading(false);
            closeModal();
        }
      }catch(err){
        setLoading(false);
        console.log(err)
      }
    }
  }

  const setVerifyFrom = () => ({
    type:"SET_VERIFY_FROM",
    payload: {
      target:"creatorQuest",
      target_matchId:quest.questId
    }
  });

  const dispatch = useDispatch()

  const toVerifyAccount = () => {
    dispatch(setVerifyFrom())
    history.push("/linkAccount")
  }

  

  const flexiblePricePool = useMemo(() => {
    if((quest.isFixedPricePool && quest.fixedPricePool && quest.fixedPricePool > 0) || (quest.prizePoolMode && quest.prizePoolMode === 2 )){
      return false
    }else {
      return true
    }
  }, [quest]);


  return <Modal
  isOpen={modalOpen}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
>
<Top>
      <ArrowLeftOutlined style={{cursor:'pointer', marginRight:8}} onClick={closeModal}/><span>Join Quest</span>
      <PointBox>
          + 5 Points
        </PointBox>
    </Top>
    {
      quest.isMultiplierOnly ? <> 
       {
        isActiveSub ? <>
           {
        flexiblePricePool ? <Datas>
          <DataItem>
      <Title>
   Price Pool
    </Title>
    <Data>
      <Coin>{prizePool}</Coin>
      <Icon src="../assets/Coin.svg" alt="icon"/>
    </Data>
      </DataItem>
      <DataItem>
      <Title>
      You Add To Prize Pool
    </Title>
    <Data>
      <Coin>+{quest.entryFee}</Coin>
      <Icon src="../assets/Coin.svg" alt="icon"/>
    </Data>
      </DataItem>
        </Datas> : <>
        <Title>
    Est. Prize Pool
    </Title>
    <Data>
      <Coin>{prizePool}</Coin>
      <Icon src="../assets/Coin.svg" alt="icon"/>
    </Data>
        </>
      } 
        <JoinButton onClick={() => {
        if(!loading && (hasFreeTickets || isActiveSub)){
          setLoading(true);
          debounceJoin()
        }
      }}
      disabled={loading}
      >
        {
          loading  && "Loading..." 
        }
        {
          !loading  && "Join Quest"
        }
      </JoinButton>
        </> : <>
        <Title style={{color:"#FFFFFF",textAlign:'left'}}>
        This Quest is only available to subscribers.
    </Title>
    <JoinButton onClick={() => {
        Mixpanel.track("Redirect to multiplier from join creator modal")
        history.push("/multiplier")
      }}>
      View Multiplier
    </JoinButton>
        </>
       }
      </> : <>
      {
      hasVerifyStatus ? <>
      <Tip style={{textAlign:'left'}}>
        {
          accountPrivate && `We Found That Your Account is Private!`
        }
      Make sure your Fortnite Account is Public!
      </Tip>
      {
        flexiblePricePool ? <Datas>
          <DataItem>
      <Title>
   Price Pool
    </Title>
    <Data>
      <Coin>{prizePool}</Coin>
      <Icon src="../assets/Coin.svg" alt="icon"/>
    </Data>
      </DataItem>
      <DataItem>
      <Title>
      You Add To Prize Pool
    </Title>
    <Data>
      <Coin>+{quest.entryFee}</Coin>
      <Icon src="../assets/Coin.svg" alt="icon"/>
    </Data>
      </DataItem>
        </Datas> : <>
        <Title>
    Est. Prize Pool
    </Title>
    <Data>
      <Coin>{prizePool}</Coin>
      <Icon src="../assets/Coin.svg" alt="icon"/>
    </Data>
        </>
      } 
      {
        !isActiveSub  && <>
         <Datas>
      <DataItem>
      <Title>
    Your Tickets
    </Title>
    <Data>
      <Coin>{freeTicketsCount}</Coin>
      <Icon src="../assets/Coin.svg" alt="icon"/>
    </Data>
      </DataItem>
      <DataItem>
      <Title>
    Quest Entry Fee
    </Title>
    <Data>
      <Coin style={{color:"#F72375"}}>{isPromoed ? "FREE WITH AD":"1 Ticket"}</Coin>
      <Icon src="../assets/Coin.svg" alt="icon"/>
    </Data>
      </DataItem>
    </Datas></> 
      }
    {
      isInviteOnly && <>
        <Title>
   Quest Code
    </Title>
    <Data>
    <StyledInput value={code}  onChange={(e) => {
          if(codeError){
            setCodeError(false);
          }
          setCode(e.target.value)
        }}/>
    </Data>
    {
          codeError && <span style={{font: "normal normal normal 12px SF PRO, serif",
            color: "#F72375"}}>Error: Code is not match, please try again</span>
        }
      </>
    }
    {
      isPromoed ? <JoinButton onClick={() => {
        if(isActiveSub){
          if(!loading){
            setLoading(true);
            debounceJoin()
          }
        }else {
          if(!loading){
            closeModal()
            redirectToAd();
          }
        } 
      }}>
         {
          loading  && "Loading..." 
        }
        {
          !loading && isActiveSub && "Join Quest"
        }
        {
          !loading && !isActiveSub && "Watch Ad & Join"
        }
      </JoinButton> : <JoinButton onClick={() => {
        if(!loading && (hasFreeTickets || isActiveSub)){
          setLoading(true);
          debounceJoin()
        }else if(!loading && !hasFreeTickets) {
          history.push(`/getTickets?questId=${quest.questId}`)
        }
      }}
      disabled={loading}
      >
        {
          loading  && "Loading..." 
        }
        {
          !loading && !hasFreeTickets && !isActiveSub && "Get Tickets & Join"
        }
        {
          !loading && (hasFreeTickets || isActiveSub) && "Join Quest"
        }
      </JoinButton>
    }
    {
      !isActiveSub && <Tip onClick={() => {
        Mixpanel.track("Redirect to multiplier from join creator modal")
        history.push("/multiplier")
      }}>
        Get FREE entry to all Quests and 200 Coins with the Multiplier
      </Tip>
    }
      </>:<>
      <Title style={{color:"#FFFFFF",textAlign:'center'}}>
    Please verify your epic account before joining the quest!
    </Title>
    <JoinButton style={{margin:"24px auto 0px", width:"60%"}} onClick={toVerifyAccount}>
      Verify Epic Account
    </JoinButton>
      </>
    }
      </>
}
    
</Modal>
}


const Top = styled.div`
display:flex;
align-items:center;
font: normal normal bold 24px/20px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const PointBox = styled.div`
width: 94px;
height: 36px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 11px;
text-align: center;
font: normal normal bold 14px/34px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-left:auto;
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 20px/36px SF PRO, serif;
letter-spacing: 0px;
color: #979797;
text-transform: capitalize;
`;

const Data = styled.div`
width:100%;
display:flex;
justify-content:space-between;
border-bottom:3px solid #979797;
align-items:center;
height:42px;
`;

const Icon = styled.img`
width:20px;
`;

const Coin = styled.div`
text-align: left;
font: normal normal bold 24px/24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const Datas = styled.div`
display:flex;
margin:12px 0px 24px;
width:100%;
justify-content:space-between;
`;

const DataItem = styled.div`
width:258px;
`;

const JoinButton = styled.div`
margin-top:24px;
text-align: center;
font: normal normal 600 16px/49px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
width:100%;
height:49px;
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 12px;
cursor:pointer;
${(p) =>
  p.disabled
    && `background: #B7B7B7 0% 0% no-repeat padding-box;
    cursor:not-allowed;
    `}
`;

const Tip = styled.div`
width:100%;
text-align: center;
text-decoration: underline;
font: normal normal normal 18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
margin-top:12px;
cursor:pointer;
`;

const StyledInput = styled(Input)`
margin-top:8px;
height: 37px;
background: #40444B 0% 0% no-repeat padding-box;
border: none;
border-radius: 5px;
color:#FFFFFF;
`;

export default withRouter(JoinQuestModal);