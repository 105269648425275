import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button,Input, message, DatePicker,  Form, Select, List, Col, Row} from "antd";
import styled from "styled-components"
import * as API from "../../util/api";
import axios from "axios";
import moment from "moment"
import { withRouter } from "react-router-dom";
const { Option } = Select;
const { TextArea } = Input;

const { RangePicker } = DatePicker;

const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
};

const CreateRound = ({tournament, backToMain}) => {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
   onChange: onSelectChange,
  };

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'userName',
      key:'userName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key:"email"
    },
  ];

  const teamscolumns = [
    {
      title: 'User Name',
      dataIndex: 'userName',
      key:'userName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key:"email"
    },

    {
      title: 'Team Number',
      key:'teamIndex',
      dataIndex:'teamIndex'
    },
  ];

  const [form] = Form.useForm();

  const selectedList = useMemo(() => {
    const list = selectedRowKeys.map((id) => {
      const target = tournament.verifiedPlayers.find(item => item.userId === id) 
      if(target){
        return target.email
      }else {
        return null;
      }
    });
    return list;
  }, [selectedRowKeys, tournament.verifiedPlayers]);

  const createNewRound = async (values) => {
    try {
      const date = values.date;
      const startDate = moment(date[0]).format();
      const endDate = moment(date[1]).format();
      const roundData = {
        startDate,
        endDate,
        ...values,
        selectedPlayers:selectedRowKeys
      }
      const requestBody = {
        roundData,
        tournamentId:tournament.tournamentId
      }
      const url = API.TOURNAMENT_ROUND_CREATE;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        message.success("Successfully create the round");
        setSelectedRowKeys([]);
        form.resetFields();
        backToMain()
      }else {
        message.error("Failed to create new round, please try again")
      }

    }catch(err){
      console.log(err);
      message.error("Failed to create new round, please try again")
    }
  }
  return <>
  <div style={{display:'flex',marginBottom:24 }}>
    <h1>Create New Rounds</h1>
    <Button style={{marginLeft:'auto',marginRight:16}}onClick={() => {
      backToMain()
    }}>
      Back
    </Button>
    <Button type="primary" onClick={() => {
      const values = form.getFieldsValue(true);
      createNewRound(values)
    }}>
      Create New Round
    </Button>
  </div>
    <Row gutter={16}>
    <Col span={8}>
    <h3>Basic</h3>
    <Form
      name="validate_other"
      onFinish={(values) => {
        console.log(values)
      }}
      labelCol={{ span: 24 }}
      style={{maxWidth:800}}
      form={form}
      initialValues={{
        
      }
      }
    > 
    <Form.Item
          name="title"
          label="title"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <Input autoComplete="off" />
      </Form.Item>
    <Form.Item
    name="roundIndex"
    label="Round Index"
    rules={[
      {
        required: true,
      },
    ]}
  >
      <Select placeholder="Round Index">
      <Option value={1}>1</Option>
      <Option value={2}>2</Option>
      <Option value={3}>3</Option>
      <Option value={4}>4</Option>
      <Option value={5}>5</Option>
    </Select>
    </Form.Item> 
      <Form.Item
          name="date"
          label="Schedule"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <RangePicker disabledDate={disabledDate} />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        labelAlign='Right'
      >
         <TextArea
          type="text"
          showCount
          autoSize={{ minRows: 3, maxRows: 8 }}
          maxLength={320}
        />
      </Form.Item>
      </Form>
    </Col>
    <Col span={8}>
    <h3>Select Players</h3>
    {
      tournament.teamsUp ?  <Table
      rowSelection={{
        ...rowSelection,
      }}
        pagination = {
          {
            pageSize: 20,
          }
        }
        columns={teamscolumns}
        dataSource={tournament.verifiedPlayers}
      /> :  <Table
      rowSelection={{
        ...rowSelection,
      }}
        pagination = {
          {
            pageSize: 20,
          }
        }
        columns={columns}
        dataSource={tournament.verifiedPlayers}
      /> 
    }
    </Col>
    <Col span={8}>
       <List
        header={<div>Selected Players List</div>}
        bordered
        dataSource={selectedList}
        style={{marginBottom:24}}
        renderItem={(item) => (
          <List.Item >
            {item}
          </List.Item>
        )}
      />
    </Col>
  </Row>
  </>
}

export default CreateRound;