import React, { useEffect } from "react";
import PrivateNavBar from "../../nav/privateNavBar";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import CreateProLeagueForm from "./createProLeagueForm";
import ProLeagueDashboard from "./proLeagueDashboard";


const CreatorProLeagueEntry = ({history}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  useEffect(() => {
    if(!profile || !loggedIn || profile.permission_level !== 2){
      history.pushState("/")
    }
  },[history, profile, loggedIn])

  return <Container>
    <PrivateNavBar isDarkMode={true}/>
    <LeftContainer>
      <CreateProLeagueForm />
    </LeftContainer>
    <RightContainer>
      <ProLeagueDashboard />
    </RightContainer>
  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#000000 0% 0% no-repeat padding-box;
  position: relative;
  min-height:calc(100vh);
  display: flex;
  justify-content:space-between;
`;

const LeftContainer = styled.div`
  width: auto;
`;

const RightContainer = styled.div`
  background: #18181B 0% 0% no-repeat padding-box;
  border-left: 1px solid #000000;
  height: calc(100vh);
  width: 504px;
  padding-top: 78px;
  overflow-y:auto;
`;

export default withRouter(CreatorProLeagueEntry);