import React, {useEffect, useState, useMemo} from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PrivateNavBar from "../nav/privateNavBar";
import PublicNavBar from "../nav/publicNavBar";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import * as API from "../util/api";
import Firebase from "../util/firebase";
import ExchangeModal from "./component/exchangeModal";
import { Mixpanel } from '../util/mixpanel';

const Exchange = ({history}) => {
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [exchangeHistory, setExchangeHistory] = useState([]);

  const isActiveSub = useMemo(() => {
    if(loggedIn && profile && profile.multiplier){
      const multiplier = profile.multiplier;
      if(multiplier.renewAt && moment(multiplier.renewAt).isAfter(moment())){
        return true
      }
    }
    return false;
  },[profile, loggedIn]);

  const checkCoins = (coins) => {
    if(profile && profile.token_count && profile.token_count > coins){
      return true;
    }
    return false;
  }

  useEffect(() => {
    const fetchExchangeOption = async() => {
      const snapshot = await Firebase.firestore()
      .collection("exchangeOptions")
      .get();

      let resultArray = [];
      snapshot.forEach((doc) => {
        const obj ={
          optionId:doc.id,
          ...doc.data()
        };
        resultArray.push(obj);
      });
      resultArray.sort((a, b) => {
        if(a.option < b.option){
          return -1;
        }else {
          return 1;
        }
      })
      setOptions(resultArray);
    };

    const fetchExchangeHistory = async() => {
      const snapshot = await Firebase.firestore()
      .collection("exchange_order")
      .get();

      let resultArray = [];
      snapshot.forEach((doc) => {
        const obj ={
          orderId:doc.id,
          ...doc.data()
        };
        resultArray.push(obj);
      });
      resultArray.sort((a, b) => {
        if(moment(a.createdAt).isBefore(moment(b.createdAt))){
          return -1;
        }else {
          return 1;
        }
      })
      setExchangeHistory(resultArray);
    };

    fetchExchangeOption();
    fetchExchangeHistory();
    Mixpanel.track("View Exchange Page", {
      loggedIn
    })
  },[loggedIn]);

  const checkAvailable = (item) => { 
    const ableToRedeem = isActiveSub || (!item.subscriberOnly && !profile.exchangeOnce);
    if(checkCoins(item.coins) && ableToRedeem){
      return true;
    }else {
      return false;
    }
  }


  
  return <Container>
    {loggedIn ? <PrivateNavBar isDarkMode={true} activeTab="exchange"/> : <PublicNavBar isDarkMode={true} activeTab="exchange"/>}
    <Content>
      <Left>
        <Top>
        <Title>Exchange</Title>
        {
          !isActiveSub && <GetMultiplier onClick={() => {
            history.push("/multiplier")
          }}>
            Get The Multiplier To Cash Out
          </GetMultiplier>
        }
        </Top>
        {
          options.map((item, index) => (
          <PayPalBox key={item.optionId}>
              <img src="../assets/paypal.png" style={{display:"block",height:30}} alt=""/>
              <PaypalDetail>
                <PaypalText>
                {`Get ${item.value} USD PayPal Transfer`}
                </PaypalText>
                <img
                  src="/assets/ic_mytokens.png"
                  alt=""
                  style={{
                    height: 14,
                    marginRight:4
                  }}
                />
                {item.coins}
                <CashOut isActive={checkAvailable(item)} onClick={() => {
                    if((isActiveSub && checkCoins(item.coins)) || (checkCoins(item.coins) && !item.subscriberOnly && !profile.exchangeOnce ) ){
                      const current = options[index];
                      setCurrentOption(current);
                      setModalOpen(true)
                    }
                }}>
                    Cash Out
                </CashOut>
              </PaypalDetail>
          </PayPalBox>
          ))
        }
      </Left>
      <Right>
        <Title>
        Recent Redemptions
        </Title>
        <RedemptionBox>
          {exchangeHistory.map((item) => (
            <History>
              <span style={{color:'#F72375'}}>{item.userName}</span> just cashed out {item.value}!
            </History>
          ))}
        </RedemptionBox>
      </Right>
    </Content>
   <ExchangeModal modalOpen={modalOpen} 
   current={currentOption}
   closeModal={() => {
     setCurrentOption(null)
     setModalOpen(false);
   }}/>
  </Container>
}
const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  background: #000000 0% 0% no-repeat padding-box;
  position: absolute;
  min-height: calc(100vh);
`;

const Content = styled.div`
  margin:108px auto;
  display:flex;
  width:1280px;
`;

const Left = styled.div`
width: 900px;
margin-right:36px;
`;

const Right = styled.div`
width: 244px;
`;

const RedemptionBox = styled.div`
height: 669px;
/* UI Properties */
background: #232526 0% 0% no-repeat padding-box;
border-radius: 7px;
width:100%;
padding:18px;
overflow-y:auto;
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 18px/25px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
margin-bottom:16px;
`;

const PayPalBox = styled.div`
width:100%;
height: 62px;
/* UI Properties */
background: #232526 0% 0% no-repeat padding-box;
border-radius: 7px;
margin-bottom:24px;
display:flex;
justify-content:space-between;
align-items:center;
padding:0 24px;
`;


const PaypalDetail = styled.div`
display:flex;
align-items:center;
font: normal normal 600 16px SF PRO, serif;
color: #FFFFFF;
`;

const PaypalText = styled.div`
letter-spacing: 0px;
margin-right:24px;
`;

const CashOut = styled.div`
margin-left:24px;
width: 94px;
height: 34px;
/* UI Properties */
border-radius: 7px;
font: normal normal 600 15px/34px SF PRO, serif;
letter-spacing: 0px;
text-align:center;
color: #FFFFFF;
${p => p.isActive ? `
background: #F72375 0% 0% no-repeat padding-box;
cursor:pointer;
`:`
background: #727272 0% 0% no-repeat padding-box;
`}
`;

const History = styled.div`
font: normal normal normal 12px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:8px;
`;

const Top = styled.div`
display:flex;
width:100%;
justify-content:space-between;
`;

const GetMultiplier = styled.div`
text-align: center;
font: normal normal 600 12px/28px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
width: 221px;
height: 28px;
/* UI Properties */
background: #FF0063 0% 0% no-repeat padding-box;
border-radius:5px;
cursor:pointer;
`;


export default withRouter(Exchange)