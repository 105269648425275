import React, { Component } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Input } from "antd";
import * as API from "../../util/api";
import axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

class ChangeNameModal extends Component {

  state = {
    newName:"",
    hasError:false,
    error:""
  }

  closeModal= () => {
    this.setState({
      newName:"",
      hasError:false,
      error:""
    })
    this.props.closeModal();
  }

  confirmChange = async () => {
    const {newName} = this.state;
    if(newName === ''){
      this.setState({
        hasError:true,
        error:'please enter a valid username'
      })
    }else {
      const url = API.CHANGE_NAME;
      const {user} = this.props;
      const {id} = user;
      const requestBody = {
        name:newName,
        userId:id
      }
      try {
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        const {isTaken} = res.data;
        if(isTaken){
          this.setState({
            error:'this username is taken by another user',
            hasError:true
          })
        }else {
          this.setState({
            newName:"",
            error:"",
            hasError:false
          });
          this.props.closeModal();
          toast.success('Change Username successfully', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        }
      }
      }catch(err){
        this.setState({
          error:'System error, please try again!',
          hasError:true
        })
      }
    }
  }

  changeName = (e) => {
    this.setState({
      newName:e.target.value,
      hasError:false,
      error:""
    })
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.confirmChange();
    }
  }

  render() {
    const {hasError, error} = this.state;
    const customStyles = {
      content: {
        top: "20%",
        margin: "0 auto",
        bottom: "auto",
        width: "638px",
        height: "379px",
        background: "#FAFAFA 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "24px",
      },
    };

    return (
      <Modal
        isOpen={this.props.open}
        style={customStyles}
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
      >
        <Detail>
        <Title>Change UserName</Title>
          <SubTitle>
          Upon clicking confirm your username will be changed.
          </SubTitle>
          <Input
                  style={{
                    width: 350,
                    height: 32,
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    borderRadius: 7,
                    border: "1px solid #000000",
                  }}
                  placeholder="username"
                  value={this.state.newName}
                  onChange={this.changeName}
                  onKeyPress={this.handleKeyPress}
                />
                {
                  hasError && <Error>Error:{error}</Error>
                }
          <Operations>
            <Cancel onClick={this.closeModal}>Cancel</Cancel>
            <Home onClick={this.confirmChange}>Confirm</Home>
          </Operations>
        </Detail>
      </Modal>
    );
  }
}

const SubTitle = styled.div`
  width: 456px;
  margin: 40px auto;
  text-align: center;
  font: normal 18px/23px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
`;

const Home = styled.div`
  width: 122px;
  height: 27px;
  background: #ff5f93 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font: bold 12px/17px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
`;

const Title = styled.div`
  font: bold 35px/38px SF PRO, serif;
  letter-spacing: 0px;
  font-weight: 900;
  color: #000000;
`;

const Detail = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-input:hover {
    border: 0.5px solid #000000 !important;
    box-shadow: none !important;
  }
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none !important;
    border: 0.5px solid #000000 !important;
  }
`;

const Operations = styled.div`
  width: 350px;
  display: flex;
  margin-top:32px;
  justify-content: space-between;
`;

const Cancel = styled.div`
  width: 122px;
  height: 27px;
  border-radius: 6px;
  font: bold 12px/17px SF PRO, serif;
  background: grey 0% 0% no-repeat padding-box;
  letter-spacing: 0px;
  color: #ffffff;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
`;

const Error = styled.span`
  font: normal 12px SF PRO, serif;
  color: red;
  text-align: left;
  margin-top:8px;
  width: 350px;
  padding-left:8px;
`;

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, null)(ChangeNameModal);