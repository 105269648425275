import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Table, Button, Skeleton, Tabs, Descriptions, Typography, Select, message} from "antd";
import TournamentPlayers from './component/tournamentPlayers';
import TournamentRounds from './component/tournamentRounds';
import * as API from "../util/api";
import axios from "axios";

const { Option } = Select;

const TournamentEntry = ({tournament}) => {
  const [currentSelect, setCurrentSelect] = useState(0);
  useEffect(() => {
    setCurrentSelect(tournament?.currentRoundIndex??-1)
  }, [tournament])


  const closeRegistration = async () => {
    try {
      const requestBody = {
        tournamentId:tournament.tournamentId
      }
      const url = API.TOURNAMENT_CLOSEREGISTRATION;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        message.success("Registration closed, no more checkin accept")
      }else {
        message.error("Failed to close registration, please try again")
      }
    }catch(err){
      message.error("Failed to close registration, please try again")
    }
  }

  const updateCurrentIndex = async () => {
    if(currentSelect !== tournament.currentRoundIndex){
      try {
        const requestBody = {
          tournamentId:tournament.tournamentId,
          current:currentSelect
        }
        const url = API.TOURNAMENT_UPDATEROUNDINDEX;
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          message.success("Update Current Round Index Successed")
        }else {
          message.error("Failed to update current round index, please try again")
        }
      }catch(err){
        message.error("Failed to update current round index, please try again")
      }
    }
  }


  return <>
  {
    tournament ? <>
    <Tabs defaultActiveKey="1">
    <Tabs.TabPane tab="Basic Info" key="1">
      <Descriptions bordered>
        <Descriptions.Item label="Start Date" span={2}>{moment(tournament.startDate).format("LL")}</Descriptions.Item>
        <Descriptions.Item label="End Date" span={2}>{moment(tournament.endDate).format("LL")}</Descriptions.Item>
        <Descriptions.Item label="Status" span={2}>{tournament.status === 0? 'Open':'Registration Closed'}</Descriptions.Item>
        <Descriptions.Item label="Current Round" span={2}>{tournament.currentRoundIndex > 0 ? tournament.currentRoundIndex: "-"}</Descriptions.Item>
        <Descriptions.Item label="Invited Players" span={2}>{tournament?.invitedList?.length?? 0}</Descriptions.Item>
        <Descriptions.Item label="Check In Players" span={2}>
          {tournament?.verifiedPlayers?.length?? 0}
        </Descriptions.Item>
        <Descriptions.Item label="Teams Up" span={2}>
          {tournament.teamsUp? 'Yes':"No"}
        </Descriptions.Item>
        <Descriptions.Item label="Players Per Team" span={2}>
          {tournament.playerPerTeam?? "-"}
        </Descriptions.Item>
      </Descriptions>
      {
        tournament.status === 0 ? <Button type="primary" style={{margin:'12px 0px'}} onClick={() => {
          closeRegistration()
        }}>
          Close Registration
        </Button> : <Button disabled={true} style={{margin:'12px 0px'}} >
          Registration Closed
        </Button>
      }
      {
        tournament.status !== 0 && <div style={{display:'flex', alignItems:'center', marginTop:16}}>
        <Typography style={{marginRight:12}}>Update Current Round</Typography>

      <Select placeholder="Round Index" 
      value={currentSelect}
      onChange={(value) => {
        setCurrentSelect(value)
      }}>
      <Option value={-1}>-</Option>
      <Option value={1}>1</Option>
      <Option value={2}>2</Option>
      <Option value={3}>3</Option>
      <Option value={4}>4</Option>
      <Option value={5}>5</Option>
    </Select>
    <Button type="primary" style={{marginLeft:12}} onClick={() => {
      updateCurrentIndex()
    }}>Confirm</Button>
      </div>
      }
    </Tabs.TabPane>
    <Tabs.TabPane tab="Players" key="2">
      <TournamentPlayers tournament={tournament}/>
    </Tabs.TabPane>
    <Tabs.TabPane tab="Rounds" key="3">
    <TournamentRounds tournament={tournament} />
    </Tabs.TabPane>
  </Tabs>
    </> : <Skeleton active />
  }
  </>
}

export default withRouter(TournamentEntry);