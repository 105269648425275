import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, Modal, Input, message, List, Typography, Card, Descriptions} from "antd";
import styled from "styled-components"
import * as API from "../../util/api";
import axios from "axios";
import moment from "moment"
import EditAbleRound from './editAbleRound';
import ConfirmedRound from "./confirmedRound";

const RoundDetail = ({currentRound, tournament})  => {

  const confirmRound = async () => {
    try {
      const requestBody = {
        roundId:currentRound.roundId,
        tournamentId:tournament.tournamentId
      }
      const url = API.TOURNAMENT_CONFIRMROUND;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        message.success("Players confirmed and quests schedule")
      }else {
        message.error("Failed to confirm, please try again")
      }
    }catch(err){
      message.error("Failed to confirm, please try again")
    }
  }

  return <>
  <div style={{display:'flex', justifyContent:'space-between', marginBottom:24}}>
    <h1>Round Title: {currentRound.title}</h1>
    <Button>Back </Button>
  </div>
      <Descriptions title="Details" bordered extra={currentRound.status === 0 && <Button type="primary" onClick={() => {
        confirmRound()
      }}>Confirm And Schedule Quests</Button>} >
      <Descriptions.Item label="Start Date" span={2}>{moment(currentRound.startDate).format("LL")}</Descriptions.Item>
      <Descriptions.Item label="End Date" span={2}>{moment(currentRound.endDate).format("LL")}</Descriptions.Item>
      <Descriptions.Item label="Description" span={4}>{currentRound.description??""}</Descriptions.Item>
      </Descriptions>
    {
      currentRound.status === 0 ? <EditAbleRound currentRound={currentRound} tournament={tournament} /> :
      <ConfirmedRound currentRound={currentRound} tournament={tournament} />
    }
  </>
}

export default RoundDetail

