import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Select,
  Upload,
  message,
  Checkbox,
} from "antd";
import styled from "styled-components";
import firebase from "firebase/app";
import Firebase from "../../util/firebase";
import axios from "axios";
import * as API from "../../util/api";
import { debounce } from "lodash";
const { Option } = Select;

const CreateProLeagueForm = () => {
  const [form] = Form.useForm();
  const [coverUrl, setCoverUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const { profile, id: userId} = useSelector((state) => state.user);
  const [gameModeSelected, setGameModeSelected] = useState(false);

  const modeOptions = [
    {
      label:"Build Solos",
      value:"defaultsolo"
    },
    {
      label:"No Build Solos",
      value:"nobuildbr_solo"
    },
    {
      label:"Arena Solos",
      value:"showdownalt_solo"
    },
    {
      label:"Build Duos",
      value:"defaultduo"
    },
    {
      label:"No Build Duos",
      value:"nobuildbr_duo"
    },
    {
      label:"Arena Duos",
      value:"showdownalt_duos"
    },
    {
      label:"Build Trios",
      value:"trios"
    },
    {
      label:"No Build Trios",
      value:"nobuildbr_trio"
    },
    {
      label:"Arena Trios",
      value:"showdownalt_trios"
    },
    {
      label:"Build Squads",
      value:"defaultsquad"
    },
    {
      label:"No Build Squads",
      value:"nobuildbr_squad"
    },
    {
      label:"Arena Squads",
      value:"showdownalt_squads"
    },
  ];



  const createQuest = async (values) => {
    const {name, entryFee, duration, startTime, modes, prizePoolMode, fixedPrizePool, hybridPrizePool} = values;
    const {first_name} = profile;
    const fee = parseInt(entryFee);
    if(prizePoolMode === 1){
      if(fee <= 0){
        message.error("Entry fee should be bigger than 0");
        setLoading(false);
      }else {
        const requestBody = {
          streamerId:userId,
          streamerName:first_name,
          entryFee:fee,
          duration:parseInt(duration),
          startTime,
          imageUrl:coverUrl,
          questName:name,
          prizePoolMode:1
        }
        if(gameModeSelected){
          requestBody.gameModeSelected = true;
          requestBody.gameModes = modes
        }
        try {
          const endpoint = API.CREATE_PROLEAGUE;
          const res = await axios.post(endpoint, requestBody);
          if(res.status === 200){
            form.resetFields();
            setCoverUrl("");
            message.success("You have successfully create the quest");
          }else {
            message.error("System error, try again")
          }
        }catch(err){
          console.log(err);
          message.error("System error, try again")
        }finally {
          setLoading(false);
        }
      }
    }else {
      const pool = prizePoolMode === 2 ? parseInt(fixedPrizePool) : parseInt(hybridPrizePool);
      if(pool > profile.token_count || pool < 0){
        message.error("Pool Number not valid")
      }else {
        const requestBody = {
          streamerId:userId,
          streamerName:first_name,
          entryFee:fee,
          duration:parseInt(duration),
          startTime,
          imageUrl:coverUrl,
          questName:name,
        }
        if(gameModeSelected){
          requestBody.gameModeSelected = true;
          requestBody.gameModes = modes
        }
        if(prizePoolMode === 2){
          requestBody.prizePoolMode = 2;
          requestBody.prizePool = pool;
        }
        if(prizePoolMode === 3){
          requestBody.prizePoolMode = 3;
          requestBody.prizePool = pool;
        }
        try {
          const endpoint = API.CREATE_PROLEAGUE_WITHPOOL;
          const res = await axios.post(endpoint, requestBody);
          if(res.status === 200){
            form.resetFields();
            setCoverUrl("");
            message.success("You have successfully create the quest");
          }else {
            message.error("System error, try again")
          }
        }catch(err){
          console.log(err);
          message.error("System error, try again")
        }finally {
          setLoading(false);
        }
      }
      }
    }

  const debounceCreateQuest = debounce((values) => {
    createQuest(values)
  },500);


  const customRequest = async ({ onError, onSuccess, file }) => {
    const metadata = {
      contentType: "image/jpeg",
    };
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("coverImage/" + fileName)
      .put(file, metadata);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setCoverUrl(downloadURL);
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const handleChange = async (info) => {
    if (info.file.status === "done") {
      console.log("done");
    }
    if (info.file.status === "error") {
      console.log("upload error");
    }
  };

  const prizePoolMode = Form.useWatch('prizePoolMode', form);


  return <Container>
  <Title>
    <span style={{color:"#F72375"}}>Create</span> Pro League
    </Title>
    <StyledForm
      style={{ width: 516 }}
      name="nest-messages"
      onFinish={(values) => {
        if(!loading){
          setLoading(true);
          debounceCreateQuest(values);
        }
      }}
      labelCol={{ span: 24 }}
      form={form}
    >
      <Form.Item
        name="name"
        rules={[{ required: true, message: "name is required" }]}
      >
        <Input autoComplete="off" placeholder="Quest Name"/>
      </Form.Item>
      <Form.Item
        name="entryFee"
        rules={[{ required: true, message: "entry fee is required" }]}
      >
        <Input autoComplete="off" placeholder="Entry Fee" type="number"/>
      </Form.Item>
      <Form.Item
        name="startTime"
        rules={[{ required: true, message: "startTime is required" }]}
      >
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" placeholder="Start Time"/>
      </Form.Item>
      <Form.Item
        name="duration"
        rules={[
          {
            required: true,
            message: "Please choose duration",
          },
        ]}
      >
        <Select placeholder="Duration">
          <Option value="1">1 HR</Option>
          <Option value="2">2 HR</Option>
          <Option value="3">3 HR</Option>
          <Option value="4">4 HR</Option>
        </Select>
      </Form.Item>
      <Checkbox onChange={(e)=> {
        setGameModeSelected(e.target.checked)
      }} defaultChecked={false}>
          Only Accept Specific Game Modes
      </Checkbox>
      <Form.Item 
      name="modes"
      hidden={!gameModeSelected}
        rules={[
          {
            required: gameModeSelected,
            message: 'please choose game modes',
          },
        ]}
      >
      <Select
      mode="multiple"
      style={{
        width: '100%',
      }}
      placeholder="Please select game mode"
      options={modeOptions}
    />
      </Form.Item>
      <Form.Item
        name="prizePoolMode"
        rules={[
          {
            required: true,
            message: "Please choose",
          },
        ]}
      >
        <Select placeholder="Prize Pool Mode">
          <Option value={1}>Variable Prize Pool</Option>
          <Option value={2}>Fixed Prize Pool</Option>
          <Option value={3}>Hybrid Prize Pool</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="fixedPrizePool"
        hidden={prizePoolMode !== 2}
        rules={[
          {
            required: (prizePoolMode === 2),
            message: 'please Enter the Price Pool',
          },
        ]}
      >
         <Input autoComplete="off" placeholder="Enter Fixed Price Pool" type="number"/>
      </Form.Item> 
      <Form.Item
        name="hybridPrizePool"
        hidden={prizePoolMode !== 3}
        rules={[
          {
            required: (prizePoolMode === 3),
            message: 'please Enter the Price Pool',
          },
        ]}
      >
         <Input autoComplete="off" placeholder="Enter Hybird Price Pool" type="number"/>
      </Form.Item> 

      <UploadSession>
        <div style={{font:"normal normal normal 16px SF Pro Display", color:"#BFBFBF"}}>Upload Image</div>
        <div>
        <Upload
        onChange={handleChange}
        accept="image/*"
        customRequest={customRequest}
        showUploadList={false}
      >
        <UploadButton>
          {coverUrl === "" ? "Choose Image" : "Replace Image"}
        </UploadButton>
      </Upload>
      {
        coverUrl !== "" && <UploadButton style={{marginLeft:16}} onClick={() => {
          setCoverUrl("")
        }}>Remove Image</UploadButton>
      }
        </div>
      </UploadSession>
      {coverUrl !== "" && (
        <>
           <div style={{font:"normal normal normal 16px SF Pro Display", color:"#BFBFBF", marginBottom:12}}>Cover Image Preview</div>
          <Preview src={coverUrl}></Preview>
        </>
      
      )}

      <Form.Item>
        <StyledButton htmlType="submit" disabled={loading}>{loading?'Loading...' :'Submit Quest'}</StyledButton>
      </Form.Item>
      </StyledForm>
  </Container>
}

const Container = styled.div`
padding:108px 10%;
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 36px/64px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    color: #f75771;
  }
  .ant-input {
    background-color:black !important;
    border: 0.5px solid #F72375;
    border-radius:5px;
    height:36px;
    color: #BFBFBF;
    font: normal normal normal 16px/36px SF PRO, serif;
  }
  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover {
    background-color:black !important;
    border: 0.5px solid #F72375;
    border-radius:2px;
  }
  .ant-picker {
    background-color:black !important;
    border: 0.5px solid #F72375;
    border-radius:5px;
    width:516px;
    height:36px;
    color: #BFBFBF;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    background-color:black !important;
    border: 0.5px solid #F72375;
    border-radius:2px;
    width:516px;
  }
  .ant-picker-input > input {
    color: #BFBFBF;
    font: normal normal normal 16px/36px SF PRO, serif
  }
  .ant-select,
  .ant-select-selector {
    background-color:black !important;
    border: 0.5px solid #F72375 !important;
    border-radius:5px;
    width:516px;
    color: #BFBFBF;
    font: normal normal normal 16px/36px SF PRO, serif;
  }
  .ant-select-focused {
    background-color:black !important;
    border: 0.5px solid #F72375 !important;
    border-radius:2px;
    width:516px;
  }
  .ant-select-selection-item {
    background-color:black !important;
  }

  .ant-btn {
    border: none;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    background: #f72375 0% 0% no-repeat padding-box;
    color: #ffffff !important;
  }

  .ant-checkbox + span {
    color: #BFBFBF;
    font: normal normal normal 16px/36px SF PRO, serif;
    margin-bottom:12px;
    padding-right:0px;
  }

  .anticon-close {
    color:#BFBFBF;
  }
`;

const UploadSession = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:16px;
`;

const UploadButton = styled(Button)`
width: 120px;
height: 36px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
font: normal normal normal 14px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
margin-left:auto;
`;

const StyledButton = styled(Button)`
width: 516px;
height: 36px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
font: normal normal normal 14px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
`;

const Preview = styled.img`
  width: 100px;
  height: 100px;
  display: block;
  margin-bottom: 24px;
`;

export default CreateProLeagueForm;