import React, { Component } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Button } from "antd";

class ChangePasswordModal extends Component {
  finish = () => {
    this.props.closeModal();
  };

  render() {
    const customStyles = {
      content: {
        top: "20%",
        margin: "0 auto",
        bottom: "auto",
        width: "638px",
        height: "379px",
        background: "#FAFAFA 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "24px",
      },
    };

    return (
      <Modal
        isOpen={this.props.open}
        style={customStyles}
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
      >
        <Detail>
          <Title>Change Password</Title>
          <SubTitle>
            Thank you! You will get reset instructions sent to your email inbox.
          </SubTitle>
          <Home onClick={this.finish}>Finish</Home>
        </Detail>
      </Modal>
    );
  }
}

const SubTitle = styled.div`
  width: 456px;
  margin: 40px auto;
  text-align: center;
  font: normal 18px/23px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
`;

const Home = styled.div`
  width: 122px;
  height: 27px;
  background: #ff5f93 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font: bold 12px/17px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
`;

const Title = styled.div`
  font: bold 35px/38px SF PRO, serif;
  letter-spacing: 0px;
  font-weight: 900;
  color: #000000;
`;

const Detail = styled.div`
  padding: 60px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default ChangePasswordModal;
