import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
import styled from 'styled-components';
import { Table, Button, Modal, Input, message, List,  Card, Col, Row, Typography} from "antd";
import Countdown from 'react-countdown';
const { Title } = Typography;



const QuestDetail = ({quest, currentRound, backToRoundDetail, teamsUp}) => {

  const displayGameModes = (modes) => {
    const result = modes.reduce((arr, curr, index) => {
      if(index === 0){
        return arr  + getLabel(curr);
      }else {
        return arr  +  " & " + getLabel(curr);
      }
    }, '');
    return result;
  }

  const getLabel = (mode) => {
    const target = modeOptions.find((item) => item.value === mode);
    return target.label;
  }


  const modeOptions = [
    {
      label:"Build Solos",
      value:"defaultsolo"
    },
    {
      label:"No Build Solos",
      value:"nobuildbr_solo"
    },
    {
      label:"Arena Solos",
      value:"showdownalt_solo"
    },
    {
      label:"Build Duos",
      value:"defaultduo"
    },
    {
      label:"No Build Duos",
      value:"nobuildbr_duo"
    },
    {
      label:"Arena Duos",
      value:"showdownalt_duos"
    },
    {
      label:"Build Trios",
      value:"trios"
    },
    {
      label:"No Build Trios",
      value:"nobuildbr_trio"
    },
    {
      label:"Arena Trios",
      value:"showdownalt_trios"
    },
    {
      label:"Build Squads",
      value:"defaultsquad"
    },
    {
      label:"No Build Squads",
      value:"nobuildbr_squad"
    },
    {
      label:"Arena Squads",
      value:"showdownalt_squads"
    },
  ];

  const getStatus = (quest) => {
    const status = quest.status;
    if(status === 1){
      return "Upcoming"
    }else if(status === 2){
      return "Ongoing"
    }else if(status === 3 && !quest.resultGenerated){
      return "Ended"
    }else if(status === 3 && quest.resultGenerated){
      return "Finalized"
    }
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Ended</span>
    } else {
      // Render a countdown
     /*  const mins = hours * 60 + minutes; */
     const hrs = days*24 + hours;
      return <span>{hrs}:{minutes}:{seconds}</span>;
    }
  };

  const beforeStartColumnsWithoutTeam = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'UserName',
      dataIndex: 'userName',
      key: 'userName',
    },
  ]

  const beforeStartWithTeam = [
    {
      title:"Team",
      dataIndex:"teamIndex",
      key:'teamIndex'
    }, 
    {
      title:"Members",
      key:"member",
      render:(record) => {
        const players = record.players??[];
        const result = players.reduce((arr, curr, index) => {
          if(index === 0){
            return arr  + curr.email;
          }else {
            return arr  +  " & " + curr.email;
          }
        }, '');
        return result;
      }
    }
  ]

  const beforeStartDataSource = useMemo(() => {
    if(!teamsUp){
      return quest.players;
    }else {
      const result = new Map();
      quest.players.forEach((item) => {
        const index = item.teamIndex;
        if(result.has(index)){
          const current = result.get(index);
          current.push(item);
        }else {
          const newArr = [item];
          result.set(index, newArr)
        }
      });
      const sortMap = new Map([...result.entries()].sort());
      const sortResult = [...sortMap].map(([index, players]) => ({
        teamIndex:index,
        players
      }));
      return sortResult;
    }
  }, [teamsUp, quest]);

  const afterStartColumnsWithoutTeam = [
    {
      title:"Placement",
      dataIndex:'placement',
      key:'placement'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'UserName',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title:"Kills",
      dataIndex:'kills'
    }
  ]

  const afterStartColumnsWithTeam = [
    {
      title:"Placement",
      dataIndex:'placement',
      key:'placement'
    },
    {
      title:"Team",
      dataIndex:"teamIndex",
      key:'teamIndex'
    }, 
    {
      title:"Members",
      key:"member",
      render:(record) => {
        const players = record.players??[];
        const result = players.reduce((acc, curr, index) => {
          if(index === 0){
            return acc  + curr.email;
          }else {
            return acc  +  " & " + curr.email;
          }
        }, '');
        return result;
      }
    },
    {
      title:"Total Kills",
      key:"totalKills",
      dataIndex:"totalKills"
    }
  ]

  const afterStartDataSource = useMemo(() => {
    const players = quest.players;
    if(!teamsUp){
      let result = players.reduce((acc, curr) => {
        const kills = (curr.currentKills??0) - (curr.startKills??0);
        const userWithKills = {
          email:curr.email,
          useName:curr.userName,
          userId:curr.userId,
          kills,
          isAccountPrivate:curr.isAccountPrivate
        }
        acc.push(userWithKills);
        return acc;
      },[]);
      result = result.sort((a,b) => {
        if(a.kills > b.kills){
          return -1;
        }else {
          return 1;
        }
      })
      const resultWithPlacement = result.map((item, index) => {
        return {
          ...item,
          placement:index+1
        }
      })
      return resultWithPlacement;
    }else {
      const playersWithKills = players.reduce((acc, curr) => {
        const kills = (curr.currentKills??0) - (curr.startKills??0);
        const userWithKills = {
          email:curr.email,
          useName:curr.userName,
          userId:curr.userId,
          kills,
          isAccountPrivate:curr.isAccountPrivate,
          teamIndex:curr.teamIndex??0
        }
        acc.push(userWithKills);
        return acc;
      },[]);

      const result = new Map();
      playersWithKills.forEach((item) => {
        const index = item.teamIndex;
        if(result.has(index)){
          const current = result.get(index);
          current.push(item);
        }else {
          const newArr = [item];
          result.set(index, newArr)
        }
      });
      const sortMap = new Map([...result.entries()].sort());
      let sortResult = [...sortMap].map(([index, players]) => {
        const totalKills = players.reduce((acc, curr) => {
          return acc + curr.kills
        }, 0);
        return {
            teamIndex:index,
            players,
            totalKills
          }
      });
      sortResult = sortResult.sort((a,b) => {
        if(a.totalKills > b.totalKills){
          return -1;
        }else {
          return 1;
        }
      });
      const resultWithPlacement = sortResult.map((item, index) => {
        return {
          ...item,
          placement:index + 1
        }
      })
      return resultWithPlacement;
    }
  }, [teamsUp, quest]);


  const endDataSource = useMemo(() => {
    const players = quest.players;
    if(!teamsUp){
      let result = players.reduce((acc, curr) => {
        const kills = (curr.finalKills??0) - (curr.startKills??0);
        const userWithKills = {
          email:curr.email,
          useName:curr.userName,
          userId:curr.userId,
          kills,
          isAccountPrivate:curr.isAccountPrivate
        }
        acc.push(userWithKills);
        return acc;
      },[]);
      result = result.sort((a,b) => {
        if(a.kills > b.kills){
          return -1;
        }else {
          return 1;
        }
      })
      const resultWithPlacement = result.map((item, index) => {
        return {
          ...item,
          placement:index+1
        }
      })
      return resultWithPlacement;
    }else {
      const playersWithKills = players.reduce((acc, curr) => {
        const kills = (curr.finalKills??0) - (curr.startKills??0);
        const userWithKills = {
          email:curr.email,
          useName:curr.userName,
          userId:curr.userId,
          kills,
          isAccountPrivate:curr.isAccountPrivate,
          teamIndex:curr.teamIndex??0
        }
        acc.push(userWithKills);
        return acc;
      },[]);

      const result = new Map();
      playersWithKills.forEach((item) => {
        const index = item.teamIndex;
        if(result.has(index)){
          const current = result.get(index);
          current.push(item);
        }else {
          const newArr = [item];
          result.set(index, newArr)
        }
      });
      const sortMap = new Map([...result.entries()].sort());
      let sortResult = [...sortMap].map(([index, players]) => {
        const totalKills = players.reduce((acc, curr) => {
          return acc + curr.kills
        }, 0);
        return {
            teamIndex:index,
            players,
            totalKills
          }
      });
      sortResult = sortResult.sort((a,b) => {
        if(a.totalKills > b.totalKills){
          return -1;
        }else {
          return 1;
        }
      });
      const resultWithPlacement = sortResult.map((item, index) => {
        return {
          ...item,
          placement:index + 1
        }
      })
      return resultWithPlacement;
    }
  }, [teamsUp, quest]);

  console.log(endDataSource)


  return <Container>
    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
      <Title level={4}>Selected Quest: {quest?.title??""}</Title>
      <Button onClick={() => {
        backToRoundDetail()
      }}>View Round Detail</Button>
    </div>
    <Content>
        <Row gutter={16}>
          <Col span={8}>
            <Title level={5} >
              Start Time: {moment(quest.startTime).format("LL")}
            </Title>
          </Col>
          <Col span={8}>
            <Title level={5} >
            Duration: {(quest.duration)/60} HR
            </Title>
          </Col>
          <Col span={8}>
            <Title level={5}>
            Status: {getStatus(quest)}
          </Title>
          </Col>
        </Row>
        <Title level={5} >
          Game Modes: {quest.gameModes? displayGameModes(quest.gameModes?? "") :"ALL"}
        </Title>
        {
          quest.status === 1 && <Title level={5}>
           Quest starts In: <Countdown date={moment(quest.startTime).toDate()} renderer={renderer} daysInHours={true} />
          </Title>
        }
        {
          quest.status === 2 && <Row>
            <Col span={12}>
            <Title level={5}>
            Last Updated At:{moment(quest.lastUpdatedAt).format("LLL")}
          </Title>
            </Col>
            <Col span={12}>
            <Title level={5}>
            Quest Ends In: <Countdown date={moment(quest.closeAt).toDate()} renderer={renderer}/>
          </Title>
            </Col>
          </Row>
        }
        {
          quest.status === 3 && <Row>
            <Col span={12}>
            <Title level={5}>
            Quests Ends At: {moment(quest.closeAt).format("LL")}
          </Title>
            </Col>
            <Col span={12}>
            <Title level={5}>
            Result {quest.resultGenerated? "Finalized":"Processing"}
          </Title>
            </Col>
          </Row>
        }
        <Title level={4} underline>
          {quest.status === 1? 'Players':"Leaderboard"}
        </Title>
        {
          quest.status === 1 && teamsUp && <Table columns={beforeStartWithTeam} dataSource={beforeStartDataSource} />
        }
        {
          quest.status === 1 && !teamsUp && <Table columns={beforeStartColumnsWithoutTeam} dataSource={beforeStartDataSource} />
        }
        {
          quest.status === 2 && teamsUp && <Table columns={afterStartColumnsWithTeam} dataSource={afterStartDataSource} />
        }
        {
          quest.status === 2 && !teamsUp && <Table columns={afterStartColumnsWithoutTeam}  dataSource={afterStartDataSource} />
        }
        {
          quest.status === 3 && !teamsUp && <Table columns={afterStartColumnsWithoutTeam} dataSource={endDataSource} />
        }
        {
          quest.status === 3 && teamsUp && <Table columns={afterStartColumnsWithTeam} dataSource={endDataSource} />
        }
    </Content>
  </Container>
}

const Container = styled.div`
 margin-top:24px;
 width:100%;
`;

const Content = styled.div`
margin-top:24px;
width:100%;
padding:24px;
border:1px solid black;
`;

export default QuestDetail;