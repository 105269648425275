import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PublicNavBar from "../nav/publicNavBar";
import PrivateNavBar from "../nav/privateNavBar";
import InfluencerNavBar from "../nav/influencerNavBar";
import UpcomingQuests from "./creatorquests/upcomingQuests";
import PastQuests from "./creatorquests/pastQuest";
import { Mixpanel } from "../util/mixpanel";
import ProLeagues from "./proleague/customerEntry"

import qs from 'query-string';

import {Helmet} from "react-helmet";
import PublisherNavBar from "../nav/publisherNavBar";


const Quest = ({match, location, search}) => {
  const [showOption, setShowOption] = useState(1);
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);

  useEffect(() => {
    const searchParams = qs.parse(location.search);
      const option = searchParams.option;
      if(option){
        if(option === "quests"){
          setShowOption(1)
        }else if(option === 'proLeague'){
          setShowOption(5)
        }
      }
  }, [match, location])

  useEffect(() => {
    Mixpanel.track("[WEB] Quests Page")
  },[]);

  const role = useMemo(() => {
   if(profile){
    if(profile.role === 'influencer'){
      return 'influencer'
    }else if(profile.role === 'publisher'){
      return 'publisher'
    }
   }
   return 'player'
  }, [profile])

  return <Container>
    <Helmet>
        <title>Quests: Compete in Fortnite for Cash Prizes - ArcadeQuest</title>
        <meta name="description" content="Cash prize Fortnite competitions for all skill levels on ArcadeQuest. Enter now and compete to win." />
        <meta name="keywords" content="Fortnite, quests, ArcadeQuest, cash prize, competitions" />
    </Helmet>
    {
      !loggedIn && <PublicNavBar />
    }
    {
      loggedIn && role === 'player' && <PrivateNavBar activeTab="quest" isDarkMode={true}/>
    }
    {
      loggedIn && role === 'influencer' && <InfluencerNavBar activeTab="quest" isDarkMode={true} />
    }
    {
      loggedIn && role === 'publisher' && <PublisherNavBar activeTab="quest" isDarkMode={true} />
    }
    <Sidebar>
        <OptionContainer selected={showOption === 1} onClick={() => {
          setShowOption(1)
        }}>
       <Title selected={showOption === 1} >
              Quests
          </Title>
        </OptionContainer>
        <OptionContainer selected={showOption === 5} onClick={() => {
          setShowOption(5)
        }}>
       <Title selected={showOption === 5}>
              pro league
          </Title>
        </OptionContainer>
        <OptionContainer selected={showOption === 2} onClick={() => {
          setShowOption(2)
        }}>
       <Title selected={showOption === 2}>
             past quest
          </Title>
        </OptionContainer>
  </Sidebar>
    <div style={{marginLeft:'180px'}}>
    {
      showOption === 1 && <UpcomingQuests />
    }
    {
      showOption === 2 && <PastQuests />
    }
    {
      showOption === 5 && <ProLeagues />
    }
    </div>
  </Container>
}

const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  padding-top: 98px;
  background: #000000 0% 0% no-repeat padding-box;
  height:calc(100vh);
`;

const Choices = styled.div`
width: 1180px;
@media (min-width: 1480px) {
  width: 1280px;
}
margin:24px auto 24px;
display:flex;
height:34px;
border-bottom:3px solid #FAFAFA;
`;

const Choice = styled.div`
width: auto;
padding:0 12px;
height: 34px;
/* UI Properties */
font: normal normal bold 20px/24px SF PRO, serif;
letter-spacing: 0px;
text-align:center;
margin-right:18px;
cursor:pointer;
color: #000000;
${(p) =>
  p.isDarkMode && !p.active 
    && `color: #FAFAFA;`}
${(p) =>
   p.active
        ? `color:#F72375; border-bottom:3px solid #F72375;`
        : ``}
`;

const Sidebar = styled.div`
  background:  #000000;
  width: 180px;
  position: fixed;
  inset: 0%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-top: 88px;
  padding-bottom:64px;
  display:flex;
  flex-direction:column;
  align-items:center;
  background: #18181B 0% 0% no-repeat padding-box;
`;


const Icon = styled.img`
width:54px;
height:54px;
margin-bottom:8px;
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 12px;
${p => p.selected ?`color: #FFFFFF;`:
`color:#9B9C9D;`}
test-align:center;
text-align: left;
font: normal normal 600 18px/45px SF PRO, serif;
letter-spacing: 0px;
text-transform: uppercase;
`;

const OptionContainer = styled.div`
${p => p.selected && `background: #F72375 0% 0% no-repeat padding-box;`}
border-radius: 7px;
height:45px;
width:100%;
padding-left:16px;
cursor:pointer;
`;

export default withRouter(Quest);