import React, {  useState, useMemo} from "react";
import styled from "styled-components";
import QuestBasic from "./questBasic";
/* import QuestRanking from "./questRanking"; */
import ProLeagueRanking from "./proLeagueRanking";
import QuestChat from "../creatorquests/questChat";
import UserInfoAfterStart from "./userInfoAfterStart";
import { calcProLeagueReward, calcProLeaguePool } from "../../util/helper";


const OngoingStatus = ({joined, quest}) => {
  const [showOption, setShowOption] = useState(1);

  const killsSort = (a, b) => {
    const killsA = (a.currentKills && a.startKills) ? a.currentKills - a.startKills : 0;
    const killsB = (b.currentKills && b.startKills) ? b.currentKills - b.startKills : 0;
    if(killsA < killsB){
      return 1;
    }else {
      return -1;
    }
  }
  const sortedPlayers = useMemo(() => {
    const players = quest.players??[];
    return players.sort(killsSort);
  },[quest]);


  const rewards = useMemo(() => {
    return calcProLeagueReward(quest);
  }, [quest])


  return <>
      {joined && <Choices isDarkMode={true}>
          <Choice active={showOption === 1} isDarkMode={true} onClick={() => {
              setShowOption(1)
            }}>
               Quests
          </Choice>
          <Choice active={showOption === 2} isDarkMode={true} onClick={() => {
              setShowOption(2)
            }}>
               Ranking
               </Choice>
        </Choices>}
        {
          (joined && showOption === 1) ? <Main>
            <QuestBasic quest={quest}  />
            <UserInfoAfterStart quest={quest} sortedPlayers={sortedPlayers} rewards={rewards}/>
            <QuestChat quest={quest} joined={joined}/>
          </Main> :
          <ProLeagueRanking quest={quest} sortedPlayers={sortedPlayers} joined={joined} rewards={rewards} />
        }
  </>
}


const Choices = styled.div`
width: 100%;
margin:12px auto 24px;
display:flex;
height:34px;
border-bottom:3px solid #FAFAFA;
`;

const Choice = styled.div`
width: auto;
padding:0 12px;
height: 34px;
/* UI Properties */
font: normal normal bold 20px/24px SF PRO, serif;
letter-spacing: 0px;
text-align:center;
margin-right:18px;
cursor:pointer;
color: #000000;
${(p) =>
  p.isDarkMode && !p.active 
    && `color: #FAFAFA;`}
${(p) =>
   p.active
        ? `color:#F72375; border-bottom:3px solid #F72375;`
        : ``}
`;

const Main = styled.div`
display:flex;
width:100%;
justify-content:space-between;
margin-top:36px;
`;


export default OngoingStatus;