import React, {  useState, useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import QuestBasic from "./questBasic";
import UserInfoBeforeStart from "./userInfoBeforeStart";
import QuestChat from "./questChat";
import QuestRanking from "./questRanking";
import { calcCreatorQuestTier, calcCreatorQuestTierPlacement } from "../../util/helper";
import { Mixpanel } from '../../util/mixpanel';

const OpenStatus = ({quest, joined, isDailyQuest, isPromoed, companyInfo, productInfo, canClaimBouns, getClickBouns}) => {
  const tiers = useMemo(() => {
    if(quest){
      const rewardPerTier = calcCreatorQuestTier(quest);
      const placementPerTier = calcCreatorQuestTierPlacement(quest);
      const firstTier = {
        placement:placementPerTier.tier1,
        reward:rewardPerTier.tier1
      }
      const secondTier = {
        placement:placementPerTier.tier2,
        reward:rewardPerTier.tier2
      }
      const thirdTier = {
        placement:placementPerTier.tier3,
        reward:rewardPerTier.tier3
      }
      return {
        firstTier,
        secondTier,
        thirdTier
      }
    }else {
      return {}
    }
  }, [quest]);
  const [showOption, setShowOption] = useState(1);
  const mixPaneltrackBanner = () => {
    if(companyInfo && productInfo){
      Mixpanel.track("Quest Promo Product Banner Clicks", {
        productName:productInfo.name,
        productId:productInfo.productId,
        partnerName:companyInfo.name,
        partnerId:companyInfo.partnerId,
      })
    }
  }
  return <>
     <Choices isDarkMode={true}>
          <Choice active={showOption === 1} isDarkMode={true} onClick={() => {
              setShowOption(1)
            }}>
               Quests
          </Choice>
          <Choice active={showOption === 2} isDarkMode={true} onClick={() => {
              setShowOption(2)
            }}>
               Ranking
               </Choice>
          </Choices>
          {
            showOption === 1 && !isPromoed && <Main>
              <QuestBasic quest={quest} isDailyQuest={isDailyQuest} isPromoed={isPromoed} />
            <UserInfoBeforeStart quest={quest}/>
            <QuestChat quest={quest} joined={joined}/>
            </Main>
          }
          {
            showOption === 1 && isPromoed &&<Main>
                <QuestBasic quest={quest} isDailyQuest={isDailyQuest} isPromoed={isPromoed} />
                <PromoBox>
                  <PromoTop>
                  <UserInfoBeforeStart quest={quest} isPromoed={isPromoed} productInfo={productInfo} companyInfo={companyInfo}/>
                  <QuestChat quest={quest} joined={joined}/>
                  </PromoTop>
                  <PromoBanner image={productInfo?.lowerBannerUrl??""}  onClick={() => {
         mixPaneltrackBanner()
         window.open(productInfo.link)
        getClickBouns()
      }}>
        {
          canClaimBouns && <ClaimBouns>
              GET 5 COINS WHEN YOU CLICK
          </ClaimBouns>
        }
      </PromoBanner>
                </PromoBox>
                </Main>
              }
          {
            showOption === 2 && <QuestRanking quest={quest} joined={joined} isPromoed={isPromoed} companyInfo={companyInfo}
             productInfo={productInfo} tiers={tiers}
             canClaimBouns={canClaimBouns}
             getClickBouns={getClickBouns}
             />
          }
  </>
}

const Choices = styled.div`
width: 100%;
margin:12px auto 24px;
display:flex;
height:34px;
border-bottom:3px solid #FAFAFA;
`;

const Choice = styled.div`
width: auto;
padding:0 12px;
height: 34px;
/* UI Properties */
font: normal normal bold 20px/24px SF PRO, serif;
letter-spacing: 0px;
text-align:center;
margin-right:18px;
cursor:pointer;
color: #000000;
${(p) =>
  p.isDarkMode && !p.active 
    && `color: #FAFAFA;`}
${(p) =>
   p.active
        ? `color:#F72375; border-bottom:3px solid #F72375;`
        : ``}
`;

const Main = styled.div`
display:flex;
width:100%;
justify-content:space-between;
margin-top:36px;
`;

const PromoBox = styled.div`
display:flex;
width:100%;
justify-content:space-between;
flex-direction:column;
margin-left:32px;
margin-bottom:32px;
@media (min-width: 1480px) {
  margin-left: 84px;
}
`;

const PromoTop = styled.div`
display:flex;
width:100%;
justify-content:space-between;
`;

const PromoBanner = styled.div`
height: 194px;
width:100%;
background: url(${(p) => p.image}); 
background-size: cover;
position:relative;
border-radius:11px;
cursor:pointer;
`;

const ClaimBouns = styled.div`
width: 221px;
height: 28px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 11px 0px;
position:absolute;
bottom:0px;
right:0px;
font: normal normal bold 12px/28px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
text-transform: uppercase;
text-align:center;
`;

export default OpenStatus;