import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { calcCreatorQuestPool } from "../../util/helper";
import moment from "moment";

const ProLeagueCard = ({joined, history, quest}) => {
  const { items: communities } = useSelector((state) => state.community);
  const { profile, id: userId} = useSelector((state) => state.user);
  const communityImage = useMemo(() => {
    if(quest && quest.streamerId){
      const creator = quest.streamerId;
      const target = communities.find((item) => item.owner_id === creator);
      if(target){
        return target.creator_image;
      }
    }
    return "https://firebasestorage.googleapis.com/v0/b/arcadequest-dev.appspot.com/o/communityCover%2Fcommunitypng?alt=media&token=54bfa81b-7f2a-4910-843b-43b49d4fc90b";
  }, [communities, quest]);

  const prizePool = useMemo(() => {
    if(quest){
      return calcCreatorQuestPool(quest);
    }else {
      return 0;
    }
  }, [quest]);


  const isActiveSub = useMemo(() => {
    return profile && profile.multiplier && profile.multiplier.renewAt && moment(profile.multiplier.renewAt).isAfter((moment()));
  }, [profile]);

  const entryFee = useMemo(() => {
    if(!isActiveSub){
      return quest.entryFee
    }else {
      return Math.ceil(quest.entryFee * 0.85)
    }
  }, [quest, isActiveSub])


  const hasCoverUrl = quest.imageUrl !== "";
  const coverUrl = hasCoverUrl ? quest.imageUrl : "../assets/active_bg.png";
  return <Container joined={joined} >
    <MatchBackground image={coverUrl} hasCoverUrl={hasCoverUrl}>
        <div style={{display:'flex', alignItems:'center'}}>
        <CommunityBack src={communityImage} alt="comm" />
        <div style={{display:'flex', flexDirection:'column'}}>
        <MatchName>{quest.questName}</MatchName>
      <StreamerName>@{quest.streamerName}</StreamerName>
        </div> 
        </div>
        {
          quest.status === 1 ? <StartIn>
          Starts: {moment(quest.startTime).format("LLL")}
        </StartIn>: <StartIn>
          Started: {moment(quest.actualStartedAt).format("LLL")}
        </StartIn>
        }
     </MatchBackground>
     <MatchDetail>
                <DetailContent style={{borderRight:"1px solid #F0F0F0"}}>
                  <DetailText>
                    {quest.players.length}
                  </DetailText>
                  <DetailIntro>
                    <ProfileIcon src="../assets/profile_style_white.svg" alt="icon"/>Player
                  </DetailIntro>
                </DetailContent>
                <DetailContent style={{borderRight:"1px solid #F0F0F0"}}>
                  <DetailText>
                  {quest.status === 1 && 'Open'}
                  {
                    quest.status === 2 && "Ongoing"
                  }
                  {
                    quest.status === 3 && "Ended"
                  }
                  </DetailText>
                  <DetailIntro>
                  <ProfileIcon src="../assets/profile_time_white.svg" alt="icon"/>Status
                  </DetailIntro>
                </DetailContent>
                <DetailContent>
                   <DetailText style={{color:"#F72375"}}>{entryFee}</DetailText>
                  
                  <DetailIntro>
                  <ProfileIcon src="../assets/Coin.svg" alt="icon"/>Entry Fee
                  </DetailIntro>
                </DetailContent>
                </MatchDetail>
                <MatchBottom>
                <div style={{font:"normal normal medium 14px SF PRO, serif", color:"#504F51", marginRight:8}}>
                  Prize
                </div>
                <img
              src="/assets/Coin.svg"
              alt=""
              style={{
                height: "14px",
                marginRight:8
              }}

            />
            <div style={{font:"normal normal bold 18px/25px SF PRO, serif", color:"#FAFAFA", marginRight:8}}>{prizePool}</div>
              <ViewButton ended={quest.status === 3} onClick={() => {
    history.push(`/proLeagueCustomer/${quest.proLeagueId}`)
  }}> 
  {
    (joined || quest.status !== 1) && `View`
  }
  {
                        
    !joined && quest.status === 1 && `Join`
   }
              </ViewButton>
              </MatchBottom>
  </Container>
}


const Container = styled.div`
width: 343px;
height: 272px;
/* UI Properties */
${p => p.joined ? `background: #000000 0% 0% no-repeat padding-box;
border: 1px solid #F72375;`:`
background: #2E2F30 0% 0% no-repeat padding-box;
`}
border-radius: 24px;
padding:12px 18px;
`;

const MatchBackground = styled.div`
background: url(${(p) => p.image}) rgba(0, 0, 0, .6) no-repeat center center;
position: relative;
width:100%;
height:100px;
background-size: cover;
border-radius: 12px;
padding:12px 12px;
${p => !p.hasCoverUrl ? `border: 1px solid #F72375;`:`background-blend-mode: multiply;` }
`;


const CommunityBack = styled.img`
width: 40px;
height: 40px;
border-radius: 12px;
margin-right:16px;
`;


const MatchName = styled.div`
text-align: left;
font: normal normal 600 16px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
`;

const StreamerName = styled.div`
text-align: left;
font: normal normal 600 16px/25px SF PRO, serif;
letter-spacing: 0px;
color: #D1D1D1;
`;


const MatchDetail = styled.div`
display:flex;
justify-content:space-between;
width:100%;
margin-top:24px;
`;

const DetailContent = styled.div`
display:flex;
flex-direction:column;
width:30%;
`;

const DetailText = styled.div`
text-align: left;
font: normal normal bold 18px/25px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
margin-bottom:8px;
`;

const DetailIntro = styled.div`
text-align: left;
font: normal normal medium 14px/25px SF PRO, serif;
letter-spacing: 0px;
color: #898A8A;
`;

const MatchBottom = styled.div`
display:flex;
width:100%;
align-items:center;
margin-top:12px;
`;

const ProfileIcon = styled.img`
height:14px;
width:14px;
margin-right:6px;
`;

const ViewButton = styled.div`
width: 182px;
height: 48px;
/* UI Properties */
background: #F42A76 0% 0% no-repeat padding-box;
border-radius: 12px;
font: normal normal 600 16px/48px SF PRO, serif;
letter-spacing: 0px;
color: #FAFAFA;
text-align:center;
cursor:pointer;
margin-left:auto;
${p => p.ended && `background: #6A6A6A 0% 0% no-repeat padding-box;`}
`;

const StartIn = styled.div`
font: normal normal 600 16px/25px SF PRO, serif;
letter-spacing: 0px;
color: #D1D1D1;
`;




export default withRouter(ProLeagueCard);