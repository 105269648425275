import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
import { Button } from 'antd';
import { debounce } from "lodash";
import moment from "moment";
import axios from "axios";
import * as API from "../util/api";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";


const GetMultiplier = ({subDetail, successSub, history}) => {
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const debounceSub = debounce(() => {
    handleSubmitSub()
  }, 500);

  const toLogin = () => {
    history.push("/login?page=multiplier")
  }

  const handleSubmitSub = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }else {
      try {
        const email = profile.email;
        const name = profile.first_name;
    
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email: email,
          },
        });
    
        if (result.error) {
          setLoading(false);
          toast.error(result.error.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        } else {
          const endpoint = API.STRIPE_MULTIPLIER_SUB;
          const res = await axios.post(endpoint, {'payment_method': result.paymentMethod.id, 'email': email, 'name':name});
          // eslint-disable-next-line camelcase
          if(res.status === 404){
            setLoading(false)
            toast.error("Payment Error, please try again or contact support", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
          }else {
            const {client_secret, status, platformSubId, renewAt, startAt } = res.data;
    
            if (status === 'requires_action') {
              stripe.confirmCardPayment(client_secret).then(async function(result) {
                if (result.error) {
                  //failed
                  setLoading(false)
                  toast.error("Payment Error, please try again or contact support", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                  });
                } else {
                  //success
                  const requestBody = {
                    platformSubId,
                    renewAt:moment(renewAt*1000).format(),
                    startAt:moment(startAt*1000).format(),
                    userId
                  }
                  const subEndpoint = API.STORE_MULTIPLIER_SUB;
                  const res = await axios.post(subEndpoint, requestBody);
                  if(res.status === 200){
                    successSub();
                  }
                }
              });
            } else if(status === "succeeded"){
              //success
              const requestBody = {
                platformSubId,
                renewAt:moment(renewAt*1000).format(),
                startAt:moment(startAt*1000).format(),
                userId
              }
              const subEndpoint = API.STORE_MULTIPLIER_SUB;
              const res = await axios.post(subEndpoint, requestBody);
              if(res.status === 200){
                successSub();
              }
            }else {
              toast.error("Payment Error, please try again or contact support", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
            }
          }
        }
      }catch(err){
        setLoading(false)
        toast.error("Payment Error, please try again or contact support", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        'color': '#32325d',
        'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
        'fontSmoothing': 'antialiased',
        'fontSize': '16px',
        'backgroundColor':"#FFFFFF",
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return <Container>
    <ToastContainer />
  {
    step === 0 && <div>
       <Title>
    Get Multiplier
    </Title>
    {subDetail}
          <LeftTitle>
           FOR JUST
          </LeftTitle>
          <Prices>
          <OriginPrice>
          $5.99 USD/mo
          </OriginPrice>
          <DiscountPrice>
            $2.99 USD/mo
            </DiscountPrice>
          </Prices>
          <Discount>
          Only for the first 1,000 subscribers!
          </Discount>
          <Bouns>
          200 COINS BONUS FOR NEW SUBSCRIBERS
          </Bouns>
            <NextButton onClick={() => {
              if(loggedIn){
                setStep(1);
              }else {
                toLogin()
              }
            }}>
             {loggedIn ? 'Get Now': 'Log In First'}
            </NextButton>
    </div>
  }
  {
    step === 1 && <div>
           <Title>
    Get Multiplier
    </Title>
    <StepTwo>
    Your membership starts immediately!
      </StepTwo>
    <StepTwo>
    No Commitments. Cancel Anytime.
    </StepTwo>
    <CardContainer>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </CardContainer>
    <Tips>
      <div>
      Guaranteed <b>safe & secure</b> checkout
      </div>
    <Box>
      Powered by <b>Stripe</b>
    </Box>
    </Tips>
    {
      loggedIn ? <ConfirmButton disabled={loading} onClick={() => {
        if(!loading){
          setLoading(true);
          debounceSub();
        }
      }}
      >
          {loading? "Loading...":'Start Premium'}
      </ConfirmButton> : <ConfirmButton onClick={toLogin}>Login to Start</ConfirmButton>
    }
    
    </div>
  }
  <CoinsImg src="../assets/coins_bg.png" />
  </Container>
}

const Title = styled.div`
  text-align: left;
  font: normal normal bold 38px SF PRO, serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-bottom:36px;
`;

const NextButton = styled.div`
width: 144px;
height: 49px;
background: #F72375 0% 0% no-repeat padding-box;
box-shadow: 0px 12px 24px #F723757B;
border-radius: 11px;
text-align: center;
font: normal normal bold 18px/49px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
cursor:pointer;
margin:32px 0px;
`;

const LeftTitle = styled.div`
font: normal normal bold 18px/17px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
text-transform: uppercase;
margin-bottom:12px;
margin-top:32px;
`;


const StepTwo = styled.div`
font: normal normal 600 16px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const CardContainer = styled.div`
width:435px;
margin-top:16px;
padding:12px;
background-color:#FFFFFF;
border-radius:5px;
margin-bottom:24px;
`;

const Tips = styled.div`
text-align: left;
font: normal normal normal 18px/23px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
display:flex;
align-items:center;
`;

const Box = styled.div`
margin-left:8px;
width: auto;
height: 34px;
border:1px solid #FFFFFF;
border-radius:5px;
padding:0px 8px;
text-align:center;
line-height:34px;
`;

const ConfirmButton = styled(Button)`
margin-top:24px;
width:auto;
padding:0 12px;
height: 34px;
/* UI Properties */
border: 1px solid #F72375;
border-radius: 5px;
margin-bottom:180px;
cursor:pointer;
font: normal normal 600 16px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
text-align:center;
background:none;
:hover {
  background:none;
  border: 1px solid #F72375;
  color: #F72375;
}
`;

const Container = styled.div`
display:flex;
justify-content:space-between;
padding-right:10%;
align-items:start;
`;

const CoinsImg = styled.img`
width:335px;
`;

const Prices = styled.div`
display:flex;
margin-bottom:12px;
`;

const DiscountPrice = styled.div`
font: normal normal bold 32px/41px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
`;

const OriginPrice = styled.div`
text-decoration: line-through;
font: normal normal bold 32px/41px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-right:12px;
`;

const Discount = styled.div`
font: normal normal normal 17px/22px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const Bouns = styled.div`
text-align: left;
font: normal normal bold 18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
text-transform: uppercase;
margin:24px 0px;
`;

export default withRouter(GetMultiplier);