import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Firebase from "../../util/firebase";
import QuestCard from "./questCard";
import moment from 'moment';
import SpouseEvent from "./spouseEvent";

const UpcomingQuests = () => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const [quests, setQuests] = useState([]);
  useEffect(() => {
    let listener = null;
    const fetchQuests = async () => {
        listener = Firebase.firestore()
          .collection("creator_quest")
          .where("status", "in", [1, 2])
          .onSnapshot(querySnapshot => {
            let quests = [];
            querySnapshot.forEach(function (doc) {
              const obj = {
                ...doc.data(),
               questId: doc.id,
              };
              quests.push(obj);
            });
            setQuests(quests);
          },
          error => {
            console.log(error);
          }
          )
    }
    fetchQuests();
    return () => {
      if(listener){
        listener();
      }
    }
  },[] );

  const timeSort = (a, b) => {
    const timeA = moment(a[0]);
    const timeB = moment(b[0]);
    if(timeA.isBefore(timeB)){
      return -1;
    }else {
      return 1;
    }
  }

  const joinedQuest = useMemo(() => {
    if(!userId || !quests || !profile.joinedCreatorQuest){
      return [];
    }else {
      const joinedCreatorQuest = profile?.joinedCreatorQuest?? [];
      return quests.filter((item) => joinedCreatorQuest.includes(item.questId));
    }
  }, [userId, quests, profile]);

  const joinedQuestWithDate = useMemo(() => {
     const result = new Map();
     joinedQuest.forEach(item => {
      const startDate = moment(item.startTime).format("LL");
      if(result.has(startDate)){
        const current = result.get(startDate);
        current.push(item);
      }else {
        const newArr = [item];
        result.set(startDate, newArr);
      }
     })
    const sortMap = new Map([...result.entries()].sort(timeSort));
    const sortResult = [...sortMap].map(([date, quests]) => ({
      date,
      quests
    }));
    return sortResult;
  }, [joinedQuest]);

  const moreQuests = useMemo(() => {
    if(!userId || !quests || !profile.joinedCreatorQuest){
      return quests;
    }else {
      const joinedCreatorQuest = profile?.joinedCreatorQuest?? [];
      
      return quests.filter((item) => !joinedCreatorQuest.includes(item.questId)).sort(timeSort);
    }
  }, [userId, quests, profile]);

  const moreQuestsWithDate = useMemo(() => {
    const result = new Map();
    moreQuests.forEach(item => {
     const startDate = moment(item.startTime).format("LL");
     if(result.has(startDate)){
       const current = result.get(startDate);
       current.push(item);
     }else {
       const newArr = [item];
       result.set(startDate, newArr);
     }
    });
    const sortMap =  new Map([...result.entries()].sort(timeSort))
    const sortResult = [...sortMap].map(([date, quests]) => ({
      date,
      quests
    }));
    return sortResult;
 }, [moreQuests]);


  return <Content>
    <Title>
    <span style={{color:"#F72375"}}>Compete</span> For Coins
    </Title>
    <Subtitle>
    Upcoming Fortnite competitions based on Kills.
    </Subtitle>
    <QuestDivider />
    <QuestDivider />
    <Cate>
      Joined Quests
    </Cate>
    {
      joinedQuestWithDate.length > 0 ? <QuestWithDate> 
      {
        joinedQuestWithDate.map(item => {
          return <div style={{marginBottom:24}}>
            <Date>
              {moment(item.date).format("MMMM D")}
            </Date>
            <QuestDivider />
            <QuestContainer>
            {item.quests.map(item => {
               return <QuestCard quest={item} joined={true} />
            })}
            </QuestContainer>
          </div>
        } )
      }
     </QuestWithDate>: <QuestWithDate>
      <Subtitle>
  No Joined Quests Yet!
  </Subtitle>
      </QuestWithDate>
    }
    <QuestDivider />
    <Cate>
      More Quests
    </Cate>
    {
      moreQuestsWithDate.length > 0 ? <QuestWithDate> 
      {
        moreQuestsWithDate.map(item => {
          return <div style={{marginBottom:24}}>
            <Date>
              {moment(item.date).format("MMMM D")}
            </Date>
            <QuestDivider />
            <QuestContainer>
            {item.quests.map(item => {
               return <QuestCard quest={item} joined={false} />
            })}
            </QuestContainer>
          </div>
        } )
      }
     </QuestWithDate>: <>
      <Subtitle>
  No Joined Quests Yet!
  </Subtitle>
      </>
    }

  </Content>
}

const Content = styled.div`
width: 1180px;
margin:0px auto 48px;
@media (min-width: 1480px) {
  width: 1280px;
}
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 60px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:16px;
`;

const Subtitle = styled.div`
text-align: left;
font: normal normal 600 32px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const QuestDivider = styled.div`
height:0px;
width:100%;
border-bottom:2px solid #707070;
margin:18px 0px;
`;

const Cate = styled.div`
text-align: left;
font: normal normal 600 32px SF PRO, serif;
letter-spacing: 0px;
color:#F72375;
width:1120px;
margin:0px auto 36px;
`;

const QuestContainer = styled.div`
display:grid;
grid-template-columns: repeat(3, 343px);
justify-content:space-between;
grid-row-gap:24px;
width:1120px;
margin:0 auto;
`;

const QuestWithDate = styled.div`
width:1120px;
margin:0px auto 36px;
`;

const Date = styled.div`
text-align: left;
font: normal normal bold 24px/29px SF PRO, serif;
letter-spacing: 0px;
color: #BFC6CD;
text-transform: uppercase;
`;

export default withRouter(UpcomingQuests);
