import React, {  useState, useMemo} from "react";
import styled from "styled-components";
import { calcProLeagueReward } from "../../util/helper";
import ProLeagueRanking from "./proLeagueRanking";

const EndStatus = ({quest, joined, isPromoed, companyInfo, productInfo}) => {

  const killsSort = (a, b) => {
    const killsA = (a.currentKills && a.startKills) ? a.currentKills - a.startKills : 0;
    const killsB = (b.currentKills && b.startKills) ? b.currentKills - b.startKills : 0;
    if(killsA < killsB){
      return 1;
    }else {
      return -1;
    }
  }

  const killsSortWithResult = (a, b) => {
    const killsA = (a.finalKills && a.startKills) ? a.finalKills - a.startKills : 0;
    const killsB = (b.finalKills && b.startKills) ? b.finalKills - b.startKills : 0;
    const coinsWonA = a.coinsWon;
    const coinsWonB = b.coinsWon;
    if((killsA < killsB) || (coinsWonA < coinsWonB) || (a.tier !== -1 && b.tier !==-1 && a.tier > b.tier)  ){
      return 1;
    }else {
      return -1;
    }
  }


  const rewards = useMemo(() => {
    return calcProLeagueReward(quest);
  }, [quest])

  const list = useMemo(() => {
    const players = quest.players;
    if(quest.resultGenerated){
      return players.sort(killsSortWithResult)
    }else {
      return players.sort(killsSort);
    }
  }, [quest.players, quest.resultGenerated])


  return <Container>
     <ProLeagueRanking quest={quest} sortedPlayers={list} joined={joined} rewards={rewards} ended={true}/>
  </Container>
}

const Container = styled.div`
margin-top:36px;
`;

export default EndStatus;