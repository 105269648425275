import React, {useMemo, useState, useCallback, useEffect} from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PrivateNavBar from "../nav/privateNavBar";
import PublicNavBar from "../nav/publicNavBar";
import { useSelector } from "react-redux";
import moment from "moment";
import Sidebar from "./sidebar";
import GetMultiplier from "./getMultiplier";
import { loadStripe } from "@stripe/stripe-js";
import {Elements} from '@stripe/react-stripe-js';
import { Spin, Skeleton } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MultiplierStatus from "./multiplierStatus";
import { Mixpanel } from '../util/mixpanel';
import Modal from "react-modal";

const Multiplier = ({history}) => {
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);
  const [showSuccess, setShowSuccess] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const successSub = useCallback(() => {
    setShowSuccess(true);
    setModalOpen(true);
  },[]);

  const getRedirect = () => {
    setModalOpen(false);
    setTimeout(() => {
      history.push("/exchange")
  },3000)
  }
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 40, color: "#F72375" }} spin />
  );

  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: "613px",
      height:'auto',
      padding:'24px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "24px",
      zIndex: "10000",
    },
  };

  const memberStatus = useMemo(() => {
    if(loggedIn && profile){
      if(profile.multiplier){
        const multiplier = profile.multiplier;
        // user has active multiplier subscription
        if(multiplier.renewAt && moment(multiplier.renewAt).isAfter(moment()) && !multiplier.requestCancel){
          return 1;
        }
        if(multiplier.renewAt && moment(multiplier.renewAt).isAfter(moment()) && multiplier.requestCancel){
          return 2;
        }
        if(multiplier.renewAt && !moment(multiplier.renewAt).isAfter(moment())){
          return 3;
        }
      }
    }
    return 0;
  },[profile, loggedIn]);

  useEffect(() => {
    Mixpanel.track("View Get Multiplier Page", {
      loggedIn
    })
  },[loggedIn])


  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY);


  const SubDetail = <>
      <IntroBox>
                <LeftItem>
                  <LeftItemImg src="../assets/Gamepad.png"/>
                  <LeftItemContent>
                    <LeftItemTitle>
                    Get Paid Per Kill
                    </LeftItemTitle>
                    <LeftItemText>
                    With the Multiplier, Get One Coin for Every Two Kills you get in Fortnite 24/7. Supported game modes are Build & No Build Solos, Duos, Trios & Squads.
                    </LeftItemText>
                  </LeftItemContent>
                  </LeftItem>
                  <LeftItem>
                  <LeftItemImg src="../assets/coin.png"/>
                  <LeftItemContent>
                    <LeftItemTitle>
                    Unlimited Cash Out
                    </LeftItemTitle>
                    <LeftItemText>
                    Exchange as many Coins as you want for $USD with PayPal when you get the Multiplier!
                    </LeftItemText>
                  </LeftItemContent>
                  </LeftItem>
                  <LeftItem>
                  <LeftItemImg src="../assets/chart.png"/>
                  <LeftItemContent>
                    <LeftItemTitle>
                    Unlimited Quest Entry
                    </LeftItemTitle>
                    <LeftItemText>
                    Enter as many of our Quests (Fortnite Kill Races) as you want, and we’ll pay for your entry! Win hundred competing in Quests!
                    </LeftItemText>
                  </LeftItemContent>
                  </LeftItem>
            </IntroBox>
  </>

  return  <Container>
  {loggedIn ? <PrivateNavBar isDarkMode={true}/> : <PublicNavBar isDarkMode={true}/>}
  <Content>
            <Left>
              <Sidebar option="multiplier" />
              </Left>
              <Right>
                {
                  showSuccess && <RightContainer>
                    <div>
                    <Title>
                    Get Multiplier
                  </Title>
                  <SuccessTitle>
                   THANK YOU, AND WELCOME TO THE CLUB
                   </SuccessTitle>
                   <StepTwo>
                   You’re being redirected to the quests page
                    </StepTwo>
                    <MiddleLoading>
                <Spin
                  indicator={antIcon}
                  style={{ textAlign: "left", marginBottom: 32 }}
                />
                <LoadingText>Loading</LoadingText>
                <Skeleton active={true} />
              </MiddleLoading>
                    </div>
                    <CoinsImg src="../assets/coins_bg.png" />
                  </RightContainer>
                }
                {
                  !showSuccess && memberStatus === 0 &&<Elements stripe={stripePromise}>
                   <GetMultiplier subDetail={SubDetail} successSub={successSub}/>
                </Elements>
                }
                {
                  !showSuccess && memberStatus !== 0 && <Elements stripe={stripePromise}>
                    <MultiplierStatus subDetail={SubDetail} memberStatus={memberStatus}

                    />
               </Elements>
                }

      <Modal isOpen={modalOpen}
          style={customStyles}
          onRequestClose={getRedirect}
          ariaHideApp={false}>
            <ModalTitle>
            Warning!
            </ModalTitle>
            <ModalText style={{marginBottom:24}}>
            You <b>MUST</b> do this so we can track your kills
            </ModalText>
            <ModalText>
            Open up your Fortnite settings in game 
            </ModalText>
            <ModalText>
            Head over to the last section called “Account and Privacy”
            </ModalText>
            <ModalText>
            Scroll down to “Gameplay Privacy”
            </ModalText>
            <ModalText>
            Turn on the “Show on Career Leaderboard” option
            </ModalText>
            <ContinueButton onClick={getRedirect}>
            Continue
      </ContinueButton>
          </Modal>
 </Right>
              </Content>
  </Container>
}

const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  background: #000000 0% 0% no-repeat padding-box;
  position: absolute;
  min-height: calc(100vh);
`;

const Content = styled.div`
  width:100%;
  display:flex;
`;

const Left = styled.div`
width:190px;
`;

const Right = styled.div`
width:calc(100vw - 190px);
padding-left:120px;
padding-top:108px;
margin-bottom:300px;
@media (min-width: 1480px) {
  padding-left:180px;
}
`;


const IntroBox = styled.div`
height: auto;
display:flex;
flex-direction:column;
`;



const LeftItem = styled.div`
display:flex;
letter-spacing: 0px;
margin-bottom:18px;
color: #FFFFFF;

`;

const LeftItemImg = styled.img`
width:36px;
height:36px;
margin-right:16px;
margin-top:4px;
`;

const LeftItemContent = styled.div`
display:flex;
flex-direction:column;
width:500px;
`;

const LeftItemTitle = styled.div`
font: normal normal bold 18px/21px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:4px;
`;

const LeftItemText = styled.div`
font: normal normal normal 18px/21px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const Title = styled.div`
  text-align: left;
  font: normal normal bold 38px SF PRO, serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-bottom:36px;
`;

const StepTwo = styled.div`
font: normal normal 600 16px SF PRO, serif;
letter-spacing: 0px;
color: #484848;
margin-bottom:16px;
`;

const SuccessTitle = styled.div`
font: normal normal bold 18px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
text-transform: uppercase;
margin-bottom:16px;
`;


const MiddleLoading = styled.div`
  width: 528px;
  display: flex;
  flex-direction: column;
  margin-top:24px;
  position: relative;
`;

const LoadingText = styled.span`
  display: block;
  font: normal normal bold 24px/28px SF PRO, serif;
  letter-spacing: 0px;
  color: #bfbfbf;
  margin-bottom: 32px;
`;

const RightContainer = styled.div`
display:flex;
justify-content:space-between;
padding-right:10%;
align-items:start;
`;

const CoinsImg = styled.img`
width:335px;
`;

// modal

const ModalTitle = styled.div`
text-align: left;
font: normal normal bold 24px/20px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:12px;
`;

const ModalText = styled.div`
text-align: left;
font: normal normal normal 20px/28px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const ContinueButton = styled.div`
width: 100%;
height: 49px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 12px;
text-align: center;
font: normal normal 600 16px/49px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
cursor:pointer;
margin-top:32px;
`;




export default withRouter(Multiplier);