import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
import styled from 'styled-components';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Firebase from "../../util/firebase";
import PrivateNavBar from "../../nav/privateNavBar";
import PublicNavBar from "../../nav/publicNavBar";
import { useSelector } from "react-redux";
import Loading from "../../util/loading";
import { LeftCircleOutlined  } from "@ant-design/icons";
import QuestCard from './questCard';
import { List} from "antd";


const TournamentRound = ({match, history}) => {
  const [round, setRound] = useState(null);
  const [quests, setQuests] = useState([]);

  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  useEffect(() => {
    let roundListener = null;
    let questsListener = null;
    const fetchRound = (roundId) => {
      roundListener = Firebase.firestore().collection("tournament_round").doc(roundId).onSnapshot((doc) => {
        const obj = {...doc.data(), roundId};
        setRound(obj);
     })
    }
    const fetchQuests = (roundId) => {
      questsListener = Firebase.firestore().collection('tournament_quest').where("roundId", "==", roundId).onSnapshot(querySnapshot => {
        let result = []
          querySnapshot.docs.forEach(doc => {
          const obj = {
            ...doc.data(),
            questId: doc.id
          }
          result.push(obj);
        });
       setQuests(result)
      });
    }
    const roundId = match.params.id;
    fetchRound(roundId);
    fetchQuests(roundId);
    return () => {
      if(roundListener){
        roundListener()
      }
      if(questsListener){
        questsListener()
      }
    }
  }, [match]);


  const ranking = useMemo(() => {
    if(round){
      if(round.teamsUp){
        const {questsPlayed, killDatas} = round;
        const map = new Map();
          killDatas.forEach((item) => {
            const teamIndex = item.teamIndex;
            if(map.has(teamIndex)){
              const curr = map.get(teamIndex);
              curr.push(item);
            }else {
              const arr = [item];
              map.set(teamIndex, arr);
            }
          })
        if(questsPlayed === 0){
          let result = [...map].map(([index, players]) => {
            return {
                teamIndex:index,
                players
              }
          });
          result = result.sort((a,b) => a.teamIndex - b.teamIndex);
          return result;
        }else {
          let result = [...map].map(([index, players]) => {
            const totalKills = players.reduce((acc, curr) => {
              return acc + (curr?.currentKills??0)
            }, 0)
            return {
                teamIndex:index,
                players,
                totalKills
              }
          });
          result = result.sort((a,b) => b.totalKills - a.totalKills);
          return result;
        }
      }else {
        const {questsPlayed, killDatas} = round;
        if(questsPlayed === 0){
          return killDatas;
        }else {
          return killDatas.sort((a, b) => b.currentKills - a.currentKills)
        }
      }
    }else {
      return []
    }
    
  }, [round]);

  const nextQuest = useMemo(() => {
    const upcomingQuests = quests.filter(item => item.status === 1);
    upcomingQuests.sort((a,b) => {
      if(moment(a.startTime).isBefore(moment(b.startTime))){
        return -1;
      }else {
        return 1;
      }
    });
    return upcomingQuests[0]?? null;
  }, [quests]);

  const isSelected = useMemo(() => {
    if(round && (round.selectedPlayers??[]).includes(userId)){
      return true;
    }
    return false;
  }, [round, userId]);

  const myRanking = useMemo(() => {
    if(isSelected){
      if(round.teamsUp){
        const index = ranking.findIndex((item) => item.players.some(user => user.userId === userId));
        return index + 1;
      }else {
        const index = ranking.findIndex((item) => item.userId === userId);
        return index + 1;
      }
    }else {
      return null;
    }
  }, [ranking, round, userId, isSelected]);

  const myTeam = useMemo(() => {
    if(isSelected){
      const {killDatas} = round;
      const myKills = killDatas.find(item => item.userId === userId);
      if(myKills && myKills.teamIndex){
        return killDatas.filter(item => item.teamIndex === myKills.teamIndex)
      }
    }
    return [];
  }, [isSelected, round, userId]);

  const renderRankingWithTeam = (item, index) => {
    const {teamIndex, players, totalKills} = item;
    const playersName = players.reduce((acc, curr, index) => {
      if(index === 0){
        return acc + curr.userName
      }else {
        return acc + curr.userName + " & "
      }
    }, '')
    return <PlayerContainer>
        <PlayerItem style={{width:'25%'}}>
          {index + 1}
        </PlayerItem>
        <PlayerItem style={{width:'50%'}}>
        {playersName}
        </PlayerItem>
        <PlayerItem style={{width:'25%'}}>
          {totalKills}
          </PlayerItem>
    </PlayerContainer>
  }

  const renderRankingWithoutTeam = (item, index) => {
    const {userName,currentKills} = item;
    
    return <PlayerContainer>
        <PlayerItem style={{width:'25%'}}>
          {index + 1}
        </PlayerItem>
        <PlayerItem style={{width:'50%'}}>
        {userName}
        </PlayerItem>
        <PlayerItem style={{width:'25%'}}>
          {currentKills}
          </PlayerItem>
    </PlayerContainer>
  }

  const renderNameWithTeam = (item, index) => {
    const {teamIndex, players} = item;
    const playersName = players.reduce((acc, curr, index) => {
      if(index === 0){
        return acc + curr.userName
      }else {
        return acc + curr.userName + " & "
      }
    }, '')
    return <PlayerContainer>
        <PlayerItem style={{width:'25%'}}>
          {index + 1}
        </PlayerItem>
        <PlayerItem style={{width:'50%'}}>
        {playersName}
        </PlayerItem>
        <PlayerItem style={{width:'25%'}}>
           -
          </PlayerItem>
    </PlayerContainer>
  }

  const renderNameWithoutTeam = (item, index) => {
    const {userName,} = item;
    
    return <PlayerContainer>
        <PlayerItem style={{width:'25%'}}>
          {index + 1}
        </PlayerItem>
        <PlayerItem style={{width:'50%'}}>
        {userName}
        </PlayerItem>
        <PlayerItem style={{width:'25%'}}>
          -
          </PlayerItem>
    </PlayerContainer>
  }

  return <Container>
      {
      loggedIn ? <PrivateNavBar activeTab="tournament" isDarkMode={true}/>:<PublicNavBar activeTab="tournament" isDarkMode={true}/>
    }
   <Content>
     {
      round ? <>
       <Title>
       <LeftCircleOutlined onClick={() => {
        history.push(`/tournament/${round.tournamentId}`)
       }}/> {round.title}
       </Title>
       <div style={{width:"100%", display:'flex', justifyContent:'space-between'}}>
          <QuestsContainer>
            {
              quests.map((item) => {
                return <QuestCard quest={item} />
              })
            }
          </QuestsContainer>
       <div style={{width:480, display:'flex', flexDirection:'column'}}>
          {
            isSelected && <div style={{width:"100%", display:'flex', alignItem:'center'}}>
              <RankBox >
                <BoxContent style={{fontSize:32}}>
                  {myRanking ? myRanking : "-"}
                </BoxContent>
                <BoxContent>
                  {round.teamsUp? 'Team Rank':" My Rank"}
                </BoxContent>
                </RankBox>
                {
                  nextQuest && <RankBox style={{width:350, marginLeft:'auto'}}>
                     <BoxContent style={{fontSize:32}}>
                  {moment(nextQuest.startTime).format("LLL")}
                    </BoxContent>
                    <BoxContent>
                     Next Quest
                    </BoxContent>
                    </RankBox>
                }
              </div>
          }
          {
            round.teamsUp && myTeam && myTeam.length > 0 && <div style={{margin:'24px 0px'}}>
             <BoxContent style={{fontSize:24}}>
                Team
             </BoxContent>
             {
              myTeam.map((item) => {
                return <MemberContainer>
                  <div>
                    {item.userName}
                  </div>
                  <div>
                    {item.currentKills} Kills
                  </div>
                </MemberContainer>
              })
             }
            </div>
          }
              <BoxContent style={{fontSize:24}}>
                {
                  round.questPlayed > 0 ? "LeaderBoard" : "Players Joined"
                }
             </BoxContent>
       
       {round.questPlayed > 0 ? <>
        <Header>
            <HeaderItem style={{ width: "25%" }}>Rank</HeaderItem>
            <HeaderItem style={{ width: "50%" }}>{round.teamsUp? 'Team Members':'UserName'}</HeaderItem>
            <HeaderItem style={{ width: "25%" }}>
             Kills
            </HeaderItem>
          </Header>
          {
            round.teamsUp ? <List
            dataSource={ranking}
            renderItem={(item, index) => (
              <List.Item key={index} >
                {renderRankingWithTeam(item, index)}
              </List.Item>
            )}
          /> : <List
          dataSource={ranking}
          renderItem={(item, index) => (
            <List.Item key={index} >
              {renderRankingWithoutTeam(item, index)}
            </List.Item>
          )}
        /> 
          }
       </> : <>
       <Header>
            <HeaderItem style={{ width: "25%" }}></HeaderItem>
            <HeaderItem style={{ width: "50%" }}>{round.teamsUp? 'Team Members':'UserName'}</HeaderItem>
            <HeaderItem style={{ width: "25%" }}>
             Kills
            </HeaderItem>
          </Header>
          {
            round.teamsUp ? <List
            dataSource={ranking}
            renderItem={(item, index) => (
              <List.Item key={index} >
                {renderNameWithTeam(item, index)}
              </List.Item>
            )}
          /> : <List
          dataSource={ranking}
          renderItem={(item, index) => (
            <List.Item key={index} >
              {renderNameWithoutTeam(item, index)}
            </List.Item>
          )}
        /> 
          }
       </>}
       </div>
       </div>
      </> : <Loading />
     }
   </Content>
  </Container>

}

const Container = styled.div`
  width: 100%;
  background:#000000 0% 0% no-repeat padding-box;
  position: relative;
  min-height:calc(100vh);
  padding-top:108px;
  font-family:SF PRO, serif;
  .ant-list-item {
    border-bottom:none;
  }
`

const Content = styled.div`
width: 1220px;
margin:0px auto 48px;
@media (min-width: 1480px) {
  width: 1320px;
}
`;

const Title = styled.div`
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom:24px;
color: #FFF;
`;

const QuestsContainer = styled.div`
display:grid;
grid-template-columns: repeat(2, 343px);
grid-row-gap:24px;
grid-column-gap:24px;
`;

const RankBox = styled.div`
width: 104px;
height: 87px;
border-radius: 10px;
background: #1B1B1B;
padding:0px 12px;
`;

const BoxContent = styled.div`
color: #FFF;
font-size: 16px;
font-style: normal;
font-weight: 700;
`;

const MemberContainer = styled.div`
display:flex;
justify-content:space-between;
border-bottom:1px solid #2E2F30;
font-size: 16px;
font-style: normal;
font-weight: 500;
padding:16px 0px;
color:#F9F9F9;
`;

const Header = styled.div`
  display: flex;
  justify-content:start;
  margin:12px 0px;
`;

const HeaderItem = styled.div`
text-align: left;
color: #A5A5A5;
font-size: 16px;
font-style: normal;
font-weight: 510;
line-height: normal;
`;

const PlayerContainer = styled.div`
display:flex;
justify-content:space-between;
border-bottom:1px solid #2E2F30;
font-size: 16px;
font-style: normal;
font-weight: 500;
padding:4px 0px;
color:#F9F9F9;
width:100%;
`;


const PlayerItem = styled.div`
font-size: 16px;
font-style: normal;
font-weight: 500;
color:#F9F9F9;
`;

export default withRouter(TournamentRound)