import React, {  useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import {  CloseOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";

const StatsModal = ({modalOpen, userDetail, closeModal, acceptModes, questFinalized, history}) => {
  const customStyles = {
    content: {
      top: "198px",
      margin: "0 auto",
      bottom: "auto",
      width: "525px",
      height:'auto',
      padding:'16px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "24px",
      zIndex: "10000",
    },
  };


  const {finalKillsDetail, startKillsDetail, userName, currentKillsDetail, userId} = userDetail;

  const getKills = (style) => {
    if(acceptModes.includes(style)){
      if(!questFinalized){
         return (currentKillsDetail[style]??0) - (startKillsDetail[style]??0);
      }else {
         return (finalKillsDetail[style]??0) - (startKillsDetail[style]??0);
      }
    }else {
      return "Not Accepted"
    }
  }


  return <Modal
  isOpen={modalOpen}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
>
<Top>
  <Name onClick={() => {
   history.push(`/profile/${userId}`)
  }}>
  {userName}
  </Name>
  <CloseOutlined style={{fontSize:18, color:"#FFFFFF"}} onClick={() => {
               closeModal();
             }}/>
</Top>
<Title>
  Stats
</Title>
<StatsBox>
  <SingleStats>
   <div />
   <SmallText>
      Kills
   </SmallText>
   <StyleText>
      Build Solos
   </StyleText>
   <Kills>
      {getKills("defaultsolo")}
   </Kills>
   <StyleText>
      No Build Solos
   </StyleText>
   <Kills>
        {getKills("nobuildbr_solo")}
   </Kills>
   <StyleText>
      Arena Solos
   </StyleText>
   <Kills>
      {getKills("showdownalt_solo")}
   </Kills>
  </SingleStats>
  <SingleStats>
   <div />
   <SmallText>
      Kills
   </SmallText>
   <StyleText>
      Squads
   </StyleText>
   <Kills>
      {getKills("defaultsquad")}
   </Kills>
   <StyleText>
   No Build Squads
   </StyleText>
   <Kills>
        {getKills("nobuildbr_squad")}
   </Kills>
   <StyleText>
      Arena Squads
   </StyleText>
   <Kills>
      {getKills("showdownalt_squads")}
   </Kills>
  </SingleStats>
  <SingleStats>
   <div />
   <SmallText>
      Kills
   </SmallText>
   <StyleText>
      Build Duos
   </StyleText>
   <Kills>
      {getKills("defaultduo")}
   </Kills>
   <StyleText>
      No Build Duos
   </StyleText>
   <Kills>
       {getKills("nobuildbr_duo")}
   </Kills>
   <StyleText>
      Arena Duos
   </StyleText>
   <Kills>
      {getKills("showdownalt_duos")}
   </Kills>
  </SingleStats>
  <SingleStats>
   <div />
   <SmallText>
      Kills
   </SmallText>
   <StyleText>
      Build Trios
   </StyleText>
   <Kills>
      {getKills("trios")}
   </Kills>
   <StyleText>
      No Build Trios
   </StyleText>
   <Kills>
        {getKills("nobuildbr_trio")}
   </Kills>
   <StyleText>
      Arena Duos
   </StyleText>
   <Kills>
      {getKills("showdownalt_trios")}
   </Kills>
  </SingleStats>
</StatsBox>

</Modal>

}


const Top = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:16px;
`;

const Name = styled.div`
text-align: left;
font: normal normal 600 16px SF PRO, serif;
letter-spacing: 0px;
color: #D1D1D1;
cursor:pointer;
`;

const Title = styled.div`
text-align: left;
font: normal normal 600 16px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
margin-bottom:16px;
`;

const StatsBox = styled.div`
display:grid;
grid-template-columns: repeat(2, 48%);
grid-row-gap:24px;
grid-column-gap:4%;
`;

const SingleStats = styled.div`
display:grid;
grid-template-columns: 110px 100px;
grid-row-gap:8px;
justify-content:space-between;
`;

const SmallText = styled.div`
text-align: left;
font: normal normal 12px SF PRO, serif;
letter-spacing: 0px;
color: #B7B7B7;
text-transform: uppercase;
`;

const StyleText = styled.div`
text-align: left;
font: normal normal 14px SF PRO, serif;
letter-spacing: 0px;
color: #B7B7B7;
`;


const Kills = styled.div`
text-align: left;
font: normal normal 14px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
`;

export default withRouter(StatsModal);