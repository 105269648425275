import styled from "styled-components";
import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {message} from 'antd';

const CurrentGameOrder = ({game, myOrders, history}) => {
  const code = useMemo(() => {
    if(game && myOrders){
      const gameId = game?.gameId;
      const order = myOrders.find(item => item.gameId === gameId);
      if(order){
        return order.code
      }
    }
    return null;
  }, [game, myOrders]);
  return <Container>
    <div style={{display:'flex', width:'944px'}}>
      <img src={game?.gameDescription?.bannerUrl} alt="bannerUrl"  style={{
        width:371,
        marginRight:24
      }}/>
      <div style={{display:'flex', flexDirection:'column'}}>
        <Text>
        Congratulations on your purchase of
        </Text>
        <Title>
        {game?.gameName}
        </Title>
        <Steam>
          STEAM KEY
        </Steam>
        <CopyToClipboard
                  text={code}
                  onCopy={() =>
                    message.success("Code is copied")
                  }
                >
                          <KeyBox>
          {code} <img src="/assets/copy.svg" alt="back" style={{marginLeft:12, cursor:'pointer'}}/>
        </KeyBox>
         </CopyToClipboard>
      </div>
    </div>
    <div style={{width:344}}>
       <Text style={{marginBottom:16}}>
       Your game is saved to your orders and the key can be accessed at anytime. Click next to continue viewing our deals on games!
       </Text>
       <ViewButton onClick={() => {
        history.push("/myAccount/orders")
       }}>
            View Orders
       </ViewButton>
    </div>
  </Container>
}

const Container = styled.div`
margin-top:24px;
display:flex;
justify-content:space-between;
width:100%;
`;

const Text = styled.div`
color: #FFF;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
margin-bottom:12px;
`;

const Title = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
`;

const Steam = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin-top:auto;
margin-bottom:12px;
`;

const KeyBox = styled.div`
width: auto;
height: 44px;
flex-shrink: 0;
padding:0 12px;

border-radius: 10px;
background: #000;

color: #FFF;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 44px;
text-align:center;
`;

const ViewButton = styled.div`
display: flex;
width: 344px;
padding: 16px 10px 16px 16px;
justify-content: center;
align-items: center;
gap: 16px;

border-radius: 8px;
border:1px solid #F72375;

color: #F72375;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;

cursor:pointer;
`;



export default withRouter(CurrentGameOrder);